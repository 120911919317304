import React from "react"
import moment from "moment"
import {useModel} from "hooks"
import {ActivityLogType, StudentActivityLog} from "types/student-activity-log"
import {AutomatedWorkflowActionType, ProcessWorkflowNodeType} from "types/workflow"
import {ContactStrategyNodeType} from "types/contact-strategy"
import {Icon} from "components"
import {Col, Row} from "antd"
import styles from "./ActivityLogDetail.module.css"

const ActivityLogDetail: React.FC<{item: StudentActivityLog}> = ({item}) => {
    const model = useModel()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const {data} = item

    const renderAutomatedWorkflowDetail = () => {
        switch (data?.action?.type as AutomatedWorkflowActionType) {
            case AutomatedWorkflowActionType.NewStatusChange:
                return <div>Status: {data?.status?.name || "-"}</div>
            case AutomatedWorkflowActionType.CalendarEvent:
                return (
                    <>
                        <div>Event: {data?.event?.name || "-"}</div>
                        <div>Type: {data?.event?.json_data?.eventType || "-"}</div>
                        <div>Date: {moment(data?.event?.start_at_timezone).format(userDateTimeFormat)}</div>
                    </>
                )
            case AutomatedWorkflowActionType.Message:
                return (
                    <>
                        {!data?.payload?.options?.email?.from?.name ? (
                            <div className={styles.errorContainer}>
                                <Icon icon="WARNING_TRIANGLE_FILL" className={styles.errorIcon} />
                                The sender is not defined
                            </div>
                        ) : (
                            <div>From: {data?.payload?.options?.email?.from?.name}</div>
                        )}
                        {!!data?.payload?.options?.email?.subject && (
                            <div>Subject: {data?.payload?.options?.email?.subject}</div>
                        )}
                        <div className="mt-8" dangerouslySetInnerHTML={{__html: data?.payload?.options?.email?.html}} />
                    </>
                )
            case AutomatedWorkflowActionType.Task:
                return !data?.task ? (
                    <>
                        <div className={styles.errorContainer}>
                            <Icon icon="WARNING_TRIANGLE_FILL" className={styles.errorIcon} />
                            Task was not created successfully.
                        </div>
                        <code className={styles.errorCode}>{JSON.stringify(data?.action)}</code>
                    </>
                ) : (
                    <>
                        <div>Code: {data?.task?.code || "-"}</div>
                        <div>Task: {data?.task?.name || "-"}</div>
                        <div>Description: {data?.task?.description || "-"}</div>
                    </>
                )
            case AutomatedWorkflowActionType.AddCheckList:
                return !data?.checklist ? (
                    <>
                        <div className={styles.errorContainer}>
                            <Icon icon="WARNING_TRIANGLE_FILL" className={styles.errorIcon} />
                            Checklist was not added successfully.
                        </div>
                        <code className={styles.errorCode}>{JSON.stringify(data?.action)}</code>
                    </>
                ) : (
                    <>
                        <div>Code: {data?.checklist?.code || "-"}</div>
                        <div>Title: {data?.checklist?.title || "-"}</div>
                    </>
                )
            case AutomatedWorkflowActionType.AddStudentActivity:
                return (
                    <>
                        <div>Activity: {data?.activity?.activity?.name || "-"}</div>
                        <div>Description: {data?.activity?.description || "-"}</div>
                    </>
                )
        }
        return null
    }

    const renderProcessWorkflowDetail = () => {
        switch (data?.node?.data?.type as ProcessWorkflowNodeType) {
            case ProcessWorkflowNodeType.ActionCreateActivity:
                return (
                    <>
                        <div>Activity: {data?.activity?.activity?.name || "-"}</div>
                        <div>Description: {data?.activity?.description || "-"}</div>
                    </>
                )
            case ProcessWorkflowNodeType.ActionSendMessage:
            case ProcessWorkflowNodeType.ActionSendEmail:
                return (
                    <>
                        {!!data?.from && <div>From: {data.from}</div>}
                        {!!data?.subject && <div>Subject: {data.subject}</div>}
                        <div className="mt-8" dangerouslySetInnerHTML={{__html: data?.contentHtml}} />
                    </>
                )
            case ProcessWorkflowNodeType.ActionCreateCalendarEvent:
                return (
                    <>
                        <div>Event: {data?.event?.name || "-"}</div>
                        <div>Type: {data?.event?.json_data?.eventType || "-"}</div>
                        <div>Date: {moment(data?.event?.start_at_timezone).format(userDateTimeFormat)}</div>
                    </>
                )
        }
        return null
    }

    const renderContactStrategyDetail = () => {
        switch (data?.node?.data?.type as ContactStrategyNodeType) {
            case ContactStrategyNodeType.ActionCreateActivity:
                return (
                    <>
                        <div>Activity: {data?.activity?.activity?.name || "-"}</div>
                        <div>Description: {data?.activity?.description || "-"}</div>
                    </>
                )
            case ContactStrategyNodeType.ActionSendMessage:
            case ContactStrategyNodeType.ActionSendEmail:
                return (
                    <>
                        {!!data?.from && <div>From: {data.from}</div>}
                        {!!data?.subject && <div>Subject: {data.subject}</div>}
                        <div className="mt-8" dangerouslySetInnerHTML={{__html: data?.contentHtml}} />
                    </>
                )
            case ContactStrategyNodeType.ActionChangeStudentStatus:
                return <div>Status: {data?.status?.name || "-"}</div>
            case ContactStrategyNodeType.ActionSetDue:
                return <div>Date: {moment(item.createdAt).format(userDateTimeFormat)}</div>
        }
        return null
    }

    return (
        <div className="px-8 py-8">
            {item.type === ActivityLogType.AutomatedWorkflowActionTriggered
                ? renderAutomatedWorkflowDetail()
                : item.type === ActivityLogType.ProcessWorkflowActionTriggered
                ? renderProcessWorkflowDetail()
                : item.type === ActivityLogType.ContactStrategyActionTriggered
                ? renderContactStrategyDetail()
                : null}
        </div>
    )
}

export default ActivityLogDetail
