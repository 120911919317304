import {Timestamps} from "./common"
import {Auth} from "./auth"
import {AutomatedWorkflowActionType, ProcessWorkflowNodeType} from "types/workflow"
import {ContactStrategyNodeType} from "types/contact-strategy"

export enum ActivityLogType {
    AuthLogIn = "auth_log_in",
    AuthLogOut = "auth_log_out",
    AuthLogInFromWeb = "auth_log_in_from_web",
    AuthLogOutFromWeb = "auth_log_out_from_web",
    AuthLogInFromMobile = "auth_log_in_from_mobile",
    AuthLogOutFromMobile = "auth_log_out_from_mobile",
    MessageSent = "message_sent",
    MessageReceived = "message_received",
    SmsReceived = "sms_received",
    EmailReceived = "email_received",
    PushReceived = "push_received",
    TaskAssigned = "task_assigned",
    TaskSubmitted = "task_submitted",
    TaskApproved = "task_approved",
    TaskRejected = "task_rejected",
    TaskCompleted = "task_completed",
    TaskReopen = "task_reopen",
    CalendarEventInvited = "calendar_event_invited",
    CalendarEventCreated = "calendar_event_created",
    CalendarEventUpdated = "calendar_event_updated",
    ActivityCreated = "activity_created",
    ActivityUpdated = "activity_updated",
    ActivityAssigned = "activity_assigned",
    DocumentStaffAdded = "document_staff_added",
    DocumentStudentAdded = "document_student_added",
    DocumentStaffDeleted = "document_staff_deleted",
    DocumentStudentDeleted = "document_student_deleted",
    DocumentStaffEdited = "document_staff_edited",
    DocumentStudentEdited = "document_student_edited",
    ContactStrategyTriggered = "contact_strategy_trigged",
    ContactStrategyActionTriggered = "contact_strategy_action_trigged",
    AutomatedWorkflowTriggered = "automated_workflow_trigged",
    AutomatedWorkflowActionTriggered = "automated_workflow_action_trigged",
    ProcessWorkflowActionTriggered = "process_workflow_action_trigged",
    AdmissionStudentCompletedApplication = "admission_student_completed_application",
    AdmissionStudentBeAccepted = "admission_student_be_accepted",
    FinAidReceivedISIR = "fin_aid_received_isir",
    FinAidOfferCreated = "fin_aid_offer_created",
    FinAidOfferUpdated = "fin_aid_offer_updated",
    AcademicStudentEnrolled = "academic_student_enrolled",
    LessonUpdated = "lesson_updated",
    AcademicRequestDropCourse = "academic_request_drop_course",
    AcademicRequestRemoveCourse = "academic_request_remove_course",
    AcademicRemoveCourse = "academic_remove_course",
    AcademicRetakeCourse = "academic_retake_course",
    AcademicReviewRequest = "academic_review_request",
    AcademicRequestRegisterCourse = "academic_request_register_course",
    AcademicVerifyActivity = "academic_verify_activity",
    AcademicRegisterCourse = "academic_register_course",
    CourseGradeUpdated = "course_grade_updated",
    FinalGradeUpdated = "final_grade_updated",
    AcademicDropCourse = "academic_drop_course",
    AddMakeupStudent = "add_makeup_student",
    DailyAttendanceUpdated = "daily_attendance_updated",
    AttendanceUpdated = "attendance_updated",
    AttendanceLessonCheckedIn = "attendance_lesson_checked_in",
    AttendanceLessonCheckedOut = "attendance_lesson_checked_out",
    AttendanceLessonAttendanceCheck = "attendance_lesson_attendance_check",
    AttendanceDailyCheckedIn = "attendance_daily_checked_in",
    AttendanceDailyCheckedOut = "attendance_daily_checked_out",
    AttendanceDailyStartedBreak = "attendance_daily_started_break",
    AttendanceDailyEndedBreak = "attendance_daily_ended_break",
    ProfileStatusUpdated = "profile_status_updated",
    ProfileUpdated = "profile_updated",
    ProfileDeleted = "profile_deleted",
    ProfileCreated = "profile_created",
    ProfileDueCleared = "profile_due_cleared",
    ProfileDueSet = "profile_due_set",
    UserAdvisorChanged = "user_advisor_changed",
    UserRolledOnTerm = "user_rolled_on_term",
    UserChangedProgram = "user_changed_program",
    UserNewProfile = "user_new_profile",
    UserUpdated = "user_updated",
    AzureEmailCreated = "azure_email_created",
    AzureWelcomeEmailSent = "azure_welcome_email_sent",
    AzureEmailSynced = "azure_email_synced",
    Generic = "generic"
}

export type StudentActivityLog = Timestamps & {
    id: string
    time: string | Date
    userId: number
    user?: Auth.UserShortDetails // populate
    profileId?: number
    profile?: Auth.Profile // populate

    type: ActivityLogType
    targetId?: number
    data?: any
}

export const getWorkflowActionText = (item: StudentActivityLog): string => {
    if (item.type === ActivityLogType.AutomatedWorkflowActionTriggered) {
        switch (item.data?.action?.type as AutomatedWorkflowActionType) {
            case AutomatedWorkflowActionType.NewStatusChange:
                return "Status Change"
            case AutomatedWorkflowActionType.CalendarEvent:
                return "Calendar Event"
            case AutomatedWorkflowActionType.Message:
                return "Message"
            case AutomatedWorkflowActionType.Task:
                return "Task"
            case AutomatedWorkflowActionType.AddCheckList:
                return "Checklist"
            case AutomatedWorkflowActionType.AddStudentActivity:
                return "Student Activity"
        }
    }
    if (item.type === ActivityLogType.ProcessWorkflowActionTriggered) {
        switch (item.data?.node?.data?.type as ProcessWorkflowNodeType) {
            case ProcessWorkflowNodeType.ActionCreateActivity:
                return "Student Activity"
            case ProcessWorkflowNodeType.ActionSendEmail:
                return "Email"
            case ProcessWorkflowNodeType.ActionSendMessage:
                return "Message"
            case ProcessWorkflowNodeType.ActionCreateCalendarEvent:
                return "Calendar Event"
        }
    }
    if (item.type === ActivityLogType.ContactStrategyActionTriggered) {
        switch (item.data?.node?.data?.type as ContactStrategyNodeType) {
            case ContactStrategyNodeType.ActionCreateActivity:
                return "Activity"
            case ContactStrategyNodeType.ActionSendEmail:
                return "Email"
            case ContactStrategyNodeType.ActionSendMessage:
                return "Message"
            case ContactStrategyNodeType.ActionChangeStudentStatus:
                return "Status Change"
            case ContactStrategyNodeType.ActionSetDue:
                return "Set Due"
        }
    }
    return ""
}
