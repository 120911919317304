import React, {useMemo, useState} from "react"
import cx from "classnames"
import moment from "moment"
import {useHistory} from "react-router-dom"
import {Icon, IconType} from "components/Icon"
import {Notification} from "types/notification"
import {getFullName, handleError} from "helpers"
import {useModel} from "hooks"
import styles from "./Notifications.module.css"
import {Auth} from "types/auth"
import {Communication} from "types/communication"
import {chatServiceV2, reportsService} from "services"
import {BaseLoading} from "components/Loading"
import {BaseDepartmentId, DepartmentStudentDetailsURLMapping} from "types/departments"
import {AcademicPlans} from "types/academicPlans"

type Props = {
    notificationMessage: Communication.NotificationItem
    type: string
    isDetailView?: boolean
    pagination?: Notification.Pagination
    markedReadNotification?: () => void
}

export function NotificationItem(props: Props) {
    const {notificationMessage, type, isDetailView, markedReadNotification} = props
    const [read, setRead] = useState(!!notificationMessage.isRead)
    const history = useHistory()
    const model = useModel()
    const [isDownloading, setIsDownloading] = useState(false)

    const icon = useMemo<IconType>(() => {
        switch (type) {
            case Communication.NotificationType.Tasks:
                return "TASK_FILL"
            case Communication.NotificationType.Calendar:
                return "CALENDAR_FILL"
            case Communication.NotificationType.Admissions:
                return "ADMISSIONS_FILL"
            case Communication.NotificationType.CareerServices:
                return "WORK"
            case Communication.NotificationType.ContactStrategy:
            case Communication.NotificationType.Workflow:
                return "WORKFLOW"
            case Communication.NotificationType.Reports:
                return "FILE_TEXT"
            case Communication.NotificationType.DirectSMS:
                return "SMS"
            case Communication.NotificationType.Activities:
            default:
                return "ACADEMIC"
        }
    }, [type])

    const markReadNotification = async () => {
        try {
            await chatServiceV2.markReadNotification({
                filter: {
                    notificationId: notificationMessage.notificationId
                }
            })
            markedReadNotification()
        } catch (error) {
            handleError(error)
        }
    }

    const removeNotification = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation()
        event.preventDefault()
        setRead(true)
        markReadNotification()
    }

    const renderRemoveIcon = () => {
        if (isDetailView) {
            return (
                <div className={styles.notificationItem__removeWrap} onClick={removeNotification}>
                    <Icon icon="CLOSE" color="#939393" className={styles.notificationItem__removeIcon} />
                </div>
            )
        }
        return null
    }

    const goDetail = (url: string) => {
        if (isDetailView) {
            markReadNotification()
            history.push(url)
        }
    }

    const downloadReport = async () => {
        try {
            setIsDownloading(true)
            const {reportId} = notificationMessage.jsonData as Communication.NotificationReport
            const {downloadUrl} = await reportsService.downloadReport({reportId})
            window.open(downloadUrl, "_blank")
        } catch (error) {
            handleError(error)
        } finally {
            setIsDownloading(false)
        }
    }

    const onClickAcademicNotification = () => {
        if (notificationMessage?.jsonData?.sapStatus) {
            const studentProfileId = notificationMessage.jsonData.studentProfileId
            if (studentProfileId) {
                goDetail(`/academics/registrar/student/${studentProfileId}?department=3&saptype=alerts&tab=sap`)
            }
        } else if (
            notificationMessage?.jsonData?.type === AcademicPlans.MakeupStudentType.MakeupAttendance ||
            notificationMessage?.jsonData?.type === AcademicPlans.MakeupStudentType.OtherReasons
        ) {
            const lesson = notificationMessage.jsonData.lesson
            const course = notificationMessage.jsonData.course
            if (lesson && course) {
                goDetail(
                    `/academics/instructional/term/${lesson.termId}/course/${course.courseId}/schedule/${lesson.scheduleId}/lessons/${lesson.id}?tab=1`
                )
            }
        } else {
            const userId = notificationMessage?.jsonData?.studentProfile?.userId
            if (userId) {
                goDetail(`/users/${userId}?tab=profile`)
            }
        }
    }

    const renderTime = () => {
        const {createdAt} = notificationMessage
        const data = moment(createdAt).startOf("date").isSame(moment().startOf("date"))
            ? moment(createdAt).format(model.user.timeFormat === Auth.TimeFormat.Time_12 ? "hh:mm A" : "HH:mm")
            : moment(createdAt).format("MMM DD")
        return <div className={styles.timeInfo}>{data}</div>
    }

    const renderTask = () => {
        const {taskId, taskCode, taskCreatedBy, taskType, rawMessage} =
            notificationMessage.jsonData as Communication.NotificationTask
        return (
            <div
                className={cx(styles.notificationItem, {
                    [styles.notificationItem__clickable]: isDetailView
                })}
                key={`${type}_${notificationMessage.notificationId}`}
                onClick={() => goDetail(`/tasks/detail?id=${taskId}&type=${taskType}`)}>
                {renderRemoveIcon()}
                <div className={styles.notificationItem__header}>
                    <div className={styles.notificationItem_iconWrap}>
                        <Icon icon={icon} className={styles.notificationItem_icon} />
                    </div>
                    <span className={styles.notificationItem__code}>{taskCode || taskId}</span>
                    <span className={styles.notificationItem__name}>{taskCreatedBy}</span>
                </div>
                <div
                    className={styles.notificationItem__desc}
                    dangerouslySetInnerHTML={{
                        __html: rawMessage
                    }}
                />
                {renderTime()}
            </div>
        )
    }

    const renderActivity = () => {
        const {activity, student, description, studentActivityId, profileId} =
            notificationMessage.jsonData as Communication.NotificationActivity

        const departmentId = activity.departmentId
        const url: string = `${DepartmentStudentDetailsURLMapping[departmentId]}/student/${profileId}`
        let queryParams: string = ""

        switch (departmentId) {
            case BaseDepartmentId.Admissions:
                queryParams = `?subtab=activities&tab=admissions`
                break
            case BaseDepartmentId.FinancialAid:
                queryParams = `?subtab=activities&tab=financial-aid`
                break
            case BaseDepartmentId.Academics:
                queryParams = `?department=${departmentId}&tab=workspace`
                break
            case BaseDepartmentId.StudentServices:
                queryParams = `?department=${departmentId}&tab=home`
                break
            case BaseDepartmentId.CareerServices:
                queryParams = `?tab=workspace&type=activities`
                break
        }

        return (
            <div
                className={cx(styles.notificationItem, {
                    [styles.notificationItem__clickable]: isDetailView
                })}
                key={`${type}_${notificationMessage.notificationId}`}
                onClick={() => goDetail(`/${url}${queryParams}`)}>
                {renderRemoveIcon()}
                <div className={styles.notificationItem__header}>
                    <div className={styles.notificationItem_iconWrap}>
                        <Icon icon={icon} className={styles.notificationItem_icon} />
                    </div>
                    {!!activity?.name && <span className={styles.notificationItem__code}>{activity.name}</span>}
                    <span className={styles.notificationItem__name}>{student?.fullName}</span>
                </div>
                <div
                    className={styles.notificationItem__desc}
                    dangerouslySetInnerHTML={{
                        __html: description
                    }}
                />
                {renderTime()}
            </div>
        )
    }

    const renderCalendar = () => {
        const {
            calendarObjectId,
            calendarEventType: type,
            rawMessage
        } = notificationMessage.jsonData as Communication.NotificationEvent
        return (
            <div
                className={cx(styles.notificationItem, {
                    [styles.notificationItem__clickable]: isDetailView && calendarObjectId
                })}
                key={`${type}_${notificationMessage.notificationId}`}
                onClick={() => (calendarObjectId ? goDetail(`/calendar-event/detail?id=${calendarObjectId}`) : null)}>
                {renderRemoveIcon()}
                <div className={styles.notificationItem__header}>
                    <div className={styles.notificationItem_iconWrap}>
                        <Icon icon={icon} className={styles.notificationItem_icon} />
                    </div>
                    {calendarObjectId && <span className={styles.notificationItem__code}>{calendarObjectId}</span>}
                    <span className={styles.notificationItem__name}>{type}</span>
                </div>
                <div
                    className={styles.notificationItem__desc}
                    dangerouslySetInnerHTML={{
                        __html: rawMessage
                    }}
                />
                {renderTime()}
            </div>
        )
    }

    const renderAdmissions = () => {
        const data = notificationMessage.jsonData
        return (
            <div
                className={cx(styles.notificationItem, {
                    [styles.notificationItem__clickable]: isDetailView && data.userId
                })}
                key={`${type}_${notificationMessage.notificationId}`}
                onClick={() => (data.userId ? goDetail(`/users/${data.userId}?tab=documents`) : null)}>
                {renderRemoveIcon()}
                <div className={styles.notificationItem__header}>
                    <div className={styles.notificationItem_iconWrap}>
                        <Icon icon={icon} className={styles.notificationItem_icon} />
                    </div>
                    <span className={styles.notificationItem__code}>{data?.userId}</span>
                    <span className={styles.notificationItem__name}>{getFullName(data)}</span>
                </div>
                <div
                    className={styles.notificationItem__desc}
                    dangerouslySetInnerHTML={{
                        __html: notificationMessage.content
                    }}
                />
                {renderTime()}
            </div>
        )
    }

    const renderCareerServices = () => {
        return (
            <div className={styles.notificationItem} key={`${type}_${notificationMessage.notificationId}`}>
                {renderRemoveIcon()}
                <div className={styles.notificationItem__header}>
                    <div className={styles.notificationItem_iconWrap}>
                        <Icon icon={icon} className={styles.notificationItem_icon} />
                    </div>
                </div>
                <div
                    className={styles.notificationItem__desc}
                    dangerouslySetInnerHTML={{
                        __html: notificationMessage.payload
                    }}
                />
                {renderTime()}
            </div>
        )
    }

    const renderContactStrategy = () => {
        const {contactStrategy, contentHtml} = notificationMessage.jsonData

        return (
            <div
                className={cx(styles.notificationItem, {
                    [styles.notificationItem__clickable]: isDetailView && contactStrategy.contactStrategyId
                })}
                key={`${type}_${notificationMessage.notificationId}`}
                onClick={() =>
                    contactStrategy.contactStrategyId
                        ? goDetail(`/settings/contact-strategy/${contactStrategy.contactStrategyId}`)
                        : null
                }>
                {renderRemoveIcon()}
                <div className={styles.notificationItem__header}>
                    <div className={styles.notificationItem_iconWrap}>
                        <Icon icon={icon} className={styles.notificationItem_icon} />
                    </div>
                    <span className={styles.notificationItem__code}>{contactStrategy?.code}</span>
                    <span className={styles.notificationItem__name}>{contactStrategy?.name}</span>
                </div>
                <div
                    className={styles.notificationItem__desc}
                    dangerouslySetInnerHTML={{
                        __html: contentHtml
                    }}
                />
                {renderTime()}
            </div>
        )
    }

    const renderWorkflow = () => {
        const {workflow, contentHtml} = notificationMessage.jsonData

        return (
            <div
                className={cx(styles.notificationItem, {
                    [styles.notificationItem__clickable]: isDetailView && workflow.workflowId
                })}
                key={`${type}_${notificationMessage.notificationId}`}
                onClick={() => (workflow.workflowId ? goDetail(`/settings/workflows/${workflow.workflowId}`) : null)}>
                {renderRemoveIcon()}
                <div className={styles.notificationItem__header}>
                    <div className={styles.notificationItem_iconWrap}>
                        <Icon icon={icon} className={styles.notificationItem_icon} />
                    </div>
                    <span className={styles.notificationItem__code}>{workflow?.code}</span>
                    <span className={styles.notificationItem__name}>{workflow?.name}</span>
                </div>
                <div
                    className={styles.notificationItem__desc}
                    dangerouslySetInnerHTML={{
                        __html: contentHtml
                    }}
                />
                {renderTime()}
            </div>
        )
    }

    const renderChannel = () => {
        return (
            <div
                className={cx(styles.notificationItem, {
                    [styles.notificationItem__clickable]: isDetailView && notificationMessage.roomId
                })}
                key={`${type}_${notificationMessage.notificationId}`}
                onClick={() => goDetail(`communication?roomId=${notificationMessage.roomId}}`)}>
                {renderRemoveIcon()}
                <div className={styles.notificationItem__header}>
                    <div className={styles.notificationItem_iconWrap}>
                        <Icon icon={icon} className={styles.notificationItem_icon} />
                    </div>
                </div>
                <div
                    className={styles.notificationItem__desc}
                    dangerouslySetInnerHTML={{
                        __html: notificationMessage.payload
                    }}
                />
                {renderTime()}
            </div>
        )
    }

    const renderDirectSMS = () => {
        return (
            <div
                className={cx(styles.notificationItem, {
                    [styles.notificationItem__clickable]: isDetailView && notificationMessage.jsonData.smsChatId
                })}
                key={`${type}_${notificationMessage.notificationId}`}
                onClick={() =>
                    goDetail(`communication?roomId=sms&smsChatId=${notificationMessage.jsonData.smsChatId}`)
                }>
                {renderRemoveIcon()}
                <div className={styles.notificationItem__header}>
                    <div className={styles.notificationItem_iconWrap}>
                        <Icon icon={icon} className={styles.notificationItem_icon} />
                    </div>
                    <span className={styles.notificationItem__name}>Direct SMS</span>
                </div>
                <div
                    className={styles.notificationItem__desc}
                    dangerouslySetInnerHTML={{
                        __html: notificationMessage.content
                    }}
                />
                {renderTime()}
            </div>
        )
    }

    const renderReports = () => {
        return (
            <div className={styles.notificationItem} key={`${type}_${notificationMessage.notificationId}`}>
                {renderRemoveIcon()}
                <div className={styles.notificationItem__header}>
                    <div className={styles.notificationItem_iconWrap}>
                        <Icon icon={icon} className={styles.notificationItem_icon} />
                    </div>
                </div>
                <div
                    className={styles.notificationItem__desc}
                    dangerouslySetInnerHTML={{
                        __html: notificationMessage.content
                    }}
                />
                <div className={styles.downloadReportWrap}>
                    {renderTime()}
                    <span className={styles.downloadReport} onClick={downloadReport}>
                        Download
                        <BaseLoading isShow={isDownloading} />
                    </span>
                </div>
            </div>
        )
    }

    const renderAcademics = () => {
        return (
            <div className={styles.notificationItem} key={`${type}_${notificationMessage.notificationId}`}>
                {renderRemoveIcon()}
                <div className={styles.notificationItem__header}>
                    <div className={styles.notificationItem_iconWrap}>
                        <Icon icon={icon} className={styles.notificationItem_icon} />
                    </div>
                </div>
                <div
                    className={styles.notificationItem__desc}
                    dangerouslySetInnerHTML={{
                        __html: notificationMessage.content
                    }}
                />
                {isDetailView ? (
                    <div className={styles.downloadReportWrap}>
                        <span className={styles.downloadReport} onClick={onClickAcademicNotification}>
                            View
                        </span>
                    </div>
                ) : null}
            </div>
        )
    }

    if (read) {
        return null
    }

    if (type.includes(Communication.NotificationType.Department)) {
        return renderChannel()
    }

    switch (type) {
        case Communication.NotificationType.Tasks:
            return renderTask()
        case Communication.NotificationType.Activities:
            return renderActivity()
        case Communication.NotificationType.Calendar:
            return renderCalendar()
        case Communication.NotificationType.Admissions:
            return renderAdmissions()
        case Communication.NotificationType.CareerServices:
            return renderCareerServices()
        case Communication.NotificationType.ContactStrategy:
            return renderContactStrategy()
        case Communication.NotificationType.Workflow:
            return renderWorkflow()
        case Communication.NotificationType.Channel:
            return renderChannel()
        case Communication.NotificationType.Private:
            return renderChannel()
        case Communication.NotificationType.Reports:
            return renderReports()
        case Communication.NotificationType.Academics:
            return renderAcademics()
        case Communication.NotificationType.DirectSMS:
            return renderDirectSMS()
        default:
            return null
    }
}
