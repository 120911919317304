/* eslint-disable react-hooks/exhaustive-deps */
import {Spin} from "antd"
import {BaseButton, Button} from "components"
import {StudentPaymentContext} from "context/StudentPaymentContext"
import {toastError} from "helpers"
import {useContext, useEffect, useState} from "react"
import {studentPaymentService} from "services"

import {GeneralPayment} from "types/student-account/ledger-accounts"
import {PaymentPlanItem} from "types/student-account/payment-plan"
import {StudentCharge} from "types/student-account/student-ledger"
import {PaymentProvider} from "types/student-account/student-payment"

declare global {
    interface Window {
        Square: any
    }
}

type Props = {
    providerConfig
    studentId
    paymentAmount
    currency
    isCheckingOut: boolean
    selectedPayment?: GeneralPayment
    customNote: string
    setCurrencyMinAmount: Function
    payerFullName?: string
    selectedCharges?: StudentCharge[]
    selectedPaymentItems?: PaymentPlanItem[]
    academicYear?: number
}

const SQUARE_ENVIRONMENT_CONSTANTS = {
    production: {
        scriptSrc: "https://web.squarecdn.com/v1/square.js"
    },
    sandbox: {
        scriptSrc: "https://sandbox.web.squarecdn.com/v1/square.js"
    }
}

export const SquarePayment = (props: Props) => {
    const {
        providerConfig,
        studentId,
        paymentAmount,
        currency,
        selectedPayment,
        customNote,
        setCurrencyMinAmount,
        payerFullName,
        selectedPaymentItems,
        academicYear
    } = props

    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false)
    const [card, setCard] = useState<any>()
    const [paymentsInstance, setPaymentsInstance] = useState<any>()

    const {setPaymentStatus, setPaymentError} = useContext(StudentPaymentContext)

    useEffect(() => {
        if (providerConfig.provider === PaymentProvider.Stripe) {
            setCurrencyMinAmount(providerConfig.minimumChargeAmountInCentsForCurrency)
        }
    }, [providerConfig])

    useEffect(() => {
        const existingScript = document.querySelector(
            `script[src="${SQUARE_ENVIRONMENT_CONSTANTS[providerConfig.paymentEnvironment].scriptSrc}"]`
        )

        if (existingScript) {
            setScriptLoaded(true)
            return
        }

        const script = document.createElement("script")
        script.src = SQUARE_ENVIRONMENT_CONSTANTS[providerConfig.paymentEnvironment].scriptSrc
        script.async = true

        script.onload = () => {
            setScriptLoaded(true)
        }

        document.body.appendChild(script)
    }, [])

    useEffect(() => {
        if (!window.Square) {
            return
        }

        if (!providerConfig.applicationId || !providerConfig.locationId) {
            return
        }

        tokenizeCard()
    }, [scriptLoaded])

    const tokenizeCard = async () => {
        try {
            if (!document.getElementById("card-container")) {
                return
            }

            if (card) {
                return
            }
            const payments = new window.Square.payments(providerConfig.applicationId, providerConfig.locationId)
            const newCard = await payments.card()

            if (document.querySelector("#card-container > iframe")) {
                return
            }

            await newCard.attach("#card-container")

            setCard(newCard)
            setPaymentsInstance(payments)
        } catch (error) {}
    }

    const handlePayment = async () => {
        if (!card) return

        try {
            setPaymentStatus("processing")
            const result = await card.tokenize()
            if (result.status === "OK") {
                const data = await studentPaymentService.createSquarePayment({
                    amountInCents: Math.floor(paymentAmount * 100),
                    currency,
                    studentProfileId: studentId,
                    generalPaymentId: selectedPayment?.generalPaymentId,
                    payerFullName,
                    academicYear,
                    paymentPlanItems: selectedPaymentItems?.length
                        ? selectedPaymentItems
                              .filter((item) => item.paymentAmount)
                              .map((item) => ({
                                  paymentPlanId: item.paymentPlanId,
                                  paymentPlanItemId: item.paymentPlanItemId,
                                  amount: paymentAmount
                              }))
                        : undefined,
                    notes: `${selectedPayment?.name || ""}${customNote ? `: ${customNote}` : ""}`,
                    sourceId: result.token
                })
                setPaymentStatus("confirmed")
            } else {
                setPaymentStatus("failed")
            }
        } catch (error: any) {
            toastError(error?.response?.data?.message ?? "Internal Server Error")
            setPaymentStatus("failed")
        }
    }
    return (
        <>
            {!scriptLoaded && (
                <div>
                    <Spin />
                </div>
            )}

            <div>
                <div id="card-container"></div>
                <div>
                    <BaseButton title={`Pay $${paymentAmount}`} isActive onClick={handlePayment} />
                </div>
            </div>
        </>
    )
}
