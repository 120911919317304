/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from "react"
import {keyBy, get, head, sortBy} from "lodash"
import Draggable from "react-draggable"
import {ResizableBox} from "react-resizable"
import cx from "classnames"
import randomColor from "randomcolor"
import {Icon} from "components/Icon"
import {DigitalDocumentFieldSetUp} from "types/tasks"
import {CheckboxForm, InitialForm, SignatureForm, TextForm, SelectForm, GradeForm} from "../FieldBodyForm"
import styles from "./FieldAction.module.css"

export function FieldAction(props) {
    const {
        field,
        fieldBodyContent,
        activeField,
        boundaryWidth,
        boundaryHeight,
        definedUsers,
        participants,
        combinedColor,
        scaleInfo,
        totalPage,
        previousFieldInfo,
        errorFieldIds,
        isEditable,
        onDragStart,
        onDragStop,
        onResize,
        getIconByType,
        onClickDelete,
        setActiveField,
        onChangeField,
        onAddNewFieldValue,
        onRemoveFieldValue,
        onClickField,
        onChangeFieldValue,
        onChangeCombinedColor,
        onDuplicateField
    } = props
    const [isDragging, setIsDragging] = useState(false)
    const [isShowContent, setIsShowContent] = useState(false)
    const [color, setColor] = useState("")
    const contentRef = useRef()
    const icon = getIconByType(field.type)
    const isActive = activeField && field.id === activeField.id

    useEffect(() => {
        getFieldActionColor()
    }, [field.signatureTypes, field.parentSignatureTypeId, combinedColor])

    useEffect(() => {
        if (activeField && field.id === activeField.id) {
            setIsShowContent(true)
        }
    }, [])

    useEffect(() => {
        const handleClickOutside = (e) => {
            const currentRefEl = contentRef.current || null
            if (isShowContent && contentRef.current && !currentRefEl.contains(e.target)) {
                setIsShowContent(false)
                setActiveField(null)
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [isShowContent])

    const getFieldActionColor = () => {
        const signatureTypeIds = (field.signatureTypes || []).map((item) => item.id)
        let color = ""
        if (signatureTypeIds.length > 1) {
            const sortedSignerIds = sortBy(signatureTypeIds).join("")
            if (!combinedColor[sortedSignerIds]) {
                color = randomColor({luminosity: "bright", format: "rgba", alpha: 0.5})
                onChangeCombinedColor(sortedSignerIds, color)
            } else {
                color = combinedColor[sortedSignerIds]
            }
        } else {
            const firstSignatureTypeId = head(signatureTypeIds)
            const signatureTypeId = firstSignatureTypeId || field.parentSignatureTypeId
            const definedUsersKeyById = keyBy(definedUsers, "id")
            color = get(definedUsersKeyById[signatureTypeId], "color", "#1e90ff")
        }
        setColor(color)
    }

    const onClick = () => {
        onClickField(field)
        if (isEditable) {
            setActiveField(isShowContent ? null : field)
            setIsShowContent(!isShowContent)
        } else {
            setActiveField(field)
            setIsShowContent(true)
        }
    }

    const dragStart = (e) => {
        onDragStart(e)
    }

    const dragStop = (event, data) => {
        setIsDragging(false)
        if (isDragging) {
            onDragStop(event, data, field)
        } else {
            onClick()
        }
    }

    const onDrag = () => {
        setIsDragging(true)
    }

    const handleDuplicateField = (field) => {
        onDuplicateField(field)
        setIsShowContent(false)
        setActiveField(null)
    }

    const renderBodyContent = () => {
        switch (field.type) {
            case DigitalDocumentFieldSetUp.SIGNATURE:
                return (
                    <SignatureForm
                        field={fieldBodyContent}
                        definedUsers={definedUsers}
                        participants={participants}
                        scaleInfo={scaleInfo}
                        totalPage={totalPage}
                        previousFieldInfo={previousFieldInfo}
                        isEditable={isEditable}
                        onClickDelete={onClickDelete}
                        onChangeField={onChangeField}
                        onAddNewFieldValue={onAddNewFieldValue}
                        onRemoveFieldValue={onRemoveFieldValue}
                        onChangeFieldValue={onChangeFieldValue}
                        onDuplicateField={handleDuplicateField}
                    />
                )
            case DigitalDocumentFieldSetUp.INITIAL:
                return (
                    <InitialForm
                        field={fieldBodyContent}
                        definedUsers={definedUsers}
                        participants={participants}
                        scaleInfo={scaleInfo}
                        totalPage={totalPage}
                        previousFieldInfo={previousFieldInfo}
                        isEditable={isEditable}
                        activeField={activeField}
                        onClickDelete={onClickDelete}
                        onChangeField={onChangeField}
                        onAddNewFieldValue={onAddNewFieldValue}
                        onRemoveFieldValue={onRemoveFieldValue}
                        onChangeFieldValue={onChangeFieldValue}
                        onDuplicateField={handleDuplicateField}
                    />
                )
            case DigitalDocumentFieldSetUp.TEXT:
                return (
                    <TextForm
                        field={fieldBodyContent}
                        definedUsers={definedUsers}
                        participants={participants}
                        scaleInfo={scaleInfo}
                        totalPage={totalPage}
                        isEditable={isEditable}
                        onClickDelete={onClickDelete}
                        onChangeField={onChangeField}
                        onChangeFieldValue={onChangeFieldValue}
                        onDuplicateField={handleDuplicateField}
                    />
                )
            case DigitalDocumentFieldSetUp.CHECKBOX:
                return (
                    <CheckboxForm
                        field={fieldBodyContent}
                        definedUsers={definedUsers}
                        participants={participants}
                        scaleInfo={scaleInfo}
                        totalPage={totalPage}
                        previousFieldInfo={previousFieldInfo}
                        isEditable={isEditable}
                        activeField={activeField}
                        onClickDelete={onClickDelete}
                        onChangeField={onChangeField}
                        onAddNewFieldValue={onAddNewFieldValue}
                        onRemoveFieldValue={onRemoveFieldValue}
                        onChangeFieldValue={onChangeFieldValue}
                        onDuplicateField={handleDuplicateField}
                    />
                )
            case DigitalDocumentFieldSetUp.SELECT:
                return (
                    <SelectForm
                        field={fieldBodyContent}
                        definedUsers={definedUsers}
                        participants={participants}
                        scaleInfo={scaleInfo}
                        totalPage={totalPage}
                        previousFieldInfo={previousFieldInfo}
                        isEditable={isEditable}
                        activeField={activeField}
                        onClickDelete={onClickDelete}
                        onChangeField={onChangeField}
                        onAddNewFieldValue={onAddNewFieldValue}
                        onRemoveFieldValue={onRemoveFieldValue}
                        onChangeFieldValue={onChangeFieldValue}
                        onDuplicateField={handleDuplicateField}
                    />
                )
            case DigitalDocumentFieldSetUp.GRADE:
                return (
                    <GradeForm
                        field={fieldBodyContent}
                        definedUsers={definedUsers}
                        participants={participants}
                        scaleInfo={scaleInfo}
                        totalPage={totalPage}
                        isEditable={isEditable}
                        onClickDelete={onClickDelete}
                        onChangeField={onChangeField}
                        onChangeFieldValue={onChangeFieldValue}
                        onDuplicateField={handleDuplicateField}
                    />
                )
            default:
                return null
        }
    }

    const renderContent = () => {
        return (
            <div
                className={cx(styles.wrap, {
                    [styles.fillOut]: isShowContent
                })}>
                <ResizableBox
                    width={field.placeInformation.width || 32}
                    height={field.placeInformation.height || 32}
                    className={styles.resizableBox}
                    minConstraints={[12, 12]}
                    maxConstraints={[1400, 400]}
                    handle={isEditable ? <span className={styles.customHandleResizable} /> : null}
                    onResize={(event, data) => onResize(event, data, field)}>
                    <div
                        className={cx(styles.fieldAction, styles.resizableItem, "handle", {
                            [styles.fieldActionSet]: !isActive,
                            [styles.errorField]: (errorFieldIds || []).includes(field.id)
                        })}
                        style={{background: color}}>
                        <div className={styles.indicatorWrap}>
                            {(field.signatureTypes || []).map((_, index) => (
                                <div className={styles.indicator} key={index}></div>
                            ))}
                        </div>
                        <Icon icon={icon} color="#000" className={styles.icon} />
                    </div>
                </ResizableBox>
                {isShowContent && <div ref={contentRef}>{renderBodyContent()}</div>}
            </div>
        )
    }

    if (!isEditable) {
        return (
            <div
                className={cx({[styles.fillOut]: isShowContent})}
                style={{
                    transform: `translate(${field.placeInformation.left}px, ${field.placeInformation.top}px)`,
                    position: "relative"
                }}
                onClick={onClick}>
                {renderContent()}
            </div>
        )
    }

    return (
        <Draggable
            bounds={{top: 0, left: 0, right: boundaryWidth, bottom: boundaryHeight}}
            handle=".handle"
            defaultPosition={{x: field.placeInformation.left || 0, y: field.placeInformation.top}}
            disabled={!isEditable}
            onStart={dragStart}
            onStop={dragStop}
            onDrag={onDrag}>
            {renderContent()}
        </Draggable>
    )
}
