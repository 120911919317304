/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {Col, Divider, Row} from "antd"
import {Checkbox, Screen} from "components/ui"
import {useLocation} from "react-router-dom"
import {BaseInput} from "components/inputs"
import {KlassDropdown} from "components/Select"
import {BaseButton, SecondaryButton} from "components/buttons"
import {PDFViewer} from "components/FileViewer"
import {FinancialAidStaffTabs} from "../../FinancialAidStaff"
import styles from "./FinAidNoticeDetail.module.css"
import {FinAidNoticeDetailTable} from "./parts"
import {handleError, handleErrorMessage} from "helpers"
import moment from "moment"
import {useTranslation} from "react-i18next"
import {noticeService} from "services"
import cx from "classnames"
import {routing} from "helpers/routing"

export enum NoticeTypes {
    ISIR = "isir",
    OTHERS = "others"
}

export const typesMaster = [
    {
        id: "isir",
        name: "ISIR"
    }
]

function FinAidNoticeDetail(props) {
    const {t} = useTranslation(["common"])

    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const noticeId: number = parseInt(query.get("id"))

    const [noticeDetails, setNoticeDetails] = useState(null)
    const [description, setDescription] = useState(null)
    const [oldDescription, setOldDescription] = useState(null)
    const [isEditNotice, setIsEditNotice] = useState<boolean>(false)

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (description !== oldDescription) {
            setIsEditNotice(true)
        } else {
            setIsEditNotice(false)
        }
    }, [description, oldDescription])

    const getParams = () => {
        const params: any = {
            filter: {
                noticeId
            },
            linkedObjects: true
        }
        return params
    }

    const getData = async () => {
        const params = getParams()
        try {
            // fetch notice details
            const result = await noticeService.getList(params)
            if (!result.data || result.data.length === 0) {
                handleErrorMessage("Notice details not found")
                return false
            }

            let noticeDetails = result.data[0]

            if (noticeDetails.type === NoticeTypes.ISIR && (process.env.REACT_APP_ISIR_WEB_BASE_URL as string)) {
                // isir
                const url = new URL(`/`, process.env.REACT_APP_ISIR_WEB_BASE_URL as string)
                url.searchParams.append("isirCode", noticeDetails.code)
                url.searchParams.append("xProfileId", props.model.profileId.toString())
                url.searchParams.append("token", props.model.authToken)
                url.searchParams.append("pdf", "")

                noticeDetails.documentUrl = url.href
            }

            setNoticeDetails(noticeDetails)
            setDescription(noticeDetails.description)
            setOldDescription(noticeDetails.description)

            // mark notice as read
            if (!noticeDetails.isRead) {
                updateData(noticeId, {isRead: true})
            }
        } catch (e) {
            handleError(e)
        }
    }

    const onClickCancel = () => {
        setDescription(oldDescription)
    }

    const onClickSave = async () => {
        try {
            await updateData(noticeId, {description: description})
            setOldDescription(description)
        } catch (e) {
            handleError(e)
        }
    }

    const saveDocumentForStudents = async (value) => {
        try {
            await updateData(noticeId, {isDocumentSavedToStudentsDocuments: value})
            setNoticeDetails({
                ...noticeDetails,
                isDocumentSavedToStudentsDocuments: value
            })
        } catch (e) {
            handleError(e)
        }
    }

    const updateData = async (noticeId, dataObject) => {
        try {
            await noticeService.edit({noticeId, ...dataObject})
        } catch (e) {
            handleError(e)
        }
    }

    return (
        <Screen
            htmlTitle="Notice"
            header={{title: "Notice", backUrl: routing.finAid.staff.index(FinancialAidStaffTabs.Notices)}}>
            <Row>
                <Col span={8}>
                    <div className={`${styles.itemBodyWrap} ${styles.itemBodyMargin}`}>
                        <p className={styles.bodyTitle}>Type</p>
                        <KlassDropdown
                            options={typesMaster}
                            value={
                                noticeDetails ? typesMaster.filter((item) => item.id === noticeDetails.type)[0] : null
                            }
                            onChange={() => {}}
                            placeholder={t("isir.type")}
                            readOnly
                        />
                    </div>
                </Col>
                <Col span={16}>
                    <div className={`${styles.itemBodyWrap}`}>
                        <p className={styles.bodyTitle}>Description</p>
                        <BaseInput
                            onChange={(newValue) => {
                                setDescription(newValue)
                            }}
                            placeholder="Description"
                            value={description}
                        />
                    </div>
                </Col>
            </Row>
            <FinAidNoticeDetailTable noticeDetails={noticeDetails} {...props} />
            {noticeDetails && (
                <>
                    {noticeDetails.type !== NoticeTypes.ISIR && (
                        <>
                            <Divider className={styles.divider} />
                            <p className={styles.documentSavedCheckContainer}>
                                <Checkbox
                                    checked={noticeDetails.isDocumentSavedToStudentsDocuments}
                                    disabled={noticeDetails.isDocumentSavedToStudentsDocuments}
                                    onChange={(value) => saveDocumentForStudents(value)}
                                />
                                <span className={styles.documentSavedCheckLabel}>Save to Student Documents</span>
                            </p>
                        </>
                    )}
                    <Divider className={styles.divider} />
                    {noticeDetails.documentUrl && <PDFViewer fileSrc={noticeDetails.documentUrl} />}
                    <p className={styles.footerInfo}>
                        Created on{" "}
                        <span className={styles.footerInfo__bold}>
                            {moment(noticeDetails.createdAt).format("DD/MM/YYYY")}
                        </span>
                        .{" "}
                        {noticeDetails.updatedByProfile && (
                            <span>
                                Last modified on{" "}
                                <span className={styles.footerInfo__bold}>
                                    {moment(noticeDetails.updatedAt).format("DD/MM/YYYY, hh:mm A")}
                                </span>{" "}
                                by
                                <span className={styles.footerInfo__bold}>
                                    {" "}
                                    {noticeDetails.updatedByProfile.fullName}
                                </span>
                            </span>
                        )}
                    </p>
                    <div className={cx(styles.buttonWrap, styles.actionBtnDiv)}>
                        <SecondaryButton
                            title={t("common:action.cancel")}
                            onClick={onClickCancel}
                            className={styles.cancelBtn}
                            disabled={!isEditNotice}
                        />
                        <BaseButton
                            title={t("common:action.save").toUpperCase()}
                            onClick={onClickSave}
                            className={styles.saveBtn}
                            disabled={!isEditNotice}
                        />
                    </div>
                </>
            )}
        </Screen>
    )
}

export default FinAidNoticeDetail
