import React, {useState, useEffect, useMemo} from "react"
import cx from "classnames"
import momentTz from "moment-timezone"
import {Modal, Row, Col} from "antd"
import moment from "moment"
import {Icon} from "components/Icon"
import {BaseInput} from "components/inputs/BaseInput"
import {BaseButton} from "components/buttons"
import {KlassDropdown} from "components/Select"
import {BaseDatePicker, BaseTimePicker} from "components/DateTimePicker"
import {MonthlyRecurringBody, WeeklyRecurringBody, DailyRecurringBody} from "components/Calendar/Common/Recurring"
import {EndTitle, EndBody} from "sections/calendar/parts/common"
import {getValuesChangeTwoObj, handleError, isInvalidValue, toastError, translate} from "helpers"
import {createRruleFromEvent, getDisabledEndHours, getDisabledEndMinutes} from "helpers/calendar"
import {CalendarRecurringType, RecurringEnd, RecurringPopupType, TargetRecurringEvent} from "types/calendar"
import {OPTIONS_TIME_SLOTS, OPTIONS_FREQUENCY as OPTIONS_FREQUENCY_ALL} from "sections/calendar/parts/common/constants"
import styles from "./EventPopup.module.css"
import {useModel, useVisible} from "hooks"
import {useTranslation} from "react-i18next"
import {isEmpty, pick} from "lodash"
import {calendarService} from "services"
import {ChooseRecurringPopup} from "components/Calendar/Common"

const OPTIONS_FREQUENCY = OPTIONS_FREQUENCY_ALL.map((opt) => ({
    value: opt.value,
    originalValue: opt.originalValue,
    label: translate(`calendar:calendar.frequency.${opt.label}`)
}))

type EventPopupProps = {
    myCalendarId: string
    isShow: boolean
    data: any
    disabledTypeEvents?: string[]
    locationsList: any[]
    onClose: () => void
    onSuccess: () => void
}

export const CalendarAvailabilityEventPopup = (props: EventPopupProps) => {
    const {t} = useTranslation(["calendar", "common"])
    const model = useModel()
    const {isShow, onClose, myCalendarId, data, locationsList, onSuccess} = props
    const userTimeFormat = model.getUserTimeFormat()
    const [errors, setErrors] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [eventData, setEventData] = useState<any>({})
    const chooseRecurringPopup = useVisible(false)

    const title = useMemo(
        () => (data.id ? t("calendar.editAvailability") : t("calendar.setAvailability")),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    )

    const isDateChanged = useMemo(
        () => moment(eventData.start_date).format("YYYY-MM-DD") !== moment(data.start_date).format("YYYY-MM-DD"),
        [eventData.start_date, data.start_date]
    )

    const isRRuleChanged = useMemo(() => {
        // TODO
        return false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventData, data.rrule])

    useEffect(() => {
        setEventData({...data})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isShow) return
        const handleKeyDown = (event) => {
            if (event.key === "Escape") onClose()
        }
        document.addEventListener("keydown", handleKeyDown)
        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow])

    const onChangeEventData = (key, value) => {
        const newErrors = {...errors}
        if (!isInvalidValue(value)) {
            delete newErrors[key]
        }
        setErrors(newErrors)
        const newData = {...eventData, [key]: value}
        if (key === "startTime" && value) {
            const isSameDate = moment(value).add(1, "hour").startOf("date").isSame(moment(value).startOf("date"))
            newData.endTime = isSameDate ? moment(value).add(1, "hour") : moment(value).endOf("date")
        }
        setEventData(newData)
    }

    const getMandatoryFields = (event) => {
        const mandatoryFields = ["startTime", "endTime", "startDate", "timeSlots", "recurring"]
        const recurring = typeof event.recurring === "string" ? event.recurring : event.recurring.originalValue
        switch (recurring) {
            case CalendarRecurringType.Yearly: {
                if (event.end?.value === RecurringEnd.AfterExecution) {
                    mandatoryFields.push("executions")
                } else if (event.end?.value === RecurringEnd.OnDate) {
                    mandatoryFields.push("endDate")
                }
                break
            }
            case CalendarRecurringType.Monthly: {
                mandatoryFields.push("every")
                if (+event.monthlyRecurring === 0) {
                    /*day of the month*/
                    mandatoryFields.push("monthDay")
                } else {
                    /*day of the week*/
                    mandatoryFields.push("monthWeek", "monthWeekDay")
                }
                break
            }
            case CalendarRecurringType.Daily: {
                mandatoryFields.push("every")
                break
            }
            case CalendarRecurringType.Weekly: {
                mandatoryFields.push("every", "days")
                break
            }
        }
        if (recurring !== CalendarRecurringType.Never) {
            mandatoryFields.push("end")
            if (event.end?.value === RecurringEnd.AfterExecution) {
                mandatoryFields.push("executions")
            } else if (event.end?.value === RecurringEnd.OnDate) {
                mandatoryFields.push("endDate")
            }
        }
        return mandatoryFields
    }

    const validate = (event) => {
        const mandatoryFields = getMandatoryFields(event)
        const errorsAux: any = {}
        mandatoryFields.forEach((field) => {
            if (isInvalidValue(event[field])) {
                errorsAux[field] = true
            }
        })
        const hasRequiredFieldError = Object.keys(errorsAux).length > 0
        if (hasRequiredFieldError) {
            setErrors(errorsAux)
            toastError(t("common:validation.fieldRequired"))
            return true
        }
        if (moment(event.startTime).isSameOrAfter(moment(event.endTime))) {
            errorsAux.startTime = true
            errorsAux.endTime = true
            setErrors(errorsAux)
            toastError(t("message.endTimeGreaterStartTime"))
            return true
        }
        return false
    }

    const handleOnSubmit = () => {
        const hasError = validate(eventData)
        if (hasError) {
            return
        }
        if (data.id) {
            if (!data.rrule) {
                updateEvent(data.id, TargetRecurringEvent.OnlyThis)
            } else {
                chooseRecurringPopup.open()
            }
        } else {
            createEvent()
        }
    }

    const getDataSubmit = () => {
        const data = {...eventData}
        const {description, startDate, startTime, endTime, timeSlots, location} = data
        const startDateTime = moment(startDate)
            .local()
            .set({
                hour: startTime.get("hour"),
                minute: startTime.get("minute"),
                second: 0
            })
        const endDateTime = moment(startDate)
            .local()
            .set({
                hour: endTime.get("hour"),
                minute: endTime.get("minute"),
                second: 0
            })
        const payloads = []
        while (endDateTime.diff(startDateTime) > 0) {
            data.startTime = startDateTime
            const rrule = createRruleFromEvent(data)
            payloads.push({
                calendar_id: myCalendarId,
                type: "appointment_slot",
                name: "N/A",
                description: description || "",
                duration: timeSlots.value * 60,
                owner_user_id: model.viewOtherUserCalendar?.id ? model.viewOtherUserCalendar?.id : +model.user.id,
                rrule,
                start_at_timezone: momentTz.tz.guess(),
                start_at_wall: moment(startDateTime).format("YYYY-MM-DD HH:mm:ss"),
                json_data: {
                    location,
                    eventType: "appointment_slot",
                    color: "var(--primary-300)"
                }
            })
            startDateTime.add(timeSlots.value, "minutes")
        }
        return payloads
    }

    const getUpdatedPayload = () => {
        const oldPayload = pick(data.originalData, ["description", "start_at_wall", "rrule", "duration", "json_data"])
        const {description, endTime, startTime, startDate, endDate, end, location} = eventData
        const rruleStarDate = eventData.id && isDateChanged ? data.originalData.start_at_wall : startDate
        const rruleStartTime = eventData.id && isDateChanged ? data.originalData.start_at_utc : startTime
        const rruleEndTime = eventData.id && isDateChanged ? data.originalData.end_at_utc : endTime
        const start_at_wall =
            eventData.id && isDateChanged
                ? data.originalData.start_at_wall
                : `${moment(startDate).format("YYYY-MM-DD")} ${moment(startTime).format("HH:mm:ss")}`
        const rrule = createRruleFromEvent({
            ...eventData,
            startDate: rruleStarDate,
            startTime: rruleStartTime,
            endTime: rruleEndTime,
            endDate: end?.value === RecurringEnd.OnDate ? endDate : null
        })
        const newPayload = {
            description,
            rrule: !!rrule ? rrule : null,
            start_at_wall,
            json_data: {
                location,
                eventType: "appointment_slot",
                color: "var(--primary-300)"
            },
            duration: moment(endTime).diff(moment(startTime), "seconds")
        }
        const payload = getValuesChangeTwoObj(oldPayload, newPayload)
        return payload
    }

    const createEvent = async () => {
        try {
            setIsSubmitting(true)
            const payloads = getDataSubmit()
            const addAvailabilityPromises = payloads.map((payload) => calendarService.addObject(payload))
            await Promise.all(addAvailabilityPromises)
            onSuccess()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const updateEvent = async (id: number, target_filter: TargetRecurringEvent) => {
        try {
            setIsSubmitting(true)
            const payload = getUpdatedPayload()
            if (!isEmpty(payload)) {
                await calendarService.updateObject(id, {
                    target_filter,
                    props_to_update: payload
                })
            }
            chooseRecurringPopup.close()
            onSuccess()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const onConfirmRecurring = (target_filter: TargetRecurringEvent) => {
        updateEvent(data.id, target_filter)
    }

    const renderEndBody = () => {
        return (
            <EndBody
                end={eventData.end}
                endDate={eventData.endDate}
                executions={eventData.executions}
                errors={errors}
                setEnd={(newValue) => onChangeEventData("end", newValue)}
                setEndDate={(newValue) => onChangeEventData("endDate", newValue)}
                setExecutions={(newValue) => onChangeEventData("executions", newValue)}
            />
        )
    }

    const renderYearlyRecurring = () => {
        return (
            <>
                <Col span={2} />
                <Col span={6}>
                    <EndTitle />
                </Col>
                <Col span={16}>{renderEndBody()}</Col>
            </>
        )
    }

    const renderMonthlyRecurring = () => {
        return (
            <>
                <Col span={8} />
                <Col span={16}>
                    <MonthlyRecurringBody eventData={eventData} errors={errors} onChangeEventData={onChangeEventData} />
                </Col>
                <Col span={2} />
                <Col span={6}>
                    <EndTitle />
                </Col>
                <Col span={16}>{renderEndBody()}</Col>
            </>
        )
    }

    const renderWeeklyRecurring = () => {
        return (
            <>
                <Col span={8} />
                <Col span={16}>
                    <WeeklyRecurringBody eventData={eventData} errors={errors} onChangeEventData={onChangeEventData} />
                </Col>
                <Col span={2} />
                <Col span={6}>
                    <EndTitle />
                </Col>
                <Col span={16}>{renderEndBody()}</Col>
            </>
        )
    }

    const renderDailyRecurring = () => {
        return (
            <>
                <Col span={8} />
                <Col span={16}>
                    <DailyRecurringBody eventData={eventData} errors={errors} onChangeEventData={onChangeEventData} />
                </Col>
                <Col span={2} />
                <Col span={6}>
                    <EndTitle />
                </Col>
                <Col span={16}>{renderEndBody()}</Col>
            </>
        )
    }

    const renderRecurring = () => {
        const originalValue = eventData.recurring?.originalValue
        switch (originalValue) {
            case CalendarRecurringType.Yearly:
                return renderYearlyRecurring()
            case CalendarRecurringType.Monthly:
                return renderMonthlyRecurring()
            case CalendarRecurringType.Weekly:
                return renderWeeklyRecurring()
            case CalendarRecurringType.Daily:
                return renderDailyRecurring()
            default:
                return null
        }
    }

    return (
        <Modal
            keyboard={false}
            closable={false}
            visible={isShow}
            onCancel={onClose}
            closeIcon={<Icon icon="CLOSE" color="#000" className={styles.closeIcon} />}
            maskClosable={false}
            footer={null}
            className="eventPopup">
            <div className={styles.eventPopupWrap}>
                <div className={styles.eventPopupHeader}>
                    <h1 className={styles.eventPopupTitle}>{title}</h1>
                </div>
                <div>
                    <Row gutter={[16, 24]} className={styles.rowItem}>
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="CALENDAR_FILL" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.startDate")}</label>
                        </Col>
                        <Col span={16}>
                            <BaseDatePicker
                                onChange={(newValue) => onChangeEventData("startDate", newValue)}
                                value={eventData.startDate ? moment(eventData.startDate) : null}
                                className={cx(styles.shadow, !!errors["startDate"] ? styles.error : "")}
                            />
                        </Col>
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="TIME_CLOCK_OUTLINE" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.time")}</label>
                        </Col>
                        <Col span={8}>
                            <BaseTimePicker
                                value={eventData.startTime}
                                placeholder="Time"
                                format={userTimeFormat}
                                onChange={(newValue) => onChangeEventData("startTime", newValue)}
                                error={errors["startTime"]}
                            />
                        </Col>
                        <Col span={8}>
                            <BaseTimePicker
                                value={eventData.endTime}
                                placeholder="Time"
                                format={userTimeFormat}
                                onChange={(newValue) => onChangeEventData("endTime", newValue)}
                                error={errors["endTime"]}
                                disabledHours={() => getDisabledEndHours(eventData.startTime)}
                                disabledMinutes={() => getDisabledEndMinutes(eventData.startTime, eventData.endTime)}
                            />
                        </Col>
                        <Col span={2}>
                            <Icon icon="PIE" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.timeSlots")}</label>
                        </Col>
                        <Col span={16}>
                            <KlassDropdown
                                onChange={(newValue) => onChangeEventData("timeSlots", newValue)}
                                options={OPTIONS_TIME_SLOTS}
                                value={eventData.timeSlots}
                                labelKey="label"
                                valueKey="value"
                                placeholder={t("calendar.timeSlots")}
                                isDisabled={!!data.id}
                                error={!!errors["timeSlots"]}
                            />
                        </Col>
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="REPEAT" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.recurring")}</label>
                        </Col>
                        <Col span={16}>
                            <div className={styles.dropDown}>
                                <KlassDropdown
                                    onChange={(newValue) => onChangeEventData("recurring", newValue)}
                                    options={OPTIONS_FREQUENCY}
                                    value={eventData.recurring}
                                    labelKey="label"
                                    valueKey="value"
                                    placeholder={t("calendar.recurring")}
                                />
                            </div>
                        </Col>
                        {renderRecurring()}
                        <Col span={2}>
                            <Icon icon="LOCATION" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.location")}</label>
                        </Col>
                        <Col span={16}>
                            <KlassDropdown
                                options={locationsList}
                                onChange={(newValue) => onChangeEventData("location", newValue)}
                                getOptionLabel={(option: any) =>
                                    `${option.name} ${option.capacity ? `(${option.capacity})` : ""}`
                                }
                                valueKey="resourceId"
                                placeholder={t("common:selectField.placeholder")}
                                value={eventData.location}
                            />
                        </Col>
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="NOTES" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.notes")}</label>
                        </Col>
                        <Col span={16}>
                            <BaseInput
                                onChange={(newValue) => onChangeEventData("description", newValue)}
                                placeholder={t("calendar.notes")}
                                value={eventData.description}
                            />
                        </Col>
                    </Row>
                    <div className={styles.actionBtn}>
                        <BaseButton
                            title={t("common:action.cancel")}
                            variant="secondary"
                            onClick={onClose}
                            disabled={isSubmitting}
                        />
                        <BaseButton title={t("common:action.save")} onClick={handleOnSubmit} loading={isSubmitting} />
                    </div>
                </div>
            </div>
            {chooseRecurringPopup.isVisible && (
                <ChooseRecurringPopup
                    isShow={chooseRecurringPopup.isVisible}
                    type={RecurringPopupType.Update}
                    loading={isSubmitting}
                    isHideThisEvent={isRRuleChanged}
                    isHideAllEvent={isDateChanged}
                    onClose={chooseRecurringPopup.close}
                    onClickSave={onConfirmRecurring}
                />
            )}
        </Modal>
    )
}
