/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import {v4 as uuid} from "uuid"
import {findIndex, get} from "lodash"
import cx from "classnames"
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd"
import {useTranslation} from "react-i18next"
import {BaseButton, SecondaryButton} from "components/buttons"
import {
    UserType,
    SelectStaffFrom,
    SelectOthersFrom,
    TaskSubtype,
    TaskType,
    ParticipantType,
    SelectStaffInstructorType
} from "types/tasks"
import {toastError} from "helpers"
import {TaskSignerItem} from "./TaskSignerItem"
import styles from "./TaskSigners.module.css"

export function TaskSigners(props) {
    const [isForm, setIsForm] = useState(false)
    const [isSelecting, setIsSelecting] = useState(false)
    const [canShowAddBtn, setCanShowAddBtn] = useState(true)
    const [canEditLibraryTask, setCanEditLibraryTask] = useState(false)
    const [isEditingParticipants, setIsEditingParticipants] = useState(false)
    const [forceDeleteKey, setForceDeleteKey] = useState(0)
    const [resetNonPrimarySignerId, setResetNonPrimarySignerId] = useState("")
    const {t} = useTranslation(["tasks", "common"])
    const {
        signers,
        subtype,
        taskId,
        onChangeData,
        onSearchChange,
        errors,
        taskType,
        type,
        isOnlyOtherSingers,
        isWorkflowAction = false,
        workflowTargetType
    } = props
    const primaryIndex = findIndex(signers, (signer: any) => signer.participantType === ParticipantType.PrimarySigner)
    const userDataOnlyIndex = findIndex(
        signers,
        (signer: any) => signer.participantType === ParticipantType.UserDataOnly
    )
    const hasStudentParticipant = signers.some((signer) => signer.userType === UserType.STUDENT)

    useEffect(() => {
        if (subtype === TaskSubtype.LIBRARY && isEditingParticipants) {
            if (taskType === TaskType.SCAN_UPLOAD) {
                const canShowInScanTask = signers.length === 0
                setCanShowAddBtn(canShowInScanTask)
            } else {
                setCanShowAddBtn(true)
            }
        } else if (type === TaskType.FROM_LIBRARY) {
            setCanShowAddBtn(false)
        } else if (taskType === TaskType.SCAN_UPLOAD) {
            const canShowInScanTask = signers.length === 0
            setCanShowAddBtn(canShowInScanTask)
        } else if (isForm || isSelecting || !!taskId || taskType === TaskType.FORM) {
            setCanShowAddBtn(false)
        } else {
            setCanShowAddBtn(true)
        }

        if (subtype === TaskSubtype.LIBRARY && !!taskId) {
            setCanEditLibraryTask(true)
        }
    }, [signers, isForm, isSelecting, taskType, type, isEditingParticipants])

    const onClickAddSigner = () => {
        setIsForm(true)
        signers.push({
            id: uuid(),
            isForm: true,
            participantType: primaryIndex === -1 ? ParticipantType.PrimarySigner : ParticipantType.NonPrimarySigner
        })
        onChangeData("signers", signers)
    }

    const onSave = (newSignerData) => {
        const newSigners = signers.map((signer) => {
            if (signer.id === newSignerData.id) {
                signer = {...signer, ...newSignerData}
                if (signer.isForm) {
                    signer.isForm = false
                    signer.isSelecting = subtype !== TaskSubtype.LIBRARY || signer.userType === UserType.STAFF
                    if (!signer.selectedType) {
                        switch (signer.userType) {
                            case UserType.STAFF:
                                signer.selectedType =
                                    subtype === TaskSubtype.LIBRARY
                                        ? SelectStaffFrom.NO_DEFAULT
                                        : SelectStaffFrom.DIRECTORY
                                break
                            case UserType.OTHERS:
                                signer.selectedType =
                                    signer.participantType === ParticipantType.PrimarySigner
                                        ? SelectOthersFrom.ROLE
                                        : SelectOthersFrom.MANUAL
                                break
                            default:
                                break
                        }
                    }
                    if (type === TaskType.CUSTOM_DD && !signer.instructorType && signer.userType === UserType.STAFF) {
                        signer.instructorType = SelectStaffInstructorType.LeadInstructor
                    }
                    setIsForm(false)
                    setIsSelecting(subtype !== TaskSubtype.LIBRARY)
                } else if (signer.isSelecting) {
                    signer.isSelecting = false
                    setIsSelecting(false)
                }
            }
            return signer
        })
        onChangeData("signers", newSigners)
    }

    const onSaveOtherSelecting = (signerId) => {
        const newSigners = signers.map((signer) => {
            if (signer.id === signerId) {
                if (signer.isSelecting) {
                    signer.isSelecting = false
                    setIsSelecting(false)
                }
            }
            return signer
        })
        onChangeData("signers", newSigners)
    }

    const onCancel = (signerData) => {
        let newSigners = []
        if (!signerData.label || !signerData.userType) {
            // remove current signer
            newSigners = signers.filter((signer) => signer.id !== signerData.id)
        } else {
            // reset to old signer
            newSigners = signers.map((signer) => {
                if (signer.id === signerData.id) {
                    signer.isForm = false
                    signer.isSelecting = false
                }
                return signer
            })
        }
        onChangeData("signers", newSigners)
        setIsForm(false)
    }

    const onCancelSelecting = (signerData) => {
        let newSigners = []
        newSigners = signers.map((signer) => {
            if (signer.id === signerData.id) {
                signer.isForm = false
                signer.isSelecting = false
                switch (signer.userType) {
                    case UserType.STUDENT:
                        signer.students = []
                        break
                    case UserType.STAFF:
                        signer.staffs = []
                        break
                    case UserType.OTHERS:
                        signer.others = []
                        break
                    default:
                        break
                }
            }
            return signer
        })
        onChangeData("signers", newSigners)
        setIsSelecting(false)
    }

    const checkHasUserInSigner = (signer) => {
        let hasUsers = true
        switch (type) {
            case UserType.STUDENT:
                hasUsers = !!(signer.students || []).length
                break
            case UserType.STAFF:
                hasUsers = !!(signer.staffs || []).length
                break
            case UserType.OTHERS:
                hasUsers = !!(signer.others || []).length
                break
            default:
                break
        }
        return hasUsers
    }

    const onClickEditSigner = (signerData) => {
        const primarySigner = signers.find((signer) => signer.participantType === ParticipantType.PrimarySigner)
        const userDataOnlySigner = signers.find((signer) => signer.participantType === ParticipantType.UserDataOnly)
        if (type === TaskType.FROM_LIBRARY && signerData.participantType === ParticipantType.NonPrimarySigner) {
            let hasUsersInPrimarySigner = checkHasUserInSigner(primarySigner)
            let hasUsersInUserDataOnly = checkHasUserInSigner(userDataOnlySigner)
            if (!hasUsersInPrimarySigner && !hasUsersInUserDataOnly) {
                toastError(t("validation.addAtLeastUserIntoPrimarySigner"))
                return
            }
        }
        const newSigners = signers.map((signer) => {
            if (signer.id === signerData.id) {
                if (type === TaskType.FROM_LIBRARY) {
                    signer.isForm = false
                    signer.isSelecting = true
                    signer.useWorkflowAllTargets = false
                    signer.useWorkflowSingleTarget = false
                    setIsSelecting(true)
                } else {
                    signer.isForm = true
                    signer.isSelecting = false
                    setIsForm(true)
                }
                if (!signer.selectedType) {
                    switch (signer.userType) {
                        case UserType.STAFF:
                            signer.selectedType =
                                subtype === TaskSubtype.LIBRARY ? SelectStaffFrom.NO_DEFAULT : SelectStaffFrom.DIRECTORY
                            break
                        case UserType.OTHERS:
                            signer.selectedType =
                                signer.participantType === ParticipantType.PrimarySigner
                                    ? SelectOthersFrom.ROLE
                                    : SelectOthersFrom.MANUAL
                            break
                        default:
                            break
                    }
                }
                if (type === TaskType.CUSTOM_DD && !signer.instructorType && signer.userType === UserType.STAFF) {
                    signer.instructorType = SelectStaffInstructorType.LeadInstructor
                }
            }
            return signer
        })
        onChangeData("signers", newSigners)
    }

    const onClickDeleteSigner = (signerData) => {
        const newSigners = signers.filter((signer) => signer.id !== signerData.id)
        onChangeData("signers", newSigners)
    }

    const onDeleteMultiUserSigner = (newSigner) => {
        if (newSigner.participantType === ParticipantType.PrimarySigner) {
            const newForceDeleteKey = forceDeleteKey + 1
            setForceDeleteKey(newForceDeleteKey)
            const newSigners = signers.map((signer) => {
                if (signer.id === newSigner.id) {
                    return newSigner
                }
                switch (signer.userType) {
                    case UserType.STUDENT:
                        signer.students = []
                        break
                    case UserType.STAFF:
                        signer.staffs = []
                        break
                    case UserType.OTHERS:
                        signer.others = []
                        break
                    default:
                        break
                }
                return signer
            })
            onChangeData("signers", newSigners)
        } else {
            setResetNonPrimarySignerId(newSigner.id)
            const newSigners = signers.map((signer) => {
                if (signer.id === newSigner.id) {
                    switch (signer.userType) {
                        case UserType.STUDENT:
                            signer.students = []
                            break
                        case UserType.STAFF:
                            signer.staffs = []
                            break
                        case UserType.OTHERS:
                            signer.others = []
                            break
                        default:
                            break
                    }
                    return signer
                }
                return signer
            })
            onChangeData("signers", newSigners)
        }
    }

    const onUpdateSignerItem = (signerId, newSigner) => {
        const newSigners = signers.map((signer) => {
            if (signer.id === signerId) {
                return newSigner
            }
            return signer
        })
        onChangeData("signers", newSigners)
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const removedItems: any = result.splice(startIndex, 1)
        const [removed] = removedItems
        result.splice(endIndex, 0, removed)
        return result
    }

    const onDragEnd = (result) => {
        const {destination, source} = result || {}
        if (!destination || destination.index === source.index) {
            return
        }
        const newSigners = reorder(signers, source.index, destination.index)
        onChangeData("signers", newSigners)
    }

    const renderTaskSignerItem = (signer, index, extraData: any = {}) => {
        const hasError = !!get(errors, ["signer", signer.id], false)
        const {provided, isDraggable} = extraData
        let providedProps = {}
        if (isDraggable) {
            providedProps = {
                ...provided.draggableProps,
                ...provided.dragHandleProps
            }
        }
        return (
            <div
                key={signer.id}
                className={cx(styles.signerWrap, {
                    [styles.error]: hasError
                })}
                ref={isDraggable ? provided.innerRef : null}
                {...providedProps}>
                <TaskSignerItem
                    signer={signer}
                    index={index}
                    forceDeleteKey={forceDeleteKey}
                    resetNonPrimarySignerId={resetNonPrimarySignerId}
                    primaryIndex={primaryIndex}
                    userDataOnlyIndex={userDataOnlyIndex}
                    userDataOnlySigner={signers[userDataOnlyIndex]}
                    primarySigner={signers[primaryIndex]}
                    taskId={taskId}
                    subtype={subtype}
                    type={type}
                    taskType={taskType}
                    hasError={hasError}
                    hasStudentParticipant={hasStudentParticipant}
                    onSave={onSave}
                    onCancel={onCancel}
                    onCancelSelecting={onCancelSelecting}
                    onSaveSelecting={onSave}
                    onClickEditSigner={onClickEditSigner}
                    onClickDeleteSigner={onClickDeleteSigner}
                    onUpdateSignerItem={onUpdateSignerItem}
                    onSaveOtherSelecting={onSaveOtherSelecting}
                    onDeleteMultiUserSigner={onDeleteMultiUserSigner}
                    onSearchChange={onSearchChange}
                    isOnlyOtherSingers={isOnlyOtherSingers}
                    isEditingParticipants={isEditingParticipants}
                    isWorkflowAction={isWorkflowAction}
                    workflowTargetType={workflowTargetType}
                    {...extraData}
                />
            </div>
        )
    }

    const renderContent = () => {
        if (subtype === TaskSubtype.LIBRARY && isEditingParticipants) {
            return (
                <DragDropContext onDragEnd={onDragEnd} className={styles.termItemWrap}>
                    <Droppable droppableId="taskParticipants">
                        {(droppableProvided) => (
                            <div
                                ref={droppableProvided.innerRef}
                                className={styles.bodyTable}
                                {...droppableProvided.droppableProps}>
                                {signers.map((signer, index) => (
                                    <Draggable draggableId={`${signer.id}`} index={index} key={signer.id}>
                                        {(provided, snapshot) =>
                                            renderTaskSignerItem(signer, index, {
                                                provided,
                                                snapshot,
                                                isDraggable: taskId ? isEditingParticipants : true
                                            })
                                        }
                                    </Draggable>
                                ))}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )
        }
        return signers.map((signer, index) => renderTaskSignerItem(signer, index))
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.header}>
                <p className={styles.title}>{t("taskDetail.signers.participants")}</p>
                {canEditLibraryTask && (
                    <SecondaryButton
                        title={t("common:action.edit")}
                        onClick={() => setIsEditingParticipants(!isEditingParticipants)}
                        className={styles.configureBtn}
                    />
                )}
            </div>
            {renderContent()}
            {canShowAddBtn && (
                <BaseButton title={t("common:action.add")} className={styles.addBtn} onClick={onClickAddSigner} />
            )}
        </div>
    )
}
