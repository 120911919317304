/* eslint-disable react-hooks/exhaustive-deps */
import {KlassappTableHOC} from "HOC"
import styles from "./SapAlert.module.css"
import {KlassappTableProps} from "types/common"
import {useEffect, useMemo, useState} from "react"
import {formatDateOnly, handleError, roundTwoDigits, toastInfo} from "helpers"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {Col, Row, Spin} from "antd"
import {FormLabel} from "components/Form"
import {KlassDropdown} from "components/Select"
import {OPTIONS_SAP_STATUS} from "sections/calendar/parts/common"
import {academicPlansService, reportsService} from "services"
import moment from "moment"
import {useModel} from "hooks"
import {AcademicPlans} from "types/academicPlans"
import {FilterKey} from "types/filter"
import {useFilterStorage} from "hooks/useFilterStorage"
import {ExportButton, ExportFileType} from "components/ui"
import {exportCsv, exportExcel} from "helpers/export-table"
import {useHistory} from "react-router-dom"
import {routing} from "helpers/routing"
import {useDebounce} from "react-use"
import {SapAlertStatsView} from "./SapAlertStatsView"

type Props = KlassappTableProps & {
    studentId?: number
    departmentId: number
}

function ListSapAlerts(props: Props) {
    const {studentId, departmentId} = props
    const [isExporting, setIsExporting] = useState(false)
    const history = useHistory()

    const {data, page, total, pageSize, columns, fields, allFields, isLoading, dispatch} = props
    const {searchValue, currentFilter, setCurrentFilter, changeSearch, clearFilter, applyFilter} = useFilterStorage(
        FilterKey.SapAlert,
        {statuses: []}
    )
    const [searchDebounce, setSearchDebounce] = useState("")

    useDebounce(() => setSearchDebounce(searchValue), 500, [searchValue])

    const model = useModel()

    const isStudentDetail = useMemo(() => {
        return !!studentId
    }, [studentId])

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    useEffect(() => {
        getData()
    }, [searchDebounce, page, pageSize])

    const checkReason = (reason: AcademicPlans.SapReason) => {
        if (reason === AcademicPlans.SapReason.LowGpa) {
            return "Low GPA"
        }
        if (reason === AcademicPlans.SapReason.AboveMaxTimeFrame) {
            return "Above Max Time Frame"
        }
        if (reason === AcademicPlans.SapReason.BelowRateOfProgress) {
            return "Below Rate Of Progress"
        }
        if (reason === AcademicPlans.SapReason.LowAttendance) {
            return "Low Attendance"
        }
        return ""
    }

    const getFilterParams = () => {
        return {
            filter: {
                sapStatuses: currentFilter.statuses?.length ? currentFilter.statuses.map((status) => status.value) : [],
                termIds: [],
                search: searchDebounce?.length ? searchDebounce : undefined,
                studentProfileIds: studentId ? [studentId] : []
            },
            range: {
                page,
                pageSize
            }
        }
    }

    async function getData() {
        try {
            dispatch({isLoading: true})
            let payload = getFilterParams()
            const {data, total} = await academicPlansService.listSapAlerts(payload)
            const alerts = data.map((item) => ({
                ...item,
                rateOfProgress:
                    item.reason === AcademicPlans.SapReason.BelowRateOfProgress
                        ? roundTwoDigits(item.rateOfProgress)
                        : undefined,
                date: formatDateOnly(item.date, model.getUserDateFormat()),
                reason: checkReason(item.reason)
            }))
            dispatch({data: alerts, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    function getColumns() {
        let columns = [
            {
                title: "Term",
                field: "termName",
                headerStyle: {minWidth: "100px"}
            }
        ]
        if (!isStudentDetail) {
            columns.push({
                title: "Student Name",
                field: "fullName",
                headerStyle: {minWidth: "150px"}
            })
        }
        columns = [
            ...columns,
            {
                title: "SAP Status",
                field: "sapStatus",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: "Date",
                field: "date",
                headerStyle: {minWidth: "100px"}
            },
            {
                title: "Reason",
                field: "reason",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: "Attendance Percentage",
                field: "attendancePercentage",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: "Rate Of Progress",
                field: "rateOfProgress",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: "Max Timeframe",
                field: "maxTimeframe",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: "Cgpa",
                field: "cgpa",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: "Current Term Course Grade",
                field: "currentTermCourseGradeAlertRiskLevel",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: "Current Term Attendance Percentage",
                field: "currentTermAttendanceAlertRiskLevel",
                headerStyle: {minWidth: "80px"}
            }
        ]
        return columns
    }

    function getFields() {
        return isStudentDetail
            ? [
                  "Term",
                  "SAP Status",
                  "Date",
                  "Reason",
                  "Attendance Percentage",
                  "Rate Of Progress",
                  "Max Timeframe",
                  "Gpa",
                  "Current Term Course Grade",
                  "Current Term Attendance Percentage"
              ]
            : [
                  "Term",
                  "Student Name",
                  "SAP Status",
                  "Date",
                  "Reason",
                  "Attendance Percentage",
                  "Rate Of Progress",
                  "Max Timeframe",
                  "Cgpa",
                  "Current Term Course Grade",
                  "Current Term Attendance Percentage"
              ]
    }

    const onChangeFilter = (key, value) => {
        const newFilter = {...currentFilter}
        newFilter[key] = value
        setCurrentFilter(newFilter)
    }

    const onApplyFilter = () => {
        applyFilter()
        getData()
    }

    const onClickExportBtn = async (type: ExportFileType) => {
        if (total > 10000) {
            try {
                setIsExporting(true)
                const params = getFilterParams()
                const reportColumns = getColumns()
                    .filter((col) => fields.includes(col.title))
                    .map((column) => ({
                        field: column.field,
                        title: column.title
                    }))
                await reportsService.generateReport({
                    reportKey: "sapAlerts",
                    payload: {filter: params.filter, fields: reportColumns},
                    format: type
                })
                toastInfo(
                    "The report contains more than 10000 records; it will take some time to generate it. We will send you a notification with the download link when the report is ready"
                )
            } catch (error) {
                handleError(error)
            } finally {
                setIsExporting(false)
            }
        } else {
            try {
                setIsExporting(true)
                const {filter} = getFilterParams()
                let listSapAlertsPayload = {
                    filter,
                    range: {
                        page: 1,
                        pageSize: total
                    }
                }
                const {data} = await academicPlansService.listSapAlerts(listSapAlertsPayload)
                const alerts = data.map((item) => ({
                    ...item,
                    rateOfProgress:
                        item.reason === AcademicPlans.SapReason.BelowRateOfProgress
                            ? roundTwoDigits(item.rateOfProgress)
                            : undefined,
                    date: formatDateOnly(item.date, model.getUserDateFormat()),
                    reason: checkReason(item.reason)
                }))
                const filename = `Sap-Alerts-Report-${moment().format("MM_DD_YYYY")}`
                const reportColumns = getColumns().filter((col) => fields.includes(col.title))
                const payload = {
                    filename,
                    columns: reportColumns.map((col) => col.title.toUpperCase()),
                    rows: alerts.map((item) =>
                        reportColumns.map((col) => {
                            return item[col.field]?.toString()?.replace(/\r\n|\n|\r/g, " ") ?? ""
                        })
                    )
                }
                if (type === "csv") {
                    exportCsv(payload)
                } else if (type === "excel") {
                    exportExcel(payload)
                }
            } catch (error) {
                handleError(error)
            } finally {
                setIsExporting(false)
            }
        }
    }

    const onClickRowItem = (data) => {
        history.push(
            routing.registrar.sapAlertDetail({
                studentProfileId: data.studentProfileId,
                departmentId
            })
        )
    }

    return (
        <div>
            <SapAlertStatsView studentProfileId={studentId} />

            <BaseNewFilter
                filter={currentFilter}
                onClick={onApplyFilter}
                searchValue={searchValue}
                onClickClear={clearFilter}
                renderRightFilter={() => (
                    <div className={styles.actionWrap}>
                        <ExportButton
                            onSelect={onClickExportBtn}
                            isLoading={isExporting}
                            availableFileTypes={["csv", "excel"]}
                        />
                    </div>
                )}
                onSearchInput={changeSearch}>
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <div>
                            <FormLabel label="SAP Status" />
                            <KlassDropdown
                                value={currentFilter.statuses}
                                placeholder="Status"
                                onChange={(option: any) => onChangeFilter("statuses", option)}
                                valueKey="value"
                                labelKey="label"
                                isMulti
                                options={OPTIONS_SAP_STATUS}
                                hasEmptyOption
                            />
                        </div>
                    </Col>
                </Row>
            </BaseNewFilter>

            <Row className={styles.marginTop}>
                <Col span={24}>
                    <KlassappTableHeader
                        page={page}
                        total={total}
                        defaultPageSize={pageSize}
                        onChangePage={props.onChangePage}
                        onChangeRowPerPage={props.onChangeRowPerPage}
                    />
                    <KlassappTable
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        onClickRowItem={onClickRowItem}
                        fields={fields}
                        allFields={allFields}
                        onChangeFields={props.onChangeFields}
                        onUpdateRowData={props.onUpdateRowData}
                        onUpdateTableData={props.onUpdateTableData}
                    />
                </Col>
            </Row>
        </div>
    )
}

export const SapAlert = KlassappTableHOC(ListSapAlerts)
