import {BaseDepartmentId, DepartmentSubunit} from "./departments"
import {Timestamps} from "./common"
import {Descendant} from "slate"
import {Auth} from "./auth"
import {TriggeredWorkflow} from "./triggered-workflow"
import {Edge, Node} from "reactflow"
import {ProcessWorkflowActionNodeData, ProcessWorkflowStepNodeData} from "types/workflow"
import {GeneralSettingField} from "types/settings/general-setting"

export type StudentActivity = Timestamps & {
    studentActivityId: number
    profileId: number
    activityId: number
    description: string
    dueDate?: string
    activityDate: string
    addStudentCalendar?: boolean
    visibleForStudent?: boolean
    completed?: boolean
    isFollowUp?: boolean
    createdBy?: number
    departmentSubunitId?: number
    completedDate?: string
    completedBy?: number
    calendarEventIds?: number[] | null
    isPinned: boolean | 0 | 1

    // associated workflow
    triggeredWorkflowId?: number | null
    workflowNodeId?: string | null
    initialActivityId?: number | null

    // assignment
    assignedRole?: boolean | null
    assignedRoleIds?: number[] | null
    assignedProfileIds?: number[] | null

    // notification
    notifyStaffRole?: boolean | null
    notifyStaffRoleIds?: number[] | null
    notifyStaffProfileIds?: number[] | null

    // student due
    studentDue?: {
        value?: number
        unit?: string
        time?: string
        color?: string
    } | null
    studentDueDate?: string | null

    // internal
    template?: Descendant[]
    name?: string
    flow?: {nodes: Node<ProcessWorkflowStepNodeData | ProcessWorkflowActionNodeData>[]; edges: Edge[]}
    nextStepNodeId?: string
} & Partial<{
        departmentSubunit: DepartmentSubunit
        activity: Activity
        student: Auth.LinkedProfileDetails
        createdByProfile: Auth.LinkedProfileDetails
        completedByProfile: Auth.LinkedProfileDetails
        triggeredWorkflow: TriggeredWorkflow
        workflowActivities: StudentActivity[]
        assignedProfiles: Auth.LinkedProfileDetails[]
        notifyStaffProfiles: Auth.LinkedProfileDetails[]
    }>

export type Activity = Timestamps & {
    activityId: number
    departmentId: number
    name: string
    isHoldActivity: boolean
    isLocked: boolean
}

export const EVENT_STUDENT_ACTIVITY_COMPLETION_TOGGLED = "EVENT_STUDENT_ACTIVITY_COMPLETION_TOGGLED"
export const EVENT_STUDENT_ACTIVITY_CREATED = "EVENT_STUDENT_ACTIVITY_CREATED"
export const EVENT_STUDENT_ACTIVITY_READ = "EVENT_STUDENT_ACTIVITY_READ"
export const EVENT_STUDENT_ACTIVITY_UNREAD = "EVENT_STUDENT_ACTIVITY_UNREAD"

export const MAP_DEPARTMENT_2_UNREAD_ACTIVITY_SETTING_FIELD: Record<number, GeneralSettingField> = {
    [BaseDepartmentId.Academics]: GeneralSettingField.AcadActivityShowUnread,
    [BaseDepartmentId.Admissions]: GeneralSettingField.AdmActivityShowUnread,
    [BaseDepartmentId.FinancialAid]: GeneralSettingField.FaActivityShowUnread,
    [BaseDepartmentId.StudentServices]: GeneralSettingField.SsActivityShowUnread,
    [BaseDepartmentId.StudentAccount]: GeneralSettingField.SaActivityShowUnread
}
