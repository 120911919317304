/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from "react"
import moment from "moment"
import {head, isEmpty, size, uniq, uniqBy} from "lodash"
import {AddItemCircleButton, BaseButton} from "components/buttons"
import {BaseLoading} from "components"
import {Major} from "types/major"
import {useMutation, useSearchParam} from "hooks"
import {generateWorkdays, handleError, toastInfo, toastSuccess, toDateOnly} from "helpers"
import {
    majorService,
    termsService,
    academicPlansService,
    courseScheduleService,
    workScheduleService,
    calendarService
} from "services"
import {KlassDropdown} from "components/Select"
import {TermItem, TermCalendarItem} from "./parts"
import styles from "./TermCourseSequence.module.css"
import {Auth} from "types/auth"

import {FormLabel} from "components/Form"

const times = []
for (let i = 0; i < 24; i += 1) {
    const time = moment().startOf("date").add(i, "hour")
    times.push(time)
}

type Props = {
    versionData?: Major.MajorVersion
    termDetail?: any
    majorVersionId?: number
    studentProfileId?: number
    student?: Auth.DepartmentStudent
}

enum ViewType {
    DEFAULT,
    CALENDAR
}

export function TermCourseSequence(props: Props) {
    const viewTypes = [
        {id: ViewType.DEFAULT, name: "Default"},
        {id: ViewType.CALENDAR, name: "Calendar"}
    ]
    const [viewType, setViewType] = useState({id: ViewType.DEFAULT, name: "Default"})
    const [isLoading, setIsLoading] = useState(false)
    const [terms, setTerms] = useState([])
    const [workdays, setWorkDays] = useState([])
    const [workdaysByTerm, setWorkdaysByTerm] = useState({})
    const [isLoadingWorkday, setIsLoadingWorkday] = useState(false)
    const [termCourseIds, setTermCourseIds] = useState([])
    const [selectableTerms, setSelectableTerms] = useState([])
    const [workSchedule, setWorkSchedule] = useState<any>(null)
    const {versionData, termDetail, majorVersionId, studentProfileId, student} = props
    const isInMajorVersion = !isEmpty(versionData)
    const isInTermSettingPreview = !isEmpty(termDetail)
    const isInAcademicPlan = !!studentProfileId
    const {value: campusId} = useSearchParam("campusId")
    const {value: academicTrackId} = useSearchParam("academicTrackId")
    const [activeCampus, setActiveCampus] = useState({id: +campusId, name: ""})
    const [activeAcademicTrack, setActiveAcademicTrack] = useState({id: +academicTrackId, name: ""})
    const [campuses, setCampuses] = useState<{id: number; name: string}[]>([])
    const [academicTracks, setAcademicTracks] = useState<{id: number; name: string}[]>([])

    const {mutateAsync: registerAllTerms, isLoading: isAllTermsRegistering} = useMutation(
        async () => {
            const promises = []
            terms
                .filter((term) => !term.registeredDate && !!term.termId)
                .forEach((term) => {
                    const calendarIds = uniq(
                        term.courses.map((course) => course.schedule?.calendar_id).filter((item) => !!item)
                    )
                    promises.push(
                        academicPlansService.registerTerm({
                            academicPlanTermId: term.id,
                            calendarIds,
                            userId: student?.userId
                        })
                    )
                })
            return await Promise.all(promises)
        },
        {
            onSuccess: () => {
                reloadView()
                toastSuccess("All terms successfully registered")
            },
            onError: (error) => handleError(error)
        }
    )

    const canShowRegisterAll = useMemo(() => terms.some((term) => !term.registeredDate && !!term.termId), [terms])

    useEffect(() => {
        if (isInMajorVersion) {
            getTermInMajorVersion()
        } else if (isInTermSettingPreview) {
            getCourseOfTerm()
            getTermCalendarPrograms(termDetail.id)
        } else if (isInAcademicPlan) {
            getTermInAcademicPlan()
        }
    }, [])

    useEffect(() => {
        if (isInTermSettingPreview) {
            getWorkSchedules(activeCampus.id, termDetail)
            getTermInTermSettingsPreview()
            getListSelectableTerms()
        }
    }, [activeAcademicTrack.id, activeCampus.id])

    const getTermInMajorVersion = async () => {
        try {
            setIsLoading(true)
            const {data} = await majorService.getListTermsCalendarBased({versionId: versionData.id})
            setTerms(data)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getTermInTermSettingsPreview = async () => {
        try {
            setIsLoading(true)
            const {data} = await termsService.getListTermsCalendarBased({
                versionId: majorVersionId,
                termId: termDetail.id,
                campusId: activeCampus.id,
                academicTrackId: activeAcademicTrack.id
            })
            await getTermCourseScheduleEvents(data)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getListSelectableTerms = async () => {
        try {
            const terms = await termsService.getListSelectableTerms({
                termId: termDetail.id,
                versionId: majorVersionId,
                campusId: activeCampus.id,
                academicTrackId: activeAcademicTrack.id
            })
            setSelectableTerms(terms)
        } catch (error) {
            handleError(error)
        }
    }

    const getWorkSchedules = async (campusId, termInfo?: any) => {
        try {
            setIsLoadingWorkday(true)
            const {data} = await workScheduleService.getAll({
                filter: {
                    campuses: [+campusId],
                    withEvents: true
                }
            })
            const workSchedule = head(data)
            if (isInTermSettingPreview) {
                if (workSchedule) {
                    const dayOfWeeks = workSchedule.workWeek.map((item) => item.dayOfWeek)
                    const dayCount = moment(termInfo.end_date).diff(moment(termInfo.start_date), "day") + 1
                    const startDate = moment(toDateOnly(termInfo.start_date))
                    let holidays = []
                    if (workSchedule.workScheduleId) {
                        const response = await calendarService.getAll({
                            filter: {
                                types: ["work_schedule"],
                                work_schedule_filter: {
                                    work_schedule_ids: [workSchedule.workScheduleId]
                                }
                            },
                            sort: {orderBy: "start_at_utc", order: "ASC"},
                            pagination: {page: 1, pageSize: 1000}
                        })
                        holidays = response.data
                    }
                    const workdays = generateWorkdays(dayCount, startDate, dayOfWeeks, holidays)
                    setWorkDays(workdays)
                }
                setWorkSchedule(workSchedule)
            }
            return workSchedule
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoadingWorkday(false)
        }
    }

    const getTermCalendarPrograms = async (termId) => {
        try {
            const {data} = await termsService.getTermCalendarPrograms({termId})
            const activeItem = data.find(
                (item) => item.academicTrackId === +academicTrackId && item.campusId === +campusId
            )
            const uniqAcademicTracks = uniqBy(data, "academicTrackId")
            const uniqCampuses = uniqBy(data, "campusId")
            const academicTracks = uniqAcademicTracks.map((item) => ({
                id: item.academicTrackId,
                name: item.academicTrackName
            }))
            const campuses = uniqCampuses.map((item) => ({
                id: item.campusId,
                name: item.campusName
            }))
            setActiveCampus({id: activeItem.campusId, name: activeItem.campusName})
            setActiveAcademicTrack({id: activeItem.academicTrackId, name: activeItem.academicTrackName})
            setAcademicTracks(academicTracks)
            setCampuses(campuses)
        } catch (error) {
            handleError(error)
        }
    }

    const getCourseOfTerm = async () => {
        try {
            const {data} = await termsService.getCourseOfTerm({
                filter: {termId: termDetail.id},
                range: {page: 1, pageSize: 1000}
            })
            const courseIds = data.map((item) => item.courseId)
            setTermCourseIds(courseIds)
        } catch (error) {
            handleError(error)
        }
    }

    const getTermCourseScheduleEvents = async (terms) => {
        const courseScheduleIds = []
        terms.forEach((term) => {
            term.courses
                .filter((course) => course.courseId && course.scheduleId)
                .forEach((course) => {
                    courseScheduleIds.push(course.scheduleId)
                })
        })
        const uniqCourseScheduleIds = uniq(courseScheduleIds)
        if (!isEmpty(uniqCourseScheduleIds)) {
            const {data: courseSchedules} = await courseScheduleService.courseScheduleGet({
                filter: {courseScheduleIds: uniq(courseScheduleIds)}
            })
            for (let index = 0; index < terms.length; index += 1) {
                const term = terms[index]
                for (let index = 0; index < term.courses.length; index += 1) {
                    const course = term.courses[index]
                    course.schedule = courseSchedules.find((item) => {
                        return item && item.course_id === course.courseId && item.id === course.scheduleId
                    })
                }
            }
        }
        setTerms(terms)
    }

    const getTermInAcademicPlan = async () => {
        try {
            setIsLoading(true)
            const {data} = await academicPlansService.getListTermsCalendarBased({studentProfileId})
            await Promise.all([getWorkScheduleByTermIds(data), getTermCourseScheduleEvents(data)])
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getWorkScheduleByTermIds = async (terms: any[]) => {
        try {
            const workdaysByTerm = {}
            const workSchedule = await getWorkSchedules(head(student.campusIds))
            let holidays = []
            if (workSchedule.workScheduleId) {
                const response = await calendarService.getAll({
                    filter: {
                        types: ["work_schedule"],
                        work_schedule_filter: {
                            work_schedule_ids: [workSchedule.workScheduleId]
                        }
                    },
                    sort: {orderBy: "start_at_utc", order: "ASC"},
                    pagination: {page: 1, pageSize: 1000}
                })
                holidays = response.data
            }
            terms.forEach((term) => {
                if (workSchedule) {
                    const dayOfWeeks = workSchedule.workWeek.map((item) => item.dayOfWeek)
                    const startDate = moment(toDateOnly(term.start_date))
                    const dayCount = moment(term.end_date).diff(moment(term.start_date), "day") + 1
                    const workdays = generateWorkdays(dayCount, startDate, dayOfWeeks, holidays)
                    workdaysByTerm[term.id] = workdays
                }
            })
            setWorkSchedule(workSchedule)
            setWorkdaysByTerm(workdaysByTerm)
        } catch (error) {
            handleError(error)
        }
    }

    const addTerm = async () => {
        if (isInMajorVersion) {
            await addTermInMajor()
        } else if (isInTermSettingPreview) {
            await addTermInTermSetting()
        }
    }

    const addTermInMajor = async () => {
        try {
            await majorService.addTermsCalendarBased({versionId: versionData.id})
            await getTermInMajorVersion()
        } catch (error) {
            handleError(error)
        }
    }

    const addTermInTermSetting = async () => {
        try {
            await termsService.addTermsCalendarBased({
                versionId: majorVersionId,
                termId: termDetail.id,
                isRegistrationHold: 0
            })
            getTermInTermSettingsPreview()
        } catch (error) {
            handleError(error)
        }
    }

    const deleteTerm = (id: number) => {
        if (isInMajorVersion) {
            deleteTermInMajor(id)
        } else if (isInTermSettingPreview) {
            deleteTermInTermSetting(id)
        } else if (isInAcademicPlan) {
            deleteTermInAcademicPlan(id)
        }
        const newTerms = terms.filter((term) => term.id !== id)
        setTerms(newTerms)
    }

    const deleteTermInMajor = async (id) => {
        try {
            await majorService.removeTermsCalendarBased({id})
        } catch (error) {
            handleError(error)
        }
    }

    const deleteTermInTermSetting = async (id) => {
        try {
            await termsService.removeTermsCalendarBased({id})
        } catch (error) {
            handleError(error)
        }
    }

    const deleteTermInAcademicPlan = async (id) => {
        try {
            await academicPlansService.removeTermsCalendarBased({id})
        } catch (error) {
            handleError(error)
        }
    }

    const reloadView = () => {
        if (isInMajorVersion) {
            getTermInMajorVersion()
        } else if (isInTermSettingPreview) {
            getTermInTermSettingsPreview()
        } else if (isInAcademicPlan) {
            getTermInAcademicPlan()
        }
    }

    const updateTerm = (newTerm) => {
        const newTerms = terms.map((term) => {
            if (term.id === newTerm.id) {
                return newTerm
            }
            return term
        })
        setTerms(newTerms)
    }

    if (isLoading) {
        return (
            <div className={styles.loading}>
                <BaseLoading isShow />
            </div>
        )
    }

    const renderCalendarView = () => {
        return (
            <>
                {terms.map((term, index) => {
                    let termInfo = null
                    if (isInTermSettingPreview) {
                        termInfo = termDetail
                    } else if (isInAcademicPlan) {
                        termInfo = term
                    }
                    return (
                        <TermCalendarItem
                            key={`${term.id}`}
                            termDetail={termInfo}
                            term={term}
                            workSchedule={workSchedule}
                            isInAcademicPlan={isInAcademicPlan}
                            index={index}
                        />
                    )
                })}
            </>
        )
    }

    const renderDefaultView = () => {
        if (isLoadingWorkday) {
            return <BaseLoading isShow />
        }
        return (
            <>
                {terms.map((term, index) => {
                    let termInfo = null
                    let workdaysInfo = null
                    if (isInTermSettingPreview) {
                        termInfo = termDetail
                        workdaysInfo = workdays
                    } else if (isInAcademicPlan) {
                        termInfo = term
                        workdaysInfo = workdaysByTerm[term.id]
                    }
                    return (
                        <TermItem
                            key={`${term.id}`}
                            termDetail={termInfo}
                            term={term}
                            index={index}
                            times={times}
                            workdays={workdaysInfo}
                            versionData={versionData}
                            isInMajorVersion={isInMajorVersion}
                            isInTermSettingPreview={isInTermSettingPreview}
                            isInAcademicPlan={isInAcademicPlan}
                            majorVersionId={majorVersionId}
                            termCourseIds={termCourseIds}
                            selectableTerms={selectableTerms}
                            studentProfileId={studentProfileId}
                            studentId={student?.userId}
                            campusId={activeCampus.id}
                            deleteTerm={deleteTerm}
                            reloadView={reloadView}
                            updateTerm={updateTerm}
                        />
                    )
                })}
            </>
        )
    }

    return (
        <div className={styles.wrap}>
            {!isInMajorVersion && (
                <div className={styles.selectWrap}>
                    {size(academicTracks) > 1 && (
                        <div className={styles.selectItem}>
                            <FormLabel label="Academic track" />
                            <KlassDropdown
                                options={academicTracks}
                                value={activeAcademicTrack}
                                onChange={setActiveAcademicTrack}
                            />
                        </div>
                    )}
                    {size(campuses) > 1 && (
                        <div className={styles.selectItem}>
                            <FormLabel label="Campus" />
                            <KlassDropdown options={campuses} value={activeCampus} onChange={setActiveCampus} />
                        </div>
                    )}
                    <div className={styles.selectItem}>
                        <FormLabel label="View" />
                        <KlassDropdown options={viewTypes} value={viewType} onChange={setViewType} />
                    </div>
                </div>
            )}
            {canShowRegisterAll && (
                <div className={styles.registerAllBtn}>
                    <BaseButton title="Register All Terms" loading={isAllTermsRegistering} onClick={registerAllTerms} />
                </div>
            )}
            {viewType.id === ViewType.CALENDAR ? renderCalendarView() : renderDefaultView()}
            {isInMajorVersion && (
                <div className={styles.addBtnWrap} onClick={addTerm}>
                    <AddItemCircleButton title="Add Term" />
                </div>
            )}
        </div>
    )
}
