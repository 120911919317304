import {useCallback} from "react"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {shiftsService, termsService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"

const queryCacheKey = "all-terms"
const INITIAL_DATA = []

export const useAllTerms = () => {
    const queryClient = useQueryClient()

    const query = useQuery(
        [queryCacheKey],
        async () => {
            const {data} = await termsService.getAllTerms({
                fields: ["id", "name", "code", "start_date", "end_date"],
                orderBy: "start_date",
                orderDir: "DESC",
                limit: UNLIMITED_PAGE_SIZE,
                offset: 0
            })
            return data
        },
        {
            refetchOnMount: false
        }
    )

    return {
        terms: query.data ?? INITIAL_DATA,
        invalidateTermsCache: useCallback(() => {
            queryClient.invalidateQueries({queryKey: [queryCacheKey]})
        }, [queryClient])
    }
}
