import {Auth} from "types/auth"
import BaseService from "./Base"
import {get} from "lodash"
import {StudentStateChangeDetail} from "types/temporaryOut"

class ProfileService extends BaseService {
    create = async (params = {}) => {
        const response = await this.post("/v1/users/profile/create", params)
        return get(response, "data.data", {})
    }

    getAll = async (params = {}) => {
        const response = await this.post("/v1/users/profile/list", params)
        return get(response, "data", {})
    }

    getOne = async (params = {}) => {
        const response = await this.post("/v3/users/profile/get", params)
        return get(response, "data", {})
    }

    delete = async (params = {}) => {
        const response = await this.post("/v1/users/profile/delete", params)
        return get(response, "data.data", {})
    }

    getRoles = async (params = {}) => {
        const response = await this.post("/v2/roles/list", params)
        return get(response, "data.data", {})
    }

    getPayingMethods = async (params = {}) => {
        const response = await this.post("/v1/settings/paying-methods/list", params)
        return get(response, "data.data", {})
    }

    markStudentAsTemporaryOut = async (params: {
        studentProfileId: number
        studentStatusIdToAssign: number
        reasonId?: number
        date: string
        notes: string
        temporaryOutReturnDate?: string
    }) => {
        const response = await this.post("/v1/students-state-transitions/mark-student-as-temporary-out", params)
        return get(response, "data.data", {})
    }

    markStudentAsPermanentOut = async (params: {
        studentProfileId: number
        studentStatusIdToAssign: number
        makeProfileInactive?: boolean
        reasonId?: number
        action: "cancel" | "drop"
        date: string
        notes: string
    }) => {
        const response = await this.post("/v1/students-state-transitions/cancel-or-drop-student-profile", params)
        return get(response, "data.data", {})
    }

    getTemporaryOutLog = async (params: {
        studentProfileId?: number
        states?: Auth.StudentState[]
        search?: string
        campusIds?: number[]
        startDate?: string
        endDate?: string
        programIds?: number[]
        currentStates?: Auth.StudentState[]
        termIds?: number[]
        sort?: {
            orderBy: keyof StudentStateChangeDetail
            orderDir: "asc" | "desc"
        }
        range?: {
            page: number
            pageSize: number
        }
    }) => {
        const response = await this.post("/v1/students-state-transitions/list", params)
        const data = get(response, "data.data", {})
        return data
    }

    deleteStudentsStateTransitions = async (params: {ids: number[]}) => {
        const response = await this.post("/v1/students-state-transitions/delete", params)
        const data = get(response, "data.data", {})
        return data
    }

    editStudentsStateTransitions = async (params: {
        studentStateTransitions: Array<{
            id: number
            date?: string
            temporaryOutReturnDate?: string | null
            reasonId?: number | null
            notes?: string
            lastDayOfAttendance?: string | null
        }>
    }) => {
        const response = await this.post("/v1/students-state-transitions/edit", params)
        const data = get(response, "data.data", {})
        return data
    }

    reinstateTemporaryOutStudent = async (params: {
        studentProfileId: number
        studentStatusIdToAssign: number
        temporaryOutReturnDate: string
        notes: string
    }) => {
        const response = await this.post("/v1/students-state-transitions/reinstate-temporary-out-student", params)
        return get(response, "data.data", {})
    }

    reinstatePermanentOutStudent = async (params: {
        studentProfileId: number
        studentStatusIdToAssign: number
        notes: string
    }) => {
        const response = await this.post(
            "/v1/students-state-transitions/reinstate-cancelled-or-dropped-student-profile",
            params
        )
        return get(response, "data.data", {})
    }

    checkCustomProfileId = async (params = {}) => {
        const response = await this.post("/v1/users/profile/check-custom-profile-id", params)
        return get(response, "data", {})
    }

    getNextCustomProfileId = async (params = {}) => {
        const response = await this.post("/v1/users/profile/next-custom-profile-id", params)
        return get(response, "data.data", {})
    }

    setUserDefaultProfile = async (params: {profileId: number; userId: number}) => {
        const response = await this.post("/v1/users/profile/set-as-default", params)
        return get(response, "data.data", {})
    }

    getAvailabilitySlots = async (
        params: Auth.ListProfileWorkSchedulesPayload
    ): Promise<Auth.ProfileAvailabilitySlot[]> => {
        const response = await this.post("/v1/users/profile/availability-slots/list", params)
        return get(response, "data.data", {})
    }

    updateAvailabilitySlots = async (params: Auth.UpdateProfileWorkSchedulesPayload): Promise<boolean> => {
        const response = await this.post("/v1/users/profile/availability-slots/update", params)
        return get(response, "data.data", {})
    }

    getCoursesStaffCanTeach = async (profileId: number): Promise<Auth.CourseStaffCanTeach[]> => {
        const response = await this.post("/v1/users/profile/courses-staff-can-teach/list", {profileId})
        return get(response, "data.data", [])
    }

    addCoursesStaffCanTeach = async (params: {profileId: number; courseIds: number[]}): Promise<boolean> => {
        const response = await this.post("/v1/users/profile/courses-staff-can-teach/add", params)
        return get(response, "data.data", false)
    }

    deleteCoursesStaffCanTeach = async (params: {profileId: number; courseIds: number[]}): Promise<boolean> => {
        const response = await this.post("/v1/users/profile/courses-staff-can-teach/delete", params)
        return get(response, "data.data", false)
    }
}
export default ProfileService
