import React, {useState} from "react"
import cx from "classnames"
import styles from "./Header.module.css"
import {SideBar} from "./parts/SideBar"
import {CollapseButton} from "./parts/CollapseButton"

export function Header() {
    const [isOpenMenu, setIsOpenMenu] = useState(true)

    const onToggleSideBar = () => {
        setIsOpenMenu(!isOpenMenu)
    }

    return (
        <header
            className={cx(styles.header, {
                [styles.collapsed]: !isOpenMenu
            })}>
            <CollapseButton onClick={onToggleSideBar} type="list" />
            {isOpenMenu && (
                <div className={styles.wrapper}>
                    <SideBar />
                </div>
            )}
        </header>
    )
}
