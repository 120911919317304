import React from "react"
import {useTranslation} from "react-i18next"

import {StudentStatus} from "types/students"
import {KlassDropdown} from "components/Select"
import {StatusStateShortName} from "helpers"
import {Auth} from "types/auth"
import {useGroupedStudentStatusesByStateAsDropdownOptions} from "./useGroupedStudentStatusesByStateAsDropdownOptions"

type NewStudentStatusSelectProps = {forState?: Auth.StudentState[]; error?: boolean} & (
    | {
          isMulti: true
          value?: StudentStatus[]
          onChange: (status: StudentStatus[]) => void
      }
    | {
          isMulti?: false
          value?: StudentStatus | null
          onChange: (status: StudentStatus | null) => void
      }
)

export const NewStudentStatusSelectFilter = ({
    forState,
    error = false,
    isMulti = false,
    value,
    onChange
}: NewStudentStatusSelectProps) => {
    const {t} = useTranslation(["user"])

    const {groupedStatuses} = useGroupedStudentStatusesByStateAsDropdownOptions({forState})

    return (
        <KlassDropdown
            error={error}
            options={groupedStatuses}
            value={value}
            valueKey="statusId"
            labelKey="name"
            onChange={onChange}
            isMulti={isMulti}
            placeholder={t("user.studentStatus")}
            getOptionLabel={(option: StudentStatus) => {
                if (!option) return ""

                return `[${StatusStateShortName[option.state]}] ${option.name}`
            }}
        />
    )
}
