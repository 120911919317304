import {get} from "lodash"
import BaseService from "./Base"
import {Settings} from "types/settings"

export default class AutoDisableAccessService extends BaseService {
    async getAutoDisabledAccessStaff(): Promise<{data: Settings.DisabledAccessStaffStatus[]}> {
        const response = await this.post("/v1/settings/auto-disabled-access/staff/list", {})
        return get(response, "data", [])
    }

    async editDisabledAccessStaff(data: Settings.ChangedDisabledAccessStaffStatus[]): Promise<void> {
        const response = await this.post("/v1/settings/auto-disabled-access/staff/edit", data)
        return get(response, "data", [])
    }

    async getAutoDisabledAccessStudent(): Promise<{data: Settings.DisabledAccessStudentStatus[]}> {
        const response = await this.post("/v1/settings/auto-disabled-access/student/list", {})
        return get(response, "data", [])
    }

    async editDisabledAccessStudent(data: Settings.ChangedDisabledAccessStudentStatus[]) {
        const response = await this.post("/v1/settings/auto-disabled-access/student/edit", data)
        return get(response, "data", [])
    }
}
