/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {useModel, useSearchParam, useVisible} from "hooks"
import {useTranslation} from "react-i18next"
import {decode, store} from "helpers"
import {ConfirmPopupChildren} from "uiKit"
import styles from "./TaskSubmit.module.css"
import {useHistory} from "react-router-dom"
import {TaskType} from "types/tasks"
import {Screen} from "components/ui"

export default function TaskSubmit() {
    const model = useModel()
    const {value: taskId} = useSearchParam("id")
    const {value: subTaskId} = useSearchParam("subTaskId")
    const {value: userId} = useSearchParam("userId")
    const {value: taskType} = useSearchParam("taskType")

    const confirmPopup = useVisible()
    const {t} = useTranslation(["tasks", "common"])
    const history = useHistory()

    useEffect(() => {
        const decodedUserId = decode(model.slug, userId)
        if (model.isLoggedIn && model?.user?.id !== decodedUserId && !store.get("profileId") && userId) {
            confirmPopup.open()
        } else {
            if ([TaskType.DIGITAL_DOCUMENT, TaskType.FORM, TaskType.CUSTOM_DD].includes(taskType)) {
                history.replace(`/tasks/submissions?id=${taskId}&subTaskId=${subTaskId}&type=${taskType}`)
            } else if (taskType === TaskType.SCAN_UPLOAD) {
                history.replace(`/tasks/detail?type=scan&subtype=assigned&id=${taskId}`)
            } else {
                history.replace(`/tasks`)
            }
        }
    }, [])

    const onCloseModal = () => {
        confirmPopup.close()
        history.replace(`/tasks`)
    }

    const confirmModal = () => {
        confirmPopup.close()
        store.clearAll()
        window.location.href = "/"
        return
    }

    if (confirmPopup.isVisible) {
    }
    return (
        <Screen htmlTitle={"Submit task"} header={{}}>
            <ConfirmPopupChildren
                title={t("common:action.confirm")}
                isVisible={confirmPopup.isVisible}
                onClose={onCloseModal}
                onConfirm={confirmModal}>
                <div className={styles.modalContainer}>
                    <p>{t("tasks.confirmCloseCurrentSession")}</p>
                    <p className={styles.alternativeText}>{t("tasks.confirmCloseCurrentSessionAlternative")}</p>
                </div>
            </ConfirmPopupChildren>
        </Screen>
    )
}
