import React from "react"
import cx from "classnames"
import {AcademicPlans} from "types/academicPlans"
import {Auth} from "types/auth"
import styles from "./TermItem.module.css"
import {TransferAndPreviouslyCompletedItem} from "./TransferAndPreviouslyCompletedItem"
import {get} from "lodash"
import {TermViewCourseType} from "./TermViewTab"

type Props = {
    index: number
    isStaff: boolean
    studentId: number
    student: Auth.DepartmentStudent
    termItem: any
    registeringTerm: AcademicPlans.Term
    availableTerms: AcademicPlans.AvailableTerm[]
    selectedAvailableTermIds: number[]
    conflictEvents: any[]
    onUpdateTermItem: (index: number, newTerm: AcademicPlans.Term) => void
    onSelectAvailableTerm: (termId: number, availableTerm: AcademicPlans.AvailableTerm) => void
    reloadTerm: () => void
    updateEvents: (data: AcademicPlans.UpdateEvents[]) => void
    onRegisterCourseSuccess: () => void
}

export function TransferAndPreviouslyCompletedTerm(props: Props) {
    const {
        studentId,
        student,
        termItem,
        registeringTerm,
        conflictEvents,
        reloadTerm,
        updateEvents,
        onRegisterCourseSuccess
    } = props
    const {sections} = termItem

    const courses = get(sections, [0, "courses"], [])
    let totalCredit = courses.reduce((total, item) => {
        return (
            total +
            (item.courseType === TermViewCourseType.Transfer ? item.appliedCredits ?? 0 : item.creditsApplied ?? 0)
        )
    }, 0)

    const renderTermTitle = () => {
        return (
            <div
                className={styles.termTitle}
                onClick={(event) => {
                    event.stopPropagation()
                }}>
                Transfer and Previously Completed
            </div>
        )
    }

    const renderHeader = () => {
        return (
            <div className={styles.termHeader}>
                <div>
                    {renderTermTitle()}
                    <div style={{height: 19}}></div>
                </div>
                <div className={styles.termHeaderRight}>
                    <div className={styles.hourInfo}>
                        <div className={cx(styles.hourInfoItem)}>
                            <span className={styles.hourInfoItemTitle}>Credit</span>
                            <span className={styles.hourInfoItemValue}>{totalCredit}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderBody = () => {
        const availableCourses = []
        return (
            <div className={styles.termItemBodyWrap}>
                {sections?.map((termSection, sectionIndex) => (
                    <TransferAndPreviouslyCompletedItem
                        key={sectionIndex}
                        studentId={studentId}
                        student={student}
                        index={sectionIndex}
                        termItem={termItem}
                        termSection={termSection}
                        canTermRegister={false}
                        conflictEvents={conflictEvents}
                        availableCourses={availableCourses}
                        registeringTerm={registeringTerm}
                        reloadView={reloadTerm}
                        updateEvents={updateEvents}
                        onRegisterCourseSuccess={onRegisterCourseSuccess}
                    />
                ))}
            </div>
        )
    }

    return (
        <div className={styles.termItem}>
            <div className={styles.termBody}>
                {renderHeader()}
                {renderBody()}
            </div>
        </div>
    )
}
