/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import dayGridPlugin from "@fullcalendar/daygrid"
import {useTranslation} from "react-i18next"
import moment from "moment"
import "moment/min/locales"
import {BaseLoading, Icon} from "components"
import {CalendarProps} from "types/calendar"
import {CalendarPicker, ViewOptions, EventContent, MoreLinkContent, ConnectedCalendars} from "./parts"
import styles from "./Calendar.module.css"
import {isEmpty} from "lodash"
import {useModel} from "hooks"
import cx from "classnames"
import {Auth} from "types/auth"

const VIEWS = {
    DAY: "timeGridDay",
    MONTH: "dayGridMonth",
    WEEK: "timeGridWeek",
    YEAR: "YEAR"
}

export const Calendar = (props: CalendarProps) => {
    const {t} = useTranslation(["calendar", "common"])
    const [selectedDay, setSelectedDay] = useState(new Date())
    const [allEvents, setAllEvents] = useState([])
    const [clickInfo, setClickInfo] = useState<any>({})
    const [isShowMoreEvent, setIsShowMoreEvent] = useState(false)
    const [isShowEventPreview, setIsShowEventPreview] = useState(null)
    const [moreLinkData, setMoreLinkData] = useState(null)
    const [updateViewTo, setUpdateViewTo] = useState(VIEWS.WEEK)
    const model = useModel()
    const {
        calendarRef,
        events,
        isLoading,
        onDateClick,
        onChangeDatesSet,
        eventOptions,
        viewOptions,
        isShowGoing,
        workDays,
        holidayDays,
        displayMode = "normal",
        renderWorkWeek,
        isWorkScheduleView = false,
        showConnectedCalendars,
        ...rest
    } = props
    const isFullDisplay = displayMode === "normal"

    useEffect(() => {
        if (calendarRef.current) {
            calendarRef.current.getApi().gotoDate(selectedDay)
        }
    }, [selectedDay])

    useEffect(() => {
        setAllEvents(events)
    }, [events])

    const onChangeView = (value) => {
        setUpdateViewTo(VIEWS[value])
        if (value !== "YEAR") {
            calendarRef.current.getApi().changeView(VIEWS[value])
        }
    }

    const changeToDayView = (date) => {
        setUpdateViewTo("DAY")
        calendarRef.current.getApi().gotoDate(date)
        setSelectedDay(date)
    }

    const onClickToday = () => {
        const today = new Date()
        calendarRef.current.getApi().gotoDate(today)
        setSelectedDay(today)
    }

    const onClickPrevMonth = () => {
        switch (updateViewTo) {
            case VIEWS.DAY: {
                const prevDay = moment(selectedDay).subtract(1, "day").toDate()
                setSelectedDay(prevDay)
                break
            }
            case VIEWS.WEEK: {
                const prevWeek = moment(selectedDay).subtract(1, "week").toDate()
                setSelectedDay(prevWeek)
                break
            }
            case VIEWS.MONTH: {
                const prevMonth = moment(selectedDay).subtract(1, "month").toDate()
                setSelectedDay(prevMonth)
                break
            }
            default:
                break
        }
    }

    const onClickNextMonth = () => {
        switch (updateViewTo) {
            case VIEWS.DAY: {
                const prevDay = moment(selectedDay).add(1, "day").toDate()
                setSelectedDay(prevDay)
                break
            }
            case VIEWS.WEEK: {
                const prevWeek = moment(selectedDay).add(1, "week").toDate()
                setSelectedDay(prevWeek)
                break
            }
            case VIEWS.MONTH: {
                const prevMonth = moment(selectedDay).add(1, "month").toDate()
                setSelectedDay(prevMonth)
                break
            }
            default:
                break
        }
    }

    const onDayClick = (date) => {
        setSelectedDay(date)
    }

    const handleDateClick = (date) => {
        if (isShowEventPreview || isShowMoreEvent) {
            return
        }
        onDateClick(date)
    }

    const handleEventClick = (clickInfo) => {
        setClickInfo(clickInfo)
    }

    const handleShowEventPreview = (visible) => {
        setIsShowEventPreview(visible)
    }

    const handleShowMoreEventPreview = (visible) => {
        setIsShowMoreEvent(visible)
    }

    const renderEventContent = (eventInfo) => {
        return (
            <EventContent
                updateViewTo={updateViewTo}
                eventInfo={eventInfo}
                clickInfo={clickInfo}
                eventOptions={eventOptions}
                isShowGoing={isShowGoing}
                handleShowEventPreview={handleShowEventPreview}
                isWorkScheduleView={isWorkScheduleView}
            />
        )
    }

    const handleMoreLinkClick = (info) => {
        setIsShowMoreEvent(true)
        setMoreLinkData(info)
        setClickInfo(info)
    }

    const renderMoreLinkContent = ({text}) => {
        return (
            <MoreLinkContent
                text={text}
                moreLinkData={moreLinkData}
                updateViewTo={updateViewTo}
                clickInfo={clickInfo}
                eventOptions={eventOptions}
                handleShowEventPreview={handleShowEventPreview}
                handleShowMoreEventPreview={handleShowMoreEventPreview}
                onClickDateDetail={onClickDateDetail}
                isWorkScheduleView={isWorkScheduleView}
                isShowGoing={isShowGoing}
            />
        )
    }

    const onClickDateDetail = (date) => {
        setUpdateViewTo("DAY")
        setSelectedDay(date)
    }

    const renderFullCalender = () => {
        return (
            <FullCalendar
                events={allEvents}
                initialView={VIEWS.WEEK}
                eventOverlap={false}
                editable={false}
                selectable={false}
                selectMirror
                allDaySlot
                dayMaxEvents={2}
                dayMaxEventRows={2}
                slotDuration="01:00:00"
                scrollTime="08:00:00"
                firstDay={0}
                contentHeight={renderWorkWeek ? 880 : 600}
                eventOrder="order"
                nowIndicator
                navLinks
                navLinkDayClick={changeToDayView}
                // TODO locale={model.getFullCalendarLocale()}
                datesSet={onChangeDatesSet}
                ref={calendarRef}
                plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
                customButtons={{
                    todayBtn: {
                        text: "Today",
                        click: onClickToday
                    },
                    prevIcon: {
                        icon: "chevron-left",
                        click: onClickPrevMonth
                    },
                    nextIcon: {
                        icon: "chevron-right",
                        click: onClickNextMonth
                    }
                }}
                headerToolbar={{
                    start: "todayBtn prevIcon,nextIcon",
                    center: "title",
                    end: ""
                }}
                dayCellClassNames={({date}) => {
                    const dayOfWeek = moment(date).day()
                    if (!isEmpty(holidayDays)) {
                        const isHolidayDay = holidayDays.some((holidayDay) =>
                            moment(date).startOf("date").isSame(moment(holidayDay).startOf("date"))
                        )
                        if (isHolidayDay) {
                            return "holiday-day"
                        }
                    }
                    return ""
                }}
                dayHeaderFormat={({date, localeCodes}) => {
                    const newDate = moment(date.array).locale(localeCodes[0])
                    if (updateViewTo === VIEWS.MONTH) {
                        return `${moment(newDate).format("ddd")}`
                    }
                    return `${moment(newDate).format("ddd")} \n ${moment(newDate).format("D")}`
                }}
                views={{
                    timeGridWeek: {
                        titleFormat: {year: "numeric", month: "long"}
                    }
                }}
                eventTimeFormat={{
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false
                }}
                slotLabelFormat={{
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: model.user?.timeFormat === Auth.TimeFormat.Time_12
                }}
                scrollTimeReset={false}
                dateClick={handleDateClick}
                eventContent={renderEventContent}
                eventClick={handleEventClick}
                moreLinkContent={renderMoreLinkContent}
                moreLinkClick={handleMoreLinkClick}
                {...rest}
            />
        )
    }

    // Display in Panel
    if (!isFullDisplay) {
        const displayEvents = allEvents.filter((x) => moment(x.start).isSame(selectedDay, "day"))
        return (
            <div className={styles.panelWrap}>
                <CalendarPicker selectedDay={selectedDay} onDayClick={onDayClick} displayMode={displayMode} />
                <div className={styles.hideCalendar}>{renderFullCalender()}</div>
                <BaseLoading isShow={isLoading} />
                {displayEvents.length > 0 ? (
                    displayEvents.map((item, index) => {
                        const {title, start, startDateTime, endDateTime, allDay, color, ownerFullName, reminders} = item
                        const editEventInfo = eventOptions.find(
                            (eventOption) =>
                                eventOption.icon === "EDIT" && (!eventOption.canShow || eventOption.canShow(item))
                        )

                        return (
                            <div
                                key={index}
                                className={cx(styles.panelItemWrap, {[styles.cursor]: editEventInfo ? true : false})}
                                style={{borderColor: color}}
                                onClick={() => editEventInfo?.action(item)}>
                                <div className={styles.panelItemTitleWrap}>
                                    <Icon className={styles.panelItemIcon} icon="CALENDAR_INPUT" />
                                    <div className={styles.panelItemTitle}>{title}</div>
                                </div>
                                <div className={styles.contentWrap}>
                                    <div className={styles.contentTitle}>Date</div>
                                    <div className={styles.contentValue}>
                                        {moment(start).format(model.getUserDateFormat())}
                                    </div>
                                </div>
                                <div className={styles.contentWrap}>
                                    <div className={styles.contentTitle}>Time</div>
                                    <div className={styles.contentValue}>
                                        {allDay
                                            ? "Whole Day"
                                            : `${moment(startDateTime).format(model.getUserTimeFormat())} - ${moment(
                                                  endDateTime
                                              ).format(model.getUserTimeFormat())}`}
                                    </div>
                                </div>
                                <div className={styles.contentWrap}>
                                    <div className={styles.contentTitle}>Reminders</div>
                                    <div
                                        className={styles.contentValue}
                                        dangerouslySetInnerHTML={{
                                            __html: reminders?.map((reminder) => reminder.label).join("<br/>")
                                        }}
                                    />
                                </div>
                                <div className={styles.contentWrap}>
                                    <div className={styles.contentTitle}>Owner</div>
                                    <div className={styles.contentValue}>{ownerFullName}</div>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <div>No Event Found</div>
                )}
            </div>
        )
    }

    return (
        <div className={styles.root}>
            <div className={styles.leftSide}>
                <div className={styles.viewOptionsWrap}>
                    <ViewOptions viewOptions={viewOptions} t={t} onChange={onChangeView} updateViewTo={updateViewTo} />
                </div>
                <CalendarPicker selectedDay={selectedDay} onDayClick={onDayClick} />
                {showConnectedCalendars && <ConnectedCalendars />}
                {renderWorkWeek?.()}
            </div>
            <div className={styles.rightSide}>
                <BaseLoading isShow={isLoading} />
                {renderFullCalender()}
            </div>
        </div>
    )
}
