import React, {useRef, useState} from "react"
import cx from "classnames"
import {Button} from "antd"
import {BasePagination} from "uiKit"
import {Icon} from "components/Icon"
import styles from "./KlassappTableHeader.module.css"
import {DisplayMode} from "types/common"
import {ColumnPicker} from "./parts"
import {isEmpty} from "lodash"

export type Action = {
    title: string
    icon: any
    action: any
    iconClassName?: string
}

export type KlassappTableHeaderProps = {
    page: number
    total: number
    defaultPageSize: number
    onChangePage: Function
    onChangeRowPerPage: Function
    actions?: Action[]
    isShowAction?: boolean
    className?: string
    displayMode?: DisplayMode
    hasShowAll?: boolean
    isShowAllRecords?: boolean
    fields?: string[]
    allFields?: string[]
    unselectableFields?: string[]
    onChangeFields?: (fields: string[]) => void
    onChangeAllFields?: (fields: string[]) => void
    onDraggableColumn?: (sourceIndex: number, destIndex: number) => void
}

export function KlassappTableHeader(props: KlassappTableHeaderProps) {
    const {
        isShowAction,
        actions,
        className,
        displayMode = "normal",
        fields,
        allFields,
        unselectableFields,
        onChangeFields,
        onChangeAllFields,
        onDraggableColumn,
        ...rest
    } = props
    const isColumnDisplay = displayMode === "panel"
    const [isShowChooseCols, setIsShowChooseCols] = useState(false)
    const colsPickerRef = useRef(null)

    const onShowChooseCols = () => {
        setIsShowChooseCols(true)
    }

    return (
        <div className={cx(styles.wrap, {[styles.panelWrap]: isColumnDisplay}, className)}>
            {isShowAction && (
                <div className={styles.actionWrap}>
                    {actions.map((action, index) => (
                        <div key={index} className={styles.actionItem} onClick={action.action}>
                            <Icon className={cx(styles.actionItemIcon, action.iconClassName)} icon={action.icon} />
                            <p className={styles.actionItemTitle}>{action.title}</p>
                        </div>
                    ))}
                </div>
            )}
            <div className={isColumnDisplay ? styles.paginationPanelWrap : styles.paginationWrap}>
                <BasePagination {...rest} displayMode={displayMode} />
            </div>
            {!isEmpty(fields) && (
                <div className={styles.columnPicker}>
                    <Button
                        className={styles.plusIconBtn}
                        icon={<Icon icon="COLUMNS" className={styles.iconPlus} />}
                        onClick={onShowChooseCols}
                    />
                </div>
            )}
            {isShowChooseCols && (
                <div ref={colsPickerRef}>
                    <ColumnPicker
                        fields={fields}
                        allFields={allFields}
                        unselectableFields={unselectableFields}
                        onChangeFields={onChangeFields}
                        onChangeAllFields={onChangeAllFields}
                        setIsShowChooseCols={setIsShowChooseCols}
                        onDraggableColumn={onDraggableColumn}
                    />
                </div>
            )}
        </div>
    )
}
