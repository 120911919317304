import {useState, useRef} from "react"
import {useHistory} from "react-router-dom"
import FullCalendar from "@fullcalendar/react"
import {useEdularModulesContext, Modules} from "@edular/modules"
import {get} from "lodash"
import moment from "moment"
import "moment/min/locales"
import {useModel, useVisible} from "hooks"
import {OPTIONS_FREQUENCY, OPTIONS_FREQUENCY_TYPE} from "sections/calendar/parts/common/constants"
import {calendarService, userServiceV3} from "services"
import {handleError, toastError} from "helpers"
import {Calendar} from "components/Calendar"
import {ChooseRecurringPopup} from "components/Calendar/Common"
import {CalendarEventOption, RSVP, RecurringPopupType} from "types/calendar"
import {CalendarEventPopup} from "../EventPopup"
import styles from "./PersonalCalendar.module.css"
import cx from "classnames"
import {DisplayMode} from "types/common"
import {ViewOtherUserChatCalendarButton, ViewOtherUserChatCalendarPopup} from "components/SharedChatCalendar"
import {PermissionType} from "@edular/permissions"
import {useGetCalendarEvents} from "./useGetCalendarEvents"

type PersonalCalendarProps = {
    displayMode?: DisplayMode
}

const INITIAL_EVENT_DATA = {
    publicId: "",
    name: "",
    typeEvent: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    rsvp: RSVP.Yes,
    recurring: OPTIONS_FREQUENCY[0],
    end: OPTIONS_FREQUENCY_TYPE[0],
    executions: "",
    every: "",
    days: {},
    monthlyRecurring: 0,
    monthDay: {},
    monthWeek: {},
    monthWeekDay: {},
    notes: "",
    allDay: false,
    withPushNotification: true,
    withSMS: false,
    withEmail: false,
    rrule: undefined
}

const PersonalCalendar = (props: PersonalCalendarProps) => {
    const {displayMode = "normal"} = props
    const model = useModel()
    const calendarRef = useRef<FullCalendar>()
    const [tempEventId, setTempEventId] = useState(null)
    const [recurringPopupData, setRecurringPopupData] = useState({
        type: null,
        data: null
    })
    const [initialEventData, setInitialEventData] = useState<any>(INITIAL_EVENT_DATA)
    const eventPopup = useVisible(false)
    const chooseRecurringPopup = useVisible(false)
    const history = useHistory()
    const viewOtherUserCalendarPopup = useVisible(false)
    const isFullDisplay = displayMode === "normal"
    const {events, isLoading, calendarId, setEvents, reloadEvents, setDateRange} = useGetCalendarEvents()
    const {isModuleEnable} = useEdularModulesContext()

    const closeViewOtherCalendar = async () => {
        model.viewOtherUserCalendar = null
        calendarService.setViewOtherUserId(null)

        await reloadEvents()
    }

    const onClickDeleteEvent = async (event) => {
        const eventId = +event.id
        const rrule = get(event, "_def.extendedProps.rrule")

        if (rrule) {
            setTempEventId(eventId)
            chooseRecurringPopup.open()
            setRecurringPopupData({
                type: RecurringPopupType.Delete,
                data: null
            })
            return
        }

        await deleteEvent(eventId)
    }

    const deleteEvent = async (eventId, target_filter = "only_this") => {
        try {
            const newEvents = events.filter((item) => item.id !== eventId)
            setEvents(newEvents)

            await calendarService.deleteEvent(eventId, {
                target_filter
            })

            reloadEvents()
        } catch (error) {
            handleError(error)
        }
    }

    const onClickUpdateGoingEvent = async (event, will_go) => {
        const eventId = +event.id
        const rrule = get(event, "_def.extendedProps.rrule")

        if (rrule) {
            setTempEventId(eventId)
            chooseRecurringPopup.open()
            setRecurringPopupData({
                type: RecurringPopupType.Update,
                data: {
                    will_go
                }
            })

            return
        }

        await updateGoingEvent(eventId, will_go)
    }

    const updateGoingEvent = async (object_id, will_go, target_filter = "only_this") => {
        try {
            await calendarService.updateStatus({
                object_id,
                target_filter,
                will_go
            })

            reloadEvents()
        } catch (error) {
            handleError(error)
        }
    }

    const onCreateEventSuccess = async () => {
        await reloadEvents()
    }

    const onDateClick = (date) => {
        if (model.viewOtherUserCalendar?.id && !model.viewOtherUserCalendar?.hasPermissionToAdd) {
            return
        }

        setInitialEventData({
            ...initialEventData,
            allDay: date.allDay,
            startDate: moment(date.date).format(),
            startTime: moment(date.date),
            endTime: moment(date.date).add(1, "hour"),
            hostUsers: [{...(model.viewOtherUserCalendar ? model.viewOtherUserCalendar : model.user), isFixed: true}]
        })

        eventPopup.open()
    }

    const onClickEditEvent = (event) => {
        history.push(`/calendar-event/detail?id=${event.id}`)
    }

    const canShowEditIcon = (event) => {
        return !event?.extendedProps?.isLesson && !event?.extendedProps?.isAppointmentSlot
    }

    const canShowDelete = (event) => {
        return !event?.extendedProps?.isLesson
    }

    const getEventOptions = (): CalendarEventOption[] => {
        return [
            {
                title: "More detail",
                icon: "EDIT",
                action: onClickEditEvent,
                iconClassName: styles.eventPreviewHeaderEditIcon,
                canShow: canShowEditIcon
            },
            {title: "Delete event", icon: "DELETE", action: onClickDeleteEvent, canShow: canShowDelete},
            {title: "Going", icon: "CALENDAR_INPUT", action: onClickUpdateGoingEvent}
        ]
    }

    const onConfirmRecurring = async (target_filter) => {
        chooseRecurringPopup.close()

        if (recurringPopupData.type === RecurringPopupType.Delete) {
            await deleteEvent(tempEventId, target_filter)
        } else {
            await updateGoingEvent(tempEventId, recurringPopupData.data.will_go, target_filter)
        }
    }

    const onSelectStaff = async (selectedStaff) => {
        try {
            if (selectedStaff) {
                calendarService.setViewOtherUserId(selectedStaff.id)
                model.viewOtherUserCalendar = selectedStaff
                const promises: Array<Promise<any>> = []
                const commonPayload = {
                    filter: {
                        userIds: [selectedStaff.id],
                        sharedType: "calendar"
                    },
                    range: {
                        pageSize: 1,
                        page: 1
                    }
                }

                promises.push(
                    userServiceV3.getUsersViewOthersChatCalendar({
                        ...commonPayload,
                        filter: {
                            ...commonPayload.filter,
                            permissionType: PermissionType.Add
                        }
                    })
                )
                promises.push(
                    userServiceV3.getUsersViewOthersChatCalendar({
                        ...commonPayload,
                        filter: {
                            ...commonPayload.filter,
                            permissionType: PermissionType.Edit
                        }
                    })
                )
                promises.push(
                    userServiceV3.getUsersViewOthersChatCalendar({
                        ...commonPayload,
                        filter: {
                            ...commonPayload.filter,
                            permissionType: PermissionType.Delete
                        }
                    })
                )

                const [{data: dataAddPermissions}, {data: dataEditPermissions}, {data: dataDeletePermissions}] =
                    await Promise.all(promises)

                model.viewOtherUserCalendar.hasPermissionToAdd = Boolean(dataAddPermissions?.length)
                model.viewOtherUserCalendar.hasPermissionToEdit = Boolean(dataEditPermissions?.length)
                model.viewOtherUserCalendar.hasPermissionToDelete = Boolean(dataDeletePermissions?.length)

                viewOtherUserCalendarPopup.close()
                await reloadEvents()
            }
        } catch (e) {
            toastError("Error to show other calendar, please try again")

            await closeViewOtherCalendar()
        }
    }

    const eventOptions = getEventOptions()

    return (
        <>
            <div className={styles.viewOtherUserCalendar}>
                <ViewOtherUserChatCalendarButton
                    type="calendar"
                    displayMode={displayMode}
                    onGoBack={closeViewOtherCalendar}
                    onOpen={viewOtherUserCalendarPopup.open}
                    viewOtherUserIdCalendar={model.viewOtherUserCalendar?.id}
                />
            </div>
            <div className={cx(styles.root, {[styles.rootPanel]: !isFullDisplay})}>
                <Calendar
                    calendarRef={calendarRef}
                    events={events}
                    isLoading={isLoading}
                    eventOptions={eventOptions}
                    isShowGoing
                    showConnectedCalendars={
                        isModuleEnable(Modules.OutlookCalendar) || isModuleEnable(Modules.GoogleCalendar)
                    }
                    onDateClick={onDateClick}
                    onChangeDatesSet={(data) => setDateRange(data)}
                    displayMode={displayMode}
                />
                {eventPopup.isVisible && (
                    <CalendarEventPopup
                        model={model}
                        calendarId={calendarId}
                        isShow={eventPopup.isVisible}
                        onClose={eventPopup.close}
                        initialEventData={initialEventData}
                        onCreateSuccess={onCreateEventSuccess}
                    />
                )}
                <ChooseRecurringPopup
                    isShow={chooseRecurringPopup.isVisible}
                    onClose={() => {
                        chooseRecurringPopup.close()
                    }}
                    type={recurringPopupData.type}
                    onClickSave={onConfirmRecurring}
                />
            </div>

            {viewOtherUserCalendarPopup.isVisible && (
                <ViewOtherUserChatCalendarPopup
                    isShow={viewOtherUserCalendarPopup.isVisible}
                    onClose={viewOtherUserCalendarPopup.close}
                    onConfirm={onSelectStaff}
                    type="calendar"
                />
            )}
        </>
    )
}

export default PersonalCalendar
