/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useReducer, useState} from "react"
import {
    Status,
    StudentFilterV2,
    StudentForm,
    StudentListTable,
    StudentPriorityList,
    ViewType
} from "sections/shared/students"
import {checkPermission} from "helpers"
import {Permissions} from "types/permission"
import {ActiveStudentContext} from "context/ActiveStudentContext"
import {AppliedFilter, FieldOperator, FilterKey} from "types/filter"
import {useModel, useSearchParam} from "hooks"
import {isEmpty} from "lodash"
import {ExportFileType} from "components/ui"
import {BaseDepartmentId} from "types/departments"

type State = {
    activeStudent: any
    staffs: any
    statsInfo: any
    studentForm: StudentForm
    studentFormSearch: string
    isExporting: boolean
    exportFileType: any
}

type Props = {
    departmentId: number
}

const INITIAL_FILTER: AppliedFilter = {
    search: "",
    filters: {
        includeArchive: {operator: FieldOperator.Equal, value: false}
    }
}

export function FinancialAidStaffStudentTab(props: Props) {
    const model = useModel()
    const storageFilter = model.getStorageFilter(FilterKey.FinAidPage_Students)
    const initialState: State = {
        activeStudent: {},
        staffs: [],
        statsInfo: [],
        studentFormSearch: !isEmpty(storageFilter) ? storageFilter.search : "",
        studentForm: !isEmpty(storageFilter?.filters) ? storageFilter.filters : {},
        isExporting: false,
        exportFileType: null
    }

    const {value: studentId} = useSearchParam("studentId", {parse: (value) => +value})

    function reducer(state, action) {
        return {...state, ...action}
    }

    const [state, dispatch] = useReducer(reducer, initialState)
    const [viewType, setViewType] = useState<ViewType>("standard") // model.getUserTableView()
    const {
        studentDetailVisible,
        setStudent,
        setDepartmentId,
        studentStatusChange,
        reloadStudent,
        reloadStudentAdvisors
    } = useContext(ActiveStudentContext)

    useEffect(() => {
        if (state.activeStudent && state.activeStudent.profileId) {
            setStudent(state.activeStudent)
            setDepartmentId(props.departmentId)
            studentDetailVisible.open()
            reloadStudent(state.activeStudent.profileId)
            reloadStudentAdvisors(state.activeStudent.profileId)
        }
    }, [state.activeStudent])

    const canShowAllStudents = React.useMemo(
        () => checkPermission({staff: [Permissions.Staff.FinancialAid.Director.View]}, model),
        [model.myPermissions]
    )

    const canHaveAccessOthers = React.useMemo(
        () => checkPermission({staff: [Permissions.Staff.FinancialAid.Students.PermissionsToAccessOthers.View]}, model),
        [model.myPermissions]
    )

    const renderContent = () => {
        switch (viewType) {
            case "standard":
            case "kanban":
                return (
                    <StudentListTable
                        studentStatusChange={studentStatusChange}
                        departmentId={props.departmentId}
                        studentId={studentId}
                        studentForm={state.studentForm}
                        studentFormSearch={state.studentFormSearch}
                        updateState={dispatch}
                        canShowAllStudents={canShowAllStudents}
                        canHaveAccessOthers={canHaveAccessOthers}
                        filterMemoryKey={FilterKey.FinAidPage_Students}
                        reloadStudentAdvisors={reloadStudentAdvisors}
                        exportFileType={state.exportFileType}
                        useNewFilter
                    />
                )
            case "priority":
            default:
                return (
                    <StudentPriorityList
                        departmentId={BaseDepartmentId.FinancialAid}
                        studentForm={state.studentForm}
                        studentFormSearch={state.studentFormSearch}
                        updateState={dispatch}
                        useNewFilter
                    />
                )
        }
    }

    const handleExportFile = async (type: ExportFileType) => {
        dispatch({exportFileType: type})
    }

    const onApplyFilter = (payload: AppliedFilter) => {
        const {filters, search} = payload
        dispatch({studentForm: filters, studentFormSearch: search})
    }

    return (
        <div>
            <StudentFilterV2
                status={Status.finAidStatus}
                canShowAllStudents={canShowAllStudents}
                canHaveAccessOthers={canHaveAccessOthers}
                // viewTypes={["standard", "priority"]}
                onChangeViewType={setViewType}
                viewType={viewType}
                filterKey={FilterKey.FinAidPage_Students}
                departmentId={props.departmentId}
                handleExport={handleExportFile}
                hideLeadRecordAction={viewType === "priority"}
                showExport={viewType !== "priority"}
                isExporting={state.isExporting}
                initialFilter={INITIAL_FILTER}
                onApplyFilter={onApplyFilter}
                onClearFilter={() => onApplyFilter({filters: {}, search: ""})}
            />
            {renderContent()}
        </div>
    )
}
