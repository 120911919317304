/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router-dom"
import {taskService} from "services"
import {KlassappTableHeader, KlassappTable} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps} from "types/common"
import styles from "./CustomDDTask.module.css"
import {BaseButton, Icon} from "components"
import {TaskSubtype, TaskType} from "types/tasks"
import {handleError} from "helpers"
import {useMutation} from "hooks"
import {useQuery} from "@tanstack/react-query"

type Props = KlassappTableProps & {search: string}

const CustomDDTask = (props: Props) => {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        isLoading,
        menuActions,
        isHideMenuActions,
        isShowTableHeaderAction,
        orderField,
        isLoadedTableFuncs,
        search
    } = props

    const {t} = useTranslation(["tasks"])
    const history = useHistory()
    const {refetch: getCustomDigitalTasks} = useQuery(
        ["get-custom-digital-tasks", page, pageSize, search],
        () =>
            taskService.getCustomDigitalTasks({
                filter: {subtype: TaskSubtype.LIBRARY, search},
                range: {page, pageSize}
            }),
        {
            enabled: false,
            onSuccess: ({data: tasks, total}) => dispatch({data: tasks, total}),
            onError: handleError,
            onSettled: () => dispatch({isLoading: false})
        }
    )
    const {mutateAsync: deleteCustomDigitalTasks} = useMutation(
        (taskIds: number[]) => taskService.deleteCustomDigitalTask(taskIds),
        {
            onSuccess: () => {
                props.dispatch({isLoading: false})
                getData()
            },
            onError: (error) => handleError(error)
        }
    )

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickEdit", func: onClickShowDetail},
            {key: "getMenuActions", func: getMenuActions},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickShowDetail}
        ])
    }, [])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, search])

    const getPageTitle = () => {
        return t("tasks.task")
    }

    const getFields = () => {
        return [t("tasks.name"), t("tasks.assignedBy"), t("tasks.createdAt")]
    }

    const getColumns = () => {
        return [
            {
                title: t("tasks.name"),
                field: "name",
                style: {minWidth: "150px"}
            },
            {
                title: t("tasks.assignedBy"),
                field: "assignedBy",
                style: {minWidth: "150px"}
            },
            {
                title: t("tasks.createdAt"),
                field: "createdAt",
                fieldType: "dateTime",
                style: {minWidth: "160px"}
            }
        ]
    }

    const getTableHeaderActions = () => {
        const {onClickShowConfirmModal} = props
        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
    }

    const getMenuActions = () => {
        return [
            {
                title: t("common:action.configure"),
                icon: "EDIT",
                action: onClickConfigure
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: onClickDelete
            }
        ]
    }

    const getData = async () => {
        dispatch({isLoading: true})
        getCustomDigitalTasks()
    }

    const onClickConfigure = (editItem) => {
        history.push(`/tasks/detail?type=${editItem.type}&subtype=library&id=${editItem.id}&configure=1`)
    }

    const onClickDeleteMulti = () => {
        props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
        const checkedItems = props.getCurrentData().filter((item) => item.isChecked)
        const taskIds = checkedItems.map((item) => item.id)
        deleteCustomDigitalTasks(taskIds)
    }

    const onClickDelete = (deletedItem) => {
        dispatch({isLoading: true})
        deleteCustomDigitalTasks([deletedItem.id])
    }

    const onClickAdd = () => {
        history.push(`/tasks/detail?type=${TaskType.CUSTOM_DD}&subtype=${TaskSubtype.LIBRARY}`)
    }

    const onClickShowDetail = (data) => {
        history.push({
            pathname: "/tasks/detail",
            search: `type=${TaskType.CUSTOM_DD}&subtype=library&id=${data.id}`
        })
    }

    return (
        <>
            <div className={styles.createBtnWrap}>
                <BaseButton
                    title={t("tasks.createTask")}
                    onClick={onClickAdd}
                    icon={<Icon className={styles.plusIcon} icon="PLUS" color="#FFF" />}
                />
            </div>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                menuActions={isHideMenuActions ? [] : menuActions}
                data={data}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                orderField={orderField}
                isShowCheckedColumn
                onClickRowItem={onClickShowDetail}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onClickSortColumn={props.onClickSortColumn}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </>
    )
}
export default KlassappTableHOC(CustomDDTask)
