/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react"
import FullCalendar from "@fullcalendar/react"
import {useModel, useVisible} from "hooks"
import {useTranslation} from "react-i18next"
import moment from "moment"
import debounce from "debounce-promise"
import {get, head, isEmpty, keyBy, uniq} from "lodash"
import "moment/min/locales"
import {
    OPTIONS_DAYS_LONG,
    OPTIONS_FREQUENCY,
    OPTIONS_FREQUENCY_TYPE,
    OPTIONS_MONTHLY_FREQUENCY,
    OPTIONS_MONTH_DAYS,
    OPTIONS_TIME_SLOTS,
    OPTIONS_WEEK_DAYS,
    RRULE_DAYS,
    RRULE_OPTIONS_FREQUENCY
} from "sections/calendar/parts/common/constants"
import cx from "classnames"
import {
    calendarService,
    courseScheduleService,
    courseService,
    generalSettingsService,
    resourceServiceV3,
    userServiceV3
} from "services"
import {Label} from "components/inputs/Label"
import {KlassDropAsyncPaginate} from "components/Select"
import {getFullName, handleError, toastError, toastSuccess} from "helpers"
import {Calendar} from "components/Calendar"
import {ChooseRecurringPopup} from "components/Calendar/Common"
import {CalendarEventOption, CalendarEventType, CalendarRecurringType, RecurringPopupType} from "types/calendar"
import {BookingModal} from "../common"
import {CalendarAvailabilityEventPopup} from "../EventPopup"
import styles from "./CalendarAvailability.module.css"
import {useLocation} from "react-router-dom"
import {rrulestr} from "rrule"
import {checkIsTermMajorSystemEvent, getEndDateTime, getStartDateTime} from "helpers/calendar"
import {GeneralSetting, GeneralSettingField} from "types/settings/general-setting"
import {YesNo} from "types/common"

const DEFAULT_EVENT_DATA = {
    location: null,
    timeSlots: null,
    publicId: "",
    title: "",
    typeEvent: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    recurring: OPTIONS_FREQUENCY[0],
    end: OPTIONS_FREQUENCY_TYPE[0],
    executions: "",
    every: "",
    days: {},
    monthlyRecurring: 0,
    monthDay: {},
    monthWeek: {},
    monthWeekDay: {},
    notes: "",
    rrule: undefined
}

const CalendarAvailability = () => {
    const {t} = useTranslation(["calendar", "common"])
    const location: any = useLocation()
    const model = useModel()
    const calendarRef = useRef<FullCalendar>()
    const [isLoading, setIsLoading] = useState(false)
    const [calendarId, setCalendarId] = useState(null)
    const [myCalendarId, setMyCalendarId] = useState(null)
    const [tempEventId, setTempEventId] = useState(null)
    const [allEvents, setAllEvents] = useState([])
    const [selectedEvent, setSelectedEvent] = useState<any>({})
    const [selectedStaff, setSelectedStaff] = useState<any>(() => {
        let user = {
            ...model.user,
            fullName: `${getFullName(model.user)} (me)`
        }
        if (location?.state?.userId) {
            user = model.getOneUser(location?.state?.userId)
            user.fullName = getFullName(user)
        }
        return user
    })
    const eventPopup = useVisible(false)
    const showBookingPopup = useVisible(false)
    const chooseRecurringPopup = useVisible(false)
    const [eventData, setEventData] = useState<any>(DEFAULT_EVENT_DATA)
    const [locationsList, setLocationsList] = useState([])
    const [fieldSettings, setFieldSettings] = useState<GeneralSetting>(null)
    const userId = +get(model, "user.id")

    useEffect(() => {
        initData()
    }, [])

    useEffect(() => {
        if (calendarId) {
            reloadEvents()
        }
    }, [calendarId])

    const getPayload = (calendarId, from, to, publicTerm) => {
        const utcFrom = moment(from).utc().format()
        const utcTo = moment(to).utc().format()
        const basePayload = {
            sort: {
                orderBy: "start_at_utc",
                order: "ASC"
            },
            filter: {
                from: utcFrom,
                to: utcTo
            },
            pagination: {
                page: 1,
                pageSize: 100
            }
        }
        const payload = {
            ...basePayload,
            filter: {
                ...basePayload.filter,
                public_terms: publicTerm,
                public_work_schedules: true,
                owner_user_ids: [userId],
                calendar_ids: [+calendarId],
                types: [CalendarEventType.Event, CalendarEventType.CourseSchedule]
            }
        }
        const invitesCalendarPayload = {
            sort: {
                orderBy: "start_at_utc",
                order: "ASC"
            },
            filter: {
                from,
                to,
                invited_user_ids: [userId]
            },
            pagination: {
                page: 1,
                pageSize: 100 // TODO fix this
            }
        }
        const bookedPayload = {
            ...basePayload,
            filter: {
                ...basePayload.filter,
                appointment_slot_status: "booked"
            },
            or_filter: {
                owner_user_ids: [userId],
                appointment_slot_status: "booked",
                booker_user_ids: [userId],
                from,
                to
            }
        }
        return {payload, invitesCalendarPayload, bookedPayload}
    }

    const getUsers = async (userIds) => {
        if (userIds.length) {
            userIds = uniq(userIds)
            const userPayload = {
                filter: {id: userIds},
                range: {limit: userIds.length, offset: 0},
                linkedObjects: false
            }
            try {
                const {data} = await userServiceV3.getAll(userPayload)
                return data
            } catch (error) {
                return []
            }
        }
        return []
    }

    const getCourseSchedules = async (courseScheduleIds) => {
        if (courseScheduleIds.length) {
            courseScheduleIds = uniq(courseScheduleIds)
            try {
                const {data} = await courseScheduleService.courseScheduleGet({
                    filter: {courseScheduleIds: courseScheduleIds}
                })
                return data
            } catch (error) {
                return []
            }
        }
        return []
    }

    const getCourses = async (courseIds) => {
        if (courseIds.length) {
            courseIds = uniq(courseIds)
            try {
                const {data} = await courseService.list({filter: {courseIds}, linkedEntities: ["submodule"]})
                return data
            } catch (error) {
                return []
            }
        }
        return []
    }

    const getPersonalEvents = async (calendarId, from, to, publicTerm) => {
        const {payload, invitesCalendarPayload, bookedPayload} = getPayload(calendarId, from, to, publicTerm)
        const [{data: myEvents}, {data: myInvitesCalendar}, {data: myInvites}] = await Promise.all([
            calendarService.getAll(payload),
            calendarService.getAllInvitesCalendar(invitesCalendarPayload),
            calendarService.getAllInvites(invitesCalendarPayload)
        ])
        const myInvitesCalendarEvents = myInvitesCalendar
            .filter((event) => event.invited_user_id !== event.object?.owner_user_id)
            .map((item) => ({...item.object, ...item.group, willGo: item.will_go}))
        const myInvitesEvents = myInvites
            .filter((event) => event.invited_user_id !== event.object?.owner_user_id)
            .map((item) => ({...item.object, ...item.group, willGo: item.will_go}))
        let userIds = []
        let courseScheduleIds = []
        let courseIds = []
        if (myEvents.length) {
            const newUserIds = myEvents
                .filter((item) => item.owner_user_id !== userId)
                .map((event) => event.owner_user_id)
            userIds = [...userIds, ...newUserIds]
        }
        if (myInvitesCalendarEvents.length) {
            const newUserIds = myInvitesCalendarEvents.map((item) => item.owner_user_id)
            const scheduleIds = myInvitesCalendarEvents.map((item) => item.json_data.schedule_id).filter((item) => item)
            const courseIdsData = myInvitesCalendarEvents.map((item) => item.json_data.course_id).filter((item) => item)
            courseScheduleIds = [...courseScheduleIds, ...scheduleIds]
            courseIds = [...courseIds, ...courseIdsData]
            userIds = [...userIds, ...newUserIds]
        }
        if (myInvitesEvents.length) {
            const newUserIds = myInvitesEvents.map((item) => item.owner_user_id)
            const scheduleIds = myInvitesEvents.map((item) => item.json_data.schedule_id).filter((item) => item)
            const courseIdsData = myInvitesCalendarEvents.map((item) => item.json_data.course_id).filter((item) => item)
            courseScheduleIds = [...courseScheduleIds, ...scheduleIds]
            courseIds = [...courseIds, ...courseIdsData]
            userIds = [...userIds, ...newUserIds]
        }
        const [users, courseSchedules, courses] = await Promise.all([
            getUsers(userIds),
            getCourseSchedules(courseScheduleIds),
            getCourses(courseIds)
        ])
        const usersKeyById = keyBy(users, "id")
        const courseSchedulesById = keyBy(courseSchedules, "id")
        const coursesById = keyBy(courses, "courseId")
        const myInvitesKeyByObjectId = keyBy(myInvites, "object_id")
        myEvents.forEach((event) => {
            const isCurrentUser = event.owner_user_id === userId
            if (myInvitesKeyByObjectId[event.object_id]) {
                event.willGo = get(myInvitesKeyByObjectId[event.object_id], "will_go")
            }
            event.ownerFullName = isCurrentUser
                ? getFullName(model.user)
                : getFullName(usersKeyById[event.owner_user_id])
        })
        myInvitesCalendarEvents.forEach((event) => {
            const isCurrentUser = event.owner_user_id === userId
            event.ownerFullName = isCurrentUser
                ? getFullName(model.user)
                : getFullName(usersKeyById[event.owner_user_id])
            if (event.json_data.schedule_id) {
                event.courseScheduleInfo = courseSchedulesById[event.json_data.schedule_id]
                event.courseInfo = coursesById[event.json_data.course_id]
            }
        })
        myInvitesEvents.forEach((event) => {
            const isCurrentUser = event.owner_user_id === userId
            event.ownerFullName = isCurrentUser
                ? getFullName(model.user)
                : getFullName(usersKeyById[event.owner_user_id])
            if (event.json_data.schedule_id) {
                event.courseScheduleInfo = courseSchedulesById[event.json_data.schedule_id]
                event.courseInfo = coursesById[event.json_data.course_id]
            }
        })
        const events = [...myEvents, ...myInvitesCalendarEvents, ...myInvitesEvents]
        return events
    }

    const initData = async () => {
        const {data} = await calendarService.getMyPrimaryCalendar({}, {})
        const calendarId = data.calendar_id
        setCalendarId(calendarId)
        setMyCalendarId(calendarId)
        await getLocations()
        if (location?.state?.userId) {
            await onSelectStaff(selectedStaff)
        }
    }

    const getLocations = async () => {
        try {
            const params = {
                filter: {
                    resourceType: ["location"]
                }
            }
            const {data: locations} = await resourceServiceV3.list(params)
            setLocationsList(locations)
        } catch (e) {}
    }

    const onDateClick = (date) => {
        if (userId !== selectedStaff.id) {
            return
        }
        setEventData({
            location: null,
            timeSlots: null,
            title: "",
            recurring: OPTIONS_FREQUENCY[0],
            end: OPTIONS_FREQUENCY_TYPE[0],
            executions: "",
            every: "",
            days: {},
            monthlyRecurring: 0,
            monthDay: {},
            monthWeek: {},
            monthWeekDay: {},
            notes: "",
            rrule: null,
            startDate: moment(date.date).format(),
            startTime: moment(date.date),
            endTime: moment(date.date).add(1, "hour")
        })
        eventPopup.open()
    }

    const getAppointmentEvents = async (calendarId, from, to) => {
        const payload = {
            sort: {orderBy: "start_at_utc", order: "ASC"},
            filter: {calendar_ids: [+calendarId], from, to, types: [CalendarEventType.AppointmentSlot]},
            pagination: {page: 1, pageSize: 100000}
        }
        const {data: events} = await calendarService.getAll(payload)
        return events
    }

    const reloadEvents = async () => {
        setIsLoading(true)
        const currentData = calendarRef.current.getApi().getCurrentData()
        const from = currentData.dateProfile.activeRange.start
        const to = currentData.dateProfile.activeRange.end
        let publicTerm = true
        if (isEmpty(fieldSettings)) {
            const response = await generalSettingsService.getSettings([
                GeneralSettingField.CalendarDisplayTermStartAndEndDates
            ])
            setFieldSettings(head(response))
            publicTerm = head(response).value === YesNo.Yes
        } else {
            publicTerm = fieldSettings.value === YesNo.Yes
        }
        const [appointmentEvents, personalEvents] = await Promise.all([
            getAppointmentEvents(calendarId, from, to),
            getPersonalEvents(calendarId, from, to, publicTerm)
        ])
        const convertedAppointmentEvents = convertEvents(appointmentEvents)
        const convertedPersonalEvents = convertPersonalEvents(personalEvents)
        setAllEvents([...convertedAppointmentEvents, ...convertedPersonalEvents])
        setIsLoading(false)
    }

    const onChangeDatesSet = async (date) => {
        if (calendarId) {
            try {
                setIsLoading(true)
                const publicTerm = fieldSettings.value === YesNo.Yes
                const [appointmentEvents, personalEvents] = await Promise.all([
                    getAppointmentEvents(calendarId, date.startStr, date.endStr),
                    getPersonalEvents(calendarId, date.startStr, date.endStr, publicTerm)
                ])
                const convertedAppointmentEvents = convertEvents(appointmentEvents)
                const convertedPersonalEvents = convertPersonalEvents(personalEvents)
                setAllEvents([...convertedAppointmentEvents, ...convertedPersonalEvents])
            } catch (error) {
                handleError(error)
            } finally {
                setIsLoading(false)
            }
        }
    }

    const getModuleTitle = (eventType, courseInfo) => {
        if (!eventType || !courseInfo) {
            return ""
        }
        switch (eventType) {
            case "classroom":
                return courseInfo.theoryOrClassroomModuleTitle || "Classroom"
            case "lab":
                return courseInfo.labModuleTitle || "Lab"
            case "test":
                return courseInfo.testModuleTitle || "Test"
            case "others":
                return courseInfo.othersModuleTitle || "Others"
            default:
                return ""
        }
    }

    const getSubModuleTitle = (subModuleId, courseInfo) => {
        if (!subModuleId || !courseInfo) {
            return null
        }
        const result = courseInfo.submodules.find((item) => item.submoduleId === subModuleId)
        return result?.title
    }

    const convertPersonalEvents = (initialEvents) => {
        const events = (initialEvents || []).map((event, index) => {
            const originalColor = event?.json_data?.color || "#1e90ff"
            const reminders = (event?.json_data?.notifications ?? []).map((value) => ({
                value,
                label: `${moment.duration(value / 60, "minutes").humanize()} before`
            }))
            const withSMS = !!event?.json_data?.with_sms
            const eventSignInRequired = !!event?.json_data?.eventSignInRequired
            const attendedChangeToStatus = !!event?.json_data?.attendedChangeToStatus
            const notAttendedChangeToStatus = !!event?.json_data?.notAttendedChangeToStatus
            const completionWorkflow = !!event?.json_data?.completionWorkflow
            const allDay =
                checkIsTermMajorSystemEvent(event.type) ||
                moment(event.end_at_utc).diff(moment(event.start_at_utc), "seconds") === 24 * 3600
            const startDateTime = getStartDateTime(event)
            const endDateTime = getEndDateTime(event)
            return {
                id: event.object_id,
                start: startDateTime.format(),
                end: endDateTime.format(),
                originalColor,
                borderColor: "transparent",
                color: "#cccccc",
                startDateTime,
                endDateTime,
                title: event.name,
                allDay,
                order: 1,
                classNames: "",
                initialEventIndex: index,
                description:
                    event.type === CalendarEventType.AppointmentSlot
                        ? get(event, "appointment_slot.booker_notes", event.description)
                        : event.description,
                isAppointmentSlot: event.type === CalendarEventType.AppointmentSlot,
                ownerUserId: event.owner_user_id,
                ownerFullName: event.ownerFullName,
                willGo: event.willGo,
                reminders,
                withSMS,
                eventSignInRequired,
                attendedChangeToStatus,
                notAttendedChangeToStatus,
                completionWorkflow,
                type: event.type,
                isLesson: !isEmpty(event.courseScheduleInfo),
                schedule_suffix: event.courseScheduleInfo?.schedule_suffix,
                moduleTitle: getModuleTitle(event.json_data.eventType, event.courseInfo),
                subModuleTitle: getSubModuleTitle(event.json_data.sub_module_id, event.courseInfo),
                isPersonalEvent: true,
                rrule: event.rrule
            }
        })
        return events
    }

    const convertEvents = (initialEvents) => {
        const events = (initialEvents || []).map((event, index) => {
            const allDay = moment(event.end_at_utc).diff(moment(event.start_at_utc), "seconds") === 24 * 3600
            return {
                id: event.object_id,
                start: moment(event.start_at_utc).local().format(),
                end: moment(event.end_at_utc).local().format(),
                startDateTime: moment(event.start_at_utc).local(),
                endDateTime: moment(event.end_at_utc).local(),
                color: event.appointment_slot?.booker_user_id
                    ? "var(--black-100)"
                    : event?.json_data?.color || "#1e90ff",
                title: "",
                order: 1,
                classNames: "",
                initialEventIndex: index,
                description: event.description,
                isAppointmentSlot: event.type === "appointment_slot",
                calendarId: event.calendar_id,
                appointmentSlot: event.appointment_slot,
                ownerUserId: event.owner_user_id,
                rrule: event.rrule,
                json_data: event.json_data,
                allDay,
                originalData: {...event}
            }
        })
        return events
    }

    const onClickDeleteEvent = async (event) => {
        const eventId = +event.id
        const rrule = get(event, "_def.extendedProps.rrule")
        if (rrule) {
            setTempEventId(eventId)
            chooseRecurringPopup.open()
            return
        }
        await deleteEvent(eventId)
    }

    const onClickEditEvent = (eventInfo) => {
        const {id, title, extendedProps, start, end} = eventInfo
        const rrule = get(eventInfo, "extendedProps.rrule")
        let initialEvent: any = {
            id: +id,
            title,
            startDate: moment(start).format(),
            endDate: null,
            startTime: moment(start),
            endTime: moment(end),
            recurring: OPTIONS_FREQUENCY[0],
            end: OPTIONS_FREQUENCY_TYPE[0],
            timeSlots: OPTIONS_TIME_SLOTS.find((item) => item.value === extendedProps.originalData.duration / 60),
            executions: "",
            every: "",
            days: {},
            monthlyRecurring: 0,
            monthDay: {},
            monthWeek: {},
            monthWeekDay: {},
            description: extendedProps.originalData.description,
            originalData: extendedProps.originalData,
            location: extendedProps.originalData.json_data?.location,
            rrule
        }
        if (rrule) {
            const rules = rrulestr(rrule)
            const {count, freq, interval, byweekday, bymonthday, bysetpos, until} = rules.origOptions
            initialEvent.rrule = rrule
            initialEvent.recurring = OPTIONS_FREQUENCY.find((option) => option.value === RRULE_OPTIONS_FREQUENCY[freq])
            if (initialEvent.recurring?.originalValue === CalendarRecurringType.Monthly && byweekday) {
                initialEvent.monthlyRecurring = 1
                initialEvent.monthWeek = OPTIONS_MONTHLY_FREQUENCY.find((option) => option.value === bysetpos)
                initialEvent.monthWeekDay = OPTIONS_DAYS_LONG[RRULE_DAYS[byweekday[0].weekday]]
            }
            if (byweekday) {
                const days = {}
                Object.keys(byweekday).forEach((key) => {
                    const day = RRULE_DAYS[byweekday[key].weekday]
                    days[day] = OPTIONS_WEEK_DAYS[day]
                })
                initialEvent.days = days
            }
            if (bymonthday) {
                initialEvent.monthDay = OPTIONS_MONTH_DAYS.find((option) => option.value === bymonthday)
            }

            if (count) {
                initialEvent.end = OPTIONS_FREQUENCY_TYPE[1]
                initialEvent.executions = count
            } else if (until) {
                initialEvent.end = OPTIONS_FREQUENCY_TYPE[2]
                initialEvent.endDate = moment.utc(until)
            }
            initialEvent.every = interval
        }
        setEventData(initialEvent)
        eventPopup.open()
    }

    const deleteEvent = async (eventId, target_filter = "only_this") => {
        try {
            const newEvents = allEvents.filter((item) => item.id !== eventId)
            setAllEvents(newEvents)
            await calendarService.deleteEvent(eventId, {
                target_filter
            })
            reloadEvents()
        } catch (error) {
            handleError(error)
        }
    }

    const onSuccess = async () => {
        eventPopup.close()
        reloadEvents()
    }

    const onSelectStaff = async (staff) => {
        setAllEvents([])
        setSelectedStaff(staff)
        await loadCalendar(staff.id)
    }

    const loadCalendar = async (id) => {
        try {
            setIsLoading(true)
            const data = {
                filter: {
                    owner_user_id: id
                }
            }
            const calendars = await calendarService.getAllCalendars(data)
            let newCalendarId = null
            if (calendars.length > 0) {
                const primaryCalendar = calendars[0]
                newCalendarId = primaryCalendar.calendar_id
            }
            setCalendarId(newCalendarId)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleBookingEvent = async (reason) => {
        setIsLoading(true)
        try {
            const payload = {
                booker_user_id: userId,
                booker_notes: reason
            }
            // note `id` it's from renderEvents method
            await calendarService.book(+selectedEvent.id, payload)
            await reloadEvents()
            await loadCalendar(selectedStaff.id)
            setIsLoading(false)
            showBookingPopup.close()
            setSelectedEvent({})
            toastSuccess("Booked successfully")
        } catch (e) {
            toastError("Sorry, There was an error, please try again")
        } finally {
            setIsLoading(false)
        }
    }

    const loadStaffs = async (search = "", loadedOptions) => {
        try {
            const {data, total} = await userServiceV3.getAll({
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                },
                filter: {
                    type: ["staff"],
                    search
                }
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        } catch (e) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const canShowBookEventIcon = (event) => {
        if (event.extendedProps.isPersonalEvent) {
            return false
        }
        return !event.extendedProps.appointmentSlot.booker_user_id && event.extendedProps.ownerUserId !== userId
    }

    const canShowDeleteEvent = (event) => {
        return !event.extendedProps.isPersonalEvent
    }

    const onClickBookEvent = (event) => {
        showBookingPopup.open()
        setSelectedEvent(event)
    }

    const getEventOptions = (): CalendarEventOption[] => {
        if (myCalendarId === calendarId) {
            return [
                {title: "Edit event", icon: "EDIT", action: onClickEditEvent, canShow: canShowDeleteEvent},
                {title: "Delete event", icon: "DELETE", action: onClickDeleteEvent, canShow: canShowDeleteEvent}
            ]
        }
        return [
            {
                title: "Book an appointment",
                icon: "CALENDAR_FILL",
                action: onClickBookEvent,
                iconClassName: styles.eventPreviewHeaderBookIcon,
                canShow: canShowBookEventIcon
            }
        ]
    }

    const onConfirmRecurring = async (target_filter) => {
        chooseRecurringPopup.close()
        await deleteEvent(tempEventId, target_filter)
    }

    const eventOptions = getEventOptions()
    const debounceUsers = debounce(loadStaffs, 500)

    return (
        <div className={cx(styles.root, styles.flexColumn)}>
            <div className={styles.selectPerson}>
                <Label text={t("calendar.selectPerson")} error="">
                    <KlassDropAsyncPaginate
                        value={selectedStaff}
                        onChange={onSelectStaff}
                        loadOptions={debounceUsers}
                        valueKey="id"
                        labelKey="fullName"
                    />
                </Label>
            </div>
            <Calendar
                calendarRef={calendarRef}
                events={allEvents}
                isLoading={isLoading}
                eventOptions={eventOptions}
                onDateClick={onDateClick}
                onChangeDatesSet={onChangeDatesSet}
            />
            {eventPopup.isVisible && (
                <CalendarAvailabilityEventPopup
                    myCalendarId={myCalendarId}
                    isShow={eventPopup.isVisible}
                    onClose={eventPopup.close}
                    data={eventData}
                    locationsList={locationsList}
                    onSuccess={onSuccess}
                />
            )}
            {showBookingPopup.isVisible && selectedEvent && (
                <BookingModal
                    title="Book an Appointment"
                    event={selectedEvent}
                    isLoading={isLoading}
                    onSubmit={handleBookingEvent}
                    onCancel={showBookingPopup.close}
                />
            )}
            <ChooseRecurringPopup
                isShow={chooseRecurringPopup.isVisible}
                type={RecurringPopupType.Delete}
                onClose={() => {
                    chooseRecurringPopup.close()
                }}
                onClickSave={onConfirmRecurring}
            />
        </div>
    )
}
export default CalendarAvailability
