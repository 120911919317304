import {get} from "lodash"
import {Course} from "types/courses"
import {ApiListResponseResult} from "types/api"
import BaseService from "./Base"

class CourseService extends BaseService {
    async getAll(params = {}): Promise<ApiListResponseResult<Course.CourseTableItem[]>> {
        const response = await this.post("/v3/courses/list/table", params)
        return get(response, "data", [])
    }

    async getTableCourses(
        params: Course.GetTableCoursesPayload
    ): Promise<ApiListResponseResult<Course.CourseTableItem[]>> {
        const response = await this.post("/v4/courses/list/table", params)
        return get(response, "data", [])
    }

    async list(params = {}): Promise<ApiListResponseResult<Course.CourseTableItem[]>> {
        const response = await this.post("/v3/courses/list", params)
        return get(response, "data", [])
    }
    async exportAsCsv(data) {
        const response = await this.post(`/v3/courses/export-as-csv`, data, {
            responseType: "blob"
        })
        return get(response, "data", null)
    }

    async getStudentStats(params: {
        filter: Partial<{
            isAllTerm: boolean
            courseIds: number[]
            termIds: number[]
            courseInMultiTerm?: boolean
            linkWithAttendance?: boolean
        }>
        range: {pageSize: number; page: number}
    }) {
        const response = await this.post(`/v3/instructional/students-stats`, params)
        return get(response, "data", {})
    }

    async getIncompleteCourses(params: {
        filter: Partial<{isAllTerm: boolean; courseIds: number[]; termIds: number[]}>
        range: {pageSize: number; page: number}
    }) {
        const response = await this.post(`/v3/instructional/incomplete-courses`, params)
        return get(response, "data", {})
    }

    async listInstructionalStudents(params: {
        filter: Partial<{
            courseIds: number[]
            termIds: number[]
            scheduleId: number
            search: string
            campusIds: number[]
        }>
        range: {pageSize: number; page: number}
    }) {
        const response = await this.post(`/v3/instructional/list-students`, params)
        return get(response, "data", {})
    }

    async getOne(id: number, params: any = {}) {
        params = {
            filter: {courseIds: [id]},
            linkedEntities: [
                "campus",
                "submodule",
                "profile",
                "prerequisiteCourse",
                "corequisiteCourse",
                "crossListCourse",
                "department"
            ],
            ...params
        }
        const response = await this.post(`/v3/courses/list`, params)
        return get(response, "data.data[0]", {})
    }

    async getAutocompleteCourse(params = {}) {
        const response = await this.post("/v3/courses/list/autocomplete", params)
        return get(response, "data", [])
    }

    async createCourse(params = {}) {
        const response = await this.post("v3/courses/create", params)
        return get(response, "data.data", {})
    }

    async updateCourse(id, data) {
        data.courseId = id
        const response = await this.post(`/v3/courses/edit`, data)
        return get(response, "data.data", {})
    }

    async duplicateCourse(courseIds) {
        const response = await this.post("/v3/courses/duplicate", {courseIds})
        return get(response, "data.data", {})
    }

    async deleteCourse(courseIds) {
        const response = await this.post("/v3/courses/delete", {courseIds})
        return get(response, "data.data", {})
    }
}

export default CourseService
