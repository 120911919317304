import {Timestamps} from "types/common"
import {TermDetails} from "types/terms"
import {formatDateRange} from "helpers"

export type PaymentPeriod = Timestamps & {
    id?: number
    code?: string | null
    periodId: number
    programId?: number | null
    program?: any // populate
    startingTermId?: number | null
    startingTerm?: TermDetails // populate
    endingTermId?: number | null
    endingTerm?: TermDetails // populate
    startDate: string
    endDate: string

    // internal
    isNew?: boolean
}

export const getPaymentPeriodLabel = (period: PaymentPeriod, userDateFormat: string): string => {
    if (!period) return ""
    return formatDateRange(period.startDate, period.endDate, userDateFormat) + (period.code ? ` [${period.code}]` : "")
}
