/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback} from "react"
import {useTranslation} from "react-i18next"
import {Col, Row} from "antd"
import debounce from "debounce-promise"
import {KlassDropAsyncPaginate} from "components/Select"
import {termsService, userService} from "services"
import {BaseNewFilter} from "uiKit"
import {formatCodeName, handleError} from "helpers"
import {useCurrentProfilePermissions, useModel} from "hooks"
import {Permissions} from "types/permission"
import {Auth} from "types/auth"
import {StatsForm} from "sections/shared/types"
import {BaseDepartmentId} from "types/departments"
import {FilterKey} from "types/filter"
import styles from "./StatsFilter.module.css"
import {BaseRangePicker} from "components/DateTimePicker"
import moment from "moment"
import CampusSelect from "components/CampusSelect"
import ProgramSelect from "components/ProgramSelect"

type StatsFilterProps = {
    form: StatsForm
    setState: any
    departmentId: number
    onClickClearFilter: any
    searchValue: string
    onSearchInput: (value: string) => void
    renderRightFilter?: () => JSX.Element
}

export function StatsFilter(props: StatsFilterProps) {
    const {form, setState, departmentId, searchValue, onSearchInput} = props
    const delayTime = 500
    const debounceAdvisors = useCallback(debounce(onAdvisorsSearch, delayTime), [])
    const debounceTerms = useCallback(debounce(onTermSearch, delayTime), [])
    const currentProfilePermissions = useCurrentProfilePermissions()
    const {t} = useTranslation(["common"])
    const model = useModel()
    const filterKey =
        departmentId === BaseDepartmentId.Admissions
            ? FilterKey.AdmissionPage_Analytics
            : FilterKey.FinAidPage_Analytics

    function onClick() {
        model.updateStorageFilter(filterKey, {filter: form})
        setState({applySearch: true})
    }

    const checkCanViewOthersByDepartment = () => {
        switch (departmentId) {
            case BaseDepartmentId.Admissions:
                return currentProfilePermissions.hasPermissions({
                    staff: [Permissions.Staff.Admissions.AdmissionsStaff.PermissionsToAccessOthers.View]
                })
            case BaseDepartmentId.FinancialAid:
                return currentProfilePermissions.hasPermissions({
                    staff: [Permissions.Staff.FinancialAid.FinancialAidStaff.PermissionsToAccessOthers.View]
                })
            default:
                return true
        }
    }

    const getAdvisorPermissions = () => {
        switch (departmentId) {
            case BaseDepartmentId.Admissions:
                return [
                    Permissions.Staff.Admissions.AdmissionsStaff.View,
                    Permissions.Staff.Admissions.AdmissionsStaff.PermissionsToAccessOthers.View
                ]
            case BaseDepartmentId.FinancialAid:
                return [
                    Permissions.Staff.FinancialAid.FinancialAidStaff.View,
                    Permissions.Staff.FinancialAid.FinancialAidStaff.PermissionsToAccessOthers.View
                ]
            default:
                return []
        }
    }

    async function onAdvisorsSearch(search = "", loadedOptions) {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const hasPermissionIds = getAdvisorPermissions()
            const {data, total} = await userService.searchUsers({
                filters: {
                    type: Auth.UserProfileType.Staff,
                    search,
                    hasPermissionIds,
                    excludeProfileIds: []
                },
                range: {
                    page,
                    pageSize
                }
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            handleError(error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    async function onTermSearch(term: string, loadedOptions) {
        try {
            const {data: terms, total} = await termsService.getAllTerms({
                limit: 20,
                offset: loadedOptions.length,
                text: term
            })
            return {
                options: terms.map(({id, name, code}) => ({id, name, code})),
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            handleError(error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const getAdvisorPlaceholder = () => {
        switch (departmentId) {
            case BaseDepartmentId.Admissions:
                return t("statsFilter.admissionAdvisor")
            case BaseDepartmentId.FinancialAid:
                return t("statsFilter.financialAidAdvisor")
            case BaseDepartmentId.Academics:
                return t("statsFilter.academicAdvisor")
            default:
                return ""
        }
    }

    const onChangeAdvisor = (newValue, actionMeta) => {
        let newAdvisors = newValue
        if (actionMeta.action === "clear") {
            newAdvisors = (form.advisor || []).filter((staff) => staff.isFixed)
        }
        setState({advisor: newAdvisors})
    }

    const onChangeSearch = (value) => {
        model.updateStorageFilter(filterKey, {search: value})
        onSearchInput(value)
    }

    const advisorPlaceholder = getAdvisorPlaceholder()
    const canViewOthers = checkCanViewOthersByDepartment()

    return (
        <BaseNewFilter
            className={styles.filterContainer}
            searchValue={searchValue ?? ""}
            onSearchInput={onChangeSearch}
            onClick={onClick}
            onClickClear={props.onClickClearFilter}
            renderRightFilter={props.renderRightFilter}
            filter={form}>
            <Row gutter={[40, 32]}>
                <Col span={12}>
                    <CampusSelect
                        isMulti
                        placeholder={t("statsFilter.campus")}
                        value={form.campus}
                        onChange={(newValue) => {
                            setState({campus: newValue})
                        }}
                    />
                </Col>
                <Col span={12}>
                    <ProgramSelect
                        isMulti
                        placeholder={t("statsFilter.program")}
                        value={form.program}
                        onChange={(newValue) => {
                            setState({program: newValue})
                        }}
                    />
                </Col>
                <Col span={12}>
                    <KlassDropAsyncPaginate
                        value={form.advisor}
                        onChange={(newValue, actionMeta) => onChangeAdvisor(newValue, actionMeta)}
                        valueKey="userId"
                        loadOptions={debounceAdvisors}
                        isMulti
                        placeholder={advisorPlaceholder}
                        readOnly={!canViewOthers}
                    />
                </Col>
                <Col span={12}>
                    <KlassDropAsyncPaginate
                        value={form.term}
                        onChange={(newValue) => {
                            setState({term: newValue})
                        }}
                        loadOptions={debounceTerms}
                        isMulti
                        placeholder={t("statsFilter.term")}
                        getOptionLabel={formatCodeName}
                    />
                </Col>
                {departmentId === BaseDepartmentId.Admissions && (
                    <Col span={12}>
                        <BaseRangePicker
                            placeholder={["Date From", "To"]}
                            value={form.dateRange ? [moment(form.dateRange[0]), moment(form.dateRange[1])] : null}
                            onChange={(value) => {
                                if (value) {
                                    setState({
                                        dateRange: [moment(value[0]), moment(value[1])]
                                    })
                                } else {
                                    setState({dateRange: null})
                                }
                            }}
                        />
                    </Col>
                )}
            </Row>
        </BaseNewFilter>
    )
}
