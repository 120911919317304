/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useMemo, useState} from "react"
import {Checkbox, Col, Row, Spin, Radio, Tabs} from "antd"
import {useActiveTab, useModel, useVisible} from "hooks"
import {TabHeader} from "components/Tab"
import {DailyAttendance} from "./DailyAttendance"
import dailyAttendanceStyles from "./DailyAttendance.module.css"
import {DailyChart} from "./DailyChart"
import {attendanceService} from "services"
import {handleError} from "helpers"
import {useQuery} from "@tanstack/react-query"
import moment from "moment"
import {DailyAttendanceShiftViewBy, DailyAttendanceViewType} from "types/attendance"
import CircularSpin from "components/CircularSpin"

const {TabPane} = Tabs

const DAILY_ATTENDANCE_TABS = {
    ALL: "all",
    DAILY: "daily"
}

type Props = {
    studentProfileId?: number
}

export function DailyAttendanceTab(props: Props) {
    const model = useModel()

    const allStatsInfoVisible = useVisible(false)

    const {studentProfileId} = props
    const [viewType, setViewType] = useState<DailyAttendanceViewType>(DailyAttendanceViewType.ViewByProgram)
    const [shiftViewBy, setShiftViewBy] = useState<{
        byShift: boolean
        byProgram: boolean
        byCampus: boolean
    }>({
        byShift: true,
        byProgram: false,
        byCampus: false
    })
    const [activeTab, onChangeTab] = useActiveTab(DAILY_ATTENDANCE_TABS.ALL, "sub-type")
    const [filterCharts, setFilterCharts] = useState<{
        majorVersionIds: number[]
        phaseIds: number[]
        shiftIds: number[]
        campusIds: number[]
        programIds: number[]
        eventType?: string
    }>({
        majorVersionIds: [],
        phaseIds: [],
        shiftIds: [],
        campusIds: [],
        programIds: [],
        eventType: undefined
    })

    const {isFetching, data: statsInfo} = useQuery(
        ["daily-attendance-stats", studentProfileId, viewType, shiftViewBy],
        () => {
            let shiftFilters = []
            if (shiftViewBy.byShift) {
                shiftFilters = [DailyAttendanceShiftViewBy.Shift]
            } else {
                if (shiftViewBy.byProgram) {
                    shiftFilters.push(DailyAttendanceShiftViewBy.Program)
                }
                if (shiftViewBy.byCampus) {
                    shiftFilters.push(DailyAttendanceShiftViewBy.Campus)
                }
            }
            return attendanceService.getDailyAttendanceStatsNew({
                viewType,
                shiftFilters,
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            })
        },
        {
            initialData: [],
            onError: (err) => {
                handleError(err)
            }
        }
    )

    const resetFilterCharts = useCallback(() => {
        setFilterCharts({
            majorVersionIds: [],
            phaseIds: [],
            shiftIds: [],
            campusIds: [],
            programIds: [],
            eventType: undefined
        })
    }, [])

    const onClickDailyChartItem = (item, type) => {
        if (
            viewType === DailyAttendanceViewType.ViewByPhase &&
            filterCharts.phaseIds.includes(item.phaseId) &&
            filterCharts.eventType === type
        ) {
            resetFilterCharts()
        } else if (
            viewType === DailyAttendanceViewType.ViewByShift &&
            filterCharts.eventType === type &&
            ((shiftViewBy.byShift && filterCharts.shiftIds.includes(item.shiftId)) ||
                (shiftViewBy.byProgram &&
                    shiftViewBy.byCampus &&
                    filterCharts.campusIds.includes(item.campusId) &&
                    filterCharts.programIds.includes(item.programId)) ||
                (shiftViewBy.byCampus && filterCharts.campusIds.includes(item.campusId)) ||
                (shiftViewBy.byProgram && filterCharts.programIds.includes(item.programId)))
        ) {
            resetFilterCharts()
        } else if (
            viewType === DailyAttendanceViewType.ViewByProgram &&
            filterCharts.majorVersionIds.includes(item.id) &&
            filterCharts.eventType === type
        ) {
            resetFilterCharts()
        } else {
            setFilterCharts({
                majorVersionIds: item.id ? [item.id] : [],
                eventType: type,
                phaseIds: item.phaseId ? [item.phaseId] : [],
                shiftIds: item.shiftId ? [item.shiftId] : [],
                campusIds: item.campusId ? [item.campusId] : [],
                programIds: item.programId ? [item.programId] : []
            })
        }
    }

    const onChangeViewType = useCallback(
        (viewType) => {
            setViewType(viewType)
            resetFilterCharts()
        },
        [viewType]
    )

    const renderStats = useMemo(() => {
        const statsInfoData = !allStatsInfoVisible.isVisible
            ? (statsInfo as any).filter((stat) => stat.activeStudentsCount > 0)
            : statsInfo
        return (
            <Row gutter={[40, 40]}>
                {isFetching ? (
                    <div className={dailyAttendanceStyles.spinWrapper}>
                        <Spin />
                    </div>
                ) : (
                    statsInfoData.map((item) => (
                        <Col key={item.id || item.phaseId} span={8}>
                            <DailyChart
                                viewType={viewType}
                                data={item}
                                shiftViewBy={shiftViewBy}
                                filter={filterCharts}
                                onClickItem={onClickDailyChartItem}
                            />
                        </Col>
                    ))
                )}
            </Row>
        )
    }, [allStatsInfoVisible, statsInfo, filterCharts, viewType, shiftViewBy])

    return (
        <div>
            {!model.isStaffOrAdmin() ? null : isFetching ? (
                <div className={dailyAttendanceStyles.chartLoading}>
                    <CircularSpin size="large" />
                </div>
            ) : (
                <>
                    <div className={dailyAttendanceStyles.checkbox}>
                        <div className={dailyAttendanceStyles.checkboxBlock}>
                            <Checkbox checked={allStatsInfoVisible.isVisible} onChange={allStatsInfoVisible.toggle}>
                                Show All
                            </Checkbox>

                            {viewType === DailyAttendanceViewType.ViewByShift && (
                                <div>
                                    <Checkbox
                                        checked={shiftViewBy.byShift}
                                        onChange={() =>
                                            setShiftViewBy({
                                                byShift: true,
                                                byProgram: false,
                                                byCampus: false
                                            })
                                        }>
                                        By Shift
                                    </Checkbox>
                                    <Checkbox
                                        checked={shiftViewBy.byProgram}
                                        onChange={() => {
                                            let byShift = false
                                            if (!!shiftViewBy.byProgram && !shiftViewBy.byCampus) {
                                                byShift = true
                                            }
                                            setShiftViewBy({
                                                byShift: byShift,
                                                byProgram: !shiftViewBy.byProgram,
                                                byCampus: shiftViewBy.byCampus
                                            })
                                        }}>
                                        By Program
                                    </Checkbox>
                                    <Checkbox
                                        checked={shiftViewBy.byCampus}
                                        onChange={() => {
                                            let byShift = false
                                            if (!shiftViewBy.byProgram && !!shiftViewBy.byCampus) {
                                                byShift = true
                                            }
                                            setShiftViewBy({
                                                byShift: byShift,
                                                byProgram: shiftViewBy.byProgram,
                                                byCampus: !shiftViewBy.byCampus
                                            })
                                        }}>
                                        By Campus
                                    </Checkbox>
                                </div>
                            )}
                        </div>
                        <Radio.Group
                            value={viewType}
                            onChange={(e) => {
                                if (e.target.value === DailyAttendanceViewType.ViewByShift) {
                                    setShiftViewBy({
                                        byShift: true,
                                        byProgram: false,
                                        byCampus: false
                                    })
                                }
                                onChangeViewType(e.target.value)
                            }}>
                            <Radio.Button
                                className={dailyAttendanceStyles.viewTypeButton}
                                value={DailyAttendanceViewType.ViewByProgram}>
                                View By Program
                            </Radio.Button>
                            <Radio.Button
                                className={dailyAttendanceStyles.viewTypeButton}
                                value={DailyAttendanceViewType.ViewByPhase}>
                                View By Phase
                            </Radio.Button>
                            <Radio.Button
                                className={dailyAttendanceStyles.viewTypeButton}
                                value={DailyAttendanceViewType.ViewByShift}>
                                View By Shift
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    {renderStats}
                </>
            )}
            <div className={dailyAttendanceStyles.tabContainer}>
                <Tabs className="fullwidth klassSubTab" activeKey={activeTab} onChange={onChangeTab}>
                    <TabPane key={DAILY_ATTENDANCE_TABS.ALL} tab={<TabHeader title="All" />}>
                        {activeTab === DAILY_ATTENDANCE_TABS.ALL && (
                            <DailyAttendance
                                studentProfileId={studentProfileId}
                                statsInfo={statsInfo}
                                filter={filterCharts}
                                showAll
                            />
                        )}
                    </TabPane>
                    <TabPane key={DAILY_ATTENDANCE_TABS.DAILY} tab={<TabHeader title="Daily" />}>
                        {activeTab === DAILY_ATTENDANCE_TABS.DAILY && (
                            <DailyAttendance
                                studentProfileId={studentProfileId}
                                statsInfo={statsInfo}
                                filter={filterCharts}
                            />
                        )}
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}
