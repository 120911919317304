/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect} from "react"
import {useTranslation} from "react-i18next"
import moment, {Moment} from "moment"
import {Checkbox, Col, Radio, Row, Tooltip} from "antd"
import {cloneDeep, get} from "lodash"
import debounce from "debounce-promise"
import {KlassDropdown} from "components/Select"
import {termsService, userServiceV3} from "services"
import {BaseNewFilter} from "uiKit"
import {BaseDatePicker, BaseRangePicker} from "components/DateTimePicker"
import {getFullName, handleError} from "helpers"
import styles from "./StudentFilter.module.css"
import {BaseButton} from "components/buttons"
import cx from "classnames"
import {useHistory} from "react-router-dom"
import {Icon} from "components/Icon"
import {useModel, useVisible} from "hooks"
import {FilterKey} from "types/filter"
import TermSelect from "components/TermSelect"
import CreateUserPopup from "sections/shared/students/CreateUserPopup"
import DepartmentActivitySelect from "components/DepartmentActivitySelect"
import {Activity} from "types/activity"
import {ExportButton} from "components/ui"
import {NewStudentStatusSelectFilter} from "components/NewStudentStatusSelect"
import {STATES_OPTIONS, StudentStatus} from "types/students"
import {useAllLeadTypes, useAllLeadSources, useAdvisors} from "hooks"
import {TermDetails} from "types/terms"
import {FAStatusBy} from "types/fin-aid/fin-aid"
import {FinancialAidStatus} from "types/fin-aid/financial-aid-status"
import {BaseDepartmentId} from "types/departments"
import {BaseInput} from "components"
import {UserOptionLabel} from "components/UserSelect/UserSelect"
import CampusSelect from "components/CampusSelect"
import ProgramSelect from "components/ProgramSelect"

export type ViewType = "standard" | "kanban" | "priority"

export type StudentForm = {
    term: any[]
    startingTerm?: any[]
    campus: any[]
    program: any[]
    advisor?: any[]
    acadAdvisors?: any[]
    faAdvisors?: any[]
    admAdvisors?: any[]
    includeArchive?: boolean
    showDueOnly?: boolean
    checklist?: string
    status?: any[]
    state?: any[]
    testStatus?: any[]
    profileStatus?: any[]
    applicationCompletedDate?: any
    startDateRange?: any
    createdAtRange?: any
    inquiryDateRange?: any
    lastActivityDateRange?: any
    fullyEnrolled?: boolean
    onlySEV?: boolean
    sapCheckpoints?: any[]
    retentionLevels?: any[]
    studentStatusIds?: any[]
    financialAidStatuses?: Partial<FinancialAidStatus>[]
    admissionsLeadSourceIds?: number[]
    leadTypeIds?: number[]
    activityIds?: any[]
    faAwardYear?: number
    faTerm?: TermDetails
    tranxDates?: Moment[]
    activityDescription?: string
}

type Props = {
    studentForm: StudentForm
    studentFormSearch?: string
    dispatch: any
    advisor: Advisor
    status?: Status
    hideSecondSection?: boolean
    hideAdvisorFilter?: boolean
    hideIncludeArchive?: boolean
    hideLeadRecordAction?: boolean
    hideLeadSource?: boolean
    hideStatuses?: boolean
    hideActivityTypesDropdown?: boolean
    hideTestStatusDropdown?: boolean
    hideNewStatusDropdown?: boolean
    hideLastActivityRange?: boolean
    hideDateAddedRange?: boolean
    hideShowDueOnly?: boolean
    hideApplicationCompletedDate?: boolean
    hideChecklist?: boolean
    children?: any
    onClickClearFilter?: any
    canShowAllStudents?: boolean
    canHaveAccessOthers?: boolean
    viewTypes?: string[]
    onChangeViewType?
    viewType?: ViewType
    backButtonUrl?: string
    filterKey?: FilterKey
    advisorFilter?: AdvisorFilter
    departmentId?: number
    withExportButton?: boolean
    exportFn?: any
    isExporting?: boolean
    isFinancialVerificationTab?: boolean
    isFinancialPackagingTab?: boolean
    faStatusBy?: string
}

export enum Advisor {
    finAidAdvisor = "financialAidAdvisor",
    admissionAdvisor = "admissionAdvisor",
    academicAdvisor = "academicAdvisor"
}

export enum AdvisorFilter {
    isAcademicAdvisor = "isAcademicAdvisor",
    isAdmissionsAdvisor = "isAdmissionsAdvisor",
    isCareerServicesAdvisor = "isCareerServicesAdvisor",
    isFinancialAidAdvisor = "isFinancialAidAdvisor",
    isStudentAccountAdvisor = "isStudentAccountAdvisor",
    isStudentServicesAdvisor = "isStudentServicesAdvisor"
}

export enum Status {
    finAidStatus = "finAidStatus",
    admissionStatus = "admissionStatus",
    academicStatus = "academicStatus"
}

enum statusDepartments {
    finAidStatus = 2,
    admissionStatus = 1,
    academicStatus = 3
}

const currentYear = moment().year()
const yearOptions = [1, 0, -1, -2, -3].map((index) => ({
    id: currentYear + index,
    name: `${currentYear + index} - ${currentYear + index + 1}`
}))

export function StudentFilter(props: Props) {
    const history = useHistory()
    const {
        studentForm,
        studentFormSearch,
        dispatch,
        canShowAllStudents,
        canHaveAccessOthers,
        viewTypes,
        onChangeViewType,
        viewType,
        filterKey,
        advisorFilter,
        departmentId,
        withExportButton,
        isExporting,
        isFinancialVerificationTab = false,
        isFinancialPackagingTab = false,
        exportFn
    } = props
    const delayTime = 500
    const debounceAdvisors = useCallback(debounce(onAdvisorsSearch, delayTime), [])
    const {t} = useTranslation(["common"])
    const model = useModel()
    const {leadTypes} = useAllLeadTypes()
    const {admissionAdvisors, finAidAdvisors, academicAdvisors} = useAdvisors().data
    const {leadSources} = useAllLeadSources()

    async function onAdvisorsSearch(search = "", loadedOptions) {
        try {
            const {data, total} = await userServiceV3.getAll({
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                },
                filter: {
                    type: ["staff"],
                    search,
                    ...(!!advisorFilter && {isModuleAdvisor: advisorFilter})
                },
                linkedObjects: true
            })
            const staffs = data
                .map((staff) => ({profileId: get(staff, "profiles[0].id", ""), name: getFullName(staff)}))
                .filter((staff) => staff.profileId)
            return {
                options: staffs,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            handleError(error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onClick = () => {
        model.updateStorageFilter(filterKey, {filter: studentForm})
        dispatch({studentForm: cloneDeep(studentForm)})
    }

    const onSearchInput = (search) => {
        model.updateStorageFilter(filterKey, {search})
        dispatch({studentFormSearch: search})
    }

    const createUserPopup = useVisible(false)

    const onClickAddUser = () => {
        createUserPopup.open()
    }

    const handleCreateUserSuccess = useCallback(() => {
        createUserPopup.close()
        dispatch({studentForm: {...studentForm}})
    }, [])

    useEffect(() => {
        if (!props.faStatusBy || props.faStatusBy === FAStatusBy.AwardYear) {
            dispatch({
                studentForm: {
                    ...studentForm,
                    faAwardYear: studentForm.faAwardYear || currentYear
                }
            })
        }
        if (props.faStatusBy === FAStatusBy.Term) {
            ;(async function getCurrentTerm() {
                const {data} = await termsService.getAllTerms({
                    fields: ["id", "name", "code", "start_date"],
                    orderBy: "is_active_start_date",
                    orderDir: "desc",
                    limit: 1,
                    offset: 0
                })
                dispatch({studentForm: {...studentForm, faTerm: studentForm.faTerm || data[0]}})
            })()
        }
    }, [props.faStatusBy])

    const renderRightFilter = () => {
        return (
            <div className={styles.actionWrap}>
                {withExportButton && (
                    <ExportButton isLoading={isExporting} onSelect={exportFn} availableFileTypes={["csv", "excel"]} />
                )}
                {viewTypes?.length && (
                    <>
                        {viewTypes.indexOf("kanban") >= 0 && (
                            <Tooltip title="Kanban">
                                <span
                                    className={cx(styles.viewTypeIconContainer, {
                                        [styles.viewTypeIconContainerSelected]: viewType === "kanban"
                                    })}
                                    onClick={() => onChangeViewType("kanban")}>
                                    <Icon className={styles.viewTypeIcon} icon="VIEW_LIST_COLUMNS" />
                                </span>
                            </Tooltip>
                        )}
                        {viewTypes.indexOf("standard") >= 0 && (
                            <Tooltip title="Standard">
                                <span
                                    className={cx(styles.viewTypeIconContainer, {
                                        [styles.viewTypeIconContainerSelected]:
                                            viewType === "standard" ||
                                            (viewType === "kanban" &&
                                                statusDepartments.finAidStatus === statusDepartments[props.status])
                                    })}
                                    onClick={() => onChangeViewType("standard")}>
                                    <Icon className={styles.viewTypeIcon} icon="VIEW_LIST" />
                                </span>
                            </Tooltip>
                        )}
                        {viewTypes.indexOf("priority") >= 0 && (
                            <Tooltip title="Priority">
                                <span
                                    className={cx(styles.viewTypeIconContainer, {
                                        [styles.viewTypeIconContainerSelected]: viewType === "priority"
                                    })}
                                    onClick={() => onChangeViewType("priority")}>
                                    <Icon className={styles.viewTypeIcon} icon="ALERT_CIRCLE_FILL" />
                                </span>
                            </Tooltip>
                        )}
                    </>
                )}
                {!props.hideLeadRecordAction && (
                    <BaseButton
                        title={t("action.createLeadRecord")}
                        onClick={onClickAddUser}
                        className={cx(styles.addButton)}
                    />
                )}
                {(isFinancialVerificationTab || isFinancialPackagingTab) && (
                    <Row gutter={8} align="middle">
                        <Col>
                            {!props.faStatusBy || props.faStatusBy === FAStatusBy.AwardYear ? "Award Year" : "Term"}:
                        </Col>
                        <Col>
                            {!props.faStatusBy || props.faStatusBy === FAStatusBy.AwardYear ? (
                                <KlassDropdown
                                    className={styles.dropdownWrapper}
                                    isClearable={false}
                                    placeholder="Award Year"
                                    options={yearOptions}
                                    value={yearOptions.find((option) => option.id === studentForm.faAwardYear)}
                                    onChange={(option) => {
                                        const newStudentForm = cloneDeep({
                                            ...studentForm,
                                            faAwardYear: option.id
                                        })
                                        model.updateStorageFilter(filterKey, {filter: newStudentForm})
                                        dispatch({studentForm: newStudentForm})
                                    }}
                                />
                            ) : (
                                <TermSelect
                                    className={styles.dropdownWrapper}
                                    isClearable={false}
                                    placeholder="Term"
                                    value={studentForm.faTerm}
                                    onChange={(option) => {
                                        const newStudentForm = cloneDeep({...studentForm, faTerm: option})
                                        model.updateStorageFilter(filterKey, {filter: newStudentForm})
                                        dispatch({studentForm: newStudentForm})
                                    }}
                                />
                            )}
                        </Col>
                    </Row>
                )}
            </div>
        )
    }

    return (
        <>
            <BaseNewFilter
                className={styles.mb30}
                searchValue={studentFormSearch}
                onSearchInput={onSearchInput}
                onClick={onClick}
                filter={studentForm}
                onClickClear={props.onClickClearFilter}
                renderRightFilter={renderRightFilter}>
                <Row gutter={[40, 32]}>
                    <Col span={12}>
                        <TermSelect
                            isMulti
                            placeholder={t("statsFilter.startingTerm")}
                            value={studentForm.startingTerm}
                            onChange={(newValue) => {
                                dispatch({studentForm: {...studentForm, startingTerm: newValue}})
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <CampusSelect
                            isMulti
                            placeholder={t("statsFilter.campus")}
                            value={studentForm.campus}
                            onChange={(newValue) => {
                                dispatch({studentForm: {...studentForm, campus: newValue}})
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <ProgramSelect
                            isMulti
                            placeholder={t("statsFilter.program")}
                            value={studentForm.program}
                            onChange={(newValue) => {
                                dispatch({studentForm: {...studentForm, program: newValue}})
                            }}
                        />
                    </Col>
                    {props.hideAdvisorFilter ? (
                        <>
                            {!props.hideTestStatusDropdown && (
                                <Col span={12}>
                                    <KlassDropdown
                                        value={studentForm.testStatus || []}
                                        onChange={(newValue) => {
                                            dispatch({studentForm: {...studentForm, testStatus: newValue}})
                                        }}
                                    />
                                </Col>
                            )}

                            {!props.hideNewStatusDropdown && (
                                <Col span={12}>
                                    <NewStudentStatusSelectFilter
                                        isMulti
                                        value={studentForm.studentStatusIds}
                                        onChange={(options?: StudentStatus[]) => {
                                            dispatch({studentForm: {...studentForm, studentStatusIds: options || []}})
                                        }}
                                    />
                                </Col>
                            )}
                            {!props.hideStatuses && (
                                <Col span={12}>
                                    <KlassDropdown
                                        options={[
                                            {id: "pass", name: t("statsFilter.pass")},
                                            {id: "fail", name: t("statsFilter.fail")}
                                        ]}
                                        isMulti
                                        placeholder={t(`statsFilter.status`)}
                                    />
                                </Col>
                            )}
                        </>
                    ) : (
                        (canShowAllStudents || canHaveAccessOthers) && (
                            <>
                                {/*
                                <Col span={12}>
                                    <KlassDropAsyncPaginate
                                        value={studentForm.advisor}
                                        onChange={(newValue) => {
                                            dispatch({studentForm: {...studentForm, advisor: newValue}})
                                        }}
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={debounceAdvisors}
                                        isMulti
                                        valueKey="profileId"
                                        placeholder={t(`statsFilter.${props.advisor}`)}
                                    />
                                </Col>
                                */}
                                <Col span={12}>
                                    <KlassDropdown
                                        isMulti
                                        options={admissionAdvisors}
                                        valueKey="profileId"
                                        labelKey="fullName"
                                        getOptionLabel={UserOptionLabel}
                                        placeholder={t("statsFilter.admissionAdvisor")}
                                        value={studentForm.admAdvisors}
                                        onChange={(options) => {
                                            dispatch({studentForm: {...studentForm, admAdvisors: options ?? []}})
                                        }}
                                    />
                                </Col>
                                <Col span={12}>
                                    <KlassDropdown
                                        isMulti
                                        options={academicAdvisors}
                                        valueKey="profileId"
                                        labelKey="fullName"
                                        getOptionLabel={UserOptionLabel}
                                        placeholder={t("statsFilter.academicAdvisor")}
                                        value={studentForm.acadAdvisors}
                                        onChange={(options) => {
                                            dispatch({studentForm: {...studentForm, acadAdvisors: options ?? []}})
                                        }}
                                    />
                                </Col>
                                <Col span={12}>
                                    <KlassDropdown
                                        isMulti
                                        options={finAidAdvisors}
                                        valueKey="profileId"
                                        labelKey="fullName"
                                        getOptionLabel={UserOptionLabel}
                                        placeholder={t("statsFilter.financialAidAdvisor")}
                                        value={studentForm.faAdvisors}
                                        onChange={(options) => {
                                            dispatch({studentForm: {...studentForm, faAdvisors: options ?? []}})
                                        }}
                                    />
                                </Col>
                            </>
                        )
                    )}
                    {!props.hideSecondSection && (
                        <>
                            <Col span={12}>
                                <KlassDropdown
                                    options={STATES_OPTIONS}
                                    value={studentForm.state}
                                    isMulti
                                    placeholder={t("statsFilter.state")}
                                    onChange={(newValue) => {
                                        dispatch({studentForm: {...studentForm, state: newValue}})
                                    }}
                                />
                            </Col>
                            <Col span={12}>
                                <NewStudentStatusSelectFilter
                                    isMulti
                                    value={studentForm.studentStatusIds}
                                    onChange={(options?: StudentStatus[]) => {
                                        dispatch({studentForm: {...studentForm, studentStatusIds: options || []}})
                                    }}
                                />
                            </Col>
                            <Col span={12}>
                                <KlassDropdown
                                    options={[
                                        {id: "active", name: t("message.active")},
                                        {id: "inactive", name: t("message.inactive")}
                                    ]}
                                    value={studentForm.profileStatus}
                                    isMulti
                                    placeholder={t("statsFilter.profileStatus")}
                                    onChange={(newValue) => {
                                        dispatch({studentForm: {...studentForm, profileStatus: newValue}})
                                    }}
                                />
                            </Col>

                            {!props.hideChecklist && (
                                <Col span={12}>
                                    <div className={styles.choiceChecklistWrap}>
                                        <p className={styles.choiceChecklist__title}>CHECKLIST</p>
                                        <Radio.Group
                                            onChange={(event) => {
                                                dispatch({studentForm: {...studentForm, checklist: event.target.value}})
                                            }}
                                            value={studentForm.checklist}>
                                            <Radio value="completed" className={styles.choiceChecklist__item}>
                                                {t("statsFilter.completed")}
                                            </Radio>
                                            <Radio value="notCompleted" className={styles.choiceChecklist__item}>
                                                {t("statsFilter.notCompleted")}
                                            </Radio>
                                        </Radio.Group>
                                    </div>
                                </Col>
                            )}
                        </>
                    )}
                    {!props.hideApplicationCompletedDate && (
                        <Col span={12}>
                            <BaseDatePicker
                                value={
                                    studentForm.applicationCompletedDate
                                        ? moment(studentForm.applicationCompletedDate)
                                        : null
                                }
                                onChange={(newValue) => {
                                    dispatch({studentForm: {...studentForm, applicationCompletedDate: newValue}})
                                }}
                                placeholder="Application Completed Date"
                            />
                        </Col>
                    )}
                    <Col span={12}>
                        <BaseRangePicker
                            placeholder={["Student Start Date From", "To"]}
                            value={
                                studentForm.startDateRange?.length
                                    ? [moment(studentForm.startDateRange[0]), moment(studentForm.startDateRange[1])]
                                    : null
                            }
                            onChange={(newValue) => {
                                dispatch({studentForm: {...studentForm, startDateRange: newValue}})
                            }}
                        />
                    </Col>
                    {!props.hideDateAddedRange && (
                        <Col span={12}>
                            <BaseRangePicker
                                placeholder={["Date added From", "To"]}
                                value={
                                    studentForm.createdAtRange
                                        ? [moment(studentForm.createdAtRange[0]), moment(studentForm.createdAtRange[1])]
                                        : null
                                }
                                onChange={(newValue) => {
                                    dispatch({studentForm: {...studentForm, createdAtRange: newValue}})
                                }}
                            />
                        </Col>
                    )}
                    <Col span={12}>
                        <BaseRangePicker
                            placeholder={["Inquiry Date From", "To"]}
                            value={
                                studentForm.inquiryDateRange?.length
                                    ? [moment(studentForm.inquiryDateRange[0]), moment(studentForm.inquiryDateRange[1])]
                                    : null
                            }
                            onChange={(newValue) => {
                                dispatch({studentForm: {...studentForm, inquiryDateRange: newValue}})
                            }}
                        />
                    </Col>

                    {!props.hideLastActivityRange && (
                        <Col span={12}>
                            <BaseRangePicker
                                placeholder={["Last Activity From", "To"]}
                                value={
                                    studentForm.lastActivityDateRange
                                        ? [
                                              moment(studentForm.lastActivityDateRange[0]),
                                              moment(studentForm.lastActivityDateRange[1])
                                          ]
                                        : null
                                }
                                onChange={(newValue) => {
                                    dispatch({studentForm: {...studentForm, lastActivityDateRange: newValue}})
                                }}
                            />
                        </Col>
                    )}
                    {!props.hideActivityTypesDropdown && (
                        <>
                            <Col span={12}>
                                <DepartmentActivitySelect
                                    departmentId={departmentId}
                                    isClearable
                                    value={studentForm.activityIds}
                                    onChange={(activity: Activity) => {
                                        dispatch({
                                            studentForm: {...studentForm, activityIds: activity}
                                        })
                                    }}
                                    isMulti
                                    placeholder="Last Activity Types"
                                />
                            </Col>
                            <Col span={12}>
                                <BaseInput
                                    placeholder="Last Activity Description"
                                    value={studentForm.activityDescription}
                                    onChange={(activityDescription) =>
                                        dispatch({
                                            studentForm: {...studentForm, activityDescription}
                                        })
                                    }
                                />
                            </Col>
                        </>
                    )}

                    {!props.hideLeadSource && (
                        <>
                            <Col span={12}>
                                <KlassDropdown
                                    options={leadSources}
                                    value={studentForm.admissionsLeadSourceIds}
                                    isMulti
                                    placeholder={t("studentInfo.leadSource")}
                                    onChange={(newValue) => {
                                        dispatch({studentForm: {...studentForm, admissionsLeadSourceIds: newValue}})
                                    }}
                                />
                            </Col>
                            <Col span={12}>
                                <KlassDropdown
                                    options={leadTypes}
                                    value={leadTypes.filter((type) => studentForm.leadTypeIds?.includes(type.id))}
                                    isMulti
                                    placeholder={t("studentInfo.leadType")}
                                    onChange={(options) => {
                                        dispatch({
                                            studentForm: {
                                                ...studentForm,
                                                leadTypeIds: (options ?? []).map((option) => option.id)
                                            }
                                        })
                                    }}
                                />
                            </Col>
                        </>
                    )}

                    {!props.hideIncludeArchive && (
                        <Col span={12}>
                            <div className={styles.includeArchiveWrapper}>
                                <Checkbox
                                    checked={studentForm.includeArchive}
                                    onChange={(event) => {
                                        dispatch({
                                            studentForm: {...studentForm, includeArchive: event.target.checked}
                                        })
                                    }}>
                                    {t("statsFilter.includeArchive")}
                                </Checkbox>
                            </div>
                        </Col>
                    )}
                    {!props.hideShowDueOnly && (
                        <Col span={12}>
                            <div className={styles.includeArchiveWrapper}>
                                <Checkbox
                                    checked={studentForm.showDueOnly}
                                    onChange={(event) => {
                                        dispatch({
                                            studentForm: {...studentForm, showDueOnly: event.target.checked}
                                        })
                                    }}>
                                    {t("statsFilter.showDueOnly")}
                                </Checkbox>
                            </div>
                        </Col>
                    )}

                    {departmentId === BaseDepartmentId.FinancialAid && (
                        <Col span={12}>
                            <BaseRangePicker
                                placeholder={["Transaction Start Date", "End Date"]}
                                value={
                                    studentForm.tranxDates
                                        ? [moment(studentForm.tranxDates[0]), moment(studentForm.tranxDates[1])]
                                        : null
                                }
                                onChange={(tranxDates) =>
                                    dispatch({
                                        studentForm: {...studentForm, tranxDates}
                                    })
                                }
                            />
                        </Col>
                    )}

                    {props.children}
                </Row>
            </BaseNewFilter>

            {!!createUserPopup.isVisible && (
                <CreateUserPopup
                    isShow={createUserPopup.isVisible}
                    onClose={createUserPopup.close}
                    onDone={handleCreateUserSuccess}
                />
            )}
        </>
    )
}
