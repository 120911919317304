/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import cx from "classnames"
import {useTranslation} from "react-i18next"
import {isEmpty} from "lodash"
import {Icon} from "components/Icon"
import {chatServiceV2} from "services"
import {Notification} from "types/notification"
import {handleError} from "helpers"
import {BaseLoading} from "components/Loading"
import {NotificationGroupItem} from "./NotificationGroupItem"
import {NotificationItem} from "./NotificationItem"
import styles from "./Notifications.module.css"
import {Communication} from "types/communication"

type Props = {
    notifications: Communication.NotificationSummaryItem[]
    onClose: () => void
    updateUnreadCount: (readCount: number) => void
    onMarkedAllAsReadSuccess: () => void
}

const ITEMS_PER_PAGE: number = 20

export function Notifications(props: Props) {
    const {onClose, updateUnreadCount, onMarkedAllAsReadSuccess} = props
    const [notifications, setNotifications] = useState<Communication.NotificationSummaryItem[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [subNotifications, setSubNotifications] = useState<Communication.NotificationItem[]>([])
    const [detailNotification, setDetailNotification] = useState<Communication.NotificationSummaryItem>(null)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [isMarkingAllAsRead, setIsMarkingAllAsRead] = useState(false)
    const [pagination, setPagination] = useState<Notification.Pagination>({
        page: 1,
        pageSize: ITEMS_PER_PAGE,
        totalItems: 0,
        totalPages: 0
    })
    const {t} = useTranslation("notification")

    useEffect(() => {
        setNotifications(props.notifications)
    }, [])

    const getNotificationTypes = async () => {
        try {
            setIsLoading(true)
            const {data} = await chatServiceV2.summaryNotifications()
            setNotifications(data.filter((x) => x.unread > 0))
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getNotificationByType = async (notification: Communication.NotificationSummaryItem) => {
        try {
            setIsLoading(true)
            const {data, total} = await chatServiceV2.listNotifications({
                filter: {type: notification.source, isRead: false},
                range: {page: pagination.page, pageSize: pagination.pageSize}
            })
            setSubNotifications(data)
            setPagination({...pagination, totalItems: total, totalPages: Math.round(total / ITEMS_PER_PAGE)})
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const onClickNotificationGroup = (notification: Communication.NotificationSummaryItem) => {
        getNotificationByType(notification)
        setDetailNotification(notification)
    }

    const loadMoreNotificationByType = async () => {
        if (isLoadingMore) {
            return
        }
        try {
            setIsLoadingMore(true)
            const {data, total} = await chatServiceV2.listNotifications({
                filter: {type: detailNotification.source, isRead: false},
                range: {page: pagination.page + 1, pageSize: pagination.pageSize}
            })
            setSubNotifications([...subNotifications, ...data])
            setPagination({...pagination, page: pagination.page + 1})
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoadingMore(false)
        }
    }

    const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget
        const canLoadMore = pagination.page <= pagination.totalPages
        const isScrollBottom = scrollTop + clientHeight >= scrollHeight - 100
        if (canLoadMore && isScrollBottom) {
            loadMoreNotificationByType()
        }
    }

    const markedReadNotification = () => {
        setDetailNotification({...detailNotification, unread: detailNotification.unread - 1})
        updateUnreadCount(-1)
    }

    const renderSubNotification = () => {
        if (isEmpty(subNotifications)) {
            return <div className={styles.allNotificationRead}>{t("noNotificationDisplay")}</div>
        }
        return (
            <div className={styles.notificationBody} onScroll={onScroll}>
                {subNotifications.map((notificationMessage) => (
                    <NotificationItem
                        key={notificationMessage.notificationId}
                        notificationMessage={notificationMessage}
                        type={detailNotification.source}
                        isDetailView
                        markedReadNotification={markedReadNotification}
                    />
                ))}
            </div>
        )
    }

    const getTitle = (type: Communication.NotificationType) => {
        if (type.includes(Communication.NotificationType.Department)) {
            return t("department")
        }
        switch (type) {
            case Communication.NotificationType.Tasks:
                return t("task")
            case Communication.NotificationType.Activities:
                return t("activities")
            case Communication.NotificationType.Calendar:
                return t("calendar")
            case Communication.NotificationType.Admissions:
                return t("admissions")
            case Communication.NotificationType.CareerServices:
                return t("careerServices")
            case Communication.NotificationType.ContactStrategy:
                return t("contactStrategy")
            case Communication.NotificationType.Workflow:
                return t("workflow")
            case Communication.NotificationType.Channel:
                return t("channel")
            case Communication.NotificationType.Private:
                return t("private")
            case Communication.NotificationType.Reports:
                return t("reports")
            case Communication.NotificationType.DirectSMS:
                return t("smsDirect")
            case Communication.NotificationType.Academics:
                return t("academics")
            default:
                return null
        }
    }

    const onCloseDetail = () => {
        setDetailNotification(null)
        setSubNotifications([])
        getNotificationTypes()
        setPagination({page: 1, pageSize: ITEMS_PER_PAGE, totalItems: 0, totalPages: 0})
    }

    const markAllAsRead = async () => {
        if (isMarkingAllAsRead) {
            return
        }
        try {
            setIsMarkingAllAsRead(true)
            const readType = detailNotification ? detailNotification.source : "all"
            await chatServiceV2.markReadNotification({
                filter: {
                    type: readType
                }
            })
            if (detailNotification) {
                setDetailNotification({...detailNotification, unread: 0})
                setSubNotifications([])
            } else {
                getNotificationTypes()
                onMarkedAllAsReadSuccess()
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsMarkingAllAsRead(false)
        }
    }

    const renderContent = () => {
        if (detailNotification) {
            const title = getTitle(detailNotification.source)
            return (
                <div className={cx(styles.notificationItemWrap, styles.notificationItemWrap__expand)}>
                    <div className={styles.notificationExpandedWrap}>
                        <div className={styles.notificationExpandedBody}>
                            <span className={styles.notificationExpanded__title}>{title}</span>
                            {detailNotification.unread > 0 && (
                                <span className={styles.notificationExpanded__count}>{detailNotification.unread}</span>
                            )}
                        </div>
                        <div className={cx(styles.markAllAsRead)} onClick={markAllAsRead}>
                            <span>Mark as read</span>
                            {isMarkingAllAsRead ? (
                                <BaseLoading isShow />
                            ) : (
                                <Icon icon="SELECT_ALL" color="var(--secondary-400-base)" size={20} />
                            )}
                        </div>
                    </div>
                    {renderSubNotification()}
                </div>
            )
        }
        if (isEmpty(notifications)) {
            return <div className={styles.allNotificationRead}>{t("noNotificationDisplay")}</div>
        }
        return (
            <>
                <div className={styles.markAllAsRead} onClick={markAllAsRead}>
                    <span>{t("markAllAsRead")}</span>
                    {isMarkingAllAsRead ? (
                        <BaseLoading isShow />
                    ) : (
                        <Icon icon="SELECT_ALL" color="var(--secondary-400-base)" size={20} />
                    )}
                </div>
                <div className={styles.notificationBody}>
                    {notifications.map((notification) => (
                        <NotificationGroupItem
                            key={notification.notification.notificationId}
                            notification={notification}
                            title={getTitle(notification.source)}
                            onClickNotificationGroup={onClickNotificationGroup}
                        />
                    ))}
                </div>
            </>
        )
    }

    const renderTitle = () => {
        if (detailNotification) {
            const title = getTitle(detailNotification.source)
            return (
                <div className={styles.headerDetailWrap}>
                    <div className={styles.headerDetail} onClick={onCloseDetail}>
                        <Icon icon="ARROW_LEFT_LINE" color="#333333" />
                        <span className={styles.title}>{title}</span>
                    </div>
                </div>
            )
        }
        return <span className={styles.title}>{t("notifications")}</span>
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.header}>
                {renderTitle()}
                <div className={styles.closeIconWrap} onClick={onClose}>
                    <Icon icon="CLOSE" color="#939393" className={styles.closeIcon} />
                </div>
            </div>
            <div className={styles.loadingWrap}>
                <BaseLoading isShow={isLoading} />
            </div>
            {renderContent()}
        </div>
    )
}
