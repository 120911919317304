import React from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdown, KlassDropdownAsyncProps} from "../Select"
import {useEmailAndSmsTemplates} from "hooks/useEmailAndSmsTemplates"
import {TemplateType} from "types/settings/email-template"

type Props = Partial<KlassDropdownAsyncProps> & {
    templateTypes?: TemplateType[]
}

export function MessageTemplateSelect(props: Props) {
    const {t} = useTranslation(["common"])
    const {templateTypes = [TemplateType.Email, TemplateType.SMS]} = props

    const {templates, isLoading} = useEmailAndSmsTemplates({templateTypes})

    return (
        <KlassDropdown
            {...props}
            isLoading={isLoading}
            placeholder={props.placeholder || t("selectField.placeholder")}
            options={templates}
            valueKey="emailTemplateId"
            getOptionLabel={(option) => `${option.title} (${option.code})`}
        />
    )
}
