/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import styles from "./Retention.module.css"
import {useHistory} from "react-router-dom"
import {getFieldLabel, handleError} from "helpers"
import {KlassappTableHOC} from "HOC"
import {Col, Row} from "antd"
import {FormLabel} from "components/Form"
import {BaseButton} from "components"
import CampusSelect from "components/CampusSelect/CampusSelect"
import ProgramSelect from "components/ProgramSelect/ProgramSelect"
import DegreeLevelSelect from "components/DegreeLevelSelect/DegreeLevelSelect"
import {retentionService} from "services"
import {Settings} from "types/settings"
import {useModel} from "hooks"
import {StageMapping} from "./parts"

function Retention(props) {
    const history = useHistory()
    const {data, page, total, pageSize, columns, fields, allFields, isLoading, dispatch} = props

    const [filter, setFilter] = useState({degreeLevels: [], campuses: [], programs: []})
    const [search, setSearch] = useState("")
    const model = useModel()

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    useEffect(() => {
        getData()
    }, [search, page, pageSize])

    async function getData() {
        try {
            dispatch({isLoading: true})
            let filterParams: any = {
                search
            }
            if (filter.campuses && filter.campuses.length > 0) {
                filterParams.campusIds = filter.campuses.map((x) => x.id)
            }
            if (filter.degreeLevels && filter.degreeLevels.length > 0) {
                filterParams.degreeLevelIds = filter.degreeLevels.map((x) => x.degreeLevelId)
            }
            if (filter.programs && filter.programs.length > 0) {
                filterParams.programIds = filter.programs.map((x) => x.id)
            }
            const {data, total} = await retentionService.getListRetentions({
                range: {page, pageSize},
                filter: filterParams
            })
            const _retentions = data.map((item) => {
                return {
                    ...item,
                    campusText: item.campuses.map((x) => x.campusName).join(", "),
                    degreeLevelText: item.degreeLevels.map((x) => x.degreeLevelName).join(", "),
                    programText: item.programs.map((x) => {
                        return <div key={x.programId}>{x.programName}</div>
                    })
                }
            })
            dispatch({data: _retentions, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    function getColumns() {
        return [
            {
                title: "ID",
                field: "code",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: "Name",
                field: "name",
                headerStyle: {minWidth: "100px"}
            },
            {
                title: "Campus",
                field: "campusText",
                headerStyle: {minWidth: "100px"}
            },
            {
                title: "DegreeLevel",
                field: "degreeLevelText",
                headerStyle: {minWidth: "100px"}
            },
            {
                title: "Program",
                field: "programText",
                headerStyle: {minWidth: "200px"}
            }
        ]
    }

    function getFields() {
        const arr = ["ID", "Name", "Campus", "DegreeLevel", "Program"]
        return arr
    }

    const onClickRowItem = (row) => {
        navigate_to(row.id)
    }

    const onChangeFilter = (key, value) => {
        const newFilter = {...filter}
        newFilter[key] = value
        setFilter(newFilter)
    }

    const navigate_to = (_id?: string) => {
        if (_id === undefined) {
            history.push(`/settings/academics/retention/detail`)
        } else {
            history.push(`/settings/academics/retention/detail?id=${_id}`)
        }
    }

    const onClickClearFilter = () => {
        setFilter({degreeLevels: [], campuses: [], programs: []})
    }

    const onSearchInput = (search) => {
        setSearch(search)
    }

    const onApplyFilter = () => {
        getData()
    }

    return (
        <div>
            <StageMapping />
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClickClearFilter}
                onSearchInput={onSearchInput}
                renderRightFilter={() => (
                    <div className={styles.action}>
                        <BaseButton title="Add" onClick={() => navigate_to()} className={styles.addButton} />
                    </div>
                )}>
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <div>
                            <FormLabel label={getFieldLabel(model, Settings.GeneralLabel.Campuses, "Campus")} />
                            <CampusSelect
                                value={filter.campuses}
                                placeholder="Select"
                                onChange={(option: any) => onChangeFilter("campuses", option ?? [])}
                                isMulti
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div>
                            <FormLabel label="Degree Level" />
                            <DegreeLevelSelect
                                value={filter.degreeLevels}
                                placeholder="Select"
                                onChange={(option: any) => onChangeFilter("degreeLevels", option ?? [])}
                                isMulti
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div>
                            <FormLabel label={getFieldLabel(model, Settings.GeneralLabel.Majors, "Majors")} />
                            <ProgramSelect
                                value={filter.programs}
                                placeholder="Select"
                                onChange={(option: any) => onChangeFilter("programs", option ?? [])}
                                isMulti
                            />
                        </div>
                    </Col>
                </Row>
            </BaseNewFilter>
            <Row className={styles.marginTop}>
                <Col span={24}>
                    <KlassappTableHeader
                        page={page}
                        total={total}
                        defaultPageSize={pageSize}
                        onChangePage={props.onChangePage}
                        onChangeRowPerPage={props.onChangeRowPerPage}
                    />
                    <KlassappTable
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fields={fields}
                        allFields={allFields}
                        onChangeFields={props.onChangeFields}
                        onUpdateRowData={props.onUpdateRowData}
                        onUpdateTableData={props.onUpdateTableData}
                        onClickRowItem={onClickRowItem}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default KlassappTableHOC(Retention)
