import {useQuery} from "@tanstack/react-query"
import {useMemo} from "react"
import {newStudentStatusService} from "services"
import {Auth} from "types/auth"
import {StudentStatus} from "types/students"
import {orderBy} from "lodash"

type Options = {
    forState?: Auth.StudentState[]
    order?: {
        field: Array<keyof StudentStatus>
        dir: Array<"asc" | "desc">
    }
    displayStatusesWithGivenStateOnly?: boolean
}

const DEFAULT_ORDER: Options["order"] = {
    field: ["priority"],
    dir: ["asc"]
}

const INITIAL_DATA: StudentStatus[] = []

export const useAllAvailableStudentStatuses = ({forState, order = DEFAULT_ORDER}: Options = {}) => {
    const allAvailableStatusesQ = useQuery(
        ["all-active-student-statuses-new"],
        () => newStudentStatusService.getAvailableStatuses(true),
        {refetchOnMount: false}
    )

    const statuses = useMemo(() => {
        let resultStatuses = allAvailableStatusesQ.data ?? INITIAL_DATA

        if (forState) {
            resultStatuses = resultStatuses.filter((status) => forState.includes(status.state))
        }

        return orderBy(resultStatuses, DEFAULT_ORDER.field, DEFAULT_ORDER.dir)
    }, [allAvailableStatusesQ.data, forState])

    const sortedStatuses = useMemo(() => orderBy(statuses, order.field, order.dir), [statuses, order])

    return {statuses, sortedStatuses, isLoading: allAvailableStatusesQ.isFetching}
}
