import {useEffect, useState} from "react"
import moment from "moment"
import styles from "./TimeInterval.module.css"

const TimeInterval = () => {
    const [now, setNow] = useState(moment())

    useEffect(() => {
        const interval = window.setInterval(() => {
            setNow(moment())
        }, 1000)

        return () => {
            window.clearInterval(interval)
        }
    }, [])

    return (
        <div className={styles.timeLabel}>
            {now.format("dddd")}
            <br />
            {now.format("LL")}
            <br />
            {now.format("LT")}
        </div>
    )
}

export default TimeInterval
