import {useTranslation} from "react-i18next"
import cx from "classnames"
import {TaskStatus} from "types/tasks"
import styles from "./CardItem.module.css"

export function CardItem(props) {
    const {item, activeStatus, onChangeFilter} = props
    const {t} = useTranslation(["tasks", "common"])
    const isActive = activeStatus && activeStatus.id === item.id
    const {status} = item

    return (
        <div className={styles.wrap} onClick={() => onChangeFilter(status)}>
            <div className={styles.headerWrap}>
                {status !== TaskStatus.ALL && (
                    <div
                        className={cx(styles.status, {
                            [styles.status__todo]: status === TaskStatus.TODO,
                            [styles.status__started]: status === TaskStatus.STARTED,
                            [styles.status__done]: status === TaskStatus.DONE,
                            [styles.status__awaiting]: status === TaskStatus.AWAITING_APPROVAL,
                            [styles.status__rejected]: status === TaskStatus.REJECTED,
                            [styles.status__overdue]: status === TaskStatus.OVERDUE,
                            [styles.status__draft]: status === TaskStatus.DRAFT
                        })}
                    />
                )}
                <div className={styles.titleWrap}>
                    <p className={styles.title}>{t(`tasks.${item.title}`)}</p>
                </div>
            </div>
            <p className={styles.count}>{item.count || 0}</p>
            {isActive && <div className={styles.activeIndicator} />}
        </div>
    )
}
