import {useModel} from "hooks"
import styles from "./MediaLibrary.module.css"
import {ReactComponent as IconUpload} from "./icons/upload-to-cloud.svg"
import cx from "classnames"
import {BaseButton, BaseInput, Icon} from "components"
import {getPreviewUnavailableImage, onErrorImageLoad} from "helpers"
import ReactPlayer from "react-player/lazy"
import {PDFViewer} from "components/FileViewer"
import {FormLabel} from "components/Form"
import {MediaLibraryItemSubType, MediaLibraryItemType} from "types/media-library"
import {useDropzone} from "react-dropzone"
import {useMemo} from "react"

const FILE_TYPE = {
    image: {
        validations: "image/*",
        types: ["JPEG", "PNG"]
    },
    video: {
        validations: "video/*",
        types: ["MP4"]
    },
    document: {
        validations: "application/pdf",
        types: ["PDF"]
    },
    link: {
        validations: "",
        types: []
    }
}

const UPLOAD_MEDIA_HEADING_LABEL = {
    [MediaLibraryItemType.Image]: "Upload image",
    [MediaLibraryItemType.Video]: "Upload preview",
    [MediaLibraryItemType.Document]: "Upload document",
    [MediaLibraryItemType.Link]: "Upload media"
}

const LINK_MEDIA_HEADING_LABEL = {
    [MediaLibraryItemType.Image]: "Image preview",
    [MediaLibraryItemType.Video]: "Video preview",
    [MediaLibraryItemType.Document]: "Document preview",
    [MediaLibraryItemType.Link]: "Preview"
}

const ALTERNATE_PREVIEW_HEADING_LABEL = {
    [MediaLibraryItemType.Image]: "Alternate image preview",
    [MediaLibraryItemType.Video]: "Video image preview",
    [MediaLibraryItemType.Document]: "Document image preview",
    [MediaLibraryItemType.Link]: "Link preview"
}

type Props = {
    mediaLibraryData: any
    itemType: MediaLibraryItemType
    itemSubType: MediaLibraryItemSubType
    link?: string
    videoLinkThumbnail?: string
    imageSource?: string
    mediaFile?: File
    errors?: any
    onChangeLink?: (value: string) => void
    onRemoveFile: () => void
    onRemoveImageThumbnail: () => void
    onDropFiles: (acceptedFiles: any) => void
    onDropThumbnails: (acceptedFiles: any) => void
}

export function FileCardHandler(props: Props) {
    const {
        mediaLibraryData,
        itemType,
        itemSubType,
        link,
        videoLinkThumbnail,
        imageSource,
        mediaFile,
        errors,
        onChangeLink,
        onRemoveFile,
        onRemoveImageThumbnail,
        onDropFiles,
        onDropThumbnails
    } = props
    const model = useModel()

    const {getRootProps, getInputProps} = useDropzone({
        multiple: false,
        onDrop: onDropFiles,
        accept: itemType ? FILE_TYPE[itemType]?.validations : ""
    })

    const {getRootProps: getRootPropsForImageThumbnail, getInputProps: getInputPropsForImageThumbnail} = useDropzone({
        multiple: false,
        onDrop: onDropThumbnails,
        accept: "image/*"
    })

    const mediaFileUrl = useMemo(() => {
        return mediaFile ? URL.createObjectURL(mediaFile) : undefined
    }, [mediaFile])

    const renderUploadFile = () => {
        if (itemSubType === "link") {
            if (itemType === MediaLibraryItemType.Link) return null

            let isMediaAvailable: boolean = true
            let imgSrc = link ?? imageSource
            if (!imgSrc) {
                isMediaAvailable = false
                imgSrc = getPreviewUnavailableImage(model.clientSetting.slug)
            }

            return (
                <div className={styles.previewImageContainer} key="5">
                    <p className={styles.uploadCardHeadingLabel}>{LINK_MEDIA_HEADING_LABEL[itemType]}</p>
                    <div
                        className={cx(styles.mediaPreviewContainer, {
                            [styles.listingMediaPreviewContainerDetailPage]:
                                mediaLibraryData.type === MediaLibraryItemType.Document
                        })}>
                        {itemType === MediaLibraryItemType.Image || !isMediaAvailable ? (
                            <img
                                className={styles.mediaImage}
                                src={imgSrc}
                                alt=""
                                onError={(currentTarget) => onErrorImageLoad(model.clientSetting.slug, currentTarget)}
                            />
                        ) : itemType === MediaLibraryItemType.Video ? (
                            <ReactPlayer url={imgSrc} controls />
                        ) : null}
                    </div>
                </div>
            )
        } else {
            if (imageSource) {
                return (
                    <div className={styles.previewImageContainer}>
                        {itemType !== MediaLibraryItemType.Link && (
                            <p className={styles.uploadCardHeadingLabel}>{LINK_MEDIA_HEADING_LABEL[itemType]}</p>
                        )}
                        <div
                            className={cx(styles.mediaPreviewContainer, {
                                [styles.listingMediaPreviewContainerDetailPage]:
                                    mediaLibraryData.type === MediaLibraryItemType.Document
                            })}>
                            <span onClick={onRemoveFile}>
                                <Icon
                                    icon="CROSS_CIRCLE_BORDER"
                                    className={cx(styles.icon, styles.mediaPreviewClose)}
                                />
                            </span>
                            {itemType === MediaLibraryItemType.Image ? (
                                <img
                                    className={styles.mediaImage}
                                    src={imageSource ?? getPreviewUnavailableImage(model.clientSetting.slug)}
                                    alt=""
                                    onError={(currentTarget) =>
                                        onErrorImageLoad(model.clientSetting.slug, currentTarget)
                                    }
                                />
                            ) : itemType === MediaLibraryItemType.Video ? (
                                <ReactPlayer url={imageSource} controls />
                            ) : (
                                itemType === MediaLibraryItemType.Document && (
                                    <PDFViewer
                                        fileSrc={mediaFileUrl}
                                        options={{toolbar: false, zoom: "fitToWidth", view: "fitToWidth"}}
                                    />
                                )
                            )}
                        </div>
                    </div>
                )
            }
            return (
                <div className={styles.previewImageContainer} key="3">
                    <p className={styles.uploadCardHeadingLabel}>{UPLOAD_MEDIA_HEADING_LABEL[itemType]}</p>
                    <div {...getRootProps()} className={styles.dropzoneItem}>
                        <input {...getInputProps()} />
                        <IconUpload width="32" height="32" className={styles.iconUpload} />
                        <p>UPLOAD {itemType?.toUpperCase()}</p>
                        <p className={styles.dropzone__text}>
                            Drag &amp; drop a {itemType ? FILE_TYPE[itemType].types.join(" or ") : ""} file into this
                            box or
                        </p>
                        <BaseButton title="Browse to upload" className={styles.dropzone__btn} />
                    </div>
                </div>
            )
        }
    }

    const renderUploadPreviewThumbnail = () => {
        let imgSrc = videoLinkThumbnail
            ? videoLinkThumbnail
            : itemSubType === MediaLibraryItemType.Link
            ? link
            : imageSource
        if (!imgSrc) {
            imgSrc = getPreviewUnavailableImage(model.clientSetting.slug)
        }

        return (
            <>
                <div className={styles.previewImageContainer} key="7">
                    <p className={styles.uploadCardHeadingLabel}>{ALTERNATE_PREVIEW_HEADING_LABEL[itemType]}</p>
                    <div className={cx(styles.mt20, styles.mediaPreviewContainer)}>
                        {videoLinkThumbnail && (
                            <span onClick={onRemoveImageThumbnail}>
                                <Icon
                                    icon="CROSS_CIRCLE_BORDER"
                                    className={cx(styles.icon, styles.mediaPreviewClose)}
                                />
                            </span>
                        )}
                        <img
                            className={styles.mediaImage}
                            src={imgSrc}
                            alt=""
                            onError={(currentTarget) => onErrorImageLoad(model.clientSetting.slug, currentTarget)}
                        />
                    </div>
                </div>
                <div className={styles.previewImageContainer} key="8">
                    <p className={styles.uploadCardHeadingLabel}>Use an alternate preview image</p>
                    <div {...getRootPropsForImageThumbnail()} className={styles.dropzoneItem}>
                        <input {...getInputPropsForImageThumbnail()} />
                        <IconUpload width="32" height="32" className={styles.iconUpload} />
                        <p>UPLOAD Preview Image</p>
                        <p className={styles.dropzone__text}>Drag &amp; drop a image file into this box or</p>
                        <BaseButton title="Browse to upload" className={styles.dropzone__btn} variant="secondary" />
                    </div>
                </div>
            </>
        )
    }

    const renderContent = () => {
        if (!itemType || !itemSubType) return null
        if (mediaLibraryData?.id) {
            if (itemSubType !== "link") {
                return (
                    <>
                        <div className={styles.previewImageContainer} key="1">
                            <p className={styles.uploadCardHeadingLabel}>{LINK_MEDIA_HEADING_LABEL[itemType]}</p>
                            <div
                                className={cx(styles.mediaPreviewContainer, {
                                    [styles.listingMediaPreviewContainerDetailPage]:
                                        mediaLibraryData.type === MediaLibraryItemType.Document
                                })}>
                                {mediaLibraryData.thumbnailCard}
                            </div>
                        </div>
                        {mediaLibraryData.previewThumbnailCard && (
                            <div className={styles.previewImageContainer} key="2">
                                <p className={styles.uploadCardHeadingLabel}>Preview Image</p>
                                <div className={styles.mediaPreviewContainer}>
                                    {mediaLibraryData.previewThumbnailCard}
                                </div>
                            </div>
                        )}
                    </>
                )
            } else {
                return renderUploadPreviewThumbnail()
            }
        } else {
            return (
                <>
                    {renderUploadFile()}
                    {renderUploadPreviewThumbnail()}
                </>
            )
        }
    }

    return (
        <>
            {itemSubType === "link" && (
                <div className={styles.formGroup} key="5">
                    <FormLabel label="Link" isRequired />
                    <BaseInput value={link} onChange={onChangeLink} placeholder="Link" error={!!errors["link"]} />
                </div>
            )}
            <div className={styles.fileCardHandler}>{renderContent()}</div>
        </>
    )
}
