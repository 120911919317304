import React from "react"
import {withTranslation} from "react-i18next"
import {isEqual, keyBy, get, isEmpty} from "lodash"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableProps} from "types/common"
import {BaseButton} from "components/buttons"
import {Icon} from "components/Icon"
import {handleError, checkTaskDepartmentPermission, getFullName} from "helpers"
import {TaskStatus, TaskType} from "types/tasks"
import {KlassappTableHOC} from "HOC"
import {taskService, departmentSubunitService} from "services"
import {Status} from "sections/Tasks/Common"
import {Model} from "Model"
import {PermissionType} from "types/permission"
import styles from "./AssignedTab.module.css"
import {Auth} from "types/auth"
import {FilterKey} from "types/filter"
import {ExportButton, ExportFileType} from "components/ui"
import moment from "moment"
import {exportCsv} from "helpers/export-table"
import {exportExcel} from "helpers/export-xlsx"

type PageProps = {
    t: Function
    filter: any
    isStaff: boolean
    history?: any
    model: Model
    onClickAdd: () => void
    departmentId?: number
    search: string
    isExported?: boolean
}

type Props = KlassappTableProps & PageProps

class AssignedTab extends React.Component<Props, {}> {
    state = {
        departmentSubunits: [],
        isExporting: false,
        defaultPageSize: 100
    }

    async componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getFilterMemoryKey", func: this.getFilterKey},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "getColumns", func: this.getColumns},
            {key: "onClickDeleteMulti", func: this.onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: this.onClickDuplicateMulti},
            {key: "onClickEdit", func: this.onClickEdit},
            {key: "onClickDelete", func: this.onClickDelete},
            {key: "onClickRowItem", func: this.onClickRowItem}
        ])
        this.props.dispatch({isLoading: true})
        const departmentSubunits = await this.getDepartmentSubunits()
        this.getData(departmentSubunits)
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.filter, this.props.filter) || prevProps.search !== this.props.search) {
            this.props.dispatch({page: 1}, () => {
                this.getData()
            })
        }
    }

    getDepartmentSubunits = async () => {
        try {
            const params: any = {
                range: {pageSize: 50, page: 1}
            }
            const {departmentId} = this.props
            if (departmentId) {
                params.filter = {
                    departmentIds: [departmentId]
                }
            }
            const {data: departmentSubunits} = await departmentSubunitService.getSubunits(params)
            this.setState({departmentSubunits})
            return departmentSubunits
        } catch (error) {
            handleError(error)
            return []
        }
    }

    getPageTitle = () => {
        return this.props.t("tasks.task")
    }

    getFilterKey = () => {
        return FilterKey.TasksPage
    }

    getFields = () => {
        const {t} = this.props
        return [
            t("tasks.code"),
            t("tasks.name"),
            t("tasks.type"),
            t("tasks.department"),
            t("tasks.responses"),
            t("tasks.dueDate"),
            t("tasks.status"),
            t("tasks.assignedBy"),
            t("tasks.assignedTo"),
            t("tasks.createdAt"),
            t("taskDetail.form.additionalApprovalAccess")
        ]
    }

    getTableHeaderActions = () => {
        const {t, onClickShowConfirmModal, isExported} = this.props

        if (isExported) {
            return []
        }

        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
    }

    getData = async (departmentSubunits = null) => {
        const {dispatch} = this.props
        const {departmentSubunits: departmentSubunitsState} = this.state
        dispatch({isLoading: true})
        try {
            const subunits = departmentSubunits || departmentSubunitsState
            const subunitsKeyById = keyBy(subunits, "subunitId")
            const params = this.getParams()
            const {data, total} = await taskService.getAll(params)
            const newData = data.map((task: any) => {
                task.statusHtml = this.renderStatusHtml(task.status, task.allSubtaskRejected)
                task.typeHtml = this.renderTypeHtml(task.type, 0)
                task.responses = task.responsesLabel
                task.departmentSubunitName = get(subunitsKeyById, [task.departmentId, "name"])
                task.assignedToHtml = this.renderAssignedToHtml(task.assignedTo)
                task.approvalAccessHtml = (task.cc || []).map((staff) => getFullName(staff)).join(", ")
                return task
            })
            this.props.dispatch({data: newData, total: total})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    renderAssignedToHtml = ({assignedTo, customProfileId}) => {
        return (
            <div className={styles.assignedTo}>
                <span>{assignedTo}</span>
                {customProfileId && <span className={styles.enrollmentIdItem}>{customProfileId}</span>}
            </div>
        )
    }

    renderTypeHtml = (type, parentId) => {
        const {t} = this.props
        if (parentId) {
            return t("tasks.taskFromLibrary")
        }
        switch (type) {
            case TaskType.DIGITAL_DOCUMENT:
                return t("tasks.digitalDocument")
            case TaskType.SCAN_UPLOAD:
                return t("tasks.scanUpload")
            case TaskType.FROM_LIBRARY:
                return t("tasks.library")
            case TaskType.FORM:
                return t("tasks.form")
            case TaskType.CUSTOM:
                return t("tasks.custom")
            default:
                return ""
        }
    }

    renderStatusHtml = (status, allSubtaskRejected) => {
        return <Status status={status} doneWithRejected={allSubtaskRejected} />
    }

    getParams = () => {
        const {page, filter, orderField, search, model} = this.props
        const {
            statuses,
            staffs,
            assignedToStaffUsers,
            departments,
            type,
            campuses,
            startTerms,
            startDateRange,
            studentStatuses,
            programs
        } = filter
        const filterData: any = {search}
        const storageData = model.getStorageFilter(FilterKey.TasksPage)
        const pageData = storageData?.page || page
        const pageSizeData = storageData?.pageSize || this.state.defaultPageSize
        let orderFieldData = orderField
        if (storageData?.colOrd) {
            const columns: any[] = this.getColumns()
            const columnOrder = columns.find((column: any) => column.columnIndex === storageData.colOrd.idx)
            orderFieldData = {field: columnOrder?.orderField || columnOrder?.field, order: storageData.colOrd.order}
        }
        if (!isEmpty(staffs)) {
            filterData.staff = staffs.map((item) => item.id)
        }
        if (!isEmpty(campuses)) {
            filterData.campusIds = campuses.map((item) => item.id)
        }
        if (!isEmpty(startTerms)) {
            filterData.startingTermIds = startTerms.map((item) => item.id)
        }
        if (!isEmpty(startDateRange)) {
            filterData.startDateRange = startDateRange
        }
        if (!isEmpty(studentStatuses)) {
            filterData.studentStatusIds = studentStatuses.map((s) => s.statusId)
        }
        if (!isEmpty(programs)) {
            filterData.programIds = programs.map((item) => item.id)
        }
        if (!isEmpty(assignedToStaffUsers)) {
            filterData.assignedToStaffUserIds = assignedToStaffUsers.map((item) => item.id)
        }
        if (!isEmpty(departments)) {
            filterData.departmentIds = (departments || []).map((department) => department.subunitId)
        }
        if (!isEmpty(statuses)) {
            const hasAllStatus = statuses.some((item) => item.id === TaskStatus.ALL)
            filterData.status = hasAllStatus
                ? [
                      TaskStatus.TODO,
                      TaskStatus.DRAFT,
                      TaskStatus.STARTED,
                      TaskStatus.OVERDUE,
                      TaskStatus.AWAITING_APPROVAL
                  ]
                : statuses.map((item) => item.status)
        }
        if (!isEmpty(type)) {
            filterData.type = type.id
        }
        const params = {
            filter: filterData,
            range: {
                page: pageData,
                pageSize: pageSizeData
            },
            sort: {
                orderBy: orderFieldData?.field ?? "updatedAt",
                orderDir: orderFieldData?.order ?? "DESC"
            }
        }
        return params
    }

    getColumns = () => {
        const {t} = this.props
        return [
            {
                title: t("tasks.code"),
                field: "code",
                style: {minWidth: "120px"},
                columnIndex: 1,
                sortable: true
            },
            {
                title: t("tasks.name"),
                field: "name",
                style: {minWidth: "150px"},
                orderField: "name",
                columnIndex: 2,
                sortable: true
            },
            {
                title: t("tasks.type"),
                field: "typeHtml",
                style: {minWidth: "150px"},
                orderField: "type",
                columnIndex: 3,
                sortable: true
            },
            {
                title: t("tasks.department"),
                field: "departmentSubunitName",
                columnIndex: 4,
                style: {minWidth: "120px"}
            },
            {
                title: t("tasks.responses"),
                field: "responses",
                style: {minWidth: "110px"},
                columnIndex: 5,
                orderField: "responses"
            },
            {
                title: t("tasks.dueDate"),
                field: "dueDate",
                style: {minWidth: "130px"},
                orderField: "dueDate",
                fieldType: "date",
                format: "MM/DD/YYYY",
                columnIndex: 6,
                sortable: true
            },
            {
                title: t("tasks.status"),
                field: "statusHtml",
                style: {minWidth: "150px"},
                orderField: "status",
                columnIndex: 7,
                sortable: true
            },
            {
                title: t("tasks.assignedBy"),
                field: "assignedBy",
                style: {minWidth: "150px"},
                orderField: "assignedBy",
                columnIndex: 8,
                sortable: true
            },
            {
                title: t("tasks.assignedTo"),
                field: "assignedToHtml",
                columnIndex: 9,
                style: {minWidth: "150px"}
            },
            {
                title: t("tasks.createdAt"),
                field: "createdAt",
                fieldType: "dateTime",
                style: {minWidth: "160px"},
                columnIndex: 10,
                sortable: true
            },
            {
                title: t("taskDetail.form.additionalApprovalAccess"),
                field: "approvalAccessHtml",
                style: {minWidth: "200px"},
                columnIndex: 11
            }
        ]
    }

    onClickDeleteMulti = async () => {
        const {data, dispatch} = this.props
        const checkedItems = data.filter((item) => item.isChecked)
        const payload = checkedItems.map((item) => item.id)
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await taskService.deleteTask(payload)
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    onClickDuplicateMulti = async () => {
        const {dispatch} = this.props
        // const payload = data
        //     .filter((item) => item.isChecked)
        //     .map(({name, code, is_lock}) => ({name, is_lock, code: `${code} (Copy)`}))
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    onClickRowItem = (data) => {
        const {history, isExported} = this.props
        if (!isExported) {
            history.push({
                pathname: "/tasks/detail",
                search: `type=${data.type}&subtype=assigned&id=${data.id}`
            })
        }
    }

    onClickEdit = (editItem) => {
        const {history} = this.props
        history.push(`/tasks/detail?type=${editItem.type}&subtype=assigned&id=${editItem.id}`)
    }

    onClickDelete = async (deletedItem) => {
        const {dispatch} = this.props
        try {
            dispatch({isLoading: true})
            await taskService.deleteTask([deletedItem.id])
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    onChangeRowPerPage = (rowPerPage) => {
        this.setState({defaultPageSize: rowPerPage}, () => {})
        this.props.onChangeRowPerPage(rowPerPage)
    }

    handleExportFileTypeSelect = async (type: ExportFileType) => {
        try {
            this.setState({isExporting: true})
            const reportColumns = this.getColumns().filter((col) => this.props.fields.includes(col.title))
            const params = this.getParams()
            const {data} = await taskService.getAll({
                ...params,
                range: {pageSize: this.props.total, page: 1}
            })
            const {departmentSubunits: subunits} = this.state
            const subunitsKeyById = keyBy(subunits, "subunitId")
            const reportData = data.map((task: any) => {
                task.statusHtml = task.status ? this.props.t(`tasks.${task.status}`) : ""
                task.typeHtml = this.renderTypeHtml(task.type, 0)
                task.responses = task.responsesLabel
                task.departmentSubunitName = get(subunitsKeyById, [task.departmentId, "name"])
                task.assignedToHtml = task.assignedTo.assignedTo
                return task
            })

            const payload = {
                filename: "Tasks Report",
                columns: reportColumns.map((col) => col.title),
                rows: reportData.map((item) =>
                    reportColumns.map((col) => {
                        if (col.fieldType === "date") {
                            const formatDateUtc = this.props.model.getUserDateFormat()
                            return item[col.field] ? moment.utc(item[col.field]).format(formatDateUtc) : ""
                        }
                        if (col.fieldType === "dateTime") {
                            const formatDateUtc = this.props.model.getUserDateTimeFormat()
                            return item[col.field] ? moment.utc(item[col.field]).format(formatDateUtc) : ""
                        }
                        return item[col.field]?.toString() ?? ""
                    })
                )
            }
            if (type === "csv") {
                exportCsv(payload)
            } else if (type === "excel") {
                exportExcel(payload)
            }
        } catch (err) {
            handleError(err)
        } finally {
            this.setState({isExporting: false})
        }
    }

    render() {
        const {
            page,
            total,
            pageSize,
            columns,
            data,
            menuActions,
            allFields,
            fields,
            tableHeaderActions,
            isLoading,
            isHideMenuActions,
            isShowTableHeaderAction,
            t,
            orderField,
            model,
            isExported
        } = this.props
        const {departmentSubunits, isExporting} = this.state
        const canCreateTask = departmentSubunits.some((departmentSubunit) => {
            return checkTaskDepartmentPermission(
                PermissionType.Add,
                model,
                departmentSubunit.departmentId,
                departmentSubunit.subunitId
            )
        })

        return (
            <div>
                {canCreateTask &&
                    !isExported &&
                    ![Auth.UserProfileType.Others, Auth.UserProfileType.Student].includes(
                        model.getUserProfileType()
                    ) && (
                        <div className={styles.createBtnWrap}>
                            <BaseButton
                                title={t("tasks.createTask").toUpperCase()}
                                onClick={this.props.onClickAdd}
                                icon={<Icon className={styles.plusIcon} icon="PLUS" color="#FFF" />}
                                disabled={false}
                            />
                        </div>
                    )}
                {isExported && (
                    <div className={styles.createBtnWrap}>
                        <ExportButton
                            availableFileTypes={["csv", "excel"]}
                            onSelect={this.handleExportFileTypeSelect}
                            isLoading={isExporting}
                        />
                    </div>
                )}
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={this.state.defaultPageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={isExported || isHideMenuActions ? [] : menuActions}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    isShowCheckedColumn={!isExported}
                    orderField={orderField}
                    onClickRowItem={this.onClickRowItem}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["tasks", "common", "user"])(AssignedTab))
