import {useTranslation} from "react-i18next"
import {Tabs} from "antd"
import {useActiveTab} from "hooks"
import {TabHeader} from "components/Tab"
import {LessonList, ClassroomAttendance, DailyAttendance, MissedAttendance} from "./parts"
import {RegistrarMissedAttendance} from "./parts/MissedAttendance"
import MakeupAttendance from "./parts/MakeupAttendance"

const {TabPane} = Tabs

const ATTENDANCE_TABS = {
    LESSON_LIST: "1",
    CLASSROOM_ATTENDANCE: "2",
    DAILY_ATTENDANCE: "3",
    MISSED_ATTENDANCE: "4",
    MAKE_UP: "5"
}

type Props = {
    departmentId: number
    isShowAllMissedAttendance?: boolean
    studentProfileId?: number
}

export function Attendance(props: Props) {
    const {t} = useTranslation(["studentServices"])
    const {isShowAllMissedAttendance, studentProfileId} = props
    const [activeTab, onChangeTab] = useActiveTab(ATTENDANCE_TABS.LESSON_LIST, "type")

    return (
        <Tabs className="fullwidth klassSubTab" activeKey={activeTab} onChange={onChangeTab}>
            <TabPane key={ATTENDANCE_TABS.LESSON_LIST} tab={<TabHeader title="Lessons" />}>
                {activeTab === ATTENDANCE_TABS.LESSON_LIST && <LessonList studentProfileId={studentProfileId} />}
            </TabPane>
            <TabPane key={ATTENDANCE_TABS.CLASSROOM_ATTENDANCE} tab={<TabHeader title="Classroom" />}>
                {activeTab === ATTENDANCE_TABS.CLASSROOM_ATTENDANCE && (
                    <ClassroomAttendance studentProfileId={studentProfileId} />
                )}
            </TabPane>
            <TabPane key={ATTENDANCE_TABS.DAILY_ATTENDANCE} tab={<TabHeader title="Today" />}>
                {activeTab === ATTENDANCE_TABS.DAILY_ATTENDANCE && (
                    <DailyAttendance studentProfileId={studentProfileId} />
                )}
            </TabPane>
            <TabPane key={ATTENDANCE_TABS.MISSED_ATTENDANCE} tab={<TabHeader title="Missed" />}>
                {activeTab === ATTENDANCE_TABS.MISSED_ATTENDANCE && isShowAllMissedAttendance ? (
                    <RegistrarMissedAttendance studentProfileId={studentProfileId} />
                ) : (
                    <MissedAttendance studentProfileId={studentProfileId} />
                )}
            </TabPane>
            <TabPane key={ATTENDANCE_TABS.MAKE_UP} tab={<TabHeader title="Make up" />}>
                {activeTab === ATTENDANCE_TABS.MAKE_UP && <MakeupAttendance studentProfileId={studentProfileId} />}
            </TabPane>
        </Tabs>
    )
}
