/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react"
import {Modules, useEdularModulesContext} from "@edular/modules"
import {useTranslation} from "react-i18next"
import {Link, useHistory} from "react-router-dom"
import moment from "moment"
import {Col, Dropdown, Menu, Row, Spin, Tooltip} from "antd"
import {get, orderBy} from "lodash"
import {Icon} from "components/Icon"
import {checkPermission, getFullNameOrPreferredName, getShortName, handleError, toastInfo} from "helpers"
import {autoDialerService, contactStrategyService, studentService, userServiceV3, workflowService} from "services"
import {useModel, useMutation, useVisible} from "hooks"
import {BaseDepartmentId, DepartmentDialerModuleMapping} from "types/departments"
import {Auth} from "types/auth"
import {EVENT_STUDENT_STATUS_UPDATED, StudentStatus} from "types/students"
import styles from "./StudentInfoPanel.module.css"
import cx from "classnames"
import {BaseInput} from "components"
import {genderPronounOptionsKeyById} from "sections/users/Detail/parts/Personal/parts/GeneralDetail"
import {StudentDepartmentInfo, StudentInfoActivityPanel, StudentInfoCalendarPanel} from "./parts"
import {ReactComponent as DetailNavigationIcon} from "./assets/detail.svg"
import {ReactComponent as CalendarIcon} from "./assets/calendar.svg"
import {ReactComponent as ProfileIcon} from "./assets/profile.svg"
import {ReactComponent as ActivityIcon} from "./assets/activity.svg"
import {ReactComponent as DocumentIcon} from "./assets/file.svg"
import {ReactComponent as ChatIcon} from "./assets/chat.svg"
import {ReactComponent as DialerIcon} from "./assets/dialer.svg"
import {MessagePanel} from "sections/NewCommunication/shared"
import {PanelNavigation} from "uiKit/PanelNavigation"
import {Permissions} from "types/permission"
import {AdvisorTitle} from "uiKit/StudentInfo/parts"
import {TriggeredWorkflow, TriggeredWorkflowStatus, TriggeredWorkflowType} from "types/triggered-workflow"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import CircularSpin from "components/CircularSpin/CircularSpin"
import {RegistrarStaffTab} from "sections/academics/registrar/RegistrarStaff/RegistrarStaff"
import {FinancialAidStaffTabs} from "sections/FinancialAid/FinancialAidStaff"
import {StudentAccountStaffTab} from "sections/StudentAccount/Staff/StudentAccountStaff"
import memoize from "lodash/memoize"
import {copyTextToClipboard} from "helpers/clipboardHelper"
import {ActiveStudentContext} from "context/ActiveStudentContext"
import {AutoDialer} from "types/auto-dialer"
import {NewStudentStatusSelect} from "components/NewStudentStatusSelect"
import EditableStudentField from "components/EditableStudentField"
import {emitEvent, offEvent, onEvent} from "helpers/eventHelper"
import {EVENT_STUDENT_ACTIVITY_CREATED, StudentActivity} from "types/activity"

type DisplayType = "panel" | "drawer"

export type StudentInfoPanelProps = {
    student?: Auth.DepartmentStudent
    departmentId?: number
    fromPage?:
        | "academics"
        | "admissions"
        | "financial-aid"
        | "student-account"
        | "career-services"
        | "student-services"
        | "academic-affairs"
    backUrl?: string
    isDetailButtonVisible?: boolean
    detailUrl?:
        | "academics"
        | "academics/registrar"
        | "admissions"
        | "financial-aid"
        | "student-account"
        | "career-services"
        | "student-services"
        | "academic-affairs"
    displayType?: DisplayType
    advisorTitle?: AdvisorTitle
    renderDepartmentInfo?: (props) => JSX.Element
    onChangeStage?: (stageId: number) => void
    onChangeStudentStatus?: (data: {profileId: number; statusId: number}) => void
    onChangeFinancialAidStatus?: (data: {profileId: number; statusId: number}) => void
    onClose?: () => void
    onOpenDrawer?: (activeSection: string) => void
    activeSection?: string
    minimizeDrawerWidth?: () => void
    maximizeDrawerWidth?: () => void
    renderAutoDialerInfo?: (props) => JSX.Element
    hideMessageButton?: boolean
}

const studentDetailUrls = {
    academics: {
        goStudentDetailUrl: "/users/",
        backUrl: `/academics/registrar/staff?tab=${RegistrarStaffTab.Students}`
    },
    "academic-affairs": {
        goStudentDetailUrl: "/users/",
        backUrl: "/academic-affairs/staff?tab=students"
    },
    admissions: {
        goStudentDetailUrl: "/users/",
        backUrl: "/admissions/staff?tab=2&loadFilters=true"
    },
    "financial-aid": {
        goStudentDetailUrl: "/users/",
        backUrl: `/financial-aid/staff?tab=${FinancialAidStaffTabs.Students}`
    },
    "student-account": {
        goStudentDetailUrl: "/users/",
        backUrl: `/student-account/staff?tab=${StudentAccountStaffTab.Students}`
    },
    "student-services": {
        goStudentDetailUrl: "/users/",
        backUrl: `/student-services/staff?tab=students`
    },
    "career-services": {
        goStudentDetailUrl: "/users/",
        backUrl: "/career-services?tab=student"
    }
}

export function StudentInfoPanel(props: StudentInfoPanelProps) {
    const {
        student,
        departmentId,
        isDetailButtonVisible = false,
        detailUrl,
        displayType = "panel",
        renderDepartmentInfo,
        onChangeStage,
        onChangeStudentStatus,
        onClose,
        onOpenDrawer,
        activeSection: initActiveSection,
        minimizeDrawerWidth,
        maximizeDrawerWidth,
        fromPage,
        backUrl,
        renderAutoDialerInfo,
        hideMessageButton = false
    } = props
    const {isModuleEnable} = useEdularModulesContext()
    const model = useModel()
    const {setDepartmentId, reloadStudent, setStudent, studentAdvisors, advisorsPopup} =
        useContext(ActiveStudentContext)
    const [studentProfileId, setStudentProfileId] = useState(0)
    const [userData, setUserData] = useState<Auth.UserProfile>(null)
    const [isLoadingUser, setIsLoadingUser] = useState(false)
    const [systemStatus, setSystemStatus] = useState("")
    const [newStudentStatus, setNewStudentStatus] = useState<Partial<StudentStatus>>()
    const [triggeredContactStrategies, setTriggeredContactStrategies] = useState<TriggeredWorkflow[]>([])

    const contactStrategyDetailVisible = useVisible(true)
    const studentDetailVisible = useVisible(true)
    const contactDetailVisible = useVisible(true)
    const history = useHistory()
    const {t} = useTranslation(["common", "chat", "financialAid", "user"])
    const [activeSection, setActiveSection] = useState<string>(initActiveSection)
    const isStaff = model.isStaffOrAdmin()
    const [reloadKey, setReloadKey] = useState<number>(1)
    const activeStudentRef = React.useRef<number | undefined>()
    useEffect(() => {
        console.log("StudentInfoPanel", "loadStudent", student?.profileId, student?.updatedAt, departmentId)
        const studentProfileId = +get(student, "profileId", 0)
        if (!studentProfileId) return
        activeStudentRef.current = studentProfileId
        setStudentProfileId(studentProfileId)
        setDepartmentId(departmentId)
        setStudent(student)
        getUserData(studentProfileId)

        let systemStatusProfile = ""
        if (student?.profileStatus) {
            systemStatusProfile = student.profileStatus === 1 ? "Active" : "Inactive"
        }
        setSystemStatus(systemStatusProfile)
        setNewStudentStatus({
            statusId: student.statusId,
            name: student.status?.name,
            hexColor: student.status?.hexColor,
            state: student.profileState
        })
    }, [student?.profileId, student?.updatedAt, departmentId])

    const handleCopyTextClick = useMemo(
        () =>
            memoize((text?: string) => () => {
                copyTextToClipboard(text || "").then(() => toastInfo(t("common:message.copiedText", {text})))
            }),
        []
    )

    const getUserData = useCallback(
        async (profileId: number) => {
            if (activeStudentRef.current !== profileId) return
            try {
                setIsLoadingUser(true)
                reloadStudent(profileId)
                setTriggeredContactStrategies([])
                const {
                    data: [user]
                } = await userServiceV3.getAll({
                    filter: {profileId: [profileId]},
                    linkedObjects: true
                })

                setUserData(user)

                const {data: triggeredContactStrategies} = await workflowService.getTriggeredWorkflows({
                    filter: {
                        profileId: [profileId],
                        departmentId: [departmentId],
                        type: [TriggeredWorkflowType.ContactStrategy]
                    },
                    range: {
                        page: 1,
                        pageSize: UNLIMITED_PAGE_SIZE
                    },
                    linkedEntities: true
                })
                setTriggeredContactStrategies(
                    orderBy(
                        triggeredContactStrategies
                            .filter(
                                (triggeredContactStrategy) =>
                                    triggeredContactStrategy.contactStrategy?.departmentSubunit?.departmentId ===
                                    departmentId
                            )
                            .map((item) => ({
                                ...item,
                                currentDay: Math.max(
                                    0,
                                    [TriggeredWorkflowStatus.InProgress, TriggeredWorkflowStatus.OnHold].includes(
                                        item.status
                                    )
                                        ? moment().diff(item.triggeredTime, "day")
                                        : moment(item.completedTime).diff(item.triggeredTime, "day")
                                )
                            })),
                        ["triggeredTime"],
                        ["desc"]
                    )
                )
            } catch (error) {
                handleError(error)
            } finally {
                setIsLoadingUser(false)
            }
        },
        [departmentId]
    )

    const goStudentDetail = () => {
        const userId = userData.id
        if (userId) {
            let pushOptions = {pathname: `/users/${userId}`, state: null}
            if (fromPage && studentDetailUrls[fromPage]) {
                pushOptions = {
                    pathname: `${studentDetailUrls[fromPage].goStudentDetailUrl}${userId}`,
                    state: {
                        backUrlFromPage:
                            backUrl || `${studentDetailUrls[fromPage].backUrl}&studentId=${studentProfileId}`
                    }
                }
            }
            pushOptions.state = {...pushOptions.state, edit: true}
            history.push(pushOptions)
        }
    }

    const reloadExpandedSection = () => {
        if (activeSection) {
            setReloadKey(new Date().getTime())
        }
    }

    const handleNewStudentStatus = (newStatus: StudentStatus) => {
        updateNewStudentStatusMutate.mutate(newStatus)
    }

    const updateNewStudentStatusMutate = useMutation(
        async (newStatus: StudentStatus) => {
            await studentService.updateStudentStatus({studentProfileId, statusId: newStatus.statusId})
        },
        {
            onMutate: (newStatus: StudentStatus) => {
                setNewStudentStatus({
                    statusId: newStatus.statusId,
                    name: newStatus.name,
                    hexColor: newStatus.hexColor,
                    state: newStatus.state
                })
                const oldStudentStatus = newStudentStatus
                return {oldStudentStatus}
            },
            onSuccess: (response, newStatus, context) => {
                onChangeStudentStatus?.({profileId: studentProfileId, statusId: newStatus.statusId})
                emitEvent(EVENT_STUDENT_STATUS_UPDATED, {profileId: studentProfileId, statusId: newStatus.statusId})
            },
            onError: (error, newStatus, context) => {
                if (!context.oldStudentStatus) return
                handleError(error)
                setNewStudentStatus({
                    statusId: context.oldStudentStatus.statusId,
                    name: context.oldStudentStatus.name,
                    hexColor: context.oldStudentStatus.hexColor,
                    state: context.oldStudentStatus.state
                })
            }
        }
    )

    const checkHasPermissionToUpdateAdvisor = () => {
        if (!departmentId) {
            return isStaff
        }
        switch (departmentId) {
            case BaseDepartmentId.Admissions:
                return checkPermission({staff: [Permissions.Staff.Admissions.Students.UpdateAdvisor.Edit]}, model)
            case BaseDepartmentId.Academics:
                return checkPermission(
                    {staff: [Permissions.Staff.Academics.Registrar.Students.UpdateAdvisor.Edit]},
                    model
                )
            case BaseDepartmentId.FinancialAid:
                return checkPermission({staff: [Permissions.Staff.FinancialAid.Students.UpdateAdvisor.Edit]}, model)
            case BaseDepartmentId.StudentAccount:
                return checkPermission({staff: [Permissions.Staff.StudentAccount.Students.UpdateAdvisor.Edit]}, model)
            case BaseDepartmentId.StudentServices:
                return checkPermission(
                    {
                        staff: [
                            Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Students.UpdateAdvisor
                                .Edit
                        ]
                    },
                    model
                )
            case BaseDepartmentId.CareerServices:
                return checkPermission({staff: [Permissions.Staff.CareerServices.Edit]}, model)
            default:
                return false
        }
    }

    const renderStudentAvatar = () => {
        let studentPhoto
        if (student?.photo) {
            let photoList = student.photo
            if (typeof student.photo === "string") {
                photoList = JSON.parse(student.photo)
            }
            studentPhoto = photoList["128"]
        }
        return (
            <div className={styles.avatarWrap}>
                <img src={studentPhoto || "/image/DefaultAvatar.png"} className={styles.avatar} alt="" />
            </div>
        )
    }

    const renderListAdvisors = () => {
        const studentAdvisorsOrderByDefault = orderBy(studentAdvisors, ["isDefault"], ["desc"])
        return (
            <div>
                {(studentAdvisorsOrderByDefault || []).map((advisor, index) => (
                    <div key={index} className={styles.advisorWrap}>
                        <span className={styles.advisorName}>{getShortName(advisor)}</span>
                        {index !== studentAdvisorsOrderByDefault.length - 1 && ", "}
                    </div>
                ))}
            </div>
        )
    }

    const getDepartmentAdvisorTitle = () => {
        switch (departmentId) {
            case BaseDepartmentId.Admissions:
                return t("studentInfo.advisor", {departmentName: "Admissions"})
            case BaseDepartmentId.FinancialAid:
                return t("studentInfo.advisor", {departmentName: "Financial Aid"})
            case BaseDepartmentId.StudentAccount:
                return t("studentInfo.advisor", {departmentName: "Student Account"})
            case BaseDepartmentId.StudentServices:
                return t("studentInfo.advisor", {departmentName: "Student Services"})
            case BaseDepartmentId.Academics:
                return t("studentInfo.advisor", {departmentName: "Academics"})
            case BaseDepartmentId.CareerServices:
                return t("studentInfo.advisor", {departmentName: "Career Services"})
            default:
                return t("studentInfo.advisor", {departmentName: ""})
        }
    }

    const onCollapsePanel = () => {
        handleCloseActiveSection()
        if (onClose) onClose()
    }

    const handleAction = (_activeSection: string) => {
        if (displayType === "panel") {
            if (onOpenDrawer) onOpenDrawer(_activeSection)
            if (maximizeDrawerWidth) maximizeDrawerWidth()
            onCollapsePanel()
        } else {
            setActiveSection(_activeSection)
            if (maximizeDrawerWidth) maximizeDrawerWidth()
        }
    }

    const handleCloseActiveSection = () => {
        if (displayType === "drawer") {
            setActiveSection("") // clear active action
            if (minimizeDrawerWidth) minimizeDrawerWidth()
        }
    }

    const handleActivityCreated = useCallback(
        (activity: StudentActivity) => {
            if (!activity?.profileId || activity.profileId === studentProfileId) {
                getUserData(studentProfileId)
            }
        },
        [studentProfileId, getUserData]
    )

    const handleStudentStatusUpdated = useCallback(
        ({profileId}: {profileId: number; statusId: number}) => {
            if (profileId === studentProfileId) {
                getUserData(studentProfileId)
            }
        },
        [studentProfileId, getUserData]
    )

    useEffect(() => {
        onEvent(EVENT_STUDENT_ACTIVITY_CREATED, handleActivityCreated)
        return () => {
            offEvent(EVENT_STUDENT_ACTIVITY_CREATED, handleActivityCreated)
        }
    }, [handleActivityCreated])

    useEffect(() => {
        onEvent(EVENT_STUDENT_STATUS_UPDATED, handleStudentStatusUpdated)
        return () => {
            offEvent(EVENT_STUDENT_STATUS_UPDATED, handleStudentStatusUpdated)
        }
    }, [handleStudentStatusUpdated])

    const onSaveSuccess = () => {
        handleCloseActiveSection()
        getUserData(studentProfileId)
    }

    const handleChangeAdmissionAcceptance = () => {
        getUserData(studentProfileId)
        reloadExpandedSection()
    }

    const handleChangeContactStrategyDay = useCallback(
        async (contactStrategyId: number, profileId: number, day: number) => {
            await contactStrategyService.changeDay(contactStrategyId, profileId, day)
        },
        []
    )

    const [isTogglingTriggeredWorkflow, setTogglingTriggeredWorkflow] = useState(false)

    const toggleTriggeredWorkflow = useCallback(async (triggeredWorkflowId: number) => {
        try {
            setTogglingTriggeredWorkflow(true)
            await workflowService.toggleTriggeredWorkflow(triggeredWorkflowId)
            setTriggeredContactStrategies((prev) =>
                prev.map((item) =>
                    item.triggeredWorkflowId === triggeredWorkflowId
                        ? {
                              ...item,
                              status:
                                  item.status === TriggeredWorkflowStatus.InProgress
                                      ? TriggeredWorkflowStatus.OnHold
                                      : TriggeredWorkflowStatus.InProgress
                          }
                        : item
                )
            )
        } catch (error) {
            handleError(error)
        } finally {
            setTogglingTriggeredWorkflow(false)
        }
    }, [])

    const stopTriggeredWorkflow = useCallback(async (triggeredWorkflowId: number) => {
        try {
            setTogglingTriggeredWorkflow(true)
            await workflowService.stopTriggeredWorkflow(triggeredWorkflowId)
            setTriggeredContactStrategies((prev) =>
                prev.map((item) =>
                    item.triggeredWorkflowId === triggeredWorkflowId
                        ? {
                              ...item,
                              status: TriggeredWorkflowStatus.Canceled
                          }
                        : item
                )
            )
        } catch (error) {
            handleError(error)
        } finally {
            setTogglingTriggeredWorkflow(false)
        }
    }, [])

    const goDocuments = () => {
        const studentId = userData.id
        if (studentId) {
            let pushOptions = {
                pathname: `/users/${studentId}`,
                search: `?tab=${Auth.UserDetailTab.Documents}`,
                state: null
            }
            if (props.fromPage) {
                pushOptions = {
                    pathname: `${
                        props.fromPage === "admissions"
                            ? `/admissions/student/${studentProfileId}`
                            : `${studentDetailUrls[props.fromPage].goStudentDetailUrl}${studentId}`
                    }`,
                    search: `?tab=${Auth.UserDetailTab.Documents}`,
                    state: {
                        backUrlFromPage:
                            props.backUrl || `${studentDetailUrls[fromPage].backUrl}&studentId=${studentProfileId}`
                    }
                }
            }
            pushOptions.state = {...pushOptions.state, edit: true}
            history.push(pushOptions)
        }
    }

    const goFerpa = () => {
        const studentId = userData.id
        if (studentId) {
            let pushOptions = {pathname: `/users/${studentId}`, search: `?tab=${Auth.UserDetailTab.FERPA}`, state: null}
            if (props.fromPage) {
                pushOptions = {
                    pathname: `${studentDetailUrls[props.fromPage].goStudentDetailUrl}${studentId}`,
                    search: `?tab=${Auth.UserDetailTab.FERPA}`,
                    state: {
                        backUrlFromPage:
                            props.backUrl || `${studentDetailUrls[fromPage].backUrl}&studentId=${studentProfileId}`
                    }
                }
            }
            pushOptions.state = {...pushOptions.state, edit: true}
            history.push(pushOptions)
        }
    }

    const startDialer = async () => {
        const data = await autoDialerService.dial({
            filter: {
                departmentId: departmentId
            },
            dialStudentProfileId: student.profileId,
            backUrl: window.location.href
        })
        const authorizedData = data as AutoDialer.ZoomAuthorization
        if (authorizedData && authorizedData.redirect === true) {
            window.location.href = authorizedData.redirectUri
        } else {
            const dialerStudent = data as AutoDialer.Student
            if (!dialerStudent) {
                return
            }
            window.open(`zoomphonecall://${dialerStudent.phone}`, "_blank", "noopener,noreferrer")
        }
    }

    if (!student) {
        return <div>Not found</div>
    }

    if (!userData) {
        return (
            <div className={styles.drawerWrap}>
                <div className={styles.panelStudentWrap}>
                    <div className={styles.panelLoading}>
                        <Spin />
                    </div>
                </div>
            </div>
        )
    }

    const renderStudentStatus = () => {
        return (
            <div className={styles.statusWrap}>
                <div className={styles.statusItem}>
                    <div className={styles.title}>{t("common:message.system")}</div>
                    <div
                        className={cx(styles.statusBar, {
                            [styles.statusBar__inactive]: systemStatus === "Inactive"
                        })}>
                        {systemStatus}
                    </div>
                </div>
                {student?.profileState && renderNewStudentStatus()}
            </div>
        )
    }

    const renderNewStudentStatus = () => {
        return (
            <NewStudentStatusSelect
                variant="sidebar"
                value={newStudentStatus}
                onChange={handleNewStudentStatus}
                studentState={student.profileState}
            />
        )
    }

    const renderProfile = () => {
        const genderPronounValue = genderPronounOptionsKeyById[student?.genderPronoun] || null
        const canShowFerpa = checkPermission(
            {
                staff: [Permissions.Staff.Users.Students.Ferpa.View]
            },
            model
        )

        return (
            <>
                <div className={styles.profileWrap}>
                    {/* TODO: render hold activity at here */}
                    <div className={styles.profileContent}>
                        {renderStudentAvatar()}
                        <div className={styles.profileInfo}>
                            <div className={styles.profileHeader}>
                                <div className={styles.profileNameContainer}>
                                    <Tooltip title={getFullNameOrPreferredName(student)}>
                                        <div
                                            className={cx(styles.profileName, "cursor-copy")}
                                            onClick={handleCopyTextClick(getFullNameOrPreferredName(student))}>
                                            {getFullNameOrPreferredName(student)}
                                        </div>
                                    </Tooltip>
                                    {isDetailButtonVisible && (
                                        <div className={styles.profileLinkWrap}>
                                            <Link
                                                to={{
                                                    pathname: `/${detailUrl}/student/${studentProfileId}`,
                                                    state: {
                                                        backUrlFromPage:
                                                            backUrl ||
                                                            `${studentDetailUrls[fromPage].backUrl}&studentId=${studentProfileId}`
                                                    }
                                                }}>
                                                <div className={styles.profileLinkIconWrap}>
                                                    <DetailNavigationIcon className={styles.profileLinkIcon} />
                                                </div>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                                <div className={styles.profileGender}>
                                    {genderPronounValue ? genderPronounValue?.name : ""}
                                </div>
                            </div>
                            <div className={styles.profileActionWrap}>
                                <Tooltip title="User Detail">
                                    <div className={styles.profileActionIconWrap} onClick={goStudentDetail}>
                                        <ProfileIcon className={styles.profileActionIcon} />
                                    </div>
                                </Tooltip>
                                <Tooltip title="Appointment">
                                    <div
                                        className={cx(styles.profileActionIconWrap, {
                                            [styles.profileActionIconActiveWrap]: activeSection === "appointment"
                                        })}
                                        onClick={() => handleAction("appointment")}>
                                        <CalendarIcon
                                            className={cx(styles.profileActionIcon, {
                                                [styles.profileActionIconActive]: activeSection === "appointment"
                                            })}
                                        />
                                    </div>
                                </Tooltip>
                                {!hideMessageButton && (
                                    <Tooltip title="Message">
                                        <div
                                            className={cx(styles.profileActionIconWrap, {
                                                [styles.profileActionIconActiveWrap]: activeSection === "message"
                                            })}
                                            onClick={() => handleAction("message")}>
                                            <ChatIcon
                                                className={cx(styles.profileActionIcon, {
                                                    [styles.profileActionIconActive]: activeSection === "message"
                                                })}
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                                {isModuleEnable(Modules.ActivitiesAndFollowups) && (
                                    <Tooltip title="Activity">
                                        <div
                                            className={cx(styles.profileActionIconWrap, {
                                                [styles.profileActionIconActiveWrap]: activeSection === "activity"
                                            })}
                                            onClick={() => handleAction("activity")}>
                                            <ActivityIcon
                                                className={cx(styles.profileActionIcon, {
                                                    [styles.profileActionIconActive]: activeSection === "activity"
                                                })}
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                                <Tooltip title="Documents">
                                    <div className={styles.profileActionIconWrap} onClick={goDocuments}>
                                        <DocumentIcon className={styles.profileActionIcon} />
                                    </div>
                                </Tooltip>
                                {canShowFerpa && (
                                    <Tooltip title="FERPA">
                                        <div className={styles.ferpaActionWrap} onClick={goFerpa}>
                                            <span className={styles.ferpaButton}>FERPA</span>
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {DepartmentDialerModuleMapping[departmentId] &&
                    isModuleEnable(DepartmentDialerModuleMapping[departmentId]) && (
                        <div className={styles.zoomBtnWrap} onClick={startDialer}>
                            <DialerIcon className={styles.zoomIcon} />
                            <span className={styles.zoomTitle}>Call</span>
                        </div>
                    )}
            </>
        )
    }

    const renderDepartmentDetail = () => {
        if (!renderDepartmentInfo || !student) return

        return <>{renderDepartmentInfo({student, handleChangeAdmissionAcceptance})}</>
    }

    const renderActiveContactStrategy = () => {
        if (!isStaff) return null
        if (!triggeredContactStrategies.length) return null
        return (
            <div className={styles.studentWrap}>
                <div className={styles.headingWrap}>
                    <div className={styles.headingTitle}>Active Contact Strategy</div>
                    <button
                        className={cx(styles.collapseButton, {
                            [styles.collapsed]: contactStrategyDetailVisible.isVisible
                        })}
                        onClick={() => contactStrategyDetailVisible.toggle()}>
                        <Icon icon="ARROW_DOWN" color="#666666" className={styles.headingIcon} />
                    </button>
                </div>
                {contactStrategyDetailVisible.isVisible &&
                    triggeredContactStrategies.map((item: TriggeredWorkflow) => (
                        <Row
                            key={item.triggeredWorkflowId}
                            gutter={4}
                            justify="space-between"
                            align="middle"
                            wrap={false}>
                            <Col
                                className={cx(styles.workflowNameText, {
                                    [styles.disabled]: [
                                        TriggeredWorkflowStatus.Canceled,
                                        TriggeredWorkflowStatus.Done
                                    ].includes(item.status)
                                })}>
                                <Row gutter={4} wrap={false}>
                                    <Col>
                                        {[TriggeredWorkflowStatus.InProgress, TriggeredWorkflowStatus.OnHold].includes(
                                            item.status
                                        ) && (
                                            <Dropdown
                                                disabled={isTogglingTriggeredWorkflow}
                                                trigger={["click"]}
                                                placement="bottomLeft"
                                                menu={{
                                                    items: [
                                                        {
                                                            key: "resume",
                                                            disabled:
                                                                item.status === TriggeredWorkflowStatus.InProgress,
                                                            label: (
                                                                <Row
                                                                    align="middle"
                                                                    gutter={8}
                                                                    onClick={() =>
                                                                        toggleTriggeredWorkflow(
                                                                            item.triggeredWorkflowId
                                                                        )
                                                                    }>
                                                                    <Col>
                                                                        <Icon icon="RESUME" />
                                                                    </Col>
                                                                    <Col flex={1}>Resume</Col>
                                                                </Row>
                                                            )
                                                        },
                                                        {
                                                            key: "pause",
                                                            disabled: item.status === TriggeredWorkflowStatus.OnHold,
                                                            label: (
                                                                <Row
                                                                    align="middle"
                                                                    gutter={8}
                                                                    onClick={() =>
                                                                        toggleTriggeredWorkflow(
                                                                            item.triggeredWorkflowId
                                                                        )
                                                                    }>
                                                                    <Col>
                                                                        <Icon icon="PAUSE" />
                                                                    </Col>
                                                                    <Col flex={1}>Pause</Col>
                                                                </Row>
                                                            )
                                                        },
                                                        {
                                                            key: "stop",
                                                            label: (
                                                                <Row
                                                                    align="middle"
                                                                    gutter={8}
                                                                    onClick={() =>
                                                                        stopTriggeredWorkflow(item.triggeredWorkflowId)
                                                                    }>
                                                                    <Col>
                                                                        <span className={styles.stopIcon} />
                                                                    </Col>
                                                                    <Col flex={1}>Stop</Col>
                                                                </Row>
                                                            )
                                                        }
                                                    ]
                                                }}>
                                                <span className="cursor-pointer">
                                                    {isTogglingTriggeredWorkflow ? (
                                                        <CircularSpin size="small" />
                                                    ) : (
                                                        <Icon
                                                            icon={
                                                                item.status === TriggeredWorkflowStatus.OnHold
                                                                    ? "PAUSE"
                                                                    : "RESUME"
                                                            }
                                                        />
                                                    )}
                                                </span>
                                            </Dropdown>
                                        )}
                                        {[TriggeredWorkflowStatus.Canceled, TriggeredWorkflowStatus.Done].includes(
                                            item.status
                                        ) && <span className={styles.stopIcon} />}
                                    </Col>
                                    <Col flex={1}>
                                        <span>{item.contactStrategy?.name}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col flex={1} />
                            <Col>
                                <BaseInput
                                    type="number"
                                    disabledArrow
                                    readOnly={item.status !== TriggeredWorkflowStatus.InProgress}
                                    value={item.currentDay}
                                    className={styles.numDaysInput}
                                    onChange={(value) =>
                                        setTriggeredContactStrategies((prev) =>
                                            prev.map((prevItem) =>
                                                prevItem.triggeredWorkflowId === item.triggeredWorkflowId
                                                    ? {
                                                          ...prevItem,
                                                          currentDay: isNaN(value) || value < 0 ? 0 : +value,
                                                          currentDayChanged: true
                                                      }
                                                    : prevItem
                                            )
                                        )
                                    }
                                    onBlur={(e) => {
                                        const value = e.target.value
                                        if (item.currentDayChanged && !isNaN(value) && value >= 0) {
                                            handleChangeContactStrategyDay(
                                                item.contactStrategyId,
                                                item.profileId,
                                                +value
                                            )
                                            item.currentDayChanged = false
                                        }
                                    }}
                                />
                            </Col>
                            <Col>Days</Col>
                        </Row>
                    ))}
            </div>
        )
    }

    const renderStudentDetail = () => {
        const activeProfile = (userData.profiles || []).find((profile) => profile.id === studentProfileId)
        const hasPermissionToUpdateAdvisor = checkHasPermissionToUpdateAdvisor()

        return (
            <div className={styles.studentWrap}>
                <div className={styles.headingWrap}>
                    <div className={styles.headingTitle}>Student Details</div>
                    <button
                        className={cx(styles.collapseButton, {[styles.collapsed]: studentDetailVisible.isVisible})}
                        onClick={() => studentDetailVisible.toggle()}>
                        <Icon icon="ARROW_DOWN" color="#666666" className={styles.headingIcon} />
                    </button>
                </div>
                {studentDetailVisible.isVisible && (
                    <>
                        <EditableStudentField {...{field: "customProfileId", activeProfile, student, getUserData}} />
                        <EditableStudentField {...{field: "customUserId", activeProfile, student, getUserData}} />

                        <div className={styles.contentWrap}>
                            <div className={styles.contentTitle}>{getDepartmentAdvisorTitle()}</div>
                            <div>
                                {hasPermissionToUpdateAdvisor ? (
                                    <div className={cx(styles.contentLink)} onClick={advisorsPopup.open}>
                                        {studentAdvisors && studentAdvisors.length ? (
                                            renderListAdvisors()
                                        ) : (
                                            <span>
                                                {departmentId
                                                    ? t("studentInfo.selectAdvisor")
                                                    : t("studentInfo.showAdvisor")}
                                            </span>
                                        )}
                                    </div>
                                ) : (
                                    renderListAdvisors()
                                )}
                            </div>
                        </div>

                        <EditableStudentField {...{field: "enrollmentType", activeProfile, student, getUserData}} />
                        <EditableStudentField {...{field: "campuses", activeProfile, student, getUserData}} />
                        <EditableStudentField {...{field: "currentMajor", activeProfile, student, getUserData}} />
                        <EditableStudentField
                            {...{
                                field: "currentMajorVersionId",
                                activeProfile,
                                student,
                                getUserData
                            }}
                        />
                        <EditableStudentField {...{field: "academicTrackId", activeProfile, student, getUserData}} />
                        <EditableStudentField {...{field: "currentTerm", activeProfile, student, getUserData}} />
                        <EditableStudentField {...{field: "startingTermId", activeProfile, student, getUserData}} />
                        <EditableStudentField {...{field: "startDate", activeProfile, student, getUserData}} />
                        <EditableStudentField {...{field: "contractEndDate", activeProfile, student, getUserData}} />
                        <EditableStudentField {...{field: "firstDateOfClass", activeProfile, student, getUserData}} />
                        <EditableStudentField {...{field: "midpointDate", activeProfile, student, getUserData}} />
                        <EditableStudentField {...{field: "projectedEndDate", activeProfile, student, getUserData}} />
                        <EditableStudentField {...{field: "actualEndDate", activeProfile, student, getUserData}} />
                        <EditableStudentField {...{field: "orientationDate", activeProfile, student, getUserData}} />
                        {[BaseDepartmentId.FinancialAid, BaseDepartmentId.StudentAccount].includes(departmentId) && (
                            <EditableStudentField
                                {...{field: "currentPaymentPeriodId", activeProfile, student, getUserData}}
                            />
                        )}
                    </>
                )}
            </div>
        )
    }

    const renderContactDetail = () => {
        const phones = userData.phones.map(({phone}) => phone)
        const emails = userData.emails.map(({email}) => email)

        return (
            <div className={styles.contactWrap}>
                <div className={styles.headingWrap}>
                    <div className={styles.headingTitle}>Contact Details</div>
                    <button
                        className={cx(styles.collapseButton, {[styles.collapsed]: contactDetailVisible.isVisible})}
                        onClick={() => contactDetailVisible.toggle()}>
                        <Icon icon="ARROW_DOWN" color="#666666" className={styles.headingIcon} />
                    </button>
                </div>
                {contactDetailVisible.isVisible && (
                    <>
                        {phones.map((item, index) => (
                            <div className={styles.contentWrap} key={index}>
                                <div className={styles.contentTitle}>{`Phone no.${index + 1}`}</div>
                                <div
                                    className={cx(styles.contentValue, "cursor-copy")}
                                    onClick={handleCopyTextClick(item)}>
                                    <span className={styles.displayValue}>{item}</span>
                                </div>
                            </div>
                        ))}
                        {emails.map((item, index) => (
                            <div className={styles.contentWrap} key={index}>
                                <div className={styles.contentTitle}>{`Email no.${index + 1}`}</div>
                                <Tooltip title={item}>
                                    <div
                                        className={cx(styles.contentValue, "cursor-copy")}
                                        onClick={handleCopyTextClick(item)}>
                                        <span className={styles.displayValue}>{item}</span>
                                    </div>
                                </Tooltip>
                            </div>
                        ))}
                        <div className={styles.contentWrap}>
                            <div className={styles.contentTitle}>Address</div>
                            <Tooltip title={student?.address}>
                                <div
                                    className={cx(styles.contentValue, "cursor-copy")}
                                    onClick={handleCopyTextClick(student?.address)}>
                                    <span className={styles.displayValue}>{student?.address || "-"}</span>
                                </div>
                            </Tooltip>
                        </div>
                    </>
                )}
            </div>
        )
    }

    const renderExpandSection = () => {
        switch (activeSection) {
            case "message":
                return (
                    <MessagePanel
                        student={student}
                        departmentId={departmentId}
                        onClose={handleCloseActiveSection}
                        key={reloadKey}
                    />
                )
            case "activity":
                return (
                    <StudentInfoActivityPanel
                        departmentId={departmentId}
                        studentId={studentProfileId}
                        onClose={handleCloseActiveSection}
                        onActivityCreated={handleActivityCreated}
                        key={reloadKey}
                    />
                )
            case "appointment":
                return <StudentInfoCalendarPanel onClose={handleCloseActiveSection} key={reloadKey} />
            case "departmentInfo": {
                return (
                    <StudentDepartmentInfo
                        key={reloadKey}
                        onClose={handleCloseActiveSection}
                        onSuccess={onSaveSuccess}
                    />
                )
            }
            default:
                return <div>{activeSection}</div>
        }
    }

    const renderModuleDetail = () => {
        if (!isModuleEnable(departmentId)) return null
        if (model.isStudent()) return null

        return (
            <div className={styles.moduleWrap} onClick={() => handleAction("departmentInfo")}>
                <div className={styles.moduleTitle}>{`${t(`moduleName.${departmentId}`)} management`}</div>
                <div className={styles.profileLinkIconWrap}>
                    <DetailNavigationIcon className={styles.profileLinkIcon} />
                </div>
            </div>
        )
    }

    const handleNavigateToLeadBucket = () => {
        const studentId = student.profileId
        if (studentId) {
            let pushOptions = {
                pathname: `/admissions/lead-bucket`,
                search: `?studentProfileId=${studentId}`,
                state: null
            }
            if (props.fromPage) {
                pushOptions.state = {
                    backUrlFromPage:
                        props.backUrl || `${studentDetailUrls[fromPage].backUrl}&studentId=${studentProfileId}`
                }
            }
            pushOptions.state = {...pushOptions.state}
            history.push(pushOptions)
        }
    }

    const renderDuplicateCount = () => {
        const duplicateEntryCount = student?.duplicateLeadEntriesCount

        if (!isStaff || !duplicateEntryCount) {
            return null
        }

        return (
            <div className={styles.duplicateCountContainer}>
                <div>
                    <p onClick={handleNavigateToLeadBucket} className={styles.duplicateCount}>
                        <Icon icon="WARNING" color="var(--black-base)" className={styles.warningIcon} />
                        {`${duplicateEntryCount} duplicated inquiries`}
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.drawerWrap}>
            {isLoadingUser && (
                <div className={styles.topLoading}>
                    <CircularSpin />
                </div>
            )}
            {/* Student Info */}
            <div className={styles.panelStudentWrap}>
                <PanelNavigation onClose={onCollapsePanel} />
                <div className={styles.panelStudentContainer}>
                    {renderAutoDialerInfo?.({student})}
                    {renderStudentStatus()}
                    {renderProfile()}
                    {renderDuplicateCount()}
                    {renderModuleDetail()}
                    {renderDepartmentDetail()}
                    {renderActiveContactStrategy()}
                    {renderStudentDetail()}
                    {renderContactDetail()}
                </div>
            </div>
            {/* Expanded action */}
            <div className={styles.panelWrap}>{renderExpandSection()}</div>
        </div>
    )
}
