import {get} from "lodash"
import BaseService from "./Base"
import {GeneralPayment, LedgerAccount, TransactionItem} from "types/student-account/ledger-accounts"
import {PaymentPlan, PaymentPlanItem, PaymentReminder} from "types/student-account/payment-plan"
import {
    StudentAccountStudent,
    StudentAccountStudentARAgingSummary,
    StudentAccountStudentBalanceDetail,
    StudentAccountStudentBalanceSummary
} from "types/student-account/reports"
import {StudentCharge} from "types/student-account/student-ledger"
import {PaymentPeriod} from "types/student-account/payment-period"
import {BankAccount} from "types/student-account/bank-account"

export default class StudentAccountService extends BaseService {
    getLedgerAccounts = async (params = {}): Promise<{total: number; data: LedgerAccount[]}> => {
        const response = await this.post("/v1/student-account/settings/ledger-accounts/list", params)
        return get(response, "data", {})
    }

    getLedgerAccount = async (id: number): Promise<LedgerAccount> => {
        const response = await this.get(`/v1/student-account/settings/ledger-accounts/${id}`)
        return get(response, "data.data", {})
    }

    createLedgerAccount = async (data: Partial<LedgerAccount>): Promise<LedgerAccount> => {
        const response = await this.post("/v1/student-account/settings/ledger-accounts/create", data)
        return get(response, "data.data", {})
    }

    updateLedgerAccount = async (data: Partial<LedgerAccount>): Promise<LedgerAccount> => {
        const response = await this.post("/v1/student-account/settings/ledger-accounts/edit", data)
        return get(response, "data.data", {})
    }

    deleteLedgerAccounts = async (ids: number[]): Promise<void> => {
        await this.post("/v1/student-account/settings/ledger-accounts/delete", {ids})
    }

    getBankAccounts = async (params = {}): Promise<{total: number; data: BankAccount[]}> => {
        const response = await this.post("/v1/student-account/settings/bank-accounts/list", params)
        return get(response, "data", {})
    }

    getBankAccount = async (id: number): Promise<BankAccount> => {
        const response = await this.get(`/v1/student-account/settings/bank-accounts/${id}`)
        return get(response, "data.data", {})
    }

    createBankAccount = async (data: Partial<BankAccount>): Promise<BankAccount> => {
        const response = await this.post("/v1/student-account/settings/bank-accounts/create", data)
        return get(response, "data.data", {})
    }

    updateBankAccount = async (accountId: number, data: Partial<BankAccount>): Promise<BankAccount> => {
        const response = await this.post("/v1/student-account/settings/bank-accounts/edit", {...data, accountId})
        return get(response, "data.data", {})
    }

    deleteBankAccounts = async (ids: number[]): Promise<void> => {
        await this.post("/v1/student-account/settings/bank-accounts/delete", {ids})
    }

    getGenralPayments = async (params = {}): Promise<{total: number; data: GeneralPayment[]}> => {
        const response = await this.post("/v1/student-account/settings/general-payments/list", params)
        return get(response, "data", {})
    }

    createGeneralPayment = async (data: Partial<GeneralPayment>): Promise<GeneralPayment> => {
        const response = await this.post("/v1/student-account/settings/general-payments/create", data)
        return get(response, "data.data", {})
    }

    updateGeneralPayment = async (generalPaymentId: number, data: Partial<GeneralPayment>): Promise<GeneralPayment> => {
        const response = await this.post("/v1/student-account/settings/general-payments/edit", {
            ...data,
            generalPaymentId
        })
        return get(response, "data.data", {})
    }

    deleteGeneralPayments = async (ids: number[]): Promise<void> => {
        await this.post("/v1/student-account/settings/general-payments/delete", {ids})
    }

    getPublicPaymentPeriods = async (params = {}): Promise<PaymentPeriod[]> => {
        const response = await this.post("/v1/student-account/settings/payment-periods/public-list", params)
        return get(response, "data.data", [])
    }

    getPaymentPeriods = async (params = {}): Promise<{total: number; data: PaymentPeriod[]}> => {
        const response = await this.post("/v1/student-account/settings/payment-periods/list", params)
        return get(response, "data", {})
    }

    createPaymentPeriod = async (data: Partial<PaymentPeriod>): Promise<PaymentPeriod> => {
        const response = await this.post("/v1/student-account/settings/payment-periods/create", data)
        return get(response, "data.data", {})
    }

    updatePaymentPeriod = async (periodId: number, data: Partial<PaymentPeriod>): Promise<PaymentPeriod> => {
        const response = await this.post("/v1/student-account/settings/payment-periods/edit", {
            ...data,
            periodId
        })
        return get(response, "data.data", {})
    }

    deletePaymentPeriods = async (ids: number[]): Promise<void> => {
        await this.post("/v1/student-account/settings/payment-periods/delete", {ids})
    }

    getTransactionItems = async (params = {}): Promise<{total: number; data: TransactionItem[]}> => {
        const response = await this.post("/v1/student-account/settings/transaction-items/list", params)
        return get(response, "data", {})
    }

    createTransactionItem = async (data: Partial<TransactionItem>): Promise<TransactionItem> => {
        const response = await this.post("/v1/student-account/settings/transaction-items/create", data)
        return get(response, "data.data", {})
    }

    updateTransactionItem = async (itemId: number, data: Partial<TransactionItem>): Promise<TransactionItem> => {
        const response = await this.post("/v1/student-account/settings/transaction-items/edit", {
            ...data,
            itemId
        })
        return get(response, "data.data", {})
    }

    deleteTransactionItems = async (ids: number[]): Promise<void> => {
        await this.post("/v1/student-account/settings/transaction-items/delete", {ids})
    }

    getPaymentReminders = async (params = {}): Promise<{total: number; data: PaymentReminder[]}> => {
        const response = await this.post("/v1/student-account/settings/payment-reminders/list", params)
        return get(response, "data", {})
    }

    createPaymentReminder = async (data: Partial<PaymentReminder>): Promise<PaymentReminder> => {
        const response = await this.post("/v1/student-account/settings/payment-reminders/create", data)
        return get(response, "data.data", {})
    }

    updatePaymentReminder = async (
        paymentReminderId: number,
        data: Partial<PaymentReminder>
    ): Promise<PaymentReminder> => {
        const response = await this.post("/v1/student-account/settings/payment-reminders/edit", {
            ...data,
            paymentReminderId
        })
        return get(response, "data.data", {})
    }

    deletePaymentReminders = async (ids: number[]): Promise<void> => {
        await this.post("/v1/student-account/settings/payment-reminders/delete", {ids})
    }

    getPaymentPlans = async (params = {}): Promise<{total: number; data: PaymentPlan[]}> => {
        const response = await this.post("/v1/payment-plans/list", params)
        return get(response, "data", {})
    }

    getIncompletePaymentPlans = async (params: {
        profileId: number
    }): Promise<{
        items: PaymentPlanItem[]
        paymentPlans: PaymentPlan[]
    }> => {
        const response = await this.post("/v1/payment-plans/incomplete", params)
        return get(response, "data.data", {})
    }

    getPaymentPlan = async (id: number): Promise<PaymentPlan> => {
        const response = await this.get(`/v1/payment-plans/${id}`)
        return get(response, "data.data", {})
    }

    getPaymentPlanNextCode = async (): Promise<string> => {
        const response = await this.get(`/v1/payment-plans/next-code`)
        return get(response, "data.data", "")
    }

    createPaymentPlan = async (data: Partial<PaymentPlan>): Promise<PaymentPlan> => {
        const response = await this.post("/v1/payment-plans/create", data)
        return get(response, "data.data", {})
    }

    updatePaymentPlan = async (data: Partial<PaymentPlan>): Promise<PaymentPlan> => {
        const response = await this.post("/v1/payment-plans/edit", data)
        return get(response, "data.data", {})
    }

    deletePaymentPlans = async (ids: number[]): Promise<void> => {
        await this.post("/v1/payment-plans/delete", {ids})
    }

    getBalanceSummary = async (
        params = {}
    ): Promise<{
        total: number
        data: StudentAccountStudentBalanceSummary[]
    }> => {
        const response = await this.post("/v1/student-account/reports/balance-summary", params)
        return get(response, "data", {})
    }

    getBalanceDetail = async (
        params = {}
    ): Promise<{
        total: number
        data: StudentAccountStudentBalanceDetail[]
        accounts: LedgerAccount[]
    }> => {
        const response = await this.post("/v1/student-account/reports/balance-detail", params)
        return get(response, "data", {})
    }

    getARAgingSummary = async (
        params = {}
    ): Promise<{
        total: number
        data: StudentAccountStudentARAgingSummary[]
    }> => {
        const response = await this.post("/v1/student-account/reports/ar-aging-summary", params)
        return get(response, "data", {})
    }

    getARAgingDetail = async (
        params = {}
    ): Promise<{
        total: number
        data: Array<StudentCharge & {student?: StudentAccountStudent}>
    }> => {
        const response = await this.post("/v1/student-account/reports/ar-aging-detail", params)
        return get(response, "data", {})
    }

    get1098TReport = async (payload: {
        profileIds: number[]
        tranxDates: string[]
        year?: number
    }): Promise<{
        urls: string[]
        efile: string
    }> => {
        const response = await this.post("/v1/student-account/reports/1098T", payload)
        return get(response, "data.data", [])
    }

    save1098TReport = async (payload: {
        profileIds: number[]
        tranxDates: string[]
        type: "A" | "B"
        year?: number
    }): Promise<string[]> => {
        const response = await this.post("/v1/student-account/reports/1098T/save", payload)
        return get(response, "data.data", {})
    }

    // 1 = 'good' | 2 = 'due' | 3 = 'at_risk' | 4 = 'delinquent'
    getStudentAccountStatus = async (profileId: number): Promise<number> => {
        const response = await this.post("/v1/student-account/reports/sa-status", {profileId})
        return get(response, "data.data", null)
    }
}
