import React from "react"
import {useTranslation} from "react-i18next"
import debounce from "debounce-promise"
import {Icon} from "components/Icon"
import {BaseFileInput} from "components/inputs"
import {KlassDropAsyncPaginate} from "components/Select"
import {taskService} from "services"
import {handleError} from "helpers"
import styles from "./TaskDocument.module.css"
import {TaskSubtype, TaskType} from "types/tasks"
import {useModel} from "hooks"

export function TaskDocument(props) {
    const {t} = useTranslation(["tasks"])
    const model = useModel()
    const {taskData, type, onChangeData, activeDigitalTaskLibraryCopy, onChangeActiveDigitalTaskLibraryCopy} = props

    const loadOptions = async (search, loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const params = {
                range: {page, pageSize},
                filter: {
                    subtype: TaskSubtype.LIBRARY,
                    type: TaskType.DIGITAL_DOCUMENT,
                    search
                }
            }
            const {data: libraryTasks, total} =
                type === TaskType.CUSTOM_DD
                    ? await taskService.getCustomDigitalTasks({
                          filter: {subtype: TaskSubtype.LIBRARY, search},
                          range: {page, pageSize}
                      })
                    : await taskService.getAll(params)

            return {
                options: libraryTasks,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            handleError(error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const debounceTasks = debounce(loadOptions, 800)
    const allowedDocumentFormats = model.getAllowedDocumentFormats() || "application/pdf"

    return (
        <div className={styles.wrap}>
            <p className={styles.title}>{t("taskDetail.document.document")}</p>
            {!taskData.file && <p className={styles.description}>{t("taskDetail.document.uploadDocument")}</p>}
            <div className={styles.uploadIconWrap}>
                <BaseFileInput
                    onChange={(newValue) => onChangeData("file", newValue)}
                    className={styles.fileNameWrap}
                    accept={allowedDocumentFormats}>
                    <span className={styles.uploadIcon}>
                        <Icon icon="UPLOAD" color="#FF349B" />
                    </span>
                </BaseFileInput>
            </div>

            {[TaskType.DIGITAL_DOCUMENT, TaskType.CUSTOM_DD].includes(type) && (
                <div className={styles.copyDigitalFormat}>
                    <p className={styles.copyDigitalFormatTitle}>Copy digital document format</p>
                    <KlassDropAsyncPaginate
                        value={activeDigitalTaskLibraryCopy}
                        onChange={onChangeActiveDigitalTaskLibraryCopy}
                        loadOptions={debounceTasks}
                        isClearable
                        placeholder="Select"
                    />
                </div>
            )}
        </div>
    )
}
