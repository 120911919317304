import BaseService from "./Base"
import {ApiListResponse, ApiResponse} from "types/api"
import {AxiosResponse} from "axios"
import {DocumentTemplate} from "types/settings/document-templates"

class DocumentTemplatesService extends BaseService {
    getAll = async (body = {}) => {
        const {data: response}: AxiosResponse<ApiListResponse<Array<DocumentTemplate>>> = await this.post(
            "/v1/settings/document-templates/list",
            body
        )
        if (!response.success) {
            throw response
        }

        return {
            data: response.data,
            total: response.total
        }
    }

    create = async (payload: any) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post(
            "/v1/settings/document-templates/create",
            payload
        )
        if (!response.success) {
            throw response
        }
    }

    update = async (payload: any) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post(
            "/v1/settings/document-templates/edit",
            payload
        )

        if (!response.success) {
            throw response
        }
    }

    deleteBulk = async (body = {}) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post(
            "/v1/settings/document-templates/delete",
            body
        )

        if (!response.success) {
            throw response
        }
    }

    getAutocomplete = async (body = {}) => {
        const {data: response}: AxiosResponse<ApiListResponse<Array<DocumentTemplate>>> = await this.post(
            "/v1/settings/document-templates/autocomplete",
            body
        )
        if (!response.success) {
            throw response
        }

        return {
            data: response.data,
            total: response.total
        }
    }
}

export default DocumentTemplatesService
