import {get} from "lodash"
import BaseService from "./Base"

class SettingStatusService extends BaseService {
    async getAllStatuses(params = {}) {
        const response = await this.post("/v1/settings/statuses/list", params)
        return get(response, "data", [])
    }
}

export default SettingStatusService
