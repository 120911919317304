import {DispatchDataResponse} from "react-acceptjs"
import {DeepPartial, Timestamps} from "types/common"
import {StudentCharge} from "types/student-account/student-ledger"
import {GeneralPayment, LedgerAccount} from "types/student-account/ledger-accounts"
import {Auth} from "types/auth"
import {round, sumBy} from "lodash"
import {PaymentPlan} from "types/student-account/payment-plan"
import {PaymentPeriod} from "types/student-account/payment-period"
import {BankAccount} from "types/student-account/bank-account"

export enum PaymentType {
    Item = "item",
    General = "general"
}

export enum PaymentProvider {
    Manual = "manual",
    Stripe = "stripe",
    AuthorizeNet = "authorize.net",
    Square = "square"
}

export const PAYMENT_METHOD_OPTIONS = [
    {id: "cash", name: "Cash"},
    {id: "card", name: "Credit Card"},
    {id: "paypal", name: "Paypal"},
    {id: "ach_debit", name: "ACH Debit"},
    {id: "bank_debit", name: "Bank Debit"},
    {id: "apple_pay", name: "Apple Pay"},
    {id: "google_pay", name: "Google Pay"},
    {id: "wise_transfer", name: "Wise Transfer"},
    {id: "pell", name: "PELL"},
    {id: "sub", name: "DL (Sub)"},
    {id: "unsub", name: "DL (Unsub)"},
    {id: "fseog", name: "FSEOG"},
    {id: "transfer", name: "Transfer"},
    {id: "check", name: "Check"},
    {id: "tuition_adjustment", name: "Tuition Adjustment"},
    {id: "wire_transfer", name: "Wire Transfer"}
]

export type StudentPaymentSupportedCurrency = "usd" | "cad"

export const paymentCurrencyOptions: Array<{id: StudentPaymentSupportedCurrency; name: string}> = [
    {id: "usd", name: "USD"},
    {id: "cad", name: "CAD"}
]

export type StudentPaymentStatus = "pending" | "success" | "canceled"

export type StudentPayment = {
    paymentId: number
    generalPaymentId?: number | null
    generalPayment?: GeneralPayment // populate
    studentProfileId: number
    studentProfile?: Auth.LinkedProfileDetails // populate
    provider: PaymentProvider
    providerIntentId: string
    amountInCents: number
    currency: StudentPaymentSupportedCurrency
    notes: string
    status: StudentPaymentStatus
    statusUpdatedAt?: string | null
    createdByProfileId: number
    createdByProfile?: Auth.LinkedProfileDetails // populate
    updatedByProfileId: number | null
    updatedByProfile?: Auth.LinkedProfileDetails // populate
    receiptDocumentId?: number | null
    metadata?: Record<string, any> | null

    tranxDate: string
    postedDate?: string | null
    receiptPrintedDate?: string | null
    bankAccountId?: number | null
    bankAccount?: BankAccount // populate
    accountId?: number | null
    account?: LedgerAccount // populate
    extraAmount?: number | null
    extraDescription?: string | null
    academicYear?: number | null
    awardYear?: number | null
    methodOfPayment?: string | null
    receiptId?: string | null
    disbursementId?: number | null
    periodId?: number | null
    period?: PaymentPeriod
    paymentCharges?: StudentPaymentCharge[] // populate
    charges?: StudentCharge[] // populate

    paymentPlanItems?: StudentPaymentPlanItem[] // populate
    paymentPlans?: PaymentPlan[] // populate

    // internal
    amount?: number
} & Timestamps

export type StudentPaymentCharge = {
    paymentId: number
    chargeId: number
    amount: number
    accountId?: number | null
}

export type StudentPaymentPlanItem = {
    paymentId: number
    paymentPlanId: number
    paymentPlanItemId: number
    amount: number
    accountId?: number | null
}

export type CreateStripePaymentIntentApiPayload = {
    studentProfileId?: number
    amountInCents: number
    currency: StudentPaymentSupportedCurrency
    generalPaymentId?: number
    notes?: string
    payerFullName?: string
    academicYear?: number
    paymentPlanItems?: Partial<StudentPaymentPlanItem>[]
}
export type CreateSquarePaymentApiPayload = {
    studentProfileId?: number
    amountInCents: number
    currency: StudentPaymentSupportedCurrency
    generalPaymentId?: number
    notes?: string
    payerFullName?: string
    academicYear?: number
    paymentPlanItems?: Partial<StudentPaymentPlanItem>[]
    sourceId: string
}

export type CreateAuthorizeNetStudentPaymentApiPayload = {
    studentProfileId?: number
    amountInCents: number
    currency: StudentPaymentSupportedCurrency
    notes?: string
    payerFullName?: string
    academicYear?: number
    generalPaymentId?: number
    paymentPlanItems?: Partial<StudentPaymentPlanItem>[]
}

export type CompleteAuthorizeNetStudentPaymentApiPayload = {
    paymentId: number
    payerFirstName: string
    payerLastName: string
    country: string
    state: string
    zip: string
    authorizeNetPaymentNonce: DispatchDataResponse
}

export type CurrencyExchangeRates = {[currecny: string]: number}

export enum StudentPaymentLinkedEntity {
    Profile = "profile",
    GeneralPayment = "general-payment",
    Account = "account",
    Period = "period",
    Charges = "charges",
    PaymentPlan = "payment-plans",
    PaymentPlanCharges = "payment-plans-charges"
}

export const getPaymentAmountDue = (
    payment: DeepPartial<StudentPayment> | undefined,
    currencyExchangeRates: CurrencyExchangeRates,
    includesExtraAmount = true,
    includesPaymentCharges = true,
    includesPaymentPlanItems = true
) => {
    if (!payment) return 0
    const paymentAmountInUSD = round(
        ((payment.amountInCents || 0) / 100 - (includesExtraAmount ? payment.extraAmount || 0 : 0)) /
            (currencyExchangeRates[payment.currency?.toUpperCase()] || 1),
        2
    )
    return Math.max(
        0,
        paymentAmountInUSD -
            (includesPaymentCharges ? sumBy(payment.paymentCharges, "amount") : 0) -
            (includesPaymentPlanItems ? sumBy(payment.paymentPlanItems, "amount") : 0)
    )
}
