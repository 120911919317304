import {useEffect, useState} from "react"
import styles from "./DocumentTemplate.module.css"
import {Checkbox, Col, Row} from "antd"
import {FormLabel} from "components/Form"
import {BaseButton, BaseInput} from "components"
import DepartmentSubunitSelect from "components/DepartmentSubunitSelect"
import {useModel} from "hooks"
import {toastError} from "helpers"

export type DocumentTemplateItem = {
    documentName?: string
    description?: string
    departmentSubunit?: any
    isVisibleForUser?: boolean
}

type DocumentTemplateProps = {
    accessibleSubunitIds: number[]
    onApply: (template: DocumentTemplateItem) => void
}

const INITIAL_DOCUMENT_INFO: DocumentTemplateItem = {
    documentName: "",
    description: "",
    departmentSubunit: null
}

export function DocumentTemplate(props: DocumentTemplateProps) {
    const {onApply, accessibleSubunitIds} = props
    const [template, setTemplate] = useState<DocumentTemplateItem>(INITIAL_DOCUMENT_INFO)
    const [errors, setErrors] = useState<string[]>([])
    const model = useModel()
    const isStaffOrAdmin = model.isStaffOrAdmin()

    useEffect(() => {
        setTemplate({...INITIAL_DOCUMENT_INFO, isVisibleForUser: !isStaffOrAdmin})
    }, [isStaffOrAdmin])

    const handleFieldChange = (key: keyof DocumentTemplateItem, value: any) => {
        const _template = {...template, [key]: value}
        setTemplate(_template)
    }

    const onApplyTemplate = () => {
        const _errors: string[] = []
        if ((template.documentName || "").trim() === "") {
            _errors.push("documentName")
        }
        if (!template.departmentSubunit) {
            _errors.push("departmentSubunit")
        }
        setErrors(_errors)
        if (_errors.length === 0) {
            onApply(template)
        } else {
            toastError("Please fill in all required fields")
        }
    }

    return (
        <div className={styles.container}>
            <span className={styles.title}>Apply the template to all documents below</span>
            <Row gutter={[12, 12]}>
                <Col span={isStaffOrAdmin ? 6 : 7}>
                    <FormLabel label="Document Name" isRequired />
                    <BaseInput
                        placeholder="name"
                        value={template.documentName}
                        onChange={(v) => handleFieldChange("documentName", v)}
                        error={errors.includes("documentName")}
                    />
                </Col>
                <Col span={isStaffOrAdmin ? 8 : 10}>
                    <FormLabel label="Description" />
                    <BaseInput
                        placeholder="description"
                        value={template.description}
                        onChange={(v) => handleFieldChange("description", v)}
                        error={errors.includes("description")}
                    />
                </Col>
                <Col span={isStaffOrAdmin ? 6 : 7}>
                    <FormLabel label="Department" isRequired />
                    <DepartmentSubunitSelect
                        subunitIds={accessibleSubunitIds}
                        placeholder="department"
                        value={template.departmentSubunit}
                        onChange={(v) => handleFieldChange("departmentSubunit", v)}
                        error={errors.includes("departmentSubunit")}
                    />
                </Col>
                {isStaffOrAdmin && (
                    <Col span={4}>
                        <FormLabel label="Visible to user" className={styles.visible} />
                        <div className={styles.checkbox}>
                            <Checkbox
                                checked={template.isVisibleForUser}
                                onChange={(v) => handleFieldChange("isVisibleForUser", v.target.checked)}
                            />
                        </div>
                    </Col>
                )}
            </Row>
            <div className={styles.action}>
                <BaseButton
                    title="Apply for all"
                    disabled={!template.documentName || !template.departmentSubunit}
                    onClick={onApplyTemplate}
                />
            </div>
        </div>
    )
}
