import {cloneDeep, get, head, isEmpty, keyBy} from "lodash"
import {useTranslation} from "react-i18next"
import moment from "moment"
import {ParticipantType, SelectStaffFrom, TasksTab, TaskSubtype, TaskType, UserType} from "types/tasks"
import {studentStaffContactRolesServiceV3, userServiceV3} from "services"
import {Auth} from "types/auth"
import {useModel, useSearchParam} from "hooks"

export const useTasks = () => {
    const {t} = useTranslation("tasks")
    const {value: taskType} = useSearchParam("type")
    const model = useModel()

    const MAP_TASK_TYPE_2_LABEL = {
        [TaskType.DIGITAL_DOCUMENT]: t("taskDetail.type.digitalDocument"),
        [TaskType.SCAN_UPLOAD]: t("taskDetail.type.scanUpload"),
        [TaskType.FROM_LIBRARY]: t("taskDetail.type.library"),
        [TaskType.FORM]: t("taskDetail.type.form"),
        [TaskType.CUSTOM]: t("taskDetail.type.custom"),
        [TaskType.CUSTOM_DD]: t("taskDetail.type.customDigitalDocument")
    }
    const TASK_DETAIL_TAB = {RESPONSES: "1", TASK_DETAIL: "2"}
    const REMINDERS = [
        {id: 1, name: "1 day before due date"},
        {id: -30, name: "Every single day for 30 days from the due date"}
    ]
    const DEFAULT_TASK = {
        name: "",
        filename: "",
        departmentId: "",
        dueDate: "",
        reminders: [],
        additionalApprovalAccess: "",
        description: "",
        signers: []
    }

    const checkIsOptionalStaffOrOther = (signer) => {
        return (
            ((signer.userType === UserType.STAFF && isEmpty(signer.staffs)) ||
                (signer.userType === UserType.OTHERS && isEmpty(signer.others))) &&
            signer.participantType === ParticipantType.NonPrimarySigner &&
            !!signer?.jsonData?.isOptional
        )
    }

    const getBackUrlTab = ({type, subtype}) => {
        if (type === TaskType.CUSTOM_DD) {
            return TasksTab.CUSTOM_DIGITAL
        }
        return subtype === TaskSubtype.LIBRARY ? TasksTab.LIBRARY : TasksTab.ASSIGNED
    }

    const initialData = async (dataProps, departments) => {
        const result = cloneDeep(DEFAULT_TASK)
        if (!dataProps) return result
        const departmentsKeyById = keyBy(departments, "subunitId")
        const remindersKeyById = keyBy(REMINDERS, "id")
        const reminderValue = dataProps.reminders.map((reminderId) => remindersKeyById[reminderId])
        let signers = []
        switch (taskType) {
            case TaskType.SCAN_UPLOAD:
                result.filename = dataProps.scan.filename
                signers = await getInitialSigners({
                    signers: dataProps.scan.signers,
                    type: dataProps.type,
                    subtype: dataProps.subtype
                })
                break
            case TaskType.DIGITAL_DOCUMENT:
            case TaskType.CUSTOM_DD:
                signers = await getInitialSigners({
                    signers: dataProps.digitalDocument.signers,
                    type: dataProps.type,
                    subtype: dataProps.subtype
                })
                break
            case TaskType.FROM_LIBRARY:
                signers = await getInitialSigners({
                    signers: dataProps.signers,
                    type: dataProps.type,
                    subtype: dataProps.subtype
                })
                break
            case TaskType.FORM:
                signers = (dataProps.form.signers || []).map((signer) => {
                    signer.userType = signer.type
                    return signer
                })
                break
            case TaskType.CUSTOM:
                signers = (dataProps.custom.signers || []).map((signer) => {
                    signer.userType = signer.type
                    return signer
                })
                break
            default:
                break
        }
        const signersKeyByOrder = keyBy(signers, "order")
        return {
            ...result,
            ...dataProps,
            reminders: reminderValue,
            department: get(departmentsKeyById, [dataProps.departmentId], null),
            departmentDeanProfiles: dataProps.cc || [],
            dueDate: dataProps.dueDate ? moment(dataProps.dueDate) : "",
            signers,
            userDocumentInfo: signersKeyByOrder[dataProps.jsonData?.savePdf?.order],
            visibleForUser: dataProps.jsonData?.savePdf?.visibleForUser
        }
    }

    const getInitialSigners = async ({signers, type, subtype}) => {
        const result = []
        for (const signer of signers) {
            if ((subtype === TaskSubtype.LIBRARY || type === TaskType.FROM_LIBRARY) && signer.type === UserType.STAFF) {
                const {selectedOption, users, role, type} = signer.jsonData || {}
                if (selectedOption === SelectStaffFrom.DIRECTORY && !isEmpty(users)) {
                    const profileIds = users.map((item) => item.profileId)
                    signer.staffs = await getStaffs(profileIds)
                } else if (selectedOption === SelectStaffFrom.ROLE && !!role) {
                    const roleInfo = await getStaffContactByRole(role)
                    signer.studentStaffContactRole = roleInfo
                }
                signer.selectedType = selectedOption
                signer.instructorType = type
            }
            signer.userType = signer.type
            result.push(signer)
        }
        return result
    }

    const getStaffs = async (profileIds: number[]) => {
        try {
            const {data} = await userServiceV3.getAll({
                range: {limit: 1000, offset: 0},
                filter: {
                    type: [Auth.UserProfileType.Staff],
                    isAccessDisabled: false,
                    active: 1,
                    profileId: profileIds
                },
                linkedObjects: true
            })
            const staffs = data
                .map((item) => {
                    item.profileId = get(item, "profiles[0].id", "")
                    return item
                })
                .filter((item) => item.profileId)
            return staffs
        } catch (error) {
            return []
        }
    }

    const getStaffContactByRole = async (roleId: number) => {
        try {
            const {data} = await studentStaffContactRolesServiceV3.getAll({
                filter: {studentStaffContactRoleIds: [roleId]}
            })
            return head(data)
        } catch (error) {
            return null
        }
    }

    const getFileUrlLibraryTask = ({taskData, type}) => {
        const taskType = taskData.type || type
        switch (taskType) {
            case TaskType.FORM: {
                const webFormsUrl = process.env.REACT_APP_WEBFORMS_BASE_URL
                return `${webFormsUrl}/?form_id=${taskData.form?.code}&token=${model.authToken}&profileId=${model.profileId}&pdf`
            }
            case TaskType.DIGITAL_DOCUMENT:
            case TaskType.CUSTOM_DD:
                return get(taskData, "digitalDocument.file", "")
            default:
                return ""
        }
    }

    return {
        MAP_TASK_TYPE_2_LABEL,
        REMINDERS,
        DEFAULT_TASK,
        TASK_DETAIL_TAB,
        initialData,
        checkIsOptionalStaffOrOther,
        getBackUrlTab,
        getFileUrlLibraryTask
    }
}
