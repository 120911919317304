/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {Model} from "Model"
import {QRScan} from "./QRScan"
import styles from "./QRScan.module.css"
import {HTMLTitle} from "components/misc"
import {TimeInterval} from "components/TimeInterval"

type Props = {
    model: Model
}

export const QRScanPage = (props: Props) => {
    const {model} = props

    useEffect(() => {
        model.menu.infoBar = false
        model.menu.sidebar = false
    }, [])

    return (
        <>
            <HTMLTitle title="QR Checkin" />
            <div className={styles.pageContainer}>
                <TimeInterval />
                <QRScan showAsStandalone />
            </div>
        </>
    )
}
