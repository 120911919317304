import React, {memo, useRef} from "react"
import styles from "./Checkbox.module.css"
import {v1 as uuid} from "uuid"
import cx from "classnames"

type CheckboxProps = {
    name?: string
    checked?: boolean
    disabled?: boolean
    readOnly?: boolean
    onChange?: (checked: boolean) => void
    className?: string
}

const Checkbox = memo(({checked = false, name, disabled = false, readOnly, onChange, className}: CheckboxProps) => {
    const id = useRef(uuid())
    const handleChange = () => {
        onChange?.(!checked)
    }

    return (
        <span
            className={cx(
                styles.container,
                {
                    [styles.containerDisabled]: disabled,
                    [styles.containerReadOnly]: readOnly
                },
                className
            )}
            onClick={(event) => {
                event.stopPropagation()
            }}>
            <input
                type="checkbox"
                className={styles.checkbox}
                id={id.current}
                name={name}
                checked={checked}
                disabled={disabled || readOnly}
                onChange={handleChange}
            />
            <label htmlFor={id.current}></label>
        </span>
    )
})

export {Checkbox}
