import BaseService from "./Base"
import {ApiListResponse, ApiResponse} from "types/api"
import {AxiosResponse} from "axios"
import {getFullName} from "helpers/user"
import {LessonsScheduleItem} from "types/attendance"
import {get} from "lodash"

class UserServiceV3 extends BaseService {
    getAll = async (params = {}) => {
        const defaultParams = {
            range: {
                limit: 10,
                offset: 0
            },
            sort: {
                orderBy: "firstName",
                orderDir: "ASC"
            }
        }
        const {data: response}: AxiosResponse<ApiListResponse<any[]>> = await this.post("/v3/users/list", {
            ...defaultParams,
            ...params
        })
        if (!response.success) {
            throw response
        }
        response.data = response.data.map((user) => {
            user.fullName = getFullName(user)
            return user
        })
        return response
    }

    async exportAsCsv(data) {
        const response = await this.post(`/v3/users/export-as-csv`, data, {
            responseType: "blob"
        })
        return get(response, "data", null)
    }

    getOne = async (id: number, params = {}): Promise<any | null> => {
        const {data: response}: AxiosResponse<ApiResponse<any[]>> = await this.post("/v3/users/list", {
            ...params,
            filter: {
                id: [id]
            },
            linkedObjects: true
        })

        if (!response.success) {
            throw response
        }
        response.data = response.data.map((user) => {
            user.fullName = getFullName(user)
            return user
        })
        const [user = null] = response.data
        return user
    }

    update = async (params) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v3/users/edit", params)
        if (!response.success) {
            throw response
        }
        return response.data
    }

    acceptPrivacyPolicy = async () => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post("/v1/users/accept-privacy-policy", {})
        if (!response.success) {
            throw response
        }
        return response.data
    }

    getTodayLessons = async (params = {}): Promise<ApiResponse<LessonsScheduleItem[]>> => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post(`/v3/users/today-lessons`, params)

        return response
    }

    getUserById = async (params = {}) => {
        const response = await this.post(`/v3/users/list`, params)
        return get(response, "data.data[0]", {})
    }

    create = async (params = {}) => {
        const response = await this.post("/v3/users/create", [params])
        return get(response, "data.data", {})
    }

    isUserEmailAlreadyExist = async (params = {}) => {
        const response = await this.post("/v3/users/check-email", [params])
        return get(response, "data.data", {})
    }

    checkEmailsExisted = async (params = {}) => {
        const response = await this.post("/v3/users/check-email", params)
        return get(response, "data.data", {})
    }

    getAllAutocomplete = async (params = {}) => {
        const defaultParams = {
            range: {
                pageSize: 100,
                page: 1
            },
            sort: {
                orderBy: "fullName",
                orderDir: "ASC"
            }
        }
        const {data: response} = await this.post("/v3/users/autocomplete", {
            ...defaultParams,
            ...params
        })
        if (!response.success) {
            throw response
        }
        return response
    }

    updateISIRConfirmed = async (params) => {
        const {data: response}: AxiosResponse<ApiResponse> = await this.post(
            "/v3/users/update-is-isir-confirmed",
            params
        )
        if (!response.success) {
            throw response
        }
        return response.data
    }

    acceptAdmissionsApplication = async (data: {
        studentProfileId: number
        studentStatusIdToAssign: number
        notes?: string
    }) => {
        const response = await this.post("/v1/students-state-transitions/accept-admissions-application", data)
        return get(response, "data.data", {})
    }

    cancelAdmissionsApplication = async (data: {
        studentProfileId: number
        studentStatusIdToAssign: number
        notes?: string
    }) => {
        const response = await this.post("/v1/students-state-transitions/cancel-admissions-application", data)
        return get(response, "data.data", {})
    }

    checkCustomUserId = async (params = {}) => {
        const response = await this.post("/v3/users/check-custom-user-id", params)
        return get(response, "data", {})
    }

    nextCustomUserId = async (params = {}) => {
        const response = await this.post("/v3/users/next-custom-user-id", params)
        return get(response, "data", {})
    }

    getStudentsList = async (params = {}) => {
        const response = await this.post(`/v1/admin-reports/id-batch-report/list`, params)
        return get(response, "data", {})
    }

    getAzureEmailCreationList = async (params = {}) => {
        const response = await this.post(`/v1/admin-reports/azure-email-creation/list`, params)
        return get(response, "data", {})
    }

    getStudentPaymentsList = async (params = {}) => {
        const response = await this.post(`/v1/admin-reports/student-payments/list`, params)
        return get(response, "data", {})
    }

    getAllForGroups = async (params = {}) => {
        const defaultParams = {
            range: {
                pageSize: 20,
                page: 1
            }
        }
        const {data: response} = await this.post("/v3/users/list-for-groups", {
            ...defaultParams,
            ...params
        })
        if (!response.success) {
            throw response
        }
        return response
    }

    mergeUserAccounts = async (params = {}) => {
        const response = await this.post(`/v3/users/merge-accounts`, params)
        return get(response, "data", {})
    }

    unsubscribe = async (params = {}) => {
        const response = await this.post(`/v3/users/unsubscribe`, params)
        return get(response, "data", {})
    }

    getUsersViewOthersChatCalendar = async (params = {}) => {
        const defaultParams = {
            range: {
                pageSize: 20,
                page: 1
            }
        }
        const {data: response} = await this.post("/v3/users/view-others-chat-calendar", {
            ...defaultParams,
            ...params
        })
        if (!response.success) {
            throw response
        }
        return response
    }

    toggleMobileDeleted = async (data: {userId: number; mobileDeleted: boolean}): Promise<void> => {
        await this.post(`/v3/users/toggle-mobile-deleted`, data)
    }
}

export default UserServiceV3
