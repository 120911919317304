import {useCallback} from "react"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {admissionService, studentAccountService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {BankAccount} from "types/student-account/bank-account"
import {AdmissionsFunnelStatus} from "types/admissions"
import {Order} from "types/common"

const queryCacheKey = "all-funnel-statuses"
const INITIAL_DATA: AdmissionsFunnelStatus[] = []

export const useAllFunnelStatuses = () => {
    const queryClient = useQueryClient()

    const query = useQuery(
        [queryCacheKey],
        async () => {
            const {data} = await admissionService.getAllFunnelStatuses({
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                sort: {
                    orderBy: "priority",
                    orderDir: Order.Asc
                }
            })
            return data
        },
        {
            refetchOnMount: false
        }
    )
    return {
        funnelStatuses: query.data ?? INITIAL_DATA,
        invalidateFunnelStatusesCache: useCallback(() => {
            queryClient.invalidateQueries({queryKey: [queryCacheKey]})
        }, [queryClient])
    }
}
