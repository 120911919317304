import {get} from "lodash"
import BaseService from "./Base"

class SettingStageService extends BaseService {
    async getAllStages(params = {}) {
        const response = await this.post("/v1/settings/stages/list", params)
        return get(response, "data", [])
    }

    async getStudentStages(params = {}) {
        const response = await this.post("/v1/students/department-statuses/stages/list", params)
        return get(response, "data", [])
    }

    async getDepartmentStagesAll(params = {}) {
        const response = await this.post("/v1/students/department-statuses/stages/list-all", params)
        return get(response, "data", [])
    }
}

export default SettingStageService
