import moment from "moment"
import {addAlpha} from "helpers"
import {uniq} from "lodash"
import {courseScheduleService, courseService, userServiceV3} from "services"
import {CalendarEventType, RSVP} from "types/calendar"
import {getScheduleEventColor} from "helpers/calendar"

export const useGetExtraEventInfo = () => {
    const getEventColor = (event) => {
        let color = event?.json_data?.color || "#1e90ff"
        if (event.type === CalendarEventType.CourseSchedule && event?.json_data.eventType) {
            color = getScheduleEventColor(event?.json_data.eventType)
        }
        const isFutureEvent = moment(event.start_at_utc).isAfter(moment())
        const isVariableColor = color.includes("var")
        const isGoing = event.willGo !== RSVP.No
        if (!isGoing) {
            return {
                borderColor: color,
                color: "transparent"
            }
        }
        return {
            borderColor: "transparent",
            color: isFutureEvent || isVariableColor ? color : addAlpha(color, 0.5)
        }
    }

    const getModuleTitle = (eventType, courseInfo) => {
        if (!eventType || !courseInfo) {
            return ""
        }
        switch (eventType) {
            case "classroom":
                return courseInfo.theoryOrClassroomModuleTitle || "Classroom"
            case "lab":
                return courseInfo.labModuleTitle || "Lab"
            case "test":
                return courseInfo.testModuleTitle || "Test"
            case "others":
                return courseInfo.othersModuleTitle || "Others"
            default:
                return ""
        }
    }

    const getSubModuleTitle = (subModuleId, courseInfo) => {
        if (!subModuleId || !courseInfo) {
            return null
        }
        const result = courseInfo.submodules.find((item) => item.submoduleId === subModuleId)
        return result?.title
    }

    const getUsers = async (userIds) => {
        if (userIds.length) {
            userIds = uniq(userIds)
            const userPayload = {
                filter: {id: userIds},
                range: {limit: userIds.length, offset: 0},
                linkedObjects: false
            }
            try {
                const {data} = await userServiceV3.getAll(userPayload)
                return data
            } catch (error) {
                return []
            }
        }
        return []
    }

    const getCourseSchedules = async (courseScheduleIds) => {
        if (courseScheduleIds.length) {
            courseScheduleIds = uniq(courseScheduleIds)
            try {
                const {data} = await courseScheduleService.courseScheduleGet({
                    filter: {courseScheduleIds: courseScheduleIds}
                })
                return data
            } catch (error) {
                return []
            }
        }
        return []
    }

    const getCourses = async (courseIds) => {
        if (courseIds.length) {
            courseIds = uniq(courseIds)
            try {
                const {data} = await courseService.list({filter: {courseIds}, linkedEntities: ["submodule"]})
                return data
            } catch (error) {
                return []
            }
        }
        return []
    }

    return {getEventColor, getModuleTitle, getSubModuleTitle, getUsers, getCourseSchedules, getCourses}
}
