import {get} from "lodash"
import BaseService from "./Base"
import {ConnectedCalendarDetail, GenerateExternalCalendarOAuthLinkParams} from "types/calendar"

class CalendarIntegrationService extends BaseService {
    constructor() {
        super(process.env.REACT_APP_CALENDAR_INTEGRATION_API_BASE_URL)
    }

    async getUserCalendars(): Promise<ConnectedCalendarDetail[]> {
        const response = await this.get("/user-calendars/my/list")
        return get(response, "data", [])
    }

    async disconnectUserCalendars(data: {calendarId: number}): Promise<void> {
        const response = await this.post("/user-calendars/my/disconnect", data)
        return get(response, "data", [])
    }

    async generateOAuth(params: GenerateExternalCalendarOAuthLinkParams): Promise<{authUrl: string}> {
        const response = await this.post("/auth/oauth-url", params)
        return get(response, "data", null)
    }
}

export default CalendarIntegrationService
