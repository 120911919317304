/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from "react"
import NumberFormat from "react-number-format"
import {Checkbox, Col, Divider, Radio, Row, Spin} from "antd"
import {useTranslation} from "react-i18next"
import cx from "classnames"

import {BaseButton, BaseInput} from "components"
import {FormLabel} from "components/Form"
import {KlassDropdown} from "components/Select"
import {
    paymentCurrencyOptions,
    PaymentProvider,
    PaymentType,
    StudentPaymentSupportedCurrency
} from "types/student-account/student-payment"
import {AuthorizeNetPayment, StripePayment} from "./parts"
import {formatCurrency, getFullName, handleError, toastError} from "helpers"
import {studentAccountService, studentPaymentService} from "services"
import styles from "./PaymentModal.module.css"
import {GeneralPayment} from "types/student-account/ledger-accounts"
import {Order} from "types/common"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {useModel} from "hooks"
import {preventMinusCharacter} from "helpers/inputHelper"
import {StudentCharge} from "types/student-account/student-ledger"
import {sum} from "lodash"
import moment from "moment"
import {PaymentPlanItem, PaymentPlanLinkedEntity} from "types/student-account/payment-plan"
import PaymentPlanItemsTable from "sections/StudentAccount/Payment/PaymentModal/parts/PaymentPlanItemsTable"
import {SquarePayment} from "./parts/SquarePayment"

const currentYear = moment().year()
const yearOptions = [1, 0, -1, -2].map((index) => ({
    value: currentYear + index,
    label: `${currentYear + index} - ${currentYear + index + 1}`
}))

type PaymentViewProps = {
    studentId: number
}

export const PaymentView: React.FC<PaymentViewProps> = ({studentId}) => {
    const {t} = useTranslation(["studentAccount", "common"])

    const [providerConfig, setProviderConfig] = useState(null)

    const [academicYear, setAcademicYear] = useState<number | undefined>(currentYear)
    const [paymentType, setPaymentType] = useState(PaymentType.General)
    const [paymentAmount, setPaymentAmount] = useState(0)
    const [currency, setCurrency] = useState<StudentPaymentSupportedCurrency>("usd")
    const [currencyMinAmount, setCurrencyMinAmount] = useState<Record<string, number>>({})
    const [generalPayments, setGeneralPayments] = useState<GeneralPayment[]>([])
    const [selectedPayment, setSelectedPayment] = useState<GeneralPayment | undefined>()
    const [customNote, setCustomNote] = useState("")
    const [isCheckingOut, setCheckingOut] = useState(false)
    const [isPaymentRecurring, setPaymentRecurring] = useState(false)
    const [paymentPlanItems, setPaymentPlanItems] = React.useState<PaymentPlanItem[]>([])
    const [selectedPaymentItems, setSelectedPaymentItems] = React.useState<PaymentPlanItem[]>([])
    const [charges, setCharges] = React.useState<StudentCharge[]>([])
    const [selectedCharges, setSelectedCharges] = React.useState<StudentCharge[]>([])

    useEffect(() => setPaymentAmount(sum(selectedCharges.map((charge) => charge.paymentAmount))), [selectedCharges])

    useEffect(
        () => setPaymentAmount(sum(selectedPaymentItems.map((item) => item.paymentAmount))),
        [selectedPaymentItems]
    )

    useEffect(() => {
        getProviderConfig()
        ;(async function getGeneralPayments() {
            try {
                const {data: generalPayments} = await studentAccountService.getGenralPayments({
                    filter: {isActive: true},
                    range: {
                        page: 1,
                        pageSize: UNLIMITED_PAGE_SIZE
                    },
                    sort: {
                        orderBy: "position",
                        orderDir: Order.Asc
                    }
                })
                setGeneralPayments(generalPayments)
                setSelectedPayment(generalPayments[0])
            } catch (error) {
                console.error(error)
            }
        })()
    }, [])

    useEffect(() => {
        // ;(async function loadStudentCharges() {
        //     try {
        //         const {data: incompletedCharges} = await studentLedgerService.getCharges({
        //             filter: {
        //                 profileIds: [studentId],
        //                 academicYears: academicYear ? [academicYear] : undefined,
        //                 statuses: [StudentChargeStatus.Pending, StudentChargeStatus.InProgress]
        //             },
        //             range: {
        //                 pageSize: UNLIMITED_PAGE_SIZE,
        //                 page: 1
        //             },
        //             sort: {
        //                 orderBy: "tranxDate",
        //                 orderDir: Order.Asc
        //             }
        //         })
        //         setCharges(
        //             incompletedCharges.map((charge) => ({
        //                 ...charge,
        //                 paymentAmount: Math.max(0, charge.amount - charge.paidAmount)
        //             }))
        //         )
        //     } catch (error) {
        //         console.error(error)
        //         setCharges([])
        //     }
        // })()
        ;(async function loadPaymentPlanItems() {
            try {
                const {data} = await studentAccountService.getPaymentPlans({
                    filter: {
                        profileIds: [studentId]
                    },
                    range: {
                        pageSize: UNLIMITED_PAGE_SIZE,
                        page: 1
                    },
                    linkedEntities: [PaymentPlanLinkedEntity.Charges, PaymentPlanLinkedEntity.Items]
                })
                setPaymentPlanItems(data.flatMap((plan) => plan.items))
            } catch (error) {
                console.error(error)
                setPaymentPlanItems([])
            }
        })()
    }, [studentId, academicYear])

    const model = useModel()
    const userDateFormat = model.getUserDateFormat()

    const payerFullName = useMemo(() => getFullName(model.user), [])

    const paymentTypeOptions = useMemo(
        () => [
            {value: PaymentType.General, label: "General Payment"},
            {value: PaymentType.Item, label: "Select Payment Item"}
        ],
        []
    )

    const getProviderConfig = async () => {
        try {
            const [providerConfig] = await studentPaymentService.getPaymentProviderConfigs()
            if (providerConfig) {
                setProviderConfig(providerConfig)
            }
        } catch (error) {
            handleError(error)
        }
    }

    const renderPaymentContentTitle = () => {
        if (paymentType === PaymentType.Item) {
            return (
                <>
                    <div>Total Amount</div>
                    <div className={styles.textGraySmall}>{t("payment.specifyPaymentAmount")}</div>
                </>
            )
        }
        return (
            <>
                <div>Specify amount</div>
                <div className={styles.textGraySmall}>Enter amount you would like to pay</div>
            </>
        )
    }

    const renderPaymentContent = () => {
        return (
            <Col span={24}>
                <Row gutter={16} align="middle" wrap={false}>
                    <Col flex={1}>{renderPaymentContentTitle()}</Col>
                    <Col flex="100px">
                        <KlassDropdown
                            readOnly={isCheckingOut}
                            placeholder={t("payment.currency")}
                            options={paymentCurrencyOptions}
                            onChange={(option) => setCurrency(option.id)}
                            value={paymentCurrencyOptions.find((option) => option.id === currency)}
                        />
                    </Col>
                    <Col flex="100px">
                        <NumberFormat
                            readOnly={isCheckingOut}
                            thousandSeparator
                            className={styles.input}
                            value={paymentAmount}
                            min={0}
                            decimalScale={2}
                            onValueChange={({floatValue}) => setPaymentAmount(floatValue)}
                            onKeyDown={preventMinusCharacter}
                        />
                    </Col>
                </Row>
            </Col>
        )
    }

    const renderPaymentItemContent = () => {
        return (
            <div className="my-24">
                <h4 className={cx(styles.sectionTitle, "mb-24")}>{t("payment.selectPaymentItem")}</h4>

                <PaymentPlanItemsTable
                    {...{
                        paymentPlanItems,
                        selectedPaymentItems,
                        setSelectedPaymentItems
                    }}
                />
            </div>
        )
    }

    const renderGeneralPaymentContent = () => {
        return (
            <div className="my-24">
                <Row gutter={[16, 16]} className={styles.highlightBlock}>
                    <Col span={24}>
                        <h4 className={cx(styles.sectionTitle, "my-0")}>MAKE GENERAL PAYMENT</h4>
                    </Col>

                    {renderPaymentContent()}
                    <Col span={24}>
                        <div className={styles.textGraySmall}>Pay for</div>
                        <KlassDropdown
                            readOnly={isCheckingOut}
                            placeholder={t("payment.note")}
                            options={generalPayments}
                            valueKey="generalPaymentId"
                            labelKey="name"
                            value={selectedPayment}
                            onChange={setSelectedPayment}
                        />
                    </Col>

                    <Col span={24}>
                        <div className={styles.textGraySmall}>Add your notes</div>
                        <BaseInput
                            value={customNote}
                            onChange={setCustomNote}
                            placeholder="Type your notes"
                            readOnly={isCheckingOut}
                        />
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <div className={styles.popupWrapper}>
            <h3 className={styles.modalTitle}>{t("payment.makeAPayment")}</h3>
            <Divider className="mt-10 mb-0" />

            <Row className={cx(styles.modalContent, {[styles.spanFull]: isCheckingOut})}>
                <Col span={isCheckingOut ? 12 : 24} className={styles.modalLeftSide}>
                    <Radio.Group
                        disabled={isCheckingOut}
                        options={paymentTypeOptions}
                        value={paymentType}
                        onChange={({target: {value}}) => {
                            setPaymentType(value)
                            setCurrency("usd")
                            setSelectedCharges([])
                            setSelectedPaymentItems([])
                            setSelectedPayment(undefined)
                            setCustomNote("")
                        }}
                        optionType="button"
                        buttonStyle="solid"
                        className={styles.radioGroup}
                    />

                    <div className="mt-24">
                        <FormLabel label={t("payments.academicYear")} />
                        <KlassDropdown
                            isClearable
                            options={yearOptions}
                            valueKey="value"
                            labelKey="label"
                            placeholder="Select"
                            value={yearOptions.find((option) => option.value === academicYear)}
                            onChange={(option) => setAcademicYear(option?.value)}
                        />
                    </div>

                    {paymentType === PaymentType.Item ? renderPaymentItemContent() : renderGeneralPaymentContent()}

                    <BaseButton
                        className="w-full mt-24"
                        variant={isCheckingOut ? "secondary" : "primary"}
                        disabled={!paymentAmount || paymentAmount <= 0}
                        title={t(isCheckingOut ? "payment.updatePayment" : "payment.payAmount", {
                            amount: formatCurrency(paymentAmount, currency)
                        })}
                        onClick={() => {
                            if (Math.floor(paymentAmount * 100) < currencyMinAmount[currency]) {
                                toastError(
                                    t("payment.minCurrencyAmountError", {
                                        min: formatCurrency(currencyMinAmount[currency] / 100, currency)
                                    })
                                )
                                return
                            }
                            setCheckingOut((prev) => !prev)
                        }}
                    />
                </Col>

                {isCheckingOut && (
                    <Col span={12} className={styles.modalRightSide}>
                        <h4 className={styles.sectionTitle}>{t("payment.methodOfPayment")}</h4>

                        <Row gutter={16}></Row>

                        {!providerConfig && (
                            <div>
                                <Spin />
                            </div>
                        )}

                        {providerConfig?.provider === PaymentProvider.Stripe ? (
                            <StripePayment
                                providerConfig={providerConfig}
                                studentId={studentId}
                                paymentAmount={paymentAmount}
                                currency={currency}
                                isCheckingOut={isCheckingOut}
                                selectedPayment={selectedPayment}
                                customNote={customNote}
                                setCurrencyMinAmount={setCurrencyMinAmount}
                                payerFullName={payerFullName}
                                selectedPaymentItems={selectedPaymentItems}
                                academicYear={academicYear}
                            />
                        ) : providerConfig?.provider === PaymentProvider.AuthorizeNet ? (
                            <AuthorizeNetPayment
                                providerConfig={providerConfig}
                                studentId={studentId}
                                paymentAmount={paymentAmount}
                                currency={currency}
                                isCheckingOut={isCheckingOut}
                                selectedPayment={selectedPayment}
                                customNote={customNote}
                                setCurrencyMinAmount={setCurrencyMinAmount}
                                payerFullName={payerFullName}
                                selectedPaymentItems={selectedPaymentItems}
                                academicYear={academicYear}
                            />
                        ) : providerConfig?.provider === PaymentProvider.Square ? (
                            <SquarePayment
                                providerConfig={providerConfig}
                                studentId={studentId}
                                paymentAmount={paymentAmount}
                                currency={currency}
                                isCheckingOut={isCheckingOut}
                                selectedPayment={selectedPayment}
                                customNote={customNote}
                                setCurrencyMinAmount={setCurrencyMinAmount}
                                payerFullName={payerFullName}
                                selectedPaymentItems={selectedPaymentItems}
                                academicYear={academicYear}
                            />
                        ) : null}

                        <div
                            className={cx(styles.checkbox, "mt-24", {
                                [styles.active]: isPaymentRecurring
                            })}>
                            <Checkbox
                                disabled
                                checked={isPaymentRecurring}
                                onChange={(event) => setPaymentRecurring(event.target.checked)}>
                                Make Payment Recurring
                            </Checkbox>
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    )
}
