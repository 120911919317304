import {Button, Dropdown, Menu} from "antd"
import {useQuery} from "@tanstack/react-query"
import {useToggle} from "react-use"
import cx from "classnames"
import {map} from "lodash"
import {useEdularModulesContext, Modules} from "@edular/modules"
import {calendarIntegrationService} from "services"
import {useMutation} from "hooks"
import {handleError} from "helpers"
import {ExternalCalendarProvider, GenerateExternalCalendarOAuthLinkParams} from "types/calendar"
import {Icon} from "components/Icon"
import googleLogoImg from "assets/images/google-logo.svg"
import microsoftLogoImg from "assets/images/microsoft-logo.svg"
import CircularSpin from "components/CircularSpin"
import styles from "./ConnectedCalendars.module.css"

export const ConnectedCalendars = () => {
    const {isModuleEnable} = useEdularModulesContext()
    const providers: {id: ExternalCalendarProvider; label: string}[] = [
        isModuleEnable(Modules.OutlookCalendar) && {id: ExternalCalendarProvider.Outlook, label: "Outlook"},
        isModuleEnable(Modules.GoogleCalendar) && {id: ExternalCalendarProvider.Google, label: "Google"}
    ].filter(Boolean)
    const {
        data: connectedCalendars,
        isLoading,
        refetch
    } = useQuery(["get-user-calendars-integration"], () => calendarIntegrationService.getUserCalendars(), {
        onError: handleError
    })
    const [showContent, toggleShowContent] = useToggle(true)
    const generateOAuthMutation = useMutation(
        (data: GenerateExternalCalendarOAuthLinkParams) => calendarIntegrationService.generateOAuth(data),
        {
            onSuccess: ({authUrl}) => window.open(authUrl),
            onError: (error) => handleError(error)
        }
    )
    const disconnectMutation = useMutation(
        (data: {calendarId: number}) => calendarIntegrationService.disconnectUserCalendars(data),
        {onSuccess: () => refetch(), onError: (error) => handleError(error)}
    )

    const menu = (
        <Menu className={styles.menuActionWrap}>
            {providers.map((provider, index) => (
                <Menu.Item
                    key={index}
                    className={styles.menuActionItem}
                    onClick={() =>
                        generateOAuthMutation.mutate({provider: provider.id, successRedirect: window.location.href})
                    }>
                    {provider.label}
                </Menu.Item>
            ))}
        </Menu>
    )

    const renderContent = () => {
        if (!showContent) {
            return null
        }
        if (isLoading) {
            return (
                <div className={styles.content}>
                    <CircularSpin />
                </div>
            )
        }
        return (
            <div className={styles.content}>
                {map(connectedCalendars, ({id, account, edularCalendarId}) => (
                    <div key={id} className={styles.itemInfo}>
                        <img
                            src={
                                account.provider === ExternalCalendarProvider.Google ? googleLogoImg : microsoftLogoImg
                            }
                            className={styles.itemLogo}
                            alt="logo"
                        />
                        <span className={styles.itemLabel}>{account.name}</span>
                        <Button
                            className={styles.actionBtn}
                            icon={<Icon icon="CLOSE" color="#000" className={styles.icon} />}
                            onClick={() => disconnectMutation.mutate({calendarId: +edularCalendarId})}
                            disabled={disconnectMutation.isLoading}
                            loading={disconnectMutation.isLoading}
                        />
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.header}>
                <p className={styles.title}>Connected calendars</p>
                <div className={styles.actions}>
                    <Dropdown overlay={menu} trigger={["click"]}>
                        <Button
                            className={cx(styles.actionBtn, styles.actionBtn__add)}
                            icon={<Icon icon="PLUS" color="#000000d9" className={styles.icon} />}
                        />
                    </Dropdown>
                    <Button
                        className={styles.actionBtn}
                        icon={
                            <Icon
                                icon="ARROW_DOWN"
                                color="#000000d9"
                                className={cx(styles.icon, {[styles.iconRotate]: !showContent})}
                            />
                        }
                        onClick={toggleShowContent}
                    />
                </div>
            </div>
            {renderContent()}
        </div>
    )
}
