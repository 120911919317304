import {Timestamps} from "./common"
import {Auth} from "./auth"
import {IconType} from "components"
import {DepartmentSubunit} from "./departments"

export type ContactStrategy = Timestamps & {
    contactStrategyId: number
    code: string
    name: string
    description?: string | null
    isActive: boolean | 0 | 1
    flow: string
    departmentSubunitId: number
    departmentSubunit?: DepartmentSubunit // populate

    createdByProfileId: number
    createdBy?: Auth.LinkedProfileDetails // populate
    updatedByProfileId: number
    updatedBy?: Auth.LinkedProfileDetails // populate
}

export enum ContactStrategyNodeType {
    ConditionDate = "condition_date",
    ConditionNewStudentStatus = "condition_new_student_status",
    ConditionStudentActivity = "condition_student_activity",
    ConditionCampus = "condition_campus",
    ConditionProgram = "condition_program",
    ConditionDegreeLevel = "condition_degree_level",
    ConditionDependencyStatus = "condition_dependency_status",
    ConditionCitizenshipStatus = "condition_citizenship_status",
    ConditionMilitaryStatus = "condition_military_status",
    ConditionStudentStartDate = "condition_student_start_date",
    ConditionStudentGradDate = "condition_student_grad_date",
    ActionCreateActivity = "action_create_activity",
    ActionSendEmail = "action_send_email",
    ActionSendMessage = "action_send_message",
    ActionSetDue = "action_set_due",
    ActionChangeStudentStatus = "action_change_student_status",
    ActionChecklistSignOff = "action_checklist_sign_off"
}

export const getContactStrategyNodeTypeIcon = (type: ContactStrategyNodeType): IconType => {
    switch (type) {
        case ContactStrategyNodeType.ConditionDate:
            return "CALENDAR_INPUT"
        case ContactStrategyNodeType.ConditionNewStudentStatus:
            return "TYPE_LINE"
        case ContactStrategyNodeType.ConditionStudentActivity:
            return "CHECKMARK_CIRCLE"
        case ContactStrategyNodeType.ConditionCampus:
            return "CLIENTS"
        case ContactStrategyNodeType.ConditionProgram:
            return "PROGRAM"
        case ContactStrategyNodeType.ConditionDegreeLevel:
            return "GRADE"
        case ContactStrategyNodeType.ConditionDependencyStatus:
            return "TYPE_LINE"
        case ContactStrategyNodeType.ConditionCitizenshipStatus:
            return "TYPE_LINE"
        case ContactStrategyNodeType.ConditionMilitaryStatus:
            return "TYPE_LINE"
        case ContactStrategyNodeType.ActionCreateActivity:
            return "CHECKMARK_CIRCLE"
        case ContactStrategyNodeType.ActionSendEmail:
            return "EMAIL_FILL"
        case ContactStrategyNodeType.ActionSendMessage:
            return "MESSAGE_CIRCLE"
        case ContactStrategyNodeType.ActionSetDue:
            return "CALENDAR_INPUT"
        case ContactStrategyNodeType.ActionChangeStudentStatus:
            return "TYPE_LINE"
        case ContactStrategyNodeType.ActionChecklistSignOff:
            return "CHECK_LIST"
        default:
            return "TYPE_LINE"
    }
}

export const CONDITION_TYPES = [
    ContactStrategyNodeType.ConditionDate,
    ContactStrategyNodeType.ConditionNewStudentStatus,
    ContactStrategyNodeType.ConditionStudentActivity,
    ContactStrategyNodeType.ConditionCampus,
    ContactStrategyNodeType.ConditionProgram,
    ContactStrategyNodeType.ConditionDegreeLevel,
    ContactStrategyNodeType.ConditionDependencyStatus,
    ContactStrategyNodeType.ConditionCitizenshipStatus,
    ContactStrategyNodeType.ConditionMilitaryStatus
]

export const getFormattedContactStrategyName = (contactStrategy: Partial<ContactStrategy>) =>
    contactStrategy.code ? `${contactStrategy.name} (${contactStrategy.code})` : contactStrategy.name

export type ContactStrategyNodeData = {
    type: ContactStrategyNodeType
    data?: Record<string, any>
}

export const DUE_COLOR_OPTIONS = [
    {id: "rgba(223, 22, 66, 0.2)", name: "Red"},
    {id: "rgba(255, 207, 116, 0.2)", name: "Yellow"},
    {id: "rgba(24, 169, 87, 0.2)", name: "Green"}
]
