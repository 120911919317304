import styles from "./ApplicationProgress.module.css"
import {ProgressItem} from "./ProgressItem"
import {useQuery} from "@tanstack/react-query"
import {admissionService} from "services"

type ApplicationProgressProps = {
    departmentId: number
    studentId: number
}

export function ApplicationProgress({studentId}: ApplicationProgressProps) {
    const query = useQuery(
        ["student-progress", studentId],
        async () => {
            return await admissionService.getStudentStatusProgress(studentId)
        },
        {
            initialData: []
        }
    )

    return (
        <div className={styles.wrap}>
            <div className={styles.progressWrap}>
                {query.data.map((status) => {
                    const color = status.isCurrent
                        ? "var(--success-400-base)"
                        : status.checked
                        ? "var(--primary-400-base)"
                        : "var(--black-200)"
                    return (
                        <ProgressItem
                            key={status.statusId}
                            {...{
                                title: status.statusName,
                                color,
                                checked: status.checked,
                                completedAt: status.completedAt
                            }}
                        />
                    )
                })}
            </div>
        </div>
    )
}
