import {useQuery} from "@tanstack/react-query"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {handleError} from "helpers"
import {settingEmailTemplatesService} from "services"
import {TemplateType} from "types/settings/email-template"

const queryCacheKey = "email-sms-templates"

type EmailAndSmsTemplateProps = {
    templateTypes?: TemplateType[]
}

export const useEmailAndSmsTemplates = ({templateTypes}: EmailAndSmsTemplateProps) => {
    const query = useQuery(
        [queryCacheKey, templateTypes],
        async () => {
            const {data} = await settingEmailTemplatesService.list({
                range: {page: 1, pageSize: UNLIMITED_PAGE_SIZE},
                filter: {templateTypes},
                linkedObjects: false
            })
            return data
        },
        {
            onError: (err) => {
                handleError(err)
            },
            initialData: []
        }
    )

    return {
        templates: query.data,
        isLoading: query.isFetching
    }
}
