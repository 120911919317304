import {get} from "lodash"
import BaseService from "./Base"
import {StudentActivity} from "types/activity"

export default class StudentActivitiesService extends BaseService {
    async getActivities(params = {}) {
        const response = await this.post("/v1/student-activity/list", params)
        return get(response, "data", [])
    }

    async autocompleteActivities(filter: {search: string; studentProfileId: number}) {
        const response = await this.post("/v1/student-activity/autocomplete", {
            filter
        })
        return get(response, "data", [])
    }

    async createActivities(params = []): Promise<StudentActivity[]> {
        const response = await this.post("/v1/student-activity/create", params)
        return get(response, "data.data.data", [])
    }

    async editActivity(params = {}): Promise<StudentActivity> {
        const response = await this.post("/v1/student-activity/edit", params)
        return get(response, "data.data", {})
    }

    async deleteActivity(params = {}) {
        const response = await this.post("/v1/student-activity/delete", params)
        return response
    }

    async getHoldActivities(params = {}) {
        const response = await this.post("/v1/student-activity/on-hold/list", params)
        return get(response, "data", [])
    }

    async getActivityStats(params = {}) {
        const response = await this.post("/v1/student-activity/stats/list", params)
        return get(response, "data", {})
    }

    async markAsRead(profileId: number) {
        await this.post("/v1/student-activity/mark-as-read", {profileId})
    }

    async setPinned(activityIds: number[], isPinned: boolean) {
        await this.post("/v1/student-activity/pin", {activityIds, isPinned})
    }

    async markAsUnread(
        profileId: number,
        activityIds?: number[]
    ): Promise<{
        profileId: number
        activityIds: number[]
    }> {
        const response = await this.post("/v1/student-activity/mark-as-unread", {profileId, activityIds})
        return get(response, "data.data", {})
    }
}
