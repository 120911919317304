import {MutableRefObject, useRef} from "react"
import {isMobile} from "react-device-detect"
import cx from "classnames"
import {Icon} from "components/Icon"
import {BaseInput} from "components/inputs"
import styles from "./item.module.css"
import {RemarkIndicator} from "./RemarkIndicator"
import {PreviousAnswer} from "../../PreviousAnswer"

type Props = {
    field: any
    canSign: boolean
    errorFieldIds: string[]
    fieldRefs: MutableRefObject<Record<string, HTMLDivElement>>
    onChangeField: (fieldId: string, data: any) => void
}

export function Grade(props: Props) {
    const {field, canSign, errorFieldIds, fieldRefs, onChangeField} = props
    const {top, left, width, height} = field.placeInformation
    const {isYourField} = field
    const wrapperRef = useRef(null)

    let fontSize = Math.min(Math.floor(height / 1.7), 14)
    if (isMobile) {
        const minFontsize = height <= 14 ? 7 : 9
        fontSize = Math.min(Math.floor(height / 1.7), minFontsize)
    }

    const onFillInput = () => {
        if (!canSign || !isYourField) {
            return
        }
        onChangeField(field.id, {isSigned: true, value: ""})
        setTimeout(() => {
            const inputEl = wrapperRef.current.querySelector("input")
            if (inputEl) {
                inputEl.focus()
            }
        }, 0)
    }

    const onChangeValue = (newValue) => {
        onChangeField(field.id, {value: newValue})
    }

    const renderInputByType = () => {
        if (field?.readOnly) {
            return <div className={styles.studentDataValue}>{field.value}</div>
        }
        return <BaseInput type="number" min={0} value={field.value} className={styles.input} onChange={onChangeValue} />
    }

    const renderField = () => {
        if (!field.isSigned) {
            return (
                <div
                    ref={(ref) => (fieldRefs.current[field.id] = ref)}
                    className={cx(styles.actionItem, {
                        [styles.actionItemRequired]: isYourField && field.required,
                        [styles.hasComment]: !!field.remarkComment,
                        [styles.actionItem_error]: errorFieldIds.includes(field.id)
                    })}
                    style={{width, height}}
                    onClick={onFillInput}>
                    <div className={styles.textWrap}>
                        <p className={styles.label}>{field.label}</p>
                        <Icon icon="TEXT" className={styles.icon} />
                    </div>
                </div>
            )
        }
        return (
            <div
                ref={(ref) => (fieldRefs.current[field.id] = ref)}
                className={cx(styles.actionItem, {
                    [styles.actionItemRequired]: field.isYourField && field.required,
                    [styles.hasComment]: !!field.remarkComment,
                    [styles.actionItem_error]: errorFieldIds.includes(field.id)
                })}
                style={{width, height}}>
                <div className={cx(styles.inputWrap)} style={{fontSize}}>
                    {renderInputByType()}
                </div>
            </div>
        )
    }

    const renderBody = () => {
        if (!canSign || !isYourField) {
            return <PreviousAnswer field={field} backgroundColor={field.backgroundColor} />
        }
        return renderField()
    }

    return (
        <>
            <div className={styles.wrap} style={{left, top}} ref={wrapperRef}>
                {renderBody()}
            </div>
            {field.remarkComment && (
                <div className={styles.wrap} style={{left, top, zIndex: 9}}>
                    <RemarkIndicator field={field} />
                </div>
            )}
        </>
    )
}
