import {get} from "lodash"
import BaseService from "./Base"

export default class EducatorLegacyTestService extends BaseService {
    async getAll(params = {}) {
        const response = await this.post("/v1/academics/educator-legacy-tests/list", params)
        return get(response as any, "data", [])
    }

    async getStats(params = {}) {
        const response = await this.post("/v1/academics/educator-legacy-tests/stats/list", params)
        return get(response as any, "data.data", [])
    }

    async edit(params = {}) {
        const response = await this.post("/v1/admissions/educator-legacy-tests/edit", params)
        return get(response, "data", [])
    }

    async delete(params = {}) {
        const response = await this.post(`/v1/academics/educator-legacy-tests/delete`, params)
        return get(response, "data", {})
    }
}
