import {RemarkIndicator} from "./RemarkIndicator"
import {PreviousAnswer} from "../../PreviousAnswer"

type Props = {
    field: any
    onChangeRemarkField: (fieldId: string, comment: string) => void
}

export function Grade(props: Props) {
    const {field, onChangeRemarkField} = props

    return (
        <RemarkIndicator field={field} onChangeRemarkField={onChangeRemarkField}>
            <PreviousAnswer field={field} backgroundColor="primary" />
        </RemarkIndicator>
    )
}
