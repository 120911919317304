/* eslint-disable react-hooks/exhaustive-deps */
import {BaseButton, BaseLoading, BasePopup, SecondaryButton} from "components"
import styles from "./MessageTemplatePopup.module.css"
import {useCallback, useEffect, useState} from "react"
import {serializeTemplate, toastError} from "helpers"
import {EmailTemplate, TemplateType} from "types/settings/email-template"
import {useModel} from "hooks"
import {INITIAL_RTE_CONTENT} from "components/RichTextEditor/slate-custom-types"
import {MessageTemplateSelect} from "components/MessageTemplateSelect"
import {useUserDetailForMessageTemplate} from "./useUserDetailForMessageTemplate"

type MessageTemplatePopupProps = {
    isShow: boolean
    selectedUserId?: number
    serializeWithoutUser: boolean
    onClose: () => void
    onSelect: (msgText: string) => void
}

export function MessageTemplatePopup({
    isShow,
    selectedUserId,
    serializeWithoutUser,
    onClose,
    onSelect
}: MessageTemplatePopupProps) {
    const model = useModel()

    const userDateFormat = model.getUserDateFormat()
    const userTimeFormat = model.getUserTimeFormat()
    const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate>()
    const {userData, isLoading} = useUserDetailForMessageTemplate({
        userId: selectedUserId,
        isEnabled: isShow && !serializeWithoutUser
    })

    useEffect(() => {
        if (isShow && selectedUserId) {
            setSelectedTemplate(undefined)
        }
    }, [isShow, selectedUserId])

    const normalizeDescendants = (node: any) => {
        if (node?.children?.length) {
            node.children = node.children.map(normalizeDescendants).filter((el) => el)
        }
        return node
    }

    const onSubmit = useCallback(() => {
        if (!selectedTemplate) {
            toastError("Please select a text template.")
            return
        }

        let newDescendants = selectedTemplate.template ? JSON.parse(selectedTemplate.template) : INITIAL_RTE_CONTENT
        newDescendants = newDescendants.map(normalizeDescendants).filter((el) => el)

        let text: string = ""
        newDescendants?.forEach(
            (descendant) =>
                (text += serializeTemplate(descendant, userData, {
                    selectedTemplate,
                    serializeWithoutUser,
                    userDateFormat,
                    userTimeFormat
                }))
        )

        onSelect(text)
        setSelectedTemplate(undefined)
        onClose()
    }, [selectedUserId, selectedTemplate])

    return (
        <BasePopup
            isShow={isShow}
            onClose={onClose}
            leftIcon="SMS"
            leftIconColor="#fff"
            width="50vw"
            closable={false}
            destroyOnClose>
            <div className={styles.headingTitle}>Send from Text Message Templates</div>
            <MessageTemplateSelect onChange={setSelectedTemplate} templateTypes={[TemplateType.SMS]} />
            <div className={styles.actionButtons}>
                <SecondaryButton title="Cancel" onClick={onClose} className={styles.addOption} />
                <BaseButton title="Send" onClick={onSubmit} className={styles.addOption} />
            </div>
            <BaseLoading isShow={isLoading} />
        </BasePopup>
    )
}
