import {Text} from "slate"
import escapeHtml from "escape-html"
import {Communication} from "types/communication"
import moment from "moment"
import {Auth} from "types/auth"
import {getFullAddress, getFullName} from "./user"
import {getBaseUrl} from "./common"

export const serializeTemplate = (
    node,
    userData: {
        userDetails
        profile
        campusDetails
        admissionAdvisor
        finAidAdvisor
        academicAdvisor
    },
    options: {
        selectedTemplate
        serializeWithoutUser
        userDateFormat
        userTimeFormat
    }
) => {
    const {userDetails, profile, campusDetails, admissionAdvisor, finAidAdvisor, academicAdvisor} = userData
    const {selectedTemplate, serializeWithoutUser, userDateFormat, userTimeFormat} = options
    if (Text.isText(node)) {
        if (selectedTemplate?.editorType === Communication.EditorType.Html) {
            return node.text
        }

        let string = escapeHtml(node.text)
        if (node.bold) {
            string = `<strong>${string}</strong>`
        }
        if (node.italic) {
            string = `<i>${string}</i>`
        }
        if (node.underline) {
            string = `<u>${string}</u>`
        }
        if (node.code) {
            string = `<code>${string}</code>`
        }
        return string
    }

    const children = node.children.map((n) => serializeTemplate(n, userData, options)).join("")

    let attrs = ""
    if (node.align) {
        attrs += ` align="${node.align}"`
    }

    if (node.type === "quote") {
        return `<blockquote${attrs}><p>${children}</p></blockquote>`
    } else if (node.type === "block-quote") {
        return `<blockquote${attrs}><p>${children}</p></blockquote>`
    } else if (node.type === "paragraph") {
        if (selectedTemplate?.editorType === Communication.EditorType.Html) {
            return children
        }
        return `<p${attrs}>${children}</p>`
    } else if (node.type === "link") {
        return `<a href="${escapeHtml(node.url)}" target="_blank" ${attrs}>${children}</a>`
    } else if (node.type === "heading-one") {
        return `<h1${attrs}>${children}</h1>`
    } else if (node.type === "heading-two") {
        return `<h2${attrs}>${children}</h2>`
    } else if (node.type === "heading-three") {
        return `<h3${attrs}>${children}</h3>`
    } else if (node.type === "heading-four") {
        return `<h4${attrs}>${children}</h4>`
    } else if (node.type === "heading-five") {
        return `<h5${attrs}>${children}</h5>`
    } else if (node.type === "heading-six") {
        return `<h6${attrs}>${children}</h6>`
    } else if (node.type === "image") {
        return `<img${attrs} src="${node.url}" />`
    } else if (node.type === "numbered-list") {
        return `<ol${attrs}>${children}</ol>`
    } else if (node.type === "bulleted-list") {
        return `<ul${attrs}>${children}</ul>`
    } else if (node.type === "list-item") {
        return `<li${attrs}>${children}</li>`
    } else if (node.type === "field-label") {
        if (serializeWithoutUser) {
            return `[${node.field}]`
        }

        if (
            userDetails &&
            userDetails[node.field] &&
            [
                "firstName",
                "middleName",
                "lastName",
                "gender",
                "ssn",
                "address",
                "city",
                "state",
                "postalCode",
                "country",
                "customUserId"
            ].indexOf(node.field) >= 0
        ) {
            return userDetails[node.field]
        } else if (userDetails?.birthDate && node.field === "birthDate") {
            return moment(userDetails.birthDate).format(userDateFormat)
        } else if (node.field === "fullName") {
            return getFullName(userDetails)
        } else if (userDetails?.emails && node.field === "email") {
            return userDetails.emails?.filter((email) => !!email.isPrimary)?.map((email) => email.email)
        } else if (userDetails?.phones && node.field === "phone") {
            return userDetails.phones?.filter((phone) => !!phone.isPrimary)?.map((phone) => phone.phone)
        } else if (profile?.campus && node.field === "campuses") {
            return profile.campus
        } else if (campusDetails?.email && node.field === "campusEmail") {
            return campusDetails.email
        } else if (campusDetails?.phone && node.field === "campusPhone") {
            return campusDetails.phone
        } else if (campusDetails?.officialCampusName && node.field === "campusOfficialName") {
            return campusDetails.officialCampusName
        } else if (campusDetails && node.field === "campusAddress") {
            return getFullAddress(campusDetails)
        } else if (profile?.currentMajor?.name && node.field === "program") {
            return profile.currentMajor.name
        } else if (
            profile?.customProfileId &&
            ["customProfileId", "enrollmentStatus", "efc", "dependencyStatus", "awardYear"].indexOf(node.field) >= 0
        ) {
            return profile.customProfileId
        } else if (profile?.startDate && node.field === "start_date") {
            return profile.startDate
        } else if (profile?.currentTerm?.name && node.field === "term") {
            return profile.currentTerm.name
        } else if (profile?.startingTerm?.name && node.field === "startTerm") {
            return profile.startingTerm.name
        } else if (userDetails?.collegeDetails[0]?.graduationDate && node.field === "graduationDate") {
            return moment(userDetails.collegeDetails[0].graduationDate).format(userDateFormat)
        } else if (node.field === "documentsFolder") {
            return `<a target="_blank" href="${getBaseUrl()}/users/${userDetails.id}?tab=${
                Auth.UserDetailTab.Documents
            }">Documents Folder</a>`
        } else if (admissionAdvisor && node.field === "admissionAdvisor") {
            return `${getFullName(admissionAdvisor)}`
        } else if (admissionAdvisor && node.field === "admissionAdvisorEmail") {
            return admissionAdvisor.advisorEmail
        } else if (admissionAdvisor && node.field === "admissionAdvisorPhone") {
            return admissionAdvisor.advisorPhone
        } else if (finAidAdvisor && node.field === "finAidAdvisor") {
            return `${getFullName(finAidAdvisor)}`
        } else if (finAidAdvisor && node.field === "finAidAdvisorEmail") {
            return finAidAdvisor.advisorEmail
        } else if (finAidAdvisor && node.field === "finAidAdvisorPhone") {
            return finAidAdvisor.advisorPhone
        } else if (academicAdvisor && node.field === "academicAdvisor") {
            return `${getFullName(academicAdvisor)}`
        } else if (academicAdvisor && node.field === "academicAdvisorEmail") {
            return academicAdvisor.advisorEmail
        } else if (academicAdvisor && node.field === "academicAdvisorPhone") {
            return academicAdvisor.advisorPhone
        } else if (node.field === "todaysDate") {
            return moment().format(userDateFormat)
        }
    } else if (node.type === "field-input") {
        switch (node.input) {
            case "text":
                return node.value
            case "date":
                return moment(node.value).format(userDateFormat)
            case "time":
                return moment(node.value).format(userTimeFormat)
        }
    } else if (node.type === "embed-video") {
        return `<iframe src="${node.videoId}" allowFullScreen></iframe>`
    } else {
        return children
    }
}
