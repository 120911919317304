import React from "react"
import cx from "classnames"
import moment from "moment-timezone"
import {Icon} from "components/Icon"
import styles from "./ProgressItem.module.css"
import {useModel} from "hooks"

type Props = {
    title: string
    color?: string
    checked?: boolean
    completedAt?: string
    isCurrent?: boolean
}

export function ProgressItem({title, color, checked, completedAt, isCurrent}: Props) {
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()

    return (
        <div className={styles.stepItem}>
            <p className={styles.title}>{title}</p>
            <div className={styles.statusWrap}>
                <div className={cx(styles.line)} />

                <div className={styles.point} style={{backgroundColor: color}}>
                    {!!checked && <Icon icon="CHECKMARK_CIRCLE_1" className={styles.pointIcon} color="#fff" />}
                    {!!completedAt && (
                        <span className={styles.pointText}>({moment(completedAt).format(userDateFormat)})</span>
                    )}
                </div>

                {!!isCurrent && (
                    <div className={styles.currentWrap}>
                        <div className={styles.currentLine} style={{backgroundColor: color}} />
                        <p className={styles.currentText} style={{backgroundColor: color}}>
                            {moment().format("MMMM")}
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}
