import {useQuery} from "@tanstack/react-query"
import moment from "moment"
import {getFullName, handleError} from "helpers"
import {useCalendarEventsViewSettings} from "hooks/useCalendarEventViewSettings"
import {get, isEmpty, keyBy} from "lodash"
import {useState} from "react"
import {calendarService} from "services"
import {useModel} from "hooks"
import {CalendarEventType} from "types/calendar"
import {checkIsTermMajorSystemEvent, getEndDateTime, getStartDateTime} from "helpers/calendar"
import {useGetExtraEventInfo} from "./useGetExtraEventInfo"

export const useGetCalendarEvents = () => {
    const model = useModel()
    const userId = +get(model, "user.id")
    const [dateRange, setDateRange] = useState(null)
    const [events, setEvents] = useState([])
    const {settings, isLoadingSettings} = useCalendarEventsViewSettings()

    const {getEventColor, getModuleTitle, getSubModuleTitle, getUsers, getCourseSchedules, getCourses} =
        useGetExtraEventInfo()

    const {data: calendarId, isLoading: isLoadingCalendar} = useQuery(
        ["primary-calendar"],
        async () => {
            const {data} = await calendarService.getMyPrimaryCalendar({}, {})
            return data.calendar_id
        },
        {onError: (error) => handleError(error)}
    )
    const {refetch: reloadEvents, isFetching} = useQuery(
        ["calendar-events", calendarId, settings, dateRange],
        async () => {
            const events = await getEvents()
            return convertEvents(events)
        },
        {
            enabled: !!calendarId && !isEmpty(settings) && !isEmpty(dateRange),
            onSuccess: (data) => {
                setEvents(data)
            },
            onError: (error) => handleError(error)
        }
    )

    const getEvents = async () => {
        const {payload, invitesCalendarPayload, bookedPayload} = getPayload()
        const [{data: myEvents}, {data: myInvitesCalendar}, {data: bookedEvents}, {data: myInvites}] =
            await Promise.all([
                calendarService.getAll(payload),
                calendarService.getAllInvitesCalendar(invitesCalendarPayload),
                calendarService.getAll(bookedPayload),
                calendarService.getAllInvites(invitesCalendarPayload)
            ])

        const myInvitesCalendarEvents = myInvitesCalendar
            .filter((event) => event.invited_user_id !== event.object?.owner_user_id)
            .map((item) => ({...item.object, ...item.group, willGo: item.will_go}))
        const inviteEventIds = myInvitesCalendarEvents.map((item) => item.object_id)
        const myInvitesEvents = myInvites
            .filter(
                (event) =>
                    event.invited_user_id !== event.object?.owner_user_id &&
                    !inviteEventIds.includes(event.object?.object_id)
            )
            .map((item) => ({...item.object, ...item.group, willGo: item.will_go}))
        let userIds = []
        let courseScheduleIds = []
        let courseIds = []

        if (myEvents.length) {
            const newUserIds = myEvents
                .filter((item) => item.owner_user_id && item.owner_user_id !== userId)
                .map((event) => event.owner_user_id)
            userIds = [...userIds, ...newUserIds]
        }

        if (bookedEvents.length) {
            const newUserIds = bookedEvents
                .filter((item) => item.appointment_slot.booker_user_id !== userId)
                .map((item) => item.appointment_slot.booker_user_id)
            const ownerUserIds = bookedEvents
                .filter((item) => item.owner_user_id !== userId)
                .map((item) => item.owner_user_id)
            userIds = [...userIds, ...newUserIds, ...ownerUserIds]
        }

        if (myInvitesCalendarEvents.length) {
            const newUserIds = myInvitesCalendarEvents.map((item) => item.owner_user_id)
            const scheduleIds = myInvitesCalendarEvents.map((item) => item.json_data.schedule_id).filter((item) => item)
            const courseIdsData = myInvitesCalendarEvents.map((item) => item.json_data.course_id).filter((item) => item)
            courseScheduleIds = [...courseScheduleIds, ...scheduleIds]
            courseIds = [...courseIds, ...courseIdsData]
            userIds = [...userIds, ...newUserIds]
        }

        if (myInvitesEvents.length) {
            const newUserIds = myInvitesEvents.map((item) => item.owner_user_id)
            const scheduleIds = myInvitesEvents.map((item) => item.json_data.schedule_id).filter((item) => item)
            const courseIdsData = myInvitesCalendarEvents.map((item) => item.json_data.course_id).filter((item) => item)
            courseScheduleIds = [...courseScheduleIds, ...scheduleIds]
            courseIds = [...courseIds, ...courseIdsData]
            userIds = [...userIds, ...newUserIds]
        }

        const [users, courseSchedules, courses] = await Promise.all([
            getUsers(userIds),
            getCourseSchedules(courseScheduleIds),
            getCourses(courseIds)
        ])

        const usersKeyById = keyBy(users, "id")
        const courseSchedulesById = keyBy(courseSchedules, "id")
        const coursesById = keyBy(courses, "courseId")
        const myInvitesKeyByObjectId = keyBy(myInvites, "object_id")

        myEvents.forEach((event) => {
            const isCurrentUser = event.owner_user_id === userId
            if (myInvitesKeyByObjectId[event.object_id]) {
                event.willGo = get(myInvitesKeyByObjectId[event.object_id], "will_go")
            }
            event.ownerFullName = isCurrentUser
                ? getFullName(model.user)
                : getFullName(usersKeyById[event.owner_user_id])
        })

        bookedEvents.forEach((item) => {
            const bookUserId =
                item.appointment_slot.booker_user_id !== userId
                    ? item.appointment_slot.booker_user_id
                    : item.owner_user_id
            const bookedUsername = getFullName(usersKeyById[bookUserId])
            item.name = `Booked event with ${bookedUsername}`
        })

        myInvitesCalendarEvents.forEach((event) => {
            const isCurrentUser = event.owner_user_id === userId
            event.ownerFullName = isCurrentUser
                ? getFullName(model.user)
                : getFullName(usersKeyById[event.owner_user_id])
            if (event.json_data.schedule_id) {
                event.courseScheduleInfo = courseSchedulesById[event.json_data.schedule_id]
                event.courseInfo = coursesById[event.json_data.course_id]
            }
        })
        myInvitesEvents.forEach((event) => {
            const isCurrentUser = event.owner_user_id === userId
            event.ownerFullName = isCurrentUser
                ? getFullName(model.user)
                : getFullName(usersKeyById[event.owner_user_id])
            if (event.json_data.schedule_id) {
                event.courseScheduleInfo = courseSchedulesById[event.json_data.schedule_id]
                event.courseInfo = coursesById[event.json_data.course_id]
            }
        })

        const events = [...myEvents, ...bookedEvents, ...myInvitesCalendarEvents, ...myInvitesEvents]

        return events
    }

    const convertEvents = (initialEvents) => {
        return (initialEvents || []).map((event, index) => {
            const originalColor = event?.json_data?.color || "#1e90ff"
            const reminders = (event?.json_data?.notifications ?? []).map((value) => ({
                value,
                label: `${moment.duration(value / 60, "minutes").humanize()} before`
            }))
            const withSMS = !!event?.json_data?.with_sms
            const withPushNotification = !!event?.json_data?.with_push_notification
            const withEmail = !!event?.json_data?.with_email
            const eventSignInRequired = !!event?.json_data?.eventSignInRequired
            const attendedChangeToStatus = !!event?.json_data?.attendedChangeToStatus
            const notAttendedChangeToStatus = !!event?.json_data?.notAttendedChangeToStatus
            const completionWorkflow = !!event?.json_data?.completionWorkflow
            const {borderColor, color} = getEventColor(event)
            const allDay =
                checkIsTermMajorSystemEvent(event.type) ||
                moment(event.end_at_utc).diff(moment(event.start_at_utc), "seconds") === 24 * 3600
            const startDateTime = getStartDateTime(event)
            const endDateTime = getEndDateTime(event)

            return {
                id: event.object_id,
                start: startDateTime.format(),
                end: endDateTime.format(),
                originalColor,
                borderColor: borderColor,
                color: color,
                startDateTime,
                endDateTime,
                title: event.name,
                allDay,
                order: 1,
                classNames: "",
                initialEventIndex: index,
                description:
                    event.type === CalendarEventType.AppointmentSlot
                        ? get(event, "appointment_slot.booker_notes", event.description)
                        : event.description,
                isAppointmentSlot: event.type === CalendarEventType.AppointmentSlot,
                ownerUserId: event.owner_user_id,
                ownerFullName: event.ownerFullName,
                willGo: event.willGo,
                reminders,
                withSMS,
                withPushNotification,
                withEmail,
                eventSignInRequired,
                attendedChangeToStatus,
                notAttendedChangeToStatus,
                completionWorkflow,
                isLesson: !isEmpty(event.courseScheduleInfo),
                type: event.type,
                isHoliday: event.type === CalendarEventType.WorkSchedule,
                schedule_suffix: event.courseScheduleInfo?.schedule_suffix,
                moduleTitle: getModuleTitle(event.json_data.eventType, event.courseInfo),
                subModuleTitle: getSubModuleTitle(event.json_data.sub_module_id, event.courseInfo),
                json_data: event.json_data,
                rrule: event.rrule
            }
        })
    }

    const getPayload = () => {
        const {startStr: from, endStr: to} = dateRange
        const utcFrom = moment(from).utc().format()
        const utcTo = moment(to).utc().format()

        const basePayload = {
            sort: {
                orderBy: "start_at_utc",
                order: "ASC"
            },
            filter: {
                from: utcFrom,
                to: utcTo
            },
            pagination: {
                page: 1,
                pageSize: 100 // TODO fix this
            }
        }

        const payload = {
            ...basePayload,
            filter: {
                ...basePayload.filter,
                public_terms: settings.termStartAndEnd,
                public_programs_start: settings.programStart,
                public_programs_end: settings.programEnd,
                public_work_schedules: true,
                owner_user_ids: [userId],
                calendar_ids: [+calendarId],
                types: [CalendarEventType.Event, CalendarEventType.CourseSchedule]
            }
        }

        const invitesCalendarPayload = {
            sort: {orderBy: "start_at_utc", order: "ASC"},
            filter: {from, to, invited_user_ids: [userId]},
            pagination: {
                page: 1,
                pageSize: 100 // TODO fix this
            }
        }

        const bookedPayload = {
            ...basePayload,
            filter: {
                ...basePayload.filter,
                appointment_slot_status: "booked"
            },
            or_filter: {
                owner_user_ids: [userId],
                appointment_slot_status: "booked",
                booker_user_ids: [userId],
                from,
                to
            }
        }

        return {payload, invitesCalendarPayload, bookedPayload}
    }

    return {
        events,
        isLoading: isFetching || isLoadingCalendar || isLoadingSettings,
        calendarId,
        setEvents,
        reloadEvents,
        setDateRange
    }
}
