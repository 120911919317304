import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {handleError} from "helpers"
import {generalSettingsService} from "services"
import {YesNo} from "types/common"
import {GeneralSetting, GeneralSettingField} from "types/settings/general-setting"

type SettingKey = "termStartAndEnd" | "programStart" | "programEnd"

type CalendarEventsViewSettings = Record<SettingKey, boolean>

const MAP_SETTING_KEY_2_GENERAL_SETTINGS_FIELD_NAME: Record<SettingKey, GeneralSettingField> = {
    termStartAndEnd: GeneralSettingField.CalendarDisplayTermStartAndEndDates,
    programStart: GeneralSettingField.CalendarDisplayProgramStartDates,
    programEnd: GeneralSettingField.CalendarDisplayProgramEndDates
}

const QUERY_KEY = "display-start-and-end-date-calendar-settings"

const DEFAULT_SETTINGS: CalendarEventsViewSettings = {termStartAndEnd: false, programStart: false, programEnd: false}

const parseSettingsInfo = (fieldSettings: GeneralSetting[]): CalendarEventsViewSettings =>
    Object.entries(MAP_SETTING_KEY_2_GENERAL_SETTINGS_FIELD_NAME).reduce(
        (acc, [settingKey, fieldName]) => ({
            ...acc,
            [settingKey]: fieldSettings.find((it) => it.field === fieldName)?.value === YesNo.Yes
        }),
        DEFAULT_SETTINGS
    )

export const useCalendarEventsViewSettings = () => {
    const queryClient = useQueryClient()

    const updateSettingsM = useMutation<
        any,
        any,
        {field: SettingKey; checked: boolean},
        {previousSettings: CalendarEventsViewSettings}
    >(
        async ({field, checked}) => {
            const generalSettingsField = MAP_SETTING_KEY_2_GENERAL_SETTINGS_FIELD_NAME[field]

            await generalSettingsService.updateSettings([
                {field: generalSettingsField, value: checked ? YesNo.Yes : YesNo.No}
            ])
        },
        {
            onMutate: async ({field, checked}) => {
                await queryClient.cancelQueries({queryKey: [QUERY_KEY]})

                const previousSettings: CalendarEventsViewSettings = queryClient.getQueryData([QUERY_KEY])

                queryClient.setQueryData([QUERY_KEY], (old: CalendarEventsViewSettings) => ({
                    ...old,
                    [field]: checked
                }))

                return {previousSettings}
            },
            onError: (err, newSettings, context) => {
                queryClient.setQueryData([QUERY_KEY], context.previousSettings)
            },
            onSettled: () => {
                queryClient.invalidateQueries({queryKey: [QUERY_KEY]})
            }
        }
    )

    const settingsQ = useQuery<CalendarEventsViewSettings>({
        queryKey: [QUERY_KEY],
        queryFn: async () => {
            const response = await generalSettingsService.getSettings([
                GeneralSettingField.CalendarDisplayTermStartAndEndDates,
                GeneralSettingField.CalendarDisplayProgramStartDates,
                GeneralSettingField.CalendarDisplayProgramEndDates
            ])

            return parseSettingsInfo(response)
        },
        initialData: DEFAULT_SETTINGS,
        onError: (err) => handleError(err)
    })

    return {
        settings: settingsQ.data,
        isLoadingSettings: settingsQ.isFetching,
        updateSettings: updateSettingsM.mutateAsync,
        isUpdatingSettings: updateSettingsM.isLoading
    }
}
