import React, {useState, useEffect} from "react"
import {useHistory} from "react-router-dom"
import {taskService} from "services"
import {handleError} from "helpers"
import {TaskStatus} from "types/tasks"
import {BaseLoading} from "components/Loading"
import {Status} from "sections/Tasks/Common"
import {TaskDueDate} from "./TaskDueDate"
import styles from "./Tasks.module.css"

export function Tasks(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [tasks, setTasks] = useState([])
    const history = useHistory()

    useEffect(() => {
        getListTasks()
    }, [])

    const getListTasks = async () => {
        try {
            setIsLoading(true)
            const params = {
                filter: {
                    status: [TaskStatus.TODO, TaskStatus.OVERDUE]
                },
                range: {
                    page: 1,
                    pageSize: 50
                },
                sort: {
                    orderBy: "updatedAt",
                    orderDir: "DESC"
                }
            }
            const {data} = await taskService.getAll(params)
            setTasks(data)
        } catch (e) {
            handleError(e)
        } finally {
            setIsLoading(false)
        }
    }

    const onClickTaskDetail = (task) => {
        history.push(`/tasks/detail?type=${task.type}&subtype=assigned&id=${task.id}`)
    }

    if (isLoading) {
        return (
            <div className={styles.body}>
                <BaseLoading isShow />
            </div>
        )
    }

    if (tasks.length === 0) {
        return (
            <div className={styles.emptyWrap}>
                <p className={styles.emptyTitle}>Empty Task</p>
            </div>
        )
    }

    return (
        <div className={styles.body}>
            {tasks.map((task) => (
                <div key={task.id} className={styles.item} onClick={() => onClickTaskDetail(task)}>
                    <p className={styles.itemTitle}>{task.name}</p>
                    <p className={styles.assignedTo}>{task.assignedTo?.assignedTo}</p>
                    <div className={styles.taskStatus}>
                        <Status status={task.status} />
                    </div>
                    <TaskDueDate date={task.dueDate} />
                </div>
            ))}
        </div>
    )
}
