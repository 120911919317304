/* eslint-disable react-hooks/exhaustive-deps */
import {useRef, useState, useEffect} from "react"
import {Checkbox} from "antd"
import cx from "classnames"
import {BaseInput} from "components/inputs"
import {KlassDropdown} from "components/Select"
import {FormLabel} from "components/Form"
import {GRADE_DATA_TYPES} from "sections/Tasks/data"
import {getContentPositionStyle} from "./utils"
import styles from "./FieldActionItem.module.css"
import {Actions} from "./Actions"

export function GradeForm(props) {
    const {field, definedUsers, scaleInfo, totalPage, isEditable, onClickDelete, onChangeField, onDuplicateField} =
        props
    const [style, setStyle] = useState({})
    const bodyRef = useRef(null)
    const [isShowScroll, setIsShowScroll] = useState(true)

    useEffect(() => {
        if (!bodyRef || !bodyRef.current) {
            return
        }
        const style = getContentPositionStyle(bodyRef, field, scaleInfo, totalPage)
        setStyle(style)
    }, [bodyRef])

    const onChangeData = (key, value) => {
        if (!isEditable) {
            return
        }
        field[key] = value
        onChangeField(field)
    }

    return (
        <div
            ref={bodyRef}
            className={cx(styles.bodyContent, {
                [styles.bodyContentHiddenScroll]: !isShowScroll
            })}
            style={style}>
            <div className={styles.bodyHeader}>
                <p className={styles.bodyHeaderTitle}>Text</p>
                <Actions
                    field={field}
                    isEditable={isEditable}
                    onClickDelete={onClickDelete}
                    onDuplicateField={onDuplicateField}
                />
            </div>
            <div className={styles.formItem}>
                <FormLabel label="Label" />
                <BaseInput
                    placeholder="Label"
                    value={field.label}
                    onChange={(newValue) => onChangeData("label", newValue)}
                />
            </div>
            <div className={styles.formItem}>
                <FormLabel label="Signers" />
                <KlassDropdown
                    options={definedUsers}
                    labelKey="label"
                    value={field.signatureTypes}
                    onChange={(newValue) => onChangeData("signatureTypes", newValue)}
                    isMulti
                    onMenuOpen={() => setIsShowScroll(false)}
                    onMenuClose={() => setIsShowScroll(true)}
                />
            </div>
            <div className={styles.formItem}>
                <Checkbox
                    checked={field.required}
                    onChange={({target: {checked}}) => onChangeData("required", checked)}>
                    <span>Required</span>
                </Checkbox>
            </div>
            <div className={styles.formItem}>
                <FormLabel label="Data type" />
                <KlassDropdown
                    options={GRADE_DATA_TYPES}
                    value={field.dataType}
                    onChange={(newValue) => onChangeData("dataType", newValue)}
                    onMenuOpen={() => setIsShowScroll(false)}
                    onMenuClose={() => setIsShowScroll(true)}
                />
            </div>
        </div>
    )
}
