/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from "react"
import {useTranslation} from "react-i18next"
import groupBy from "lodash/groupBy"
import {Descendant} from "slate"
import {BaseInput} from "components/inputs"
import {KlassDropdown} from "components/Select"
import {INITIAL_RTE_CONTENT} from "components/RichTextEditor/slate-custom-types"
import {EmailTemplate, TemplateType} from "types/settings/email-template"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {handleError, serializeTemplate} from "helpers"
import {useModel} from "hooks"
import {settingEmailTemplatesService} from "services"
import styles from "./TemplateSelection.module.css"
import {useUserDetailForMessageTemplate} from "../MessageTemplatePopup/useUserDetailForMessageTemplate"

type Props = {
    withEmail: boolean
    withSMS: boolean
    setValue: Function
    selectedTemplate
    setSelectedTemplate: Function
    descendants: Descendant[]
    setDescendants: Function
    setSubject: Function
    subject: string
    selectedUserId?: number
    setLoading: Function
    serializeWithoutUser: boolean
}

export function TemplateSelection(props: Props) {
    const {
        withEmail,
        withSMS,
        setValue,
        selectedTemplate,
        setSelectedTemplate,
        descendants,
        setDescendants,
        setSubject,
        subject,
        selectedUserId,
        setLoading,
        serializeWithoutUser
    } = props

    const {t} = useTranslation(["chat", "common"])
    const model = useModel()

    const userDateFormat = model.getUserDateFormat()
    const userTimeFormat = model.getUserTimeFormat()
    const [templates, setTemplates] = useState([])
    const {userData, isLoading} = useUserDetailForMessageTemplate({
        userId: selectedUserId,
        isEnabled: withEmail || withSMS
    })

    useEffect(() => {
        setLoading(isLoading)
    }, [isLoading])

    useEffect(() => {
        if (withEmail || withSMS) {
            loadTemplates()
        }
    }, [withEmail, withSMS])

    useEffect(() => {
        if ((withEmail || withSMS) && (selectedUserId || serializeWithoutUser)) {
            serializeUserDataInTemplate()
        }
    }, [withEmail, withSMS, selectedUserId, serializeWithoutUser, JSON.stringify(descendants)])

    const serializeUserDataInTemplate = () => {
        let text: string = ""
        descendants?.forEach(
            (descendant) =>
                (text += serializeTemplate(descendant, userData, {
                    selectedTemplate,
                    userDateFormat,
                    userTimeFormat,
                    serializeWithoutUser
                }))
        )

        setValue(text)
    }

    const loadTemplates = async () => {
        try {
            let templateTypes: string[] = [TemplateType.Email, TemplateType.SMS]
            if (!withEmail) {
                templateTypes = templateTypes.filter((templateType) => templateType !== TemplateType.Email)
            }
            if (!withSMS) {
                templateTypes = templateTypes.filter((templateType) => templateType !== TemplateType.SMS)
            }
            const {data} = await settingEmailTemplatesService.list({
                range: {page: 1, pageSize: UNLIMITED_PAGE_SIZE},
                filter: {templateTypes},
                linkedObjects: false
            })

            const dataFormatted = data.map(({title, ...rest}) => ({...rest, title: `${title} (${rest.code})`}))

            setTemplates(
                Object.entries(groupBy(dataFormatted, "templateType")).map(([label, options]) => ({
                    label,
                    options
                }))
            )
        } catch (err) {
            handleError(err)
        }
    }

    const onChangeTemplate = (option?: EmailTemplate) => {
        let newDescendants = option?.template ? JSON.parse(option.template) : INITIAL_RTE_CONTENT
        const newSubject: string = option?.template ? option.subject || "" : ""

        newDescendants = newDescendants.map(normalizeDescendants).filter((el) => el)

        setDescendants(newDescendants)
        setSubject(newSubject)
        setSelectedTemplate(option)
    }

    const normalizeDescendants = (node) => {
        if (node?.children?.length) {
            node.children = node.children.map(normalizeDescendants).filter((el) => el)
        }
        return node
    }

    return (
        <>
            {(withEmail || withSMS) && (
                <>
                    <div className={styles.subjectWrapper}>
                        <label>{t("chat.selectTemplate")}</label>
                        <KlassDropdown
                            {...props}
                            placeholder={t("common:selectField.placeholder")}
                            options={templates}
                            defaultOptions
                            labelKey="title"
                            valueKey="emailTemplateId"
                            onChange={onChangeTemplate}
                            value={selectedTemplate}
                            className={styles.subjectWrapperDropdown}
                        />
                    </div>
                    {withEmail && (
                        <div className={styles.subjectWrapper}>
                            <label>{t("chat.emailSubject")}</label>
                            <BaseInput value={subject} onChange={setSubject} />
                        </div>
                    )}
                </>
            )}
        </>
    )
}
