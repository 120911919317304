import React from "react"
import {UserTextIcon} from "components/UserTextIcon"
import styles from "./UserIcon.module.css"
import {useHistory} from "react-router-dom"
import {useCurrentProfilePermissions} from "hooks"
import {Permissions} from "types/permission"
import {head} from "lodash"

type Props = {
    firstName: string
    lastName: string
    image?: string
    type?: "small" | "middle"
    userId?: number
}

export const UserIcon: React.FC<Props> = (props) => {
    const history = useHistory()
    const currentProfilePermissions = useCurrentProfilePermissions()
    const {image, firstName, lastName, type, userId} = props
    const canViewPhoto = currentProfilePermissions.hasPermissions({
        staff: [Permissions.Staff.Users.Self.Personal.PersonalInfo.View],
        student: [Permissions.Student.Users.Personal.ProfilePhoto.View],
        others: [Permissions.Others.Users.Personal.ProfilePhoto.View]
    })

    const onClick = () => {
        history.push({pathname: `/users/${userId}`, state: {notShowBackUrl: true}})
    }

    if (image && canViewPhoto) {
        return (
            <div className={styles.wrapper} onClick={onClick}>
                <img src={image} alt={`${firstName} ${lastName}`} className={styles.avatar} />
            </div>
        )
    }
    const text = `${head(firstName)}${head(lastName)}`.trim() || "A"
    return (
        <div className={styles.wrapper} onClick={onClick}>
            <UserTextIcon text={text} small={type === "small"} />
        </div>
    )
}
