import axios from "axios"
import {get} from "lodash"
import BaseService from "./Base"

class TaskService extends BaseService {
    constructor(baseURL = process.env.REACT_APP_TASKS_API_BASE_URL) {
        super()
        this.axios = axios.create({
            timeout: 30 * 1000,
            baseURL
        })
    }

    async getTaskDashboard(params = {}) {
        const response = await this.post("/v3/tasks/dashboard", params)
        return get(response, "data.data", {})
    }

    async getAll(params = {}) {
        const response = await this.post("/v3/tasks/list", params)
        return get(response, "data", [])
    }

    async autocomplete(filter: {search: string; signerProfileIds: number[]}) {
        const response = await this.post("/v3/tasks/autocomplete", {filter})
        return get(response, "data", [])
    }

    async getOne(id: number) {
        const response = await this.post("/v3/tasks/details", {taskId: id, linkedEntities: true})
        return get(response, "data.data", null)
    }

    async createTask(body = {}) {
        const response = await this.post("/v3/tasks/create", body)
        return get(response, "data.data", {})
    }

    async assignTask(body) {
        const response = await this.post("/v3/tasks/assign", body)
        return get(response, "data", {})
    }

    async editDigitalDocumentField(body) {
        const response = await this.post("/v3/digital-document-fields/edit", body)
        return get(response, "data", {})
    }

    async updateTask(id, data, params = {}) {
        data.taskId = id
        const response = await this.post(`/v3/tasks/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteTask(taskIds) {
        const response = await this.post(`/v3/tasks/delete`, {taskIds})
        return get(response, "data", {})
    }

    async answerTask(body = {}, queryParams = "") {
        const response = await this.post(`/v3/tasks/answer${queryParams ? `?${queryParams}` : ""}`, body)
        return get(response, "data", {})
    }

    async answerScanTask(body = {}, queryParams = "") {
        const response = await this.post(`/v3/tasks/answer-scan${queryParams ? `?${queryParams}` : ""}`, body)
        return get(response, "data", {})
    }

    async rejectTaskUser(body = {}) {
        const response = await this.post(`/v3/task-users/reject`, body)
        return get(response, "data", {})
    }

    async resendTaskUser(body = {}) {
        const response = await this.post(`/v3/task-users/resend`, body)
        return get(response, "data", {})
    }

    async remarkTaskUser(body = {}) {
        const response = await this.post(`/v3/task-users/remark`, body)
        return get(response, "data", {})
    }

    async remindTaskUser(body = {}) {
        const response = await this.post(`/v3/task-users/remind`, body)
        return get(response, "data", {})
    }

    async reassignTaskUser(body = {}, queryParams = "") {
        const response = await this.post(`/v3/task-users/reassign${queryParams ? `?${queryParams}` : ""}`, body)
        return get(response, "data", {})
    }

    async approveSubTask(body = {}) {
        const response = await this.post(`/v3/subtasks/approve`, body)
        return get(response, "data", {})
    }

    async rejectSubTask(body = {}) {
        const response = await this.post(`/v3/subtasks/reject`, body)
        return get(response, "data", {})
    }

    async getRejectedSubtasks(body = {}) {
        const response = await this.post(`/v3/subtasks/rejected`, body)
        return get(response, "data", {})
    }

    async getRejectedTaskUsers(body = {}) {
        const response = await this.post(`/v3/task-users/rejected`, body)
        return get(response, "data", {})
    }

    async remindSubTask(body = {}) {
        const response = await this.post(`/v3/subtasks/remind`, body)
        return get(response, "data", {})
    }

    async getSubTasksDashboard(body) {
        const response = await this.post("/v3/subtasks/dashboard", body)
        return get(response, "data.data", [])
    }

    async getSubTasks(taskId, params: any = {filter: {}}) {
        params.filter = {...params.filter, taskId}
        const response = await this.post("/v3/subtasks/list", params)
        return get(response, "data", [])
    }

    async getNextCode(body = {}) {
        const response = await this.post(`/v3/tasks/next-code`, body)
        return get(response, "data", {})
    }

    async checkIfCodeIsValid(body = {}) {
        const response = await this.post(`/v3/tasks/check-code`, body)
        return get(response, "data", {})
    }

    async userMerge(data) {
        const response = await this.post(`/v3/user-merge`, data)
        return get(response, "data", [])
    }

    async getCustomDigitalTasks(body) {
        const response = await this.post("/v3/custom-dd-task/list", body)
        return get(response, "data", [])
    }

    async createCustomDigitalTask(body) {
        const response = await this.post("/v3/custom-dd-task/create", body)
        return get(response, "data.data", {})
    }

    async getCustomDigitalTask(id: number) {
        const response = await this.post("/v3/custom-dd-task/details", {taskId: id, linkedEntities: true})
        return get(response, "data.data", {})
    }

    async deleteCustomDigitalTask(taskIds: number[]) {
        const response = await this.post(`/v3/custom-dd-task/delete`, {taskIds})
        return get(response, "data", {})
    }

    async updateCustomDigitalTask(id, data, params = {}) {
        data.taskId = id
        const response = await this.post(`/v3/custom-dd-task/edit`, data, params)
        return get(response, "data", {})
    }

    async assignCustomDigitalTask(body) {
        const response = await this.post("/v3/custom-dd-task/assign", body)
        return get(response, "data", {})
    }

    async getCustomDigitalTasksOfStudent(body) {
        const response = await this.post("/v3/custom-dd-task/list-by-students", body)
        return get(response, "data.data", {})
    }

    async remindCustomDigitalTask(body) {
        const response = await this.post("/v3/custom-dd-task/remind", body)
        return get(response, "data", {})
    }

    async answerCustomDigitalTask(body = {}, queryParams = "") {
        const response = await this.post(`/v3/custom-dd-task/answer${queryParams ? `?${queryParams}` : ""}`, body)
        return get(response, "data", {})
    }

    async approveCustomDigitalTask(body) {
        const response = await this.post("/v3/custom-dd-task/approve", body)
        return get(response, "data", {})
    }

    async getCustomDigitalSubTask(body) {
        const response = await this.post("/v3/custom-dd-task/subtask", body)
        return get(response, "data", {})
    }
}

export default TaskService
