/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useState} from "react"

export const useGetGeolocation = ({
    onSuccess,
    onError
}: {
    onSuccess?: (position: GeolocationPosition) => void
    onError?: (error: GeolocationPositionError | Error) => void
}) => {
    const [isLoading, setIsLoading] = useState(false)

    const handleSuccess = (position: GeolocationPosition) => {
        onSuccess(position)
        setIsLoading(false)
    }

    const handleError = (error: GeolocationPositionError | Error) => {
        onError(error)
        setIsLoading(false)
    }

    const getGeolocation = useCallback(async () => {
        try {
            setIsLoading(true)
            const result = await navigator.permissions.query({name: "geolocation"})

            if (["prompt", "granted"].includes(result.state)) {
                navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
                    enableHighAccuracy: true,
                    maximumAge: 0
                })
            } else {
                setIsLoading(false)
            }
        } catch (error: any) {
            handleError(error)
        }
    }, [handleSuccess, handleError])

    return {getGeolocation, isLoading}
}
