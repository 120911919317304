import React from "react"
import {BaseButton, SecondaryButton} from "components/buttons"
import {Icon} from "components/Icon"
import {TaskType} from "types/tasks"
import {useVisible, useModel} from "hooks"
import {TaskSubmissionDisclosureConfirm, ScanUploadTaskPopup, RejectTaskPopup} from ".."
import styles from "./TaskAnswerHeader.module.css"
import {useHistory} from "react-router-dom"

export function TaskAnswerHeader(props) {
    const {taskData, signerUser, openedSubTaskId, onClose, backUrl} = props
    const model = useModel()
    const history = useHistory()
    const rejectTaskPopup = useVisible(false)
    const scanUploadPopup = useVisible(false)
    const taskSubmissionDisclosureConfirmPopup = useVisible(false)
    const subTaskId = openedSubTaskId || signerUser?.subTaskId || ""

    const onCloseRejectTaskPopup = () => {
        rejectTaskPopup.close()
        if (onClose) {
            onClose()
        }
        if (model.isSubmittingTaskFromEmail) {
            history.push(`/closing`, {
                closeSessionStorage: true,
                closeLocalStorage: false
            })
        }
    }

    const onCloseScanUploadPopup = (isSubmitted = false) => {
        scanUploadPopup.close()
        if (onClose) {
            onClose()
        }
        if (model.isSubmittingTaskFromEmail) {
            history.push(`/closing`, {
                text: isSubmitted ? "The task has been submitted successfully." : "The task was not answered",
                closeSessionStorage: true,
                closeLocalStorage: false
            })
        }
    }

    const onClickOpenTask = () => {
        if (
            taskData.type === TaskType.DIGITAL_DOCUMENT ||
            taskData.type === TaskType.FORM ||
            taskData.type === TaskType.CUSTOM
        ) {
            taskSubmissionDisclosureConfirmPopup.open()
        } else {
            scanUploadPopup.open()
        }
    }

    return (
        <div className={styles.studentHeaderAction}>
            <div className={styles.studentHeaderActionInfo}>
                <Icon icon="INFO" color="#FF349B" />
                <p className={styles.studentHeaderAction__title}>It’s your turn to complete the task</p>
            </div>
            <div className={styles.studentHeaderActionWrap}>
                <SecondaryButton title="Reject" className={styles.rejectBtn} onClick={() => rejectTaskPopup.open()} />
                <BaseButton
                    title={
                        taskData.type === TaskType.DIGITAL_DOCUMENT ||
                        taskData.type === TaskType.FORM ||
                        taskData.type === TaskType.CUSTOM
                            ? `Open Task`
                            : `Scan/Upload`
                    }
                    className={styles.openTaskBtn}
                    onClick={onClickOpenTask}
                />
            </div>
            <RejectTaskPopup
                isShow={rejectTaskPopup.isVisible}
                onClose={onCloseRejectTaskPopup}
                taskUserIds={signerUser ? [signerUser.id] : []}
                taskData={taskData}
                subTaskId={subTaskId}
            />
            <ScanUploadTaskPopup
                isShow={scanUploadPopup.isVisible}
                onClose={onCloseScanUploadPopup}
                taskId={taskData.id}
                subTaskId={subTaskId}
                taskUserId={signerUser ? signerUser.id : ""}
                taskUserEncodedSecurityLevelWeb={signerUser ? signerUser.encodedSecurityLevelWeb : ""}
                model={model}
            />
            <TaskSubmissionDisclosureConfirm
                isShow={taskSubmissionDisclosureConfirmPopup.isVisible}
                onClose={() => taskSubmissionDisclosureConfirmPopup.close()}
                taskId={taskData.id}
                subTaskId={subTaskId}
                backUrl={backUrl}
            />
        </div>
    )
}
