import {useCallback} from "react"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {fundSourcesService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {Order} from "types/common"
import {FundSource} from "types/fin-aid/fund-source"

const queryCacheKey = "all-fund-sources"
const INITIAL_DATA: FundSource[] = []

export const useAllFundSources = () => {
    const queryClient = useQueryClient()

    const query = useQuery(
        [queryCacheKey],
        async () => {
            const {data} = await fundSourcesService.getFundSources({
                filter: {},
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                sort: {
                    orderBy: "position",
                    orderDir: Order.Asc
                }
            })
            return data
        },
        {
            refetchOnMount: false
        }
    )
    return {
        fundSources: query.data ?? INITIAL_DATA,
        invalidateFundSourcesCache: useCallback(() => {
            queryClient.invalidateQueries({queryKey: [queryCacheKey]})
        }, [queryClient])
    }
}
