import React from "react"

import {BaseDepartmentId} from "types/departments"
import {Permissions} from "types/permission"
import {RouteWithPermissionsRequired} from "./RouteWithPermissionsRequired"
import {routing} from "../helpers/routing"
import ConcernCreate from "sections/Concerns/ConcernCreate"
import AddGrading from "sections/Settings/Academics/parts/GradingSystem/AddGrading"
import {Modules, useEdularModulesContext} from "@edular/modules"
import Chat from "sections/Settings/Chat"

// Admissions
const Admissions = React.lazy(() => import("sections/Admission"))
const AdmissionTargetDetail = React.lazy(() => import("sections/Admission/parts/Targets/AdmissionTargetDetail"))
const AdmissionStaff = React.lazy(() => import("sections/Admission/AdmissionStaff"))
const AptitudeTestList = React.lazy(() => import("sections/Admission/AptitudeTestList"))
const AptitudeTestDetail = React.lazy(() => import("sections/Admission/AptitudeTestDetail"))
const ReportsList = React.lazy(() => import("sections/Admission/ReportsList"))
const AdmissionManagementReport = React.lazy(() => import("sections/Admission/AdmissionManagementReport"))
const Reports = React.lazy(() => import("sections/Admin/ReportsList"))
const UsersReport = React.lazy(() => import("sections/Admin/UsersReport"))
const CoursesReport = React.lazy(() => import("sections/Admin/CoursesReport"))
const MajorsReport = React.lazy(() => import("sections/Admin/MajorsReport"))
const TermsReport = React.lazy(() => import("sections/Admin/TermsReport"))
const TermCoursesReport = React.lazy(() => import("sections/Admin/TermCoursesReport"))
const TermMajorsReport = React.lazy(() => import("sections/Admin/TermMajorsReport"))
const MajorVersionsReport = React.lazy(() => import("sections/Admin/MajorsVersionsReport"))
const IdBatchReport = React.lazy(() => import("sections/Admin/IdBatchReport"))
const AzureEmailCreationReport = React.lazy(() => import("sections/Admin/AzureEmailCreationReport"))
const IntegrationErrorsReport = React.lazy(() => import("sections/Admin/IntegrationErrorsReport"))
const SmsLogReport = React.lazy(() => import("sections/Admin/SmsLogReport"))

const TenNinetyEightReport = React.lazy(() => import("sections/StudentAccount/reports/TenNinetyEight"))
const GradeExport = React.lazy(() => import("sections/Admin/GradeExport"))
const GradeGenesisExport = React.lazy(() => import("sections/Admin/GradeGenesisExport"))
const CourseGradesExport = React.lazy(() => import("sections/Admin/CourseGradesExport"))
const IncompleteCourseExport = React.lazy(() => import("sections/Admin/IncompleteCourseExport"))
const TasksReport = React.lazy(() => import("sections/Admin/TaskReport"))
const StudentPaymentsReport = React.lazy(() => import("sections/Admin/StudentPaymentsReport"))
const StudentChargesReport = React.lazy(() => import("sections/Admin/StudentChargesReport"))
const EnrollmentAgreementReport = React.lazy(() => import("sections/Admin/EnrollmentAgreementReport"))
const NaccasAnnualReport = React.lazy(() => import("sections/Admin/NaccasReports/NaccasAnnualReport"))
const NaccasAnnualExemptedStudentListReport = React.lazy(
    () => import("sections/Admin//NaccasReports/NaccasAnnualExemptedStudentListReport")
)
const NaccasAnnualEnrollmentCounts = React.lazy(
    () => import("sections/Admin/NaccasReports/NaccasAnnualEnrollmentCounts")
)
const NaccasAnnualSummaryReport = React.lazy(() => import("sections/Admin/NaccasReports/NaccasAnnualSummaryReport"))
const BppeAnnualAllGradsReport = React.lazy(() => import("sections/Admin/NaccasReports/BppeAnnualAllGradsReport"))
const BppeAnnualStartDateReport = React.lazy(() => import("sections/Admin/NaccasReports/BppeAnnualStartDateReport"))
const BppeAnnualGradsByContractedDate = React.lazy(
    () => import("sections/Admin/NaccasReports/BppeAnnualGradsByContractedDate")
)
const BPPEAnnualTitleIVAudit = React.lazy(() => import("sections/Admin/NaccasReports/BPPEAnnualTitleIVAudit"))
const Tasks = React.lazy(() => import("sections/Tasks"))

// Academic
const RegistrarStaff = React.lazy(() => import("sections/academics/registrar/RegistrarStaff"))
const AttendanceReportDetail = React.lazy(() => import("sections/academics/attendance-reports/AttendanceReportDetail"))
const AcademicsAttendanceReports = React.lazy(() => import("sections/academics/attendance-reports/AttendanceReports"))
const ClassAttendanceReportWeekly = React.lazy(() => import("sections/Admin/ClassAttendanceReportWeekly"))
const AcademicsTermCodeIntegrationReports = React.lazy(
    () => import("sections/academics/term-code-integration-reports/Reports")
)
const AcademicsAttendanceChange = React.lazy(
    () => import("sections/academics/attendance-change-reports/AttendanceChangeReport")
)
const LegacyTestStaff = React.lazy(() => import("sections/academics/LegacyTest/LegacyTestStaff"))
const LegacyTestDetail = React.lazy(() => import("sections/academics/LegacyTest/legacyTestDetail"))
const EducatorLegacyTest = React.lazy(() => import("sections/academics/EducatorLegacyTest/EducatorLegacyTest"))
const EducatorLegacyTestDetail = React.lazy(
    () => import("sections/academics/EducatorLegacyTest/EducatorLegacyTestDetail")
)
const Practical = React.lazy(() => import("sections/academics/Practical/Practical"))
const AcademicRetentions = React.lazy(() => import("sections/Retentions"))
const AcademicD2lSemesterReport = React.lazy(() => import("sections/Admin/D2LReports/D2LSemesterReport"))
const AcademicD2lCourseOfferingReport = React.lazy(() => import("sections/Admin/D2LReports/D2LCourseOfferingReport"))
const AcademicD2lUsersReport = React.lazy(() => import("sections/Admin/D2LReports/D2LUsersReport"))
const AcademicClassroomAttendance = React.lazy(() => import("sections/Admin/ClassroomAttendanceReport"))
const AcademicDailyLesson = React.lazy(() => import("sections/Admin/DailyLessonReport"))
const AcademicTemporaryOut = React.lazy(() => import("sections/Admin/AcademicTemporaryOut/AcademicTemporaryOut"))
const AcademicPermanentOut = React.lazy(() => import("sections/Admin/AcademicPermanentOut/AcademicPermanentOut"))
const UnofficialTranscript = React.lazy(() => import("sections/Admin/UnofficialTranscript"))
const AcademicDailyAttendanceFrame = React.lazy(
    () => import("sections/Admin/AcademicDailyAttendanceFrame/AcademicDailyAttendanceFrame")
)
const AcademicTermCourseSchedule = React.lazy(
    () => import("sections/Admin/AcademicTermCourseSchedule/AcademicTermCourseSchedule")
)
const TermRosterReport = React.lazy(() => import("sections/Admin/TermRosterReport/TermRosterReport"))
const SapReport = React.lazy(() => import("sections/Admin/SapReport/SapReport"))
const AcademicMissedAttendance = React.lazy(() => import("sections/Admin/MissedAttendanceReport"))
const CourseRegistrationReport = React.lazy(() => import("sections/Admin/CourseRegistrationReport"))
const MajorVersionDegreeRequirementReport = React.lazy(
    () => import("sections/Admin/MajorVersionDegreeRequirementReport")
)
const StudentLdaReport = React.lazy(() => import("sections/Admin/StudentLdaReport"))
const AcademicUndergradMastersSAPReport = React.lazy(() => import("sections/Admin/UndergradMastersSAPReport"))
const AdmissionsLeadSourceReport = React.lazy(() => import("sections/Admin/AdmissionsLeadReports/LeadSourceReport"))
const LeadSourceReportNew = React.lazy(() => import("sections/Admin/AdmissionsLeadReports/LeadSourceReportNew"))
const LeadAdvisorReportNew = React.lazy(() => import("sections/Admin/AdmissionsLeadReports/LeadAdvisorReportNew"))
const AdmissionsLeadAdvisorReport = React.lazy(() => import("sections/Admin/AdmissionsLeadReports/LeadAdvisorReport"))
const QRScanPage = React.lazy(() => import("sections/Attendance/parts/MakeupAttendance/QRScan"))
const CareerServicesPlacementRatesSummaryReport = React.lazy(
    () => import("sections/Admin/CareerServicesReports/PlacementRatesSummary")
)
const CareerServicesPlacementRatesDetailsReport = React.lazy(
    () => import("sections/Admin/CareerServicesReports/PlacementRatesDetails")
)
const CareerServicesStudentGraduationReport = React.lazy(
    () => import("sections/Admin/CareerServicesReports/StudentGraduation")
)
const CareerServicesPlacementVerificationReport = React.lazy(
    () => import("sections/Admin/CareerServicesReports/PlacementVerification")
)

// Student Services
const StudentServices = React.lazy(() => import("sections/StudentServices/StudentServicesStaff/StudentServicesStaff"))

// Attendance
const AttendanceSideMenu = React.lazy(() => import("sections/AttendanceSideMenu"))

// Student Account
const StudentAccountSettings = React.lazy(() => import("sections/Settings/StudentAccount/StudentAccountSettings"))
const ChartOfAccountDetail = React.lazy(
    () => import("sections/Settings/StudentAccount/parts/LedgerAccounts/parts/ChartOfAccounts/ChartOfAccountDetail")
)
const ProgramPaymentPeriods = React.lazy(
    () => import("sections/Settings/StudentAccount/parts/PaymentPeriodManagement/parts/ProgramPaymentPeriods")
)

// General
const CourseLevels = React.lazy(() => import("sections/CourseLevels"))
const DegreeLevel = React.lazy(() => import("sections/DegreeLevel"))
const DegreeLevelDetail = React.lazy(() => import("sections/DegreeLevel/DegreeLevelDetail"))
const Departments = React.lazy(() => import("sections/Departments"))
const DepartmentDetails = React.lazy(() => import("sections/Departments/DepartmentDetails"))
const Terms = React.lazy(() => import("sections/Terms"))
const TermDetail = React.lazy(() => import("sections/Terms/TermDetail"))
const TermCalendarPreview = React.lazy(() => import("sections/Terms/TermCalendarPreview"))
const Workflows = React.lazy(() => import("sections/Settings/Workflows"))
const WorkflowDetail = React.lazy(() => import("sections/Settings/Workflows/WorkflowDetail"))
const ContactStrategyDetail = React.lazy(
    () => import("sections/Settings/Workflows/ContactStrategy/ContactStrategyDetail")
)
const TermCourseSchedule = React.lazy(() => import("sections/Terms/TermCourseSchedule"))
const CostOfAttendanceDetail = React.lazy(
    () => import("sections/Terms/parts/Detail/CostOfAttendance/CostOfAttendanceDetail")
)
const GradeDetail = React.lazy(() => import("sections/Terms/parts/Detail/Grade/GradeDetail"))
const Courses = React.lazy(() => import("sections/Courses"))
const CourseDetail = React.lazy(() => import("sections/Courses/CourseDetail"))
const Majors = React.lazy(() => import("sections/Majors"))
const MajorDetail = React.lazy(() => import("sections/Majors/MajorDetail"))
const Campuses = React.lazy(() => import("sections/Campuses"))
const CampusDetail = React.lazy(() => import("sections/Campuses/CampusDetail"))
const CheckListDetail = React.lazy(() => import("sections/Settings/CheckList/CheckListDetail"))
const RetentionDetail = React.lazy(() => import("sections/Settings/Academics/parts/RetentionDetail/RetentionDetail"))
const ExternshipStudents = React.lazy(() => import("sections/Externship/ExternshipStudents"))
const ExternshipSettings = React.lazy(() => import("sections/Externship"))
const Resources = React.lazy(() => import("sections/Resources"))
const ResourceDetail = React.lazy(() => import("sections/Resources/ResourceDetail"))
const ResourcesTypes = React.lazy(() => import("sections/ResourcesTypes"))
const ResourceTypeDetail = React.lazy(() => import("sections/ResourcesTypes/ResourceTypeDetail"))
const CalendarSettings = React.lazy(() => import("sections/CalendarSettings"))
const WorkScheduleDetails = React.lazy(() => import("sections/CalendarSettings/parts/WorkScheduleDetails"))
const CustomDetails = React.lazy(() => import("sections/CalendarSettings/parts/CustomDetails"))
const EventTypeDetails = React.lazy(() => import("sections/CalendarSettings/parts/EventTypeDetails"))
const StudentCourses = React.lazy(() => import("sections/development/StudentCourses"))
const Development = React.lazy(() => import("sections/development/Development"))
const SAP = React.lazy(() => import("sections/Settings/SAP"))
const SMS = React.lazy(() => import("sections/Settings/SMS"))
const Groups = React.lazy(() => import("sections/Settings/Groups"))
const GroupDetails = React.lazy(() => import("sections/Settings/Groups/GroupDetails"))
const DocumentSettings = React.lazy(() => import("sections/Settings/DocumentSettings"))
const DocumentTypeDetails = React.lazy(() => import("sections/Settings/DocumentTypes/DocumentTypeDetails"))
const DocumentTemplateDetails = React.lazy(() => import("sections/Settings/DocumentTemplates/DocumentTemplateDetails"))
const Tags = React.lazy(() => import("sections/Tags"))
const StudentStatuses = React.lazy(() => import("sections/StudentStatuses"))
const TagDetail = React.lazy(() => import("sections/Tags/TagDetail"))
const UsersPage = React.lazy(() => import("sections/users/UsersTab"))
const ImportUsers = React.lazy(() => import("sections/users/ImportUsers"))
const IndividualDetails = React.lazy(() => import("sections/users/Detail/parts/Ferpa/parts/IndividualDetail"))
const InformationRequestDetail = React.lazy(
    () => import("sections/users/Detail/parts/Ferpa/parts/InformationRequestDetail")
)
const PermissionGroupsDetail = React.lazy(() => import("sections/RolePermission/parts/PermissionGroupsDetail"))
const RoleDetail = React.lazy(() => import("sections/RolePermission/parts/RoleDetail"))
const Academics = React.lazy(() => import("sections/Settings/Academics"))
const RolePermission = React.lazy(() => import("sections/RolePermission"))
const Branding = React.lazy(() => import("sections/Branding"))
const MediaPrivacy = React.lazy(() => import("sections/MediaPrivacy"))
const Activities = React.lazy(() => import("sections/Activities"))
const ActivityDetail = React.lazy(() => import("sections/shared/student/ActivityDetail"))
const AppReleases = React.lazy(() => import("sections/superAdmin/AppReleases"))
const Concerns = React.lazy(() => import("sections/Concerns"))
const ConcernDetails = React.lazy(() => import("sections/Concerns/ConcernDetails"))
const ExternalInstitutions = React.lazy(() => import("sections/Settings/ExternalInstitutions"))
const HighschoolDetail = React.lazy(
    () => import("sections/Settings/ExternalInstitutions/parts/Highschools/HighschoolDetail")
)

const ConcernReportsList = React.lazy(() => import("sections/Concerns/parts/ReportsList"))
const Fields = React.lazy(() => import("sections/Fields"))
const CustomField = React.lazy(() => import("sections/CustomField"))
const UserMerge = React.lazy(() => import("sections/UserMerge"))
const Templates = React.lazy(() => import("sections/Settings/Templates"))
const ForDevelopers = React.lazy(() => import("sections/ForDevelopers"))
const EmailTemplateDetail = React.lazy(
    () => import("sections/Settings/Templates/parts/EmailTemplates/EmailTemplateDetail")
)
const SMSTemplateDetail = React.lazy(() => import("sections/Settings/Templates/parts/SMSTemplates/SMSTemplateDetail"))

// Career Service
const CareerServices = React.lazy(() => import("sections/CareerServices"))
const CareerServicesEmployerDetail = React.lazy(() => import("sections/CareerServicesEmployerDetail"))
const CareerServicesJobDetail = React.lazy(() => import("sections/CareerServicesJobDetail"))
const CareerServicesJobEdit = React.lazy(() => import("sections/CareerServicesJobEdit"))
const CareerServicesApplicationDetail = React.lazy(() => import("sections/CareerServicesApplicationDetail"))

// IPEDS reports
const IPEDSReport = React.lazy(() => import("sections/Admin/IPEDSReport"))
const CompletionsForAllInstitutions = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/Completions/CompletionsForAllInstitutions")
)
const FourYearDegreeGranting = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/TwelveMonthEnrollment/FourYearDegreeGranting")
)
const TwoYearDegreeGranting = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/TwelveMonthEnrollment/TwoYearDegreeGranting")
)
const PublicNonDegreeGranting = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/TwelveMonthEnrollment/PublicNonDegreeGranting")
)
const PrivateNonDegreeGranting = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/TwelveMonthEnrollment/PrivateNonDegreeGranting")
)
const PublicAcademicFallCohort = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/StudentFinancialAid/PublicAcademicFallCohort")
)
const PrivateAcademicFallCohort = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/StudentFinancialAid/PrivateAcademicFallCohort")
)
const PublicProgramFullYearCohort = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/StudentFinancialAid/PublicProgramFullYearCohort")
)
const PrivateProgramFullYearCohort = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/StudentFinancialAid/PrivateProgramFullYearCohort")
)
const GraduatesStudentOnly = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/StudentFinancialAid/GraduatesStudentsOnly")
)
const GraduationRatesInstitutions = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/GraduationRatesInstitutions")
)
const GraduationRatesLessThan2YearInstitutionsFallCohort = React.lazy(
    () =>
        import("sections/Admin/IPEDSReport/parts/GraduationRates/GraduationLessThanTwoYearInstitutionReportFallCohort")
)
const GraduationRatesLessThan2YearInstitutionsFullCohort = React.lazy(
    () =>
        import("sections/Admin/IPEDSReport/parts/GraduationRates/GraduationLessThanTwoYearInstitutionReportFullCohort")
)
const GraduationRates2YearInstitutionsFullCohort = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/GraduationRates/GraduationTwoYearInstitutionReportFullCohort")
)
const GraduationRates2YearInstitutionsFallCohort = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/GraduationRates/GraduationTwoYearInstitutionReportFallCohort")
)
const GraduationRates4YearInstitutionsFullCohort = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/GraduationRates/GraduationFourYearInstitutionReportFullCohort")
)
const GraduationRates4YearInstitutionsFallCohort = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/GraduationRates/GraduationFourYearInstitutionReportFallCohort")
)
const CostOneForAcademicYearTuitionReporters = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/CostOne/CostOneForAcademicYearTuitionReporters")
)
const CostOneForProgramTuitionReporters = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/CostOne/CostOneForProgramTuitionReporters")
)
const CostTwoForAcademicYearTuitionReporters = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/CostTwo/CostTwoForAcademicYearTuitionReporters")
)
const CostTwoForProgramTuitionReporters = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/CostTwo/CostTwoForProgramTuitionReporters")
)
const OutcomeMeasures = React.lazy(() => import("sections/Admin/IPEDSReport/parts/OutcomeMeasures"))
const FallEnrollment4Years = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/FallEnrollmentReports/FallEnrollment4Years")
)
const FallEnrollment2Years = React.lazy(
    () => import("sections/Admin/IPEDSReport/parts/FallEnrollmentReports/FallEnrollment2Years")
)
const IpedsAdmissions = React.lazy(() => import("sections/Admin/IPEDSReport/parts/Admissions"))
const FallEnrollment = React.lazy(() => import("sections/Admin/IPEDSReport/parts/FallEnrollment"))
const GraduationRates = React.lazy(() => import("sections/Admin/IPEDSReport/parts/GraduationRates200"))

const AutoDialer = React.lazy(() => import("sections/AutoDialer"))
const LeadBucket = React.lazy(() => import("sections/LeadBucket"))

export function StaffRoutes(props) {
    const {model} = props
    const {isLoading} = useEdularModulesContext()
    if (isLoading) {
        return null
    }

    if (!model.isStaffOrAdmin()) {
        return null
    }

    const isProduction = process.env.REACT_APP_NODE_ENV === "production"
    return (
        <>
            {/* Admission */}
            <RouteWithPermissionsRequired
                exact
                model={model}
                path="/settings/admissions"
                component={Admissions}
                module={Modules.Admissions}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.Admissions.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/admissions/checklist/detail"
                model={model}
                departmentId={BaseDepartmentId.Admissions}
                component={CheckListDetail}
                module={Modules.Admissions}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.Admissions.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/admissions/targets/detail"
                model={model}
                component={AdmissionTargetDetail}
                module={Modules.Admissions}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.Admissions.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/admissions/staff"
                model={model}
                component={AdmissionStaff}
                module={Modules.Admissions}
                permissions={{
                    staff: [
                        Permissions.Staff.Admissions.AdmissionsStaff.View,
                        Permissions.Staff.Admissions.Students.AdmissionsStudentDetails.View
                    ],
                    student: [Permissions.Student.Admissions.View],
                    others: [Permissions.Others.Admissions.View]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                path="/admissions/aptitude-test"
                backUrl="/admissions/staff"
                module={Modules.AdmissionsAptitudeTest}
                component={AptitudeTestList}
            />
            <RouteWithPermissionsRequired
                exact
                path="/admissions/aptitude-test/details/:id"
                backUrl="/admissions/aptitude-test"
                module={Modules.AdmissionsAptitudeTest}
                component={AptitudeTestDetail}
            />
            <RouteWithPermissionsRequired
                exact
                path="/admissions/reports"
                backUrl="/admissions/staff"
                module={Modules.Admissions}
                component={ReportsList}
            />
            <RouteWithPermissionsRequired
                exact
                path="/admissions/reports/management-report"
                backUrl="/admissions/reports"
                module={Modules.Admissions}
                component={AdmissionManagementReport}
            />
            {/** Academics */}
            <RouteWithPermissionsRequired
                exact
                path="/academics/registrar/staff"
                model={model}
                module={Modules.Academic}
                component={RegistrarStaff}
                permissions={{
                    staff: [
                        Permissions.Staff.Academics.Registrar.Workspace.View,
                        Permissions.Staff.Academics.Registrar.Students.WorkspaceStudent.View,
                        Permissions.Staff.Academics.Registrar.Students.TermsAndCourses.View,
                        Permissions.Staff.Academics.Registrar.Students.AcademicPlanning.View,
                        Permissions.Staff.Academics.Registrar.Students.DegreeAudit.View,
                        Permissions.Staff.Academics.Registrar.Students.OfficialTranscript.View,
                        Permissions.Staff.Academics.Registrar.Students.ProgressReport.View,
                        Permissions.Staff.Academics.Registrar.Attendance.View
                    ]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academics/retentions"
                model={model}
                module={Modules.Academic}
                component={AcademicRetentions}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academic-affairs/retentions"
                model={model}
                module={Modules.Academic}
                component={AcademicRetentions}
            />
            <RouteWithPermissionsRequired
                exact
                path="/qr/make-up"
                model={model}
                module={Modules.Academic}
                component={QRScanPage}
            />

            {/** Student Services */}

            <RouteWithPermissionsRequired
                exact
                path="/student-services/staff"
                model={model}
                component={StudentServices}
                module={Modules.StudentServices}
                departmentId={BaseDepartmentId.StudentServices}
                permissions={{
                    staff: [Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Workspace.View]
                }}
            />

            {/** Attendance */}

            <RouteWithPermissionsRequired exact path="/attendance" model={model} component={AttendanceSideMenu} />

            {/** Academic Affairs */}

            <RouteWithPermissionsRequired
                exact
                path="/academic-affairs/staff"
                model={model}
                component={StudentServices}
                module={Modules.AcademicAffair}
                departmentId={BaseDepartmentId.Academics}
                permissions={{
                    staff: [Permissions.Staff.AcademicAffairs.StudentServices.Workspace.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/course-levels"
                model={model}
                component={CourseLevels}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.CourseLevels.View]
                }}
            />

            {/* Degree Level */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/degree-level"
                model={model}
                component={DegreeLevel}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.DegreeLevel.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/degree-level/detail"
                model={model}
                component={DegreeLevelDetail}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.DegreeLevel.View]
                }}
            />

            {/* Department */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/departments"
                model={model}
                component={Departments}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Department.View]
                }}
            />
            <RouteWithPermissionsRequired
                path="/settings/departments/details/:departmentId?"
                model={model}
                component={DepartmentDetails}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Department.View]
                }}
            />
            {/* Document Settings */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/documents"
                model={model}
                component={DocumentSettings}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Groups.View]
                }}
            />
            <RouteWithPermissionsRequired
                path="/settings/documents/details/:documentTypeId?"
                model={model}
                component={DocumentTypeDetails}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Groups.View]
                }}
            />
            <RouteWithPermissionsRequired
                path="/settings/documents/templates/:id?"
                model={model}
                component={DocumentTemplateDetails}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Groups.View]
                }}
            />

            {/* Fields */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/fields"
                model={model}
                component={Fields}
                permissions={{
                    staff: [Permissions.Staff.Settings.SystemSettings.Fields.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/custom-field"
                model={model}
                component={CustomField}
                permissions={{
                    staff: [Permissions.Staff.Settings.SystemSettings.Fields.View]
                }}
            />

            {/* Terms */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/terms"
                model={model}
                component={Terms}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Terms.General.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/terms/detail"
                model={model}
                component={TermDetail}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Terms.General.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/terms/detail/:termId/program-calendar/:majorVersionId"
                model={model}
                component={TermCalendarPreview}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Terms.General.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/terms/:termId/schedules"
                model={model}
                component={TermCourseSchedule}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Terms.General.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/terms/:termId/coa/detail"
                model={model}
                component={CostOfAttendanceDetail}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Terms.General.View]
                }}
            />
            <RouteWithPermissionsRequired exact path="/settings/sap" model={model} component={SAP} />
            <RouteWithPermissionsRequired
                exact
                path="/settings/sms"
                model={model}
                component={SMS}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Sms.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/terms/:termId/grade/detail"
                model={model}
                component={GradeDetail}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Terms.General.View]
                }}
            />

            {/* Workflow */}
            <RouteWithPermissionsRequired
                exact
                path={routing.settings.workflow.index()}
                component={Workflows}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Workflow.View]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                path={routing.settings.workflow.detailPath()}
                component={WorkflowDetail}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Workflow.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path={routing.settings.workflow.contactStrategyDetailPath}
                component={ContactStrategyDetail}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Workflow.View]
                }}
            />

            {/* Major */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/majors"
                model={model}
                component={Majors}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Majors.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/majors/detail"
                model={model}
                component={MajorDetail}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Majors.View]
                }}
            />

            {/* Course */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/courses"
                model={model}
                component={Courses}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Courses.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/courses/detail"
                model={model}
                component={CourseDetail}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Courses.View]
                }}
            />

            {/* Campus */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/campuses"
                model={model}
                component={Campuses}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Campus.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/campuses/detail"
                model={model}
                component={CampusDetail}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Campus.View]
                }}
            />

            {/* Externship */}
            <RouteWithPermissionsRequired
                exact
                path="/academics/externship/students"
                model={model}
                module={Modules.AcademicExternship}
                component={ExternshipStudents}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/externship"
                model={model}
                module={Modules.AcademicExternship}
                component={ExternshipSettings}
                permissions={{
                    staff: [Permissions.Staff.Academics.Externship.View]
                }}
            />

            {/* Chat settings */}
            <RouteWithPermissionsRequired exact path="/settings/chat" model={model} component={Chat} />

            {/* Resources */}
            <RouteWithPermissionsRequired exact path="/resources" model={model} component={Resources} />
            <RouteWithPermissionsRequired exact path="/resources/detail" model={model} component={ResourceDetail} />

            {/* Resources Types*/}
            <RouteWithPermissionsRequired
                exact
                path="/settings/resources-types"
                model={model}
                component={ResourcesTypes}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/resources-types/detail"
                model={model}
                component={ResourceTypeDetail}
            />

            {/* Student Course */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/student-courses"
                model={model}
                component={StudentCourses}
            />

            {/* groups */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/groups"
                model={model}
                component={Groups}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Groups.View]
                }}
            />
            <RouteWithPermissionsRequired
                path="/settings/groups/details/:groupId?"
                model={model}
                component={GroupDetails}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Groups.View]
                }}
            />

            {/* Users */}
            <RouteWithPermissionsRequired exact path="/users" model={model} component={UsersPage} />
            <RouteWithPermissionsRequired
                path="/users/:userId/authorized-individuals/detail"
                model={model}
                component={IndividualDetails}
            />

            <RouteWithPermissionsRequired
                path="/users/:userId/information-request/detail"
                model={model}
                component={InformationRequestDetail}
            />
            <RouteWithPermissionsRequired
                exact
                path="/data-imports/:importType"
                model={model}
                component={ImportUsers}
            />
            <RouteWithPermissionsRequired
                exact
                path="/data-imports/:importType/:id"
                model={model}
                component={ImportUsers}
            />

            {/* Admin Reports */}
            <RouteWithPermissionsRequired
                exact
                path="/reports"
                module={Modules.Reports}
                component={Reports}
                permissions={{
                    staff: [
                        Permissions.Staff.Reports.AdminReports.ComplianceReports.View,
                        Permissions.Staff.Reports.AdminReports.ComplianceReports.View,
                        Permissions.Staff.Reports.AdminReports.GeneralReports.View,
                        Permissions.Staff.Reports.AdminReports.IpedsReports.View,
                        Permissions.Staff.Reports.Academics.General.View,
                        Permissions.Staff.Reports.Academics.ClassAttendance.View,
                        Permissions.Staff.Reports.Academics.ClassAttendance.PermissionsToAccessOthers.View,
                        Permissions.Staff.Reports.Admissions.General.View,
                        Permissions.Staff.Reports.FinancialAid.General.View,
                        Permissions.Staff.Reports.StudentAccounts.General.View,
                        Permissions.Staff.Reports.StudentServices.General.View
                    ]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports"
                backUrl="/reports"
                module={Modules.Reports}
                component={IPEDSReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/completions/completions-for-all-institutions"
                backUrl="/reports"
                module={Modules.Reports}
                component={CompletionsForAllInstitutions}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/12-month-enrollment/four-year-degree-granting"
                backUrl="/reports"
                module={Modules.Reports}
                component={FourYearDegreeGranting}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/12-month-enrollment/two-year-degree-granting"
                backUrl="/reports"
                module={Modules.Reports}
                component={TwoYearDegreeGranting}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/12-month-enrollment/public-non-degree-granting"
                backUrl="/reports"
                module={Modules.Reports}
                component={PublicNonDegreeGranting}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/12-month-enrollment/private-non-degree-granting"
                backUrl="/reports"
                module={Modules.Reports}
                component={PrivateNonDegreeGranting}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/student-financial-aid/public-academic-fall-cohort"
                backUrl="/reports"
                module={Modules.Reports}
                component={PublicAcademicFallCohort}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/student-financial-aid/private-academic-fall-cohort"
                backUrl="/reports"
                module={Modules.Reports}
                component={PrivateAcademicFallCohort}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/student-financial-aid/public-program-full-year-cohort"
                backUrl="/reports"
                module={Modules.Reports}
                component={PublicProgramFullYearCohort}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/student-financial-aid/private-program-full-year-cohort"
                backUrl="/reports"
                module={Modules.Reports}
                component={PrivateProgramFullYearCohort}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/student-financial-aid/graduate-students-only"
                backUrl="/reports"
                module={Modules.Reports}
                component={GraduatesStudentOnly}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/fall-enrollment-non-degree-granting-institutions"
                backUrl="/reports"
                module={Modules.Reports}
                component={FallEnrollment}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/graduations-rates-institutions-reporting"
                backUrl="/reports"
                module={Modules.Reports}
                component={GraduationRates}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/graduations-rates-institutions-reporting-cohort"
                backUrl="/reports"
                module={Modules.Reports}
                component={GraduationRatesInstitutions}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/graduation-rates/less-than-2-year-fall-cohort"
                backUrl="/reports"
                module={Modules.Reports}
                component={GraduationRatesLessThan2YearInstitutionsFallCohort}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/graduation-rates/less-than-2-year-full-cohort"
                backUrl="/reports"
                module={Modules.Reports}
                component={GraduationRatesLessThan2YearInstitutionsFullCohort}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/graduation-rates/2-year-full-cohort"
                backUrl="/reports"
                module={Modules.Reports}
                component={GraduationRates2YearInstitutionsFullCohort}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/graduation-rates/2-year-fall-cohort"
                backUrl="/reports"
                module={Modules.Reports}
                component={GraduationRates2YearInstitutionsFallCohort}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/graduation-rates/4-year-full-cohort"
                backUrl="/reports"
                module={Modules.Reports}
                component={GraduationRates4YearInstitutionsFullCohort}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/graduation-rates/4-year-fall-cohort"
                backUrl="/reports"
                module={Modules.Reports}
                component={GraduationRates4YearInstitutionsFallCohort}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/cost-1/academic-year-tuition-reporters"
                backUrl="/reports"
                module={Modules.Reports}
                component={CostOneForAcademicYearTuitionReporters}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/cost-1/program-tuition-reporters"
                backUrl="/reports"
                module={Modules.Reports}
                component={CostOneForProgramTuitionReporters}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/cost-2/academic-year-tuition-reporters"
                backUrl="/reports"
                module={Modules.Reports}
                component={CostTwoForAcademicYearTuitionReporters}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/cost-2/program-tuition-reporters"
                backUrl="/reports"
                module={Modules.Reports}
                component={CostTwoForProgramTuitionReporters}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/outcome-measures"
                backUrl="/reports"
                module={Modules.Reports}
                component={OutcomeMeasures}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/fall-enrollment-4-year"
                backUrl="/reports"
                module={Modules.Reports}
                component={FallEnrollment4Years}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/fall-enrollment-2-year"
                backUrl="/reports"
                module={Modules.Reports}
                component={FallEnrollment2Years}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/ipeds-reports/ipeds-admissions"
                backUrl="/reports"
                module={Modules.Reports}
                component={IpedsAdmissions}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/id-batch-report"
                backUrl="/reports"
                module={Modules.Reports}
                component={IdBatchReport}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/integration-errors-report"
                module={Modules.Reports}
                component={IntegrationErrorsReport}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/sms-log"
                module={Modules.Reports}
                component={SmsLogReport}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/azure-email-creation-report"
                module={Modules.Reports}
                component={AzureEmailCreationReport}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/user-report"
                module={Modules.Reports}
                component={UsersReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/courses-report"
                module={Modules.Reports}
                component={CoursesReport}
            />
            <RouteWithPermissionsRequired exact path="/reports/majors-report" component={MajorsReport} />
            <RouteWithPermissionsRequired
                exact
                path="/reports/terms-report"
                module={Modules.Reports}
                component={TermsReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/term-courses-report"
                module={Modules.Reports}
                component={TermCoursesReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/term-majors-report"
                module={Modules.Reports}
                component={TermMajorsReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/majors-versions-report"
                module={Modules.Reports}
                component={MajorVersionsReport}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/1098T-report"
                backUrl="/reports"
                module={Modules.Reports}
                component={TenNinetyEightReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/grade-export"
                backUrl="/reports"
                module={Modules.Reports}
                component={GradeExport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/grade-genesis-export"
                backUrl="/reports"
                module={Modules.Reports}
                component={GradeGenesisExport}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/course-grades-report"
                backUrl="/reports"
                module={Modules.Reports}
                component={CourseGradesExport}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/incomplete-course-report"
                backUrl="/reports"
                module={Modules.Reports}
                component={IncompleteCourseExport}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/task-library-report"
                backUrl="/reports"
                module={Modules.Reports}
                component={TasksReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/task-report"
                backUrl="/reports"
                model={model}
                module={Modules.Reports}
                component={Tasks}
                isExported
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/student-payments-report"
                backUrl="/reports"
                module={Modules.Reports}
                component={StudentPaymentsReport}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/student-charges-report"
                backUrl="/reports"
                module={Modules.Reports}
                component={StudentChargesReport}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/enrollment-agreement"
                module={Modules.Reports}
                component={EnrollmentAgreementReport}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/naccas-annual"
                module={Modules.Reports}
                component={NaccasAnnualReport}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/naccas-annual-exempted-student"
                module={Modules.Reports}
                component={NaccasAnnualExemptedStudentListReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/naccas-annual-enrollment-counts"
                module={Modules.Reports}
                component={NaccasAnnualEnrollmentCounts}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/naccas-annual-summary-report"
                module={Modules.Reports}
                component={NaccasAnnualSummaryReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/bppe-annual-all-grads"
                module={Modules.Reports}
                component={BppeAnnualAllGradsReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/bppe-annual-all-grads-with-loans"
                module={Modules.Reports}
                component={BppeAnnualAllGradsReport}
                withLoans
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/bppe-annual-start-date"
                module={Modules.Reports}
                component={BppeAnnualStartDateReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/bppe-annual-start-date-with-loans"
                module={Modules.Reports}
                component={BppeAnnualStartDateReport}
                withLoans
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/bppe-annual-grads-contracted"
                module={Modules.Reports}
                component={BppeAnnualGradsByContractedDate}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/bppe-annual-title-iv-audit"
                module={Modules.Reports}
                component={BPPEAnnualTitleIVAudit}
            />

            {/* Calendar */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/calendar"
                model={model}
                component={CalendarSettings}
                module={Modules.Calendar}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Calendar.AccessStaffList.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/calendar/work-schedule-details"
                model={model}
                component={WorkScheduleDetails}
                module={Modules.Calendar}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Calendar.AccessStaffList.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/calendar/work-schedule-details/:workScheduleId"
                model={model}
                component={WorkScheduleDetails}
                module={Modules.Calendar}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Calendar.AccessStaffList.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/calendar/custom-details"
                model={model}
                module={Modules.Calendar}
                component={CustomDetails}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/calendar/custom-details/:customId"
                model={model}
                module={Modules.Calendar}
                component={CustomDetails}
                // permissions={{
                //     staff: [Permissions.Staff.Settings.General.Calendar.AccessStaffList.View]
                // }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/calendar/event-type-details"
                model={model}
                component={EventTypeDetails}
                module={Modules.Calendar}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Calendar.AccessStaffList.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/calendar/event-type-details/:eventTypeId"
                model={model}
                component={EventTypeDetails}
                module={Modules.Calendar}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Calendar.AccessStaffList.View]
                }}
            />

            {/* Development */}
            <RouteWithPermissionsRequired exact path="/development*" model={model} component={Development} />

            <RouteWithPermissionsRequired
                exact
                path="/settings/student-statuses"
                model={model}
                component={StudentStatuses}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Statuses.View]
                }}
            />

            {/* Tags */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/tags"
                model={model}
                component={Tags}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Tags.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/tags/detail"
                model={model}
                component={TagDetail}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.Tags.View]
                }}
            />

            {/* Role Permission User Settings */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/user-settings"
                model={model}
                component={RolePermission}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.UserSettings.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/external-institutions"
                model={model}
                component={ExternalInstitutions}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.UserSettings.View]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                path="/settings/external-institutions/high-school/detail"
                model={model}
                component={HighschoolDetail}
            />

            {/* email templates */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/templates"
                model={model}
                component={Templates}
                permissions={{
                    staff: [Permissions.Staff.Settings.SystemSettings.EmailTemplates.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/email-templates/detail"
                model={model}
                component={EmailTemplateDetail}
                permissions={{
                    staff: [Permissions.Staff.Settings.SystemSettings.EmailTemplates.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/sms-templates/detail"
                model={model}
                component={SMSTemplateDetail}
                permissions={{
                    staff: [Permissions.Staff.Settings.SystemSettings.EmailTemplates.View]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                path="/settings/user-settings/permission-groups/detail"
                model={model}
                component={PermissionGroupsDetail}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.UserSettings.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/user-settings/roles/detail"
                model={model}
                component={RoleDetail}
                permissions={{
                    staff: [Permissions.Staff.Settings.General.UserSettings.View]
                }}
            />

            {/* Academics Settings module */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/academics"
                model={model}
                component={Academics}
                departmentId={BaseDepartmentId.Academics}
                module={Modules.Academic}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.Academics.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/academics/checklist/detail"
                model={model}
                departmentId={BaseDepartmentId.Academics}
                component={CheckListDetail}
                module={Modules.Academic}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.Academics.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/academics/retention/detail"
                model={model}
                departmentId={BaseDepartmentId.Academics}
                component={RetentionDetail}
                module={Modules.Academic}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.Academics.View]
                }}
            />
            {/* Career Services Settings module */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/career-services"
                model={model}
                component={Academics}
                departmentId={BaseDepartmentId.CareerServices}
                module={Modules.CareerServices}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.CareerServices.View]
                }}
            />
            {/* Student Services Settings module */}
            <RouteWithPermissionsRequired
                exact
                path="/settings/student-services"
                model={model}
                component={Academics}
                departmentId={BaseDepartmentId.StudentServices}
                module={Modules.StudentServices}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.Academics.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/student-services/checklist/detail"
                model={model}
                departmentId={BaseDepartmentId.StudentServices}
                component={CheckListDetail}
                module={Modules.StudentServices}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.Academics.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/student-account"
                model={model}
                component={StudentAccountSettings}
                departmentId={BaseDepartmentId.StudentAccount}
                module={Modules.StudentAccount}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.StudentAccount.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/student-account/chart-of-accounts/:id"
                model={model}
                component={ChartOfAccountDetail}
                departmentId={BaseDepartmentId.StudentAccount}
                module={Modules.StudentAccount}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.StudentAccount.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/student-account/payment-periods/:id"
                model={model}
                component={ProgramPaymentPeriods}
                departmentId={BaseDepartmentId.StudentAccount}
                module={Modules.StudentAccount}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.StudentAccount.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/student-account/checklist/detail"
                model={model}
                departmentId={BaseDepartmentId.StudentAccount}
                component={CheckListDetail}
                module={Modules.StudentAccount}
                permissions={{
                    staff: [Permissions.Staff.Settings.Modules.StudentAccount.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/branding"
                model={model}
                component={Branding}
                permissions={{
                    staff: [Permissions.Staff.Settings.SystemSettings.Branding.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/media-privacy"
                model={model}
                component={MediaPrivacy}
                permissions={{
                    staff: [Permissions.Staff.Settings.SystemSettings.Media.View]
                }}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/add-grading"
                model={model}
                component={AddGrading}
                permissions={{
                    staff: [Permissions.Staff.Settings.SystemSettings.Media.View]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                path="/activities"
                model={model}
                component={Activities}
                module={Modules.ActivitiesAndFollowups}
            />
            <RouteWithPermissionsRequired
                exact
                path="/activities/:id"
                model={model}
                component={ActivityDetail}
                module={Modules.ActivitiesAndFollowups}
            />

            <RouteWithPermissionsRequired
                exact
                path="/settings/app-releases/:slug"
                model={model}
                component={AppReleases}
                permissions={{
                    staff: [Permissions.Staff.Settings.SystemSettings.AppStore.View]
                }}
            />

            {/* Concerns */}
            <RouteWithPermissionsRequired exact path="/concerns" model={model} component={Concerns} />
            <RouteWithPermissionsRequired exact path="/concerns/create" model={model} component={ConcernCreate} />
            <RouteWithPermissionsRequired exact path="/concerns/detail" model={model} component={ConcernDetails} />
            <RouteWithPermissionsRequired exact path="/concerns/reports" model={model} component={ConcernReportsList} />

            {/* User Merge */}
            <RouteWithPermissionsRequired
                exact
                path="/user-merge"
                model={model}
                component={UserMerge}
                permissions={{
                    staff: [Permissions.Staff.Users.UsersMerge.Edit]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/term-code-integration"
                model={model}
                component={AcademicsTermCodeIntegrationReports}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/class-attendance-lesson"
                model={model}
                component={AcademicsAttendanceReports}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/class-attendance-weekly"
                model={model}
                component={ClassAttendanceReportWeekly}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/d2l-semester"
                model={model}
                component={AcademicD2lSemesterReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/d2l-course-offering"
                model={model}
                component={AcademicD2lCourseOfferingReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/d2l-users"
                model={model}
                component={AcademicD2lUsersReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/class-attendance/:id"
                model={model}
                component={AttendanceReportDetail}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/classroom-attendance"
                model={model}
                component={AcademicClassroomAttendance}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/missed-attendance"
                model={model}
                component={AcademicMissedAttendance}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/course-registration"
                model={model}
                component={CourseRegistrationReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/program-version-degree-requirement"
                model={model}
                component={MajorVersionDegreeRequirementReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/student-last-day-of-attendance"
                model={model}
                component={StudentLdaReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/daily-lesson"
                model={model}
                component={AcademicDailyLesson}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/temporary-out"
                model={model}
                component={AcademicTemporaryOut}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/permanent-out"
                model={model}
                component={AcademicPermanentOut}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/unofficial-transcript"
                model={model}
                component={UnofficialTranscript}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/daily-attendance-export-fame"
                model={model}
                component={AcademicDailyAttendanceFrame}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/term-course-schedule-report"
                model={model}
                component={AcademicTermCourseSchedule}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/term-roster-report"
                model={model}
                component={TermRosterReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/sap-summary-report"
                model={model}
                component={SapReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/undergrad-masters-sap"
                model={model}
                component={AcademicUndergradMastersSAPReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/academics/attendance-change"
                model={model}
                module={Modules.Reports}
                component={AcademicsAttendanceChange}
            />

            <RouteWithPermissionsRequired
                exact
                path="/reports/admissions/lead-source-conversions"
                model={model}
                component={AdmissionsLeadSourceReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/admissions/lead-source-conversions-new"
                model={model}
                component={LeadSourceReportNew}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/admissions/lead-advisor-conversions"
                model={model}
                component={AdmissionsLeadAdvisorReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/admissions/lead-advisor-conversions-new"
                model={model}
                component={LeadAdvisorReportNew}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/career-services/placement-rates-summary"
                model={model}
                component={CareerServicesPlacementRatesSummaryReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/career-services/placement-rates-details"
                model={model}
                component={CareerServicesPlacementRatesDetailsReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/career-services/student-graduation"
                model={model}
                component={CareerServicesStudentGraduationReport}
            />
            <RouteWithPermissionsRequired
                exact
                path="/reports/career-services/placement-verification"
                model={model}
                component={CareerServicesPlacementVerificationReport}
            />
            {/* Legacy Test */}
            {/* Student Success Assessment */}
            <RouteWithPermissionsRequired
                exact
                path="/academics/legacy-test"
                backUrl="/academics/staff"
                module={Modules.AcademicLegacyTest}
                component={LegacyTestStaff}
            />
            {!isProduction && (
                <>
                    <RouteWithPermissionsRequired
                        exact
                        path="/academics/legacy-test/details/:id"
                        backUrl="/academics/legacy-test"
                        // module={Modules.AdmissionsAptitudeTest}
                        component={LegacyTestDetail}
                    />
                </>
            )}

            <RouteWithPermissionsRequired
                exact
                path="/academics/educator-legacy-test"
                backUrl="/academics/staff"
                module={Modules.AcademicLegacyTest}
                component={EducatorLegacyTest}
            />
            {!isProduction && (
                <>
                    <RouteWithPermissionsRequired
                        exact
                        path="/academics/educator-legacy-test/details/:id"
                        backUrl="/academics/legacy-test"
                        component={EducatorLegacyTestDetail}
                    />
                </>
            )}

            <RouteWithPermissionsRequired
                exact
                path="/academics/practical"
                component={Practical}
                permissions={{
                    staff: [
                        Permissions.Staff.Academics.Practical.Students.View,
                        Permissions.Staff.Academics.Practical.Submissions.View
                    ]
                }}
            />

            <RouteWithPermissionsRequired
                exact
                path="/career-services"
                backUrl="/career-services"
                component={CareerServices}
                module={Modules.CareerServices}
                departmentId={BaseDepartmentId.CareerServices}
            />
            <RouteWithPermissionsRequired
                exact
                path="/career-services/employers/:id"
                backUrl="/career-services"
                component={CareerServicesEmployerDetail}
                module={Modules.CareerServices}
                departmentId={BaseDepartmentId.CareerServices}
            />
            <RouteWithPermissionsRequired
                exact
                path="/career-services/job-details/:id"
                backUrl="/career-services"
                component={CareerServicesJobDetail}
                module={Modules.CareerServices}
                departmentId={BaseDepartmentId.CareerServices}
            />
            <RouteWithPermissionsRequired
                exact
                path="/career-services/jobs/:id"
                backUrl="/career-services"
                component={CareerServicesJobEdit}
                module={Modules.CareerServices}
                departmentId={BaseDepartmentId.CareerServices}
            />
            <RouteWithPermissionsRequired
                exact
                path="/career-services/applications/:id"
                backUrl="/career-services"
                component={CareerServicesApplicationDetail}
                module={Modules.CareerServices}
                departmentId={BaseDepartmentId.CareerServices}
            />
            <RouteWithPermissionsRequired
                exact
                path="/settings/for-developers"
                model={model}
                component={ForDevelopers}
            />
            {/* Auto Dialer module routing */}
            <RouteWithPermissionsRequired
                exact
                path="/academics/auto-dialer"
                component={AutoDialer}
                module={Modules.AcademicDialer}
                departmentId={BaseDepartmentId.Academics}
            />
            <RouteWithPermissionsRequired
                exact
                path="/admissions/auto-dialer"
                component={AutoDialer}
                module={Modules.AdmissionsDialer}
                departmentId={BaseDepartmentId.Admissions}
            />
            <RouteWithPermissionsRequired
                exact
                path="/admissions/lead-bucket"
                component={LeadBucket}
                module={Modules.Admissions}
                departmentId={BaseDepartmentId.Admissions}
            />
            <RouteWithPermissionsRequired
                exact
                path="/financial-aid/auto-dialer"
                component={AutoDialer}
                module={Modules.FinancialAidDialer}
                departmentId={BaseDepartmentId.FinancialAid}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-account/auto-dialer"
                component={AutoDialer}
                module={Modules.StudentAccountDialer}
                departmentId={BaseDepartmentId.StudentAccount}
            />
            <RouteWithPermissionsRequired
                exact
                path="/academic-affairs/auto-dialer"
                component={AutoDialer}
                module={Modules.AcademicAffairDialer}
                departmentId={BaseDepartmentId.Academics}
            />
            <RouteWithPermissionsRequired
                exact
                path="/student-services/auto-dialer"
                component={AutoDialer}
                module={Modules.StudentServicesDialer}
                departmentId={BaseDepartmentId.StudentServices}
            />
            <RouteWithPermissionsRequired
                exact
                path="/career-services/auto-dialer"
                component={AutoDialer}
                module={Modules.CareerServicesDialer}
                departmentId={BaseDepartmentId.CareerServices}
            />
        </>
    )
}
