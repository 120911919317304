import {Auth} from "./auth"

export namespace Settings {
    export enum AcademicLabel {
        TotalProgress = "academics.total_progress",
        TotalHoursCompleted = "academics.completed_hours",
        DistantEduHours = "academics.distant_edu_hours",
        AttemptedHours = "academics.attempted_hours",
        RemainingHours = "academics.remaining_hours",
        ScheduledHours = "academics.scheduled_hours",
        TotalHours = "academics.total_hours",
        TransferHours = "academics.transfer_hours",
        TotalMajorHours = "academics.total_major_hours",
        Gpa = "academics.gpa",
        Cgpa = "academics.cgpa",
        MaxTimeframePercentage = "academics.max_timeframe_percentage",
        AttendancePercentage = "academics.attendance_percentage",
        MissedAttendanceHours = "academics.missed_attendance_hours",
        MakeUpAttendanceHours = "academics.make_up_attendance_hours",
        HourToMakeUp = "academics.hours_to_make_up",
        AcademicStage1 = "academics.stage1",
        AcademicStage2 = "academics.stage2",
        AcademicStage3 = "academics.stage3",
        AcademicStage4 = "academics.stage4"
    }

    export enum FinAidLabel {
        CollegeFinancingPlan = "financial_aid.college_financing_plan",
        AwardWorksheet = "financial_aid.award_worksheet",
        AWClock = "financial_aid.aw_clock",
        FinAidOffer = "financial_aid.fin_aid_offer",
        FinAidPackages = "financial_aid.fin_aid_packages"
    }

    export enum GeneralLabel {
        StartDate = "general.start_date",
        Lda = "general.lda",
        AcademicTrack = "general.academic_track",
        CurrentTerm = "general.current_term",
        ContractEndDate = "general.contract_end_date_projected",
        ProjectedEndDate = "general.projected_end_date",
        FirstDateOfClass = "general.first_date_of_class",
        MidpointDate = "general.midpoint_date",
        ActualEndDate = "general.actual_end_date",
        DegreeConferred = "general.degree_conferred",
        Program = "general.program",
        ProgramVersion = "general.program_version",
        Campus = "general.campus",
        Majors = "general.majors",
        Terms = "general.terms",
        Campuses = "general.campuses",
        EnrollmentStatus = "general.enrollment_status"
    }

    export enum StaffIDCardLabel {
        SchoolEmail = "staff_id_card.school_contact_email",
        SchoolName = "staff_id_card.school_name",
        SchoolPhone = "staff_id_card.school_phone_number",
        Email = "staff_id_card.email"
    }

    export enum StudentIDCardLabel {
        SchoolEmail = "student_id_card.school_contact_email",
        SchoolName = "student_id_card.school_name",
        SchoolPhone = "student_id_card.school_phone_number",
        Email = "student_id_card.email",
        Status = "student_id_card.status",
        Program = "student_id_card.program",
        StartDate = "student_id_card.start_date",
        GraduationDate = "student_id_card.graduation_date"
    }

    export enum DirectoryLabels {
        StaffPhoto = "directory.staff_photo",
        StaffState = "directory.staff_state",
        StaffUserId = "directory.staff_user_id",
        StaffProfileId = "directory.staff_profile_id",
        StaffTitle = "directory.staff_title",
        StaffRole = "directory.staff_role",
        StaffCampus = "directory.staff_campus",
        StaffDepartment = "directory.staff_department",
        StaffPhone = "directory.staff_phone",
        StaffEmail = "directory.staff_email",
        StaffAddress = "directory.staff_address"
    }

    export enum CustomFieldType {
        Text = "text",
        Number = "number"
    }

    export type PublicBranding = {
        login_screen_promo_image_mobile: string
        login_screen_promo_image_web: string
        mobile_app_intro_page_video: string
        mobile_app_launch_screen_image: string
        official_logo_rectangular: string
    }

    export type ClientSetting = {
        slug: string
        name: string
        contactPhone: string
        collegeAddress: string
        collegePhone: string
        collegeUrl: string
        contactEmail: string
        privacyPolicy: string
        description: string
        branding: PublicBranding
        admissionUrl: string
        isClassroomAttendanceEnabled: boolean
        isClientsGroup: boolean
        isDailyAttendanceEnabled: boolean
        isTypedSignaturesEnabled: boolean
        isDepartmentalStatusesHidden: boolean
        isNewStudentStatusesVisible: boolean
        isSupportChatEnabled: boolean
    }

    export type FieldLabel = {
        labelId: GeneralLabel | AcademicLabel
        module: string
        fieldType: string
        isActive: boolean
        originalEnglish: string
        displayEnglish: string
        originalSpanish: string
        displaySpanish: string
        hint: string
        updatedByProfileId: number
        description: string
        createdAt: string
        updatedAt: string
        deletedAt: string
    }

    export type AcademicTrack = {
        trackId: number
        code: string
        name: string
        isLocked: number
        isActive: number
        description: string
        createdAt: string
        updatedAt: string
        deletedAt: string
        sisId: string
    }

    export type CustomFieldModule =
        | "general"
        | "academics"
        | "admissions"
        | "financial-aid"
        | "career-services"
        | "student-account"
        | "student-services"
        | "users"
        | "student-id-card"
        | "staff-id-card"
        | "directory"

    export type CustomField = {
        customFieldId: number
        label: string
        fieldType: CustomFieldType
        module: CustomFieldModule
        createdByProfileId: number
        updatedByProfileId: number
        deletedByProfileId: number
        createdAt: string
        updatedAt: string
        deletedAt: string
    }

    export type DisabledAccessStudentStatus = {
        statusId: number
        name: string
        state: Auth.StudentState
        doDisableUserAccess: boolean
        doDeactivateProfile: boolean
    }

    export type ChangedDisabledAccessStudentStatus = {
        statusId: number
        doDisableUserAccess: boolean
        doDeactivateProfile: boolean
    }

    export type DisabledAccessStaffStatus = {
        state: Auth.UserProfileState
        isUserAccessDisabled: boolean
        isPersonalContactsAccessDisabled: boolean
        updatedAt: string
    }

    export type ChangedDisabledAccessStaffStatus = {
        state: Auth.UserProfileState
        isUserAccessDisabled: boolean
    }
}
