import React, {useState} from "react"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import {KlassDropdown} from "components/Select"
import {FormLabel} from "components/Form"
import {useMutation} from "hooks"
import {taskService} from "services"
import {handleError, toastSuccess} from "helpers"
import styles from "./AssignTaskPopup.module.css"

type Props = {
    isShow: boolean
    studentProfileId: number
    termId: number
    courseId: number
    scheduleId: number
    edularTasks: any[]
    onClose: () => void
}

export function AssignTaskPopup(props: Props) {
    const {isShow, studentProfileId, termId, courseId, scheduleId, edularTasks, onClose} = props
    const [activeTask, setActiveTask] = useState(null)

    const assignTaskMutation = useMutation(
        async () => {
            await taskService.assignCustomDigitalTask({
                taskId: activeTask.id,
                profileId: studentProfileId,
                courseId,
                scheduleId,
                termId
            })
        },
        {
            onSuccess: () => {
                toastSuccess("Assign task successful")
                onClose()
            },
            onError: (error) => {
                handleError(error)
            }
        }
    )

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="EDIT_LINE" width="60vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Assign Task</p>
                <div>
                    <FormLabel label="Select task" isRequired />
                    <KlassDropdown options={edularTasks} onChange={setActiveTask} />
                </div>
                <div className={styles.action}>
                    <BaseButton
                        title="Cancel"
                        variant="secondary"
                        onClick={onClose}
                        disabled={assignTaskMutation.isLoading}
                    />
                    <BaseButton
                        title="Assign"
                        disabled={!activeTask}
                        onClick={assignTaskMutation.mutate}
                        loading={assignTaskMutation.isLoading}
                    />
                </div>
            </div>
        </BasePopup>
    )
}
