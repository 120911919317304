import {StatusStateName} from "helpers"
import {useAllAvailableStudentStatuses} from "hooks/student-statuses/useAllAvailableStudentStatuses"
import {orderBy, uniq} from "lodash"
import {useMemo} from "react"
import {Auth} from "types/auth"

type Options = {
    forState?: Auth.StudentState[]
}

export const useGroupedStudentStatusesByStateAsDropdownOptions = ({forState}: Options) => {
    const {statuses} = useAllAvailableStudentStatuses({forState})

    const groupedStatuses = useMemo(() => {
        const states = uniq(statuses.map((item) => item.state))

        return states
            .map((state) => {
                const options = orderBy(
                    statuses.filter((status) => status.state === state),
                    ["state", "priority"],
                    ["asc", "asc"]
                )

                return {
                    label: StatusStateName[state] ?? "WITHOUT STATE",
                    options
                }
            })
            .filter((group) => group.options.length > 0)
    }, [statuses])

    return {statuses, groupedStatuses}
}
