import {useQuery} from "@tanstack/react-query"
import {majorService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"

const queryCacheKey = "program-versions"

type ProgramVersionsProps = {
    programId?: number | number[]
    isActive?: boolean
}

export const useProgramVersions = ({programId, isActive}: ProgramVersionsProps) => {
    const query = useQuery(
        [queryCacheKey, isActive, programId],
        async () =>
            majorService.getMajorVersions({
                orderBy: "version_name",
                orderDir: "ASC",
                program_id: programId,
                isActive,
                offset: 0,
                limit: UNLIMITED_PAGE_SIZE
            }),
        {
            refetchOnMount: false
        }
    )

    return {
        programVersions: query.data ?? [],
        isLoading: query.isFetching
    }
}
