import {useQuery} from "@tanstack/react-query"
import {studentStaffContactsServiceV3, userServiceV3} from "services"
import {BaseDepartmentId} from "types/departments"

type UserProps = {
    userId?: number
    isEnabled?: boolean
}

export const useUserDetailForMessageTemplate = ({userId, isEnabled = true}: UserProps) => {
    const query = useQuery(
        ["user-details-for-message-template", userId],
        async () => {
            const userDetails = await userServiceV3.getOne(userId)
            if (!userDetails) {
                return null
            }

            const profile = userDetails.profiles?.find((profile) => !!profile.active)

            const campusDetails: any = profile?.campuses?.length ? profile.campuses[0] : null
            let admissionAdvisor: any = null,
                finAidAdvisor: any = null,
                academicAdvisor: any = null

            if (profile && campusDetails) {
                const [{data: allAdmissionAdvisors}, {data: allFinAidAdvisors}, {data: allAcademicAdvisor}] =
                    await Promise.all([
                        studentStaffContactsServiceV3.getStaffs({
                            range: {
                                pageSize: 1,
                                page: 1
                            },
                            filter: {
                                profileIds: [profile.id],
                                departmentId: BaseDepartmentId.Admissions,
                                search: "",
                                campusIds: [campusDetails.id]
                            }
                        }),
                        studentStaffContactsServiceV3.getStaffs({
                            range: {
                                pageSize: 1,
                                page: 1
                            },
                            filter: {
                                profileIds: [profile.id],
                                departmentId: BaseDepartmentId.FinancialAid,
                                search: "",
                                campusIds: [campusDetails.id]
                            }
                        }),
                        studentStaffContactsServiceV3.getStaffs({
                            range: {
                                pageSize: 1,
                                page: 1
                            },
                            filter: {
                                profileIds: [profile.id],
                                departmentId: BaseDepartmentId.Academics,
                                search: "",
                                campusIds: [campusDetails.id]
                            }
                        })
                    ])
                if (allAdmissionAdvisors?.length) {
                    admissionAdvisor = allAdmissionAdvisors[0]
                }

                if (allFinAidAdvisors?.length) {
                    finAidAdvisor = allFinAidAdvisors[0]
                }

                if (allAcademicAdvisor?.length) {
                    academicAdvisor = allAcademicAdvisor[0]
                }
            }

            return {
                userDetails,
                profile,
                campusDetails,
                admissionAdvisor,
                finAidAdvisor,
                academicAdvisor
            }
        },
        {
            enabled: !!userId && isEnabled,
            initialData: {
                userDetails: null,
                profile: null,
                campusDetails: null,
                admissionAdvisor: null,
                finAidAdvisor: null,
                academicAdvisor: null
            },
            refetchOnMount: false
        }
    )

    return {
        userData: query.data,
        isLoading: query.isFetching
    }
}
