/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import moment from "moment"
import {useHistory} from "react-router-dom"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {disbursementService} from "services"
import {useDepartmentStudent, useModel, useVisible} from "hooks"
import {Col, Row} from "antd"
import {routing} from "helpers/routing"
import {formatCurrency, formatDateOnly, formatDateRange, getFullName, handleError} from "helpers"
import {KlassappTableProps} from "types/common"
import {Auth} from "types/auth"
import {FundSource} from "types/fin-aid/fund-source"
import {User} from "types/user"
import {Disbursement, DisbursementStatus} from "types/fin-aid/disbursement"
import {KlassDropdown} from "components/Select"
import FundSourceSelect from "components/FundSourceSelect"
import {AwardSummary} from "types/fin-aid/fin-aid"
import {BaseRangePicker} from "components/DateTimePicker"
import {BaseButton, BasePopup, Icon} from "components"
import styles from "./Disbursements.module.css"
import DrawAndPost from "./parts/DrawAndPost"
import LoanDisbursementEligibilityCheck from "./parts/LoanDisbursementEligibilityCheck"
import Status from "./parts/Status"
import {TermDetails} from "types/terms"
import {Permissions} from "types/permission"
import {PermissionsRequired} from "components/PermissionsRequired"

import {ExportButton, ExportFileType} from "components/ui"
import {exportCsv, exportExcel} from "helpers/export-table"
import {get} from "lodash"
import PaymentPeriodStartDateSelect from "components/PaymentPeriodStartDateSelect"
import {useMutation} from "@tanstack/react-query"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {PaymentPeriod} from "types/student-account/payment-period"

type Props = KlassappTableProps & {
    studentId?: number
    awardSummary?: AwardSummary
    academicStats?: Auth.StudentStatistics
    // for Draw & Post
    drawAndPost?: boolean
    onItemsChecked?: (checkedItems: Disbursement[]) => void
    // for Report
    isReport?: boolean
    isFAO?: boolean
}

const currentYear = moment().year()
const yearOptions = [-1, 0, 1, 2, 3, 4, 5, 6].map((index) => ({
    id: currentYear + index,
    name: `${currentYear + index} - ${currentYear + index + 1}`
}))

const INITIAL_FILTER = {
    awardYears: [],
    terms: [],
    fundSources: [],
    statuses: [],
    periods: [],
    disbursementDates: undefined
}

function Disbursements({
    dispatch,
    dispatchFunc,
    page,
    total,
    pageSize,
    columns,
    data,
    allFields,
    fields,
    tableHeaderActions,
    isLoading,
    menuActions,
    isHideMenuActions,
    isShowTableHeaderAction,
    onClickShowConfirmModal,
    getCurrentData,
    getCurrentPage,
    getCurrentPageSize,
    onChangePage,
    onChangeRowPerPage,
    onChangeFields,
    onDraggableColumn,
    onChangeAllFields,
    onUpdateRowData,
    onUpdateTableData,
    studentId,
    awardSummary,
    academicStats,
    drawAndPost = false,
    onItemsChecked,
    isReport = false,
    isFAO = false
}: Props) {
    const history = useHistory()
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const {t} = useTranslation(["financialAid", "common"])
    const [isRequesting, setRequesting] = React.useState(false)
    const {student} = useDepartmentStudent({studentId})

    // region filter

    const [search, setSearch] = useState("")
    const [filter, setFilter] = useState(INITIAL_FILTER)
    const [appliedFilter, setAppliedFilter] = useState(filter)

    const onChangeFilter = (key: keyof typeof filter, value: any) => {
        setFilter((prev) => ({...prev, [key]: value}))
    }

    const onClearFilter = () => {
        setFilter(INITIAL_FILTER)
        setAppliedFilter(INITIAL_FILTER)
    }

    const onApplyFilter = () => {
        setAppliedFilter(filter)
    }

    const onSearchInput = (search = "") => {
        setSearch(search)
    }

    // endregion filter

    const getFilter = useCallback(
        () => ({
            studentProfileId: studentId,
            search,
            awardYear: appliedFilter.awardYears?.length
                ? appliedFilter.awardYears.map((option) => option.id)
                : undefined,
            termId: appliedFilter.terms?.length ? appliedFilter.terms.map((item) => item.id) : undefined,
            fundSourceId: appliedFilter.fundSources?.length
                ? appliedFilter.fundSources.map((item) => item.fundSourceId)
                : undefined,
            status: appliedFilter.statuses?.length ? appliedFilter.statuses.map((item) => item.id) : undefined,
            periods: appliedFilter.periods?.length ? appliedFilter.periods.map((option) => option.periodId) : undefined,
            disbursementDates: appliedFilter.disbursementDates
        }),
        [appliedFilter, search, studentId]
    )

    const getData = React.useCallback(async () => {
        dispatch({isLoading: true})
        const page = getCurrentPage()
        const pageSize = getCurrentPageSize()
        const {total, data: disbursements} = await disbursementService.list({
            filter: getFilter(),
            range: isFAO ? {offset: 0, limit: UNLIMITED_PAGE_SIZE} : {limit: pageSize, offset: (page - 1) * pageSize},
            linkedEntities: true
        })
        dispatch({total, data: disbursements.map((item) => ({...item, id: item.disbursementId})), isLoading: false})
    }, [getFilter, isFAO])

    const onClickRowItem = React.useCallback(
        ({studentProfileId, disbursementId}) => {
            if (drawAndPost) return
            history.push(routing.finAid.student.disbursementDetail(studentProfileId, disbursementId))
        },
        [drawAndPost]
    )

    const onClickEdit = ({studentProfileId, disbursementId}) => {
        history.push(routing.finAid.student.disbursementDetail(studentProfileId, disbursementId))
    }

    const onClickDelete = React.useCallback(
        async (deletedItem: Disbursement) => {
            // if (deletedItem.isLock) {
            //     toastError(t("common:message.cantDeleteLockedItem"))
            //     return
            // }
            try {
                dispatch({isLoading: true})
                await disbursementService.bulkDelete([deletedItem.disbursementId])
                await getData()
            } catch (e) {
                handleError(e)
            } finally {
                dispatch({isLoading: false})
            }
        },
        [getData]
    )

    const onClickDeleteMulti = React.useCallback(async () => {
        const checkedItems = getCurrentData().filter((item) => item.isChecked)
        // if (checkedItems.find((item) => item.isLock)) {
        //     toastError(t("common:message.cantDeleteLockedItem"))
        //     return
        // }
        try {
            dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await disbursementService.bulkDelete(checkedItems.map((item) => item.disbursementId))
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }, [getData])

    const totalCompletedHours = React.useMemo(() => academicStats?.totalHoursCompleted || 0, [academicStats])

    const getPageTitle = () => {
        return t("finAidAward.disbursement.tableTitle")
    }

    const getFields = () => {
        const fields = [
            t("finAidAward.disbursement.column.awardYear"),
            t("finAidAward.disbursement.column.fundSource"),
            t("finAidAward.disbursement.column.disbursedSequenceNo"),
            t("finAidAward.disbursement.column.disbursedDate"),
            t("finAidAward.disbursement.column.grossAmount"),
            t("finAidAward.disbursement.column.netAmount"),
            isFAO ? null : t("finAidAward.disbursement.column.paymentPeriod")
        ]
        if (!studentId) {
            fields.push(t("finAidAward.disbursement.column.student"))
        }
        if (!drawAndPost && !isReport && !isFAO) {
            fields.push(t("finAidAward.disbursement.column.status"), t("finAidAward.disbursement.column.action"))
        }
        return fields.filter(Boolean)
    }

    const getColumns = React.useCallback(
        () =>
            [
                {
                    title: t("finAidAward.disbursement.column.awardYear"),
                    field: "awardYear",
                    render: (value) => `${value} - ${value + 1}`
                },
                {
                    title: t("finAidAward.disbursement.column.student"),
                    field: "studentProfile",
                    render: (value) => getFullName(value)
                },
                {
                    title: t("finAidAward.disbursement.column.startingTerm"),
                    field: "term",
                    render: (value: TermDetails) => value?.name
                },
                {
                    title: t("finAidAward.disbursement.column.fundSource"),
                    field: "fundSource",
                    render: (value: FundSource) => value.title
                },
                {
                    title: t("finAidAward.disbursement.column.disbursedSequenceNo"),
                    field: "disbursedSequenceNo"
                },
                {
                    title: t("finAidAward.disbursement.column.disbursedDate"),
                    field: "disbursedDate",
                    render: (value) => formatDateOnly(value, userDateFormat)
                },
                {
                    title: t("finAidAward.disbursement.column.comments"),
                    field: "comments",
                    render: (value) => value ?? ""
                },
                {
                    title: t("finAidAward.disbursement.column.scheduledDate"),
                    field: "scheduledDate",
                    render: (value) => formatDateOnly(value, userDateFormat)
                },
                {
                    title: t("finAidAward.disbursement.column.grossAmount"),
                    field: "grossAmount",
                    render: (value) => formatCurrency(value),
                    renderText: (value: number) => (value ?? 0).toFixed(2)
                },
                {
                    title: t("finAidAward.disbursement.column.netAmount"),
                    field: "netAmount",
                    render: (value) => formatCurrency(value),
                    renderText: (value: number) => (value ?? 0).toFixed(2)
                },
                {
                    title: t("finAidAward.disbursement.column.originationFee"),
                    field: "originationFee"
                },
                {
                    title: t("finAidAward.disbursement.column.interestRebase"),
                    field: "interestRebase"
                },
                {
                    title: t("finAidAward.disbursement.column.codDisbReleaseIndicator"),
                    field: "codDisbReleaseIndicator"
                },
                {
                    title: t("finAidAward.disbursement.column.codDisbReleaseTime"),
                    field: "codDisbReleaseTime",
                    render: (value) => (value ? moment(value).format(userDateFormat) : "")
                },
                {
                    title: t("finAidAward.disbursement.column.codStatusCode"),
                    field: "codStatusCode"
                },
                {
                    title: t("finAidAward.disbursement.column.codDisbSequenceNo"),
                    field: "codDisbSequenceNo"
                },
                {
                    title: t("finAidAward.disbursement.column.codPrevDisbSequenceNo"),
                    field: "codPrevDisbSequenceNo"
                },
                {
                    title: t("finAidAward.disbursement.column.codCycleFlag"),
                    field: "codCycleFlag"
                },
                {
                    title: t("finAidAward.disbursement.column.academicYear"),
                    field: "academicYear",
                    render: (value) => (value ? `${value} - ${value + 1}` : "")
                },
                {
                    title: t("finAidAward.disbursement.column.academicYearBegin"),
                    field: "academicYearBegin",
                    render: (value) => formatDateOnly(value, userDateFormat)
                },
                {
                    title: t("finAidAward.disbursement.column.academicYearEnd"),
                    field: "academicYearEnd",
                    render: (value) => formatDateOnly(value, userDateFormat)
                },
                {
                    title: t("finAidAward.disbursement.column.paymentPeriod"),
                    field: "period",
                    render: (period?: PaymentPeriod) => {
                        if (!period) return ""
                        return formatDateRange(period.startDate, period.endDate, userDateFormat)
                    }
                },
                {
                    title: t("finAidAward.disbursement.column.acceptanceDate"),
                    field: "acceptanceDate",
                    render: (value) => formatDateOnly(value, userDateFormat)
                },
                isFAO
                    ? null
                    : {
                          title: t("finAidAward.disbursement.column.lastEditor"),
                          field: "updatedBy",
                          render: (value: User) => getFullName(value)
                      },
                isFAO
                    ? null
                    : {
                          title: t("finAidAward.disbursement.column.lastEdited"),
                          field: "updatedAt",
                          render: (value) => (value ? moment(value).format(userDateTimeFormat) : "")
                      },
                {
                    title: t("finAidAward.disbursement.column.status"),
                    field: "status",
                    render: (status: DisbursementStatus) => <Status status={status} />,
                    renderText: (status: DisbursementStatus) => t(`finAidAward.disbursement.status.${status}`)
                },
                isReport || isFAO
                    ? null
                    : {
                          title: t("finAidAward.disbursement.column.action"),
                          titleHtml: <span />,
                          isHideTitle: true,
                          field: "",
                          sortable: false,
                          render: (_, record: Disbursement) => {
                              if (record.status === DisbursementStatus.Requested) {
                                  return (
                                      <BaseButton
                                          size="small"
                                          title={t("finAidAward.disbursement.download")}
                                          className={styles.btnRequest}
                                          onClick={(e) => {
                                              e.stopPropagation()
                                              handleDownloadClick(record)
                                          }}
                                          disabled={isRequesting}
                                      />
                                  )
                              }
                              let disabled = true
                              if (record.status === DisbursementStatus.Rejected) {
                                  disabled = false
                              }
                              if (record.status === DisbursementStatus.Pending) {
                                  if (record.scheduledDate != null && moment().isAfter(record.scheduledDate)) {
                                      disabled = false
                                  }
                                  if (
                                      record.numberOfHoursCompleted != null &&
                                      record.numberOfHoursCompleted <= totalCompletedHours
                                  ) {
                                      disabled = false
                                  }
                              }
                              return (
                                  <Row gutter={8} align="middle" wrap={false}>
                                      <Col>
                                          <BaseButton
                                              size="small"
                                              title={t("finAidAward.disbursement.request")}
                                              className={styles.btnRequest}
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                                  handleRequestClick(record)
                                              }}
                                              disabled={isRequesting || disabled}
                                          />
                                      </Col>
                                      <Col>
                                          {/*
                                <Tooltip title={t(`finAidAward.disbursement.status.${record.status}`)}>
                                    <div>
                                        <Icon icon="ALERT_CIRCLE" color="#DF1642" />
                                    </div>
                                </Tooltip>
                            */}
                                      </Col>
                                  </Row>
                              )
                          }
                      }
            ].filter(Boolean),
        [isRequesting, totalCompletedHours, isReport, isFAO]
    )

    const getTableHeaderActions = React.useCallback(
        (isShowDuplicateBtn = true, checkedData = []) => {
            if (drawAndPost) return []
            const actions = []
            const hasLockedItem = (checkedData || []).some((item: Disbursement) => false)
            if (checkedData?.length === 1) {
                actions.push({
                    title: t("common:action.edit"),
                    icon: "EDIT",
                    action: () => onClickEdit(checkedData[0])
                })
            }
            if (!hasLockedItem) {
                actions.push({
                    title: t("common:action.delete"),
                    icon: "DELETE",
                    action: () => onClickShowConfirmModal("DELETE")
                })
            }
            return actions
        },
        [t, onClickShowConfirmModal, onClickEdit, drawAndPost]
    )

    const getMenuActions = React.useCallback(() => {
        if (drawAndPost) return []
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: onClickEdit
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: onClickDelete,
                canShow: (item: Disbursement) => true
            }
        ]
    }, [t, drawAndPost])

    useEffect(() => {
        const checkedItems: Disbursement[] = data.filter((item) => item.isChecked)
        onItemsChecked?.(checkedItems)
    }, [data, onItemsChecked])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "getMenuActions", func: getMenuActions},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [])

    useEffect(() => {
        dispatchFunc([{key: "getColumns", func: getColumns}])
    }, [getColumns])

    useEffect(() => {
        getData()
    }, [search, appliedFilter, page, pageSize])

    useEffect(() => {
        if (awardSummary) {
            const filter = {
                awardYears: yearOptions.filter((option) => option.id === awardSummary.awardYear),
                terms: [],
                fundSources: [awardSummary.fundSource],
                statuses: [],
                periods: [],
                disbursementDates: undefined
            }
            setFilter(filter)
            setAppliedFilter(filter)
        } else {
            setFilter(INITIAL_FILTER)
            setAppliedFilter(INITIAL_FILTER)
        }
    }, [awardSummary])

    const statusOptions = React.useMemo(
        () =>
            [
                DisbursementStatus.Pending,
                DisbursementStatus.Requested,
                DisbursementStatus.Approved,
                DisbursementStatus.Rejected,
                DisbursementStatus.Disbursed
            ].map((status) => ({
                id: status,
                name: t(`finAidAward.disbursement.status.${status}`)
            })),
        [t]
    )

    const requestPopup = useVisible(false)
    const [disbursementToBeRequested, setDisbursementToBeRequested] = useState<Disbursement | undefined>()

    const handleRequestClick = React.useCallback((item: Disbursement) => {
        setDisbursementToBeRequested(item)
        requestPopup.open()
    }, [])

    const handleRequestConfirm = React.useCallback(async () => {
        try {
            setRequesting(true)
            await disbursementService.requestDisbursement(disbursementToBeRequested.disbursementId)
            await getData()
            requestPopup.close()
        } catch (error) {
            handleError(error)
        } finally {
            setRequesting(false)
        }
    }, [getData, disbursementToBeRequested])

    const handleDownloadClick = React.useCallback(async (item: Disbursement) => {
        try {
            setRequesting(true)
            const xml = await disbursementService.requestDisbursement(item.disbursementId)
            const filename = `CRAA23IN.${item.disbursementId}.${moment(item.requestedDate).format(
                "YYYY-MM-DD.HH-mm"
            )}.xml`
            const pseudoelement = document.createElement("a")
            const blob = new Blob([xml], {type: "text/plain"})
            pseudoelement.setAttribute("href", window.URL.createObjectURL(blob))
            pseudoelement.setAttribute("download", filename)
            pseudoelement.dataset.downloadurl = ["text/plain", pseudoelement.download, pseudoelement.href].join(":")
            pseudoelement.click()
        } catch (error) {
            handleError(error)
        } finally {
            setRequesting(false)
        }
    }, [])

    const [drawAndPostVisible, setDrawAndPostVisible] = React.useState(false)
    const handleClickDrawPost = React.useCallback(() => setDrawAndPostVisible(true), [])
    const closeDrawAndPost = React.useCallback(() => setDrawAndPostVisible(false), [])

    const onClickAdd = React.useCallback(() => {
        history.push(routing.finAid.student.disbursementDetail(studentId, "new"))
    }, [])

    const exportMutation = useMutation(
        async (type: ExportFileType) => {
            const {data: disbursements} = await disbursementService.list({
                filter: getFilter(),
                range: {offset: 0, limit: UNLIMITED_PAGE_SIZE},
                linkedEntities: true
            })
            const payload = {
                filename: `FA Disbursement Report ${moment().format("YYYY-MM-DD")}`,
                columns: columns.map((col) => col.title),
                rows: disbursements.map((item) =>
                    columns.map((col) => {
                        if (col.fieldType === "date") {
                            const formatDateUtc = model.getUserDateFormat()
                            return get(item, col.field) ? moment.utc(get(item, col.field)).format(formatDateUtc) : ""
                        }
                        const value = col.renderText
                            ? col.renderText(get(item, col.field), item)
                            : col.render
                            ? col.render(get(item, col.field), item)?.toString?.()
                            : get(item, col.field)?.toString?.()
                        return (type === "csv" ? `"${value?.replaceAll(/[|,;"]/g, "") ?? ""}"` : value) || ""
                    })
                )
            }
            if (type === "csv") {
                exportCsv(payload)
            } else if (type === "excel") {
                exportExcel(payload)
            }
        },
        {
            onError: (error) => {
                handleError(error)
            }
        }
    )

    const filterView = (
        <BaseNewFilter
            filter={filter}
            onClick={onApplyFilter}
            onClickClear={onClearFilter}
            renderRightFilter={() => (
                <Row gutter={16} justify="end" align="middle">
                    {!drawAndPost && !isReport && (
                        <Col>
                            <BaseButton
                                title={t("finAidAward.disbursement.drawAndPost")}
                                onClick={handleClickDrawPost}
                                className={styles.addButton}
                            />
                        </Col>
                    )}
                    {!isReport && (
                        <PermissionsRequired
                            permissions={{
                                staff: [Permissions.Staff.FinancialAid.Students.FinancialAidStudentDetail.Add]
                            }}>
                            <Col>
                                <BaseButton
                                    title="CREATE"
                                    icon={<Icon className={styles.plusIcon} icon="PLUS" color="#FFF" />}
                                    onClick={onClickAdd}
                                />
                            </Col>
                        </PermissionsRequired>
                    )}

                    {isReport && (
                        <Col>
                            <ExportButton
                                isLoading={exportMutation.isLoading}
                                onSelect={exportMutation.mutate}
                                label="Download"
                                availableFileTypes={["csv", "excel"]}
                            />
                        </Col>
                    )}
                </Row>
            )}
            onSearchInput={onSearchInput}>
            <Row gutter={[24, 24]}>
                <Col span={12}>
                    <KlassDropdown
                        isClearable
                        isMulti
                        placeholder={t("finAidAward.disbursement.column.awardYear")}
                        options={yearOptions}
                        value={filter.awardYears}
                        onChange={(options) => onChangeFilter("awardYears", options || [])}
                    />
                </Col>
                {/*
                    <Col span={12}>
                        <TermSelect
                            isClearable
                            isMulti
                            placeholder={t("finAidAward.disbursement.column.term")}
                            value={filter.terms}
                            onChange={(options) => onChangeFilter("terms", options || [])}
                        />
                    </Col>
                     */}
                <Col span={12}>
                    <FundSourceSelect
                        isClearable
                        isMulti
                        placeholder={t("finAidAward.disbursement.column.fundSource")}
                        value={filter.fundSources}
                        onChange={(options) => onChangeFilter("fundSources", options || [])}
                    />
                </Col>
                <Col span={12}>
                    <KlassDropdown
                        isClearable
                        isMulti
                        placeholder={t("finAidAward.disbursement.column.status")}
                        options={statusOptions}
                        value={filter.statuses}
                        onChange={(options) => onChangeFilter("statuses", options || [])}
                    />
                </Col>
                <Col span={12}>
                    <PaymentPeriodStartDateSelect
                        programIds={student?.programId && [student?.programId]}
                        isClearable
                        isMulti
                        placeholder={t("finAidAward.disbursement.column.periodStartDate")}
                        value={filter.periods}
                        onChange={(options) => onChangeFilter("periods", options || [])}
                    />
                </Col>
                <Col span={12}>
                    <BaseRangePicker
                        placeholder={["Disb Start Date", "End Date"]}
                        value={filter.disbursementDates}
                        onChange={(value) => onChangeFilter("disbursementDates", value)}
                    />
                </Col>
            </Row>
        </BaseNewFilter>
    )

    return (
        <div>
            {!isFAO && filterView}
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={onChangeFields}
                onChangeAllFields={onChangeAllFields}
                onDraggableColumn={onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                menuActions={isHideMenuActions || isReport || isFAO ? [] : menuActions}
                fields={fields}
                allFields={allFields}
                isShowCheckedColumn={!isReport && !isFAO}
                onClickRowItem={onClickRowItem}
                onChangeFields={onChangeFields}
                onDraggableColumn={onDraggableColumn}
                onChangeAllFields={onChangeAllFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={onUpdateTableData}
            />
            <DrawAndPost visible={drawAndPostVisible} onClose={closeDrawAndPost} />
            <BasePopup
                isShow={requestPopup.isVisible}
                onClose={requestPopup.close}
                leftIcon="SEND"
                leftIconColor="#fff"
                dialogContentClassName={styles.popupContent}
                width="80vw">
                <LoanDisbursementEligibilityCheck
                    disbursement={disbursementToBeRequested}
                    onClose={requestPopup.close}
                    onConfirm={handleRequestConfirm}
                />
            </BasePopup>
        </div>
    )
}

export default KlassappTableHOC(Disbursements)
