/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useContext} from "react"
import {useTranslation} from "react-i18next"
import {useActiveTab} from "hooks"
import {Tabs} from "antd"
import {Screen} from "components/ui"
import {TabHeader} from "components/Tab"
import {Students, Workspace} from "./parts"
import {Attendance} from "sections/Attendance"
import {BaseDepartmentId} from "types/departments"
import {Modules, useEdularModulesContext} from "@edular/modules"
import {ActivitiesStaffTab} from "sections/StudentServices/StudentServicesStaff/parts/Activities"
import {ActiveStudentContext, ActiveStudentProvider} from "context/ActiveStudentContext"
import styles from "./StudentServicesStaff.module.css"
import cx from "classnames"
import {AdvisorTitle, StudentInfoSidebar} from "uiKit"
import {StudentServicesPanelInfo} from "sections/StudentServices/StudentServicesPanelInfo"

const {TabPane} = Tabs

export enum StudentServicesStaffTab {
    Workspace = "workspace",
    Activities = "activities",
    Students = "students",
    Attendance = "attendance"
}

type Props = {
    departmentId: BaseDepartmentId
}

const StudentServicesStaffView = (props: Props) => {
    const {t} = useTranslation(["studentServices"])
    const {isModuleEnable} = useEdularModulesContext()
    const [activeTabKey, onChangeTab] = useActiveTab(StudentServicesStaffTab.Workspace)
    const {departmentId} = props
    const [statsKey, setStatsKey] = React.useState(Math.random())

    const title = departmentId === BaseDepartmentId.Academics ? t("academicAffairs") : t("studentServices")

    const {
        student,
        setStudent,
        setStudentStatusChange,
        setFinancialAidStatusChange,
        studentDetailVisible,
        setEnrollmentProfileIdChange
    } = useContext(ActiveStudentContext)

    React.useEffect(() => {
        setStudent(undefined)
        studentDetailVisible.close()
    }, [activeTabKey])

    const goToStudentTab = useCallback(() => {
        onChangeTab(StudentServicesStaffTab.Students)
    }, [])

    return (
        <div className={styles.screenWrap}>
            <Screen htmlTitle={title} header={{title}} isReducingPaddingRight={studentDetailVisible.isVisible}>
                <Tabs className="fullwidth klassTab" activeKey={activeTabKey} onChange={onChangeTab}>
                    <TabPane
                        tab={<TabHeader icon="PIE_CHART" title={t("workspace")} />}
                        key={StudentServicesStaffTab.Workspace}>
                        <Workspace departmentId={departmentId} />
                    </TabPane>
                    {isModuleEnable(Modules.ActivitiesAndFollowups) && (
                        <TabPane
                            tab={<TabHeader icon="DOOR_OPEN" title={t("activities")} />}
                            key={StudentServicesStaffTab.Activities}>
                            <ActivitiesStaffTab departmentId={departmentId} />
                        </TabPane>
                    )}
                    {departmentId === BaseDepartmentId.Academics && (
                        <TabPane
                            tab={<TabHeader icon="PERSON" title={t("attendance.title")} />}
                            key={StudentServicesStaffTab.Attendance}>
                            <Attendance isShowAllMissedAttendance departmentId={BaseDepartmentId.StudentServices} />
                        </TabPane>
                    )}
                    <TabPane
                        tab={<TabHeader icon="PEOPLE" title={t("students")} />}
                        key={StudentServicesStaffTab.Students}>
                        <Students departmentId={departmentId} statsKey={statsKey} />
                    </TabPane>
                </Tabs>
            </Screen>

            <div className={cx(styles.expandedSidebar, {[styles.hidden]: !studentDetailVisible.isVisible})}>
                {!!student?.profileId && (
                    <StudentInfoSidebar
                        onClose={studentDetailVisible.close}
                        data={{
                            departmentId,
                            student,
                            isDetailButtonVisible: true,
                            detailUrl:
                                departmentId === BaseDepartmentId.Academics ? "academic-affairs" : "student-services",
                            fromPage:
                                departmentId === BaseDepartmentId.Academics ? "academic-affairs" : "student-services",
                            renderDepartmentInfo: (props) => (
                                <StudentServicesPanelInfo
                                    student={student}
                                    reloadStudent={() => {
                                        setStudent(undefined)
                                        studentDetailVisible.close()
                                    }}
                                    reloadStudentTable={(profileId, newProfileId) => {
                                        setEnrollmentProfileIdChange({
                                            profileId,
                                            newProfileId,
                                            departmentId
                                        })
                                        setStatsKey(Math.random())
                                    }}
                                    {...props}
                                />
                            ),
                            onChangeStudentStatus: setStudentStatusChange,
                            onChangeFinancialAidStatus: setFinancialAidStatusChange,
                            advisorTitle: AdvisorTitle.advisors
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default function StudentServicesStaff(props: Props) {
    return (
        <ActiveStudentProvider departmentId={props.departmentId}>
            <StudentServicesStaffView {...props} />
        </ActiveStudentProvider>
    )
}
