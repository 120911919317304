import {Timestamps} from "../common"
import {Auth} from "types/auth"
import {api_response} from "lib/gate/interfaces"

export enum WorkflowTriggerElement {
    DateStartDate = "date_start_date",
    DateDOB = "date_dob",
    DateProjectedGradDate = "date_projected_grad_date",
    DateActualGradDate = "date_actual_grad_date",
    DateSpecificDate = "date_specific_date",
    DatePaymentDueDate = "date_payment_due_date",
    NewStudentStatus = "new_student_status",
    StudentActivity = "student_activity",
    StudentADAAccommodations = "student_ada_accommodations",
    StudentClockStatus = "student_clock_status",
    StudentNumberOfTermsInSchool = "student_num_terms_in_school",
    GradesGPA = "grades_gpa",
    GradesCGPA = "grades_cgpa",
    AttendancePercentage = "attendance_percentage",
    AttendanceCompletedHours = "attendance_completed_hours",
    AttendanceMissedHours = "attendance_missed_hours",
    AttendanceRemainingHours = "attendance_remaining_hours",
    MissedAttendance = "missed_attendance",
    LastDayAttendance = "last_day_attendance",
    RiskOverall = "risk_overall",
    RiskCGPA = "risk_cgpa",
    RiskGPA = "risk_gpa",
    RiskCourseGrades = "risk_course_grades",
    RiskAttendancePercentage = "risk_attendance_percentage",
    RiskNumMissedHours = "risk_num_missed_hours",
    RiskLDA = "risk_lda",
    TasksCompletedTask = "tasks_completed_task",
    CalendarEventCompletion = "calendar_event_completion"
}

export enum WorkflowTriggerElementGroup {
    Date = "date",
    Student = "student",
    Grades = "grades",
    Attendance = "attendance",
    Risk = "risk",
    Tasks = "tasks",
    CalendarEvent = "calendarEvent"
}

export enum WorkflowTriggerElementUnit {
    Days = "days",
    Weeks = "weeks",
    Months = "months",
    Years = "years"
}

export enum WorkflowTriggerElementFrequency {
    Once = "once",
    Daily = "daily",
    Weekly = "weekly",
    BiWeekly = "bi-weekly"
}

export const TRIGGER_ELEMENT_GROUPS: Array<{
    group: WorkflowTriggerElementGroup
    elements: WorkflowTriggerElement[]
}> = [
    {
        group: WorkflowTriggerElementGroup.Date,
        elements: [
            WorkflowTriggerElement.DateStartDate,
            WorkflowTriggerElement.DateDOB,
            WorkflowTriggerElement.DateProjectedGradDate,
            WorkflowTriggerElement.DateActualGradDate,
            WorkflowTriggerElement.DateSpecificDate,
            WorkflowTriggerElement.DatePaymentDueDate
        ]
    },
    {
        group: WorkflowTriggerElementGroup.Student,
        elements: [
            WorkflowTriggerElement.NewStudentStatus,
            WorkflowTriggerElement.StudentActivity,
            WorkflowTriggerElement.StudentADAAccommodations,
            WorkflowTriggerElement.StudentClockStatus,
            WorkflowTriggerElement.StudentNumberOfTermsInSchool
        ]
    },
    {
        group: WorkflowTriggerElementGroup.Grades,
        elements: [WorkflowTriggerElement.GradesGPA, WorkflowTriggerElement.GradesCGPA]
    },
    {
        group: WorkflowTriggerElementGroup.Attendance,
        elements: [
            WorkflowTriggerElement.AttendancePercentage,
            WorkflowTriggerElement.AttendanceCompletedHours,
            WorkflowTriggerElement.AttendanceMissedHours,
            WorkflowTriggerElement.AttendanceRemainingHours,
            WorkflowTriggerElement.MissedAttendance,
            WorkflowTriggerElement.LastDayAttendance
        ]
    },
    {
        group: WorkflowTriggerElementGroup.Risk,
        elements: [
            // WorkflowTriggerElement.RiskOverall,
            WorkflowTriggerElement.RiskCGPA,
            WorkflowTriggerElement.RiskGPA,
            WorkflowTriggerElement.RiskCourseGrades,
            WorkflowTriggerElement.RiskAttendancePercentage,
            WorkflowTriggerElement.RiskNumMissedHours,
            WorkflowTriggerElement.RiskLDA
        ]
    },
    {
        group: WorkflowTriggerElementGroup.Tasks,
        elements: [WorkflowTriggerElement.TasksCompletedTask]
    },
    {
        group: WorkflowTriggerElementGroup.CalendarEvent,
        elements: [WorkflowTriggerElement.CalendarEventCompletion]
    }
]

export enum AutomatedWorkflowActionType {
    Message = "message",
    Task = "task",
    CalendarEvent = "calendar_event",
    NewStatusChange = "new_status_change",
    AddCheckList = "add_checklist",
    AddStudentActivity = "add_student_activity",
    NotificationOnly = "notif_only"
}

export type AutomatedWorkflow = {
    targets: WorkflowTarget[]
    triggers: WorkflowTrigger[]
    actions: WorkflowAction[]
}

export type WorkflowTarget = {
    readonly id: number
    readonly order: number
    type: Auth.UserProfileType
    startDate?: string
    contractEndDateFrom?: string | null
    contractEndDateTo?: string | null
    projectedEndDateFrom?: string | null
    projectedEndDateTo?: string | null
    campusIds?: number[]
    programIds?: number[]
    programVersionIds?: number[]
    statusIds?: number[]
    studentStatusIds?: number[]
    groupIds?: number[]
    roleIds?: number[]
    roles?: any[]
    departmentSubunitIds?: number[]
    advisorIds?: number[]
    advisorProfileIds?: number[]
    advisorRoleId?: number
    usersFromTask?: boolean
    usersFromCalendarEvent?: boolean
    leadsOfProgramIds?: number[]
    leadsOfPrograms?: api_response.Program[]
}

export type WorkflowTrigger = {
    readonly id: number
    readonly order: number
    element: WorkflowTriggerElement
    operator?: string
    value?: string
    extra?: string
    unit?: WorkflowTriggerElementUnit
    frequency?: WorkflowTriggerElementFrequency
}

export type WorkflowAction = {
    readonly id: number
    readonly order: number
    type: AutomatedWorkflowActionType
    value: string
}

export type StudentDepartmentStatus = Timestamps & {
    statusId: number
    name: string
    admissionCategory: string
    stageId: number
    departmentId?: number
    stage?: {
        stageId: number
        departmentId: number
        name: string
        state: string
    }
}
