/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo} from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdownAsyncProps} from "../Select"
import {KlassDropdown} from "components/inputs"
import {useProgramVersions} from "hooks"
import {orderBy} from "lodash"

type Props = Partial<KlassDropdownAsyncProps> & {
    programId?: number | number[]
    isActive?: boolean
}

const ProgramVersionSelect: React.FC<Props> = ({programId, value, isActive = true, ...props}) => {
    const {t} = useTranslation(["common"])

    const {programVersions} = useProgramVersions({programId, isActive})

    const groupedOptions = useMemo(() => {
        const _options: any[] = []
        const activeOptions = orderBy(
            programVersions.filter((x) => x.isActive === 1),
            ["version_code", "version_name"],
            ["asc", "asc"]
        )
        const inactiveOptions = orderBy(
            programVersions.filter((x) => x.isActive !== 1),
            ["version_code", "version_name"],
            ["asc", "asc"]
        )
        if (activeOptions.length) {
            _options.push({
                label: "Active",
                options: activeOptions
            })
        }
        if (inactiveOptions.length) {
            _options.push({
                label: "Inactive",
                options: inactiveOptions
            })
        }
        return _options
    }, [programVersions])

    return (
        <KlassDropdown
            {...props}
            key={JSON.stringify({programId})}
            options={groupedOptions}
            placeholder={props.placeholder || t("selectField.placeholder")}
            valueKey="id"
            labelKey="version_name"
            getOptionLabel={(option) =>
                `${option.version_name || option.versionName} (${option.version_code || option.versionCode})`
            }
            value={
                typeof value === "number"
                    ? programVersions.find((version) => version.id === value)
                    : Array.isArray(value) && typeof value[0] === "number"
                    ? programVersions.filter((version) => value.includes(version.id))
                    : value
            }
        />
    )
}

export default ProgramVersionSelect
