import {memo} from "react"
import {SapAlertStats} from "types/sap-alerts"
import styles from "./SapAlertStatsView.module.css"
import {useQuery} from "@tanstack/react-query"
import {academicPlansService} from "services"
import {handleError} from "helpers"
import {Spin} from "antd"

type SapAlertStatsViewProps = {
    studentProfileId: number
}

export const SapAlertStatsView = memo(({studentProfileId}: SapAlertStatsViewProps) => {
    const {data: sapAlertStats, isFetching: isFetchingSapAlertStats} = useQuery<SapAlertStats>(
        ["sap-alert-stats", studentProfileId],
        () =>
            academicPlansService.getSapAlertStats({
                studentProfileIds: studentProfileId ? [studentProfileId] : undefined
            }),
        {
            initialData: {
                numberOfSapAlertsCgpa: 0,
                numberOfSapAlertsCompletionRate: 0,
                numberOfSapAlertsMaximumTimeframe: 0,
                numberOfSapAlertsAttendance: 0,
                numberOfCurrentTermCourseGradeRiskLevel: 0,
                numberOfCurrentTermAttendanceRiskLevel: 0
            },
            onError: handleError
        }
    )

    return (
        <div className={styles.stats}>
            {[
                ["CGPA", sapAlertStats.numberOfSapAlertsCgpa],
                ["Completion Rate", sapAlertStats.numberOfSapAlertsCompletionRate],
                ["Maximum Timeframe", sapAlertStats.numberOfSapAlertsMaximumTimeframe],
                ["Attendance", sapAlertStats.numberOfSapAlertsAttendance],
                ["Current Term Course Grade", sapAlertStats.numberOfCurrentTermCourseGradeRiskLevel],
                ["Current Term Attendance Percentage", sapAlertStats.numberOfCurrentTermAttendanceRiskLevel]
            ].map(([label, value]) => (
                <div className={styles.statsBox} key={label}>
                    <span className={styles.statsTitle}>{label}</span>
                    <span className={styles.statsValue}>{value}</span>
                </div>
            ))}

            {isFetchingSapAlertStats ? (
                <div className={styles.loadingWrap}>
                    <Spin />
                </div>
            ) : null}
        </div>
    )
})
